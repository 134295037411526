<template functional>
    <div
        :title="props.content"
        class="group-sport-header__footer"
    >
        {{ props.content }}
    </div>
</template>

<script>
    export default {
        functional: true,
        name: 'SectionFooterRow',
        props: {
            content: {
                type: String,
                required: true,
            },
        },
    };
</script>

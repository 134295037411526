<template>
    <div class="lucky-loser-wrapper" :class="{ 'smaller-height': smallerHeight }">
        <div class="lucky-loser-notification">
            <i class="icon-lucky icon--md nav-sidebar-tree__icon icon-pariu-sansa">
            </i>
            <div class="text" v-html="notification">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "LuckyLoserNotification",
    props: {
        isLuckyLoser: {
            default: false,
            required: false
        }
    },
    computed: {
      smallerHeight() {
          return this.notification.length < 80;
      },
      notification() {
          return this.isLuckyLoser ? this.$t('Progressive Lucky Loser is ACTIVE')
              : this.$t('Your ticket is not eligible for Progressive Lucky Loser if there are in-play selections, ' +
                  'selections with an odd lower than 1.30 or selections that are part ' +
                  'of the Super Cota/ Super extra categories.')
      }
    }
}
</script>

import common from '@src/config/common';
import Singleton from '@core/services/common/Singleton';
import { instanceGuard } from '@core/utils/services';
import ServerConfig from '@core/models/country/ServerConfig';
import HostService from './_private/HostService';
import router, { links, routeNames } from '@src/app/router';
import Modals from 'src/app/store/modules/ui/shared/modal/modals';
import store from '@app/store';
import RetailService from '../retail/RetailService';
import { prepareTicketForPrintV2 } from './ticket';
import Ticket from '@core/models/tickets/Ticket';
import { get as _get } from '@lodash';
import i18n from '@src/app/localization/i18n';
import { isConfigError, openBettingServiceErrorModal } from './utils';
import BettingAvailableService from '../betting/BettingAvailableService';
import { NotReadySource } from '../betting/_private/enums';
import { INotification, IPlatform } from '../platform/IPlatform';
import recreateTicket from './_private/RecreateTicket';
import FeatureFlagService from '../flags/FeatureFlagService';
import * as Sentry from '@sentry/vue';
import { PlatformConfig } from './models/config/PlatformConfig';
import { BalanceChange, ErrorData, HostPayload, ITerminalConfig, OnStatus, PrintOutcome } from './models/Payload';
import { prepareTicketForPrintV1 } from '../gravity/helpers/ticket';

class ProductService implements IPlatform {
  private static instance: ProductService;
  private slaveId: string;
  private terminalBalance: number;
  private terminalConfiguration: any = {};
  private authToken: string = '';

  // Inactivity monitor variables
  private inactivityTimer!: NodeJS.Timeout;

  private constructor(private config: ServerConfig) {
    BettingAvailableService.getInstance().set(NotReadySource.Printer, {
      reason: 'default printer off',
      available: false,
    });
    BettingAvailableService.getInstance().set(NotReadySource.RetailConn, {
      reason: 'default connection to retail',
      available: false,
    });

    if(!window.name) {
      if(!common.environment.isDevelopment) {
        Sentry.captureMessage('ProductService: slaveId empty', Sentry.Severity.Error);
        
        openBettingServiceErrorModal(NotReadySource.RetailConn, 'Product is not set');
      } else {
        // TODO: improve BettingAvailableService get
        // On dev, unset printer source to show RetailConn err on ticket submit
        BettingAvailableService.getInstance().unset(NotReadySource.Printer);
      }

      return;
    }

    this.setSlaveId(window.name);
    console.log('SSBT::Slave Id set to >>', this.slaveId);
    HostService.connect('http://localhost:3000', { query: { productName: this.slaveId } });
    HostService.onConnected((payload: any) => {
      console.log(payload);
      Sentry.setTag('productId', this.getSlaveId());
    });

    const initV1 = async (config: ITerminalConfig) => {
      const { retailDetails, storeId, terminalId, authToken } = config;
      localStorage.setItem('storeId', storeId);
      localStorage.setItem('terminalId', terminalId);
      localStorage.setItem('authToken', authToken);
      Sentry.setTag('storeId', storeId);
      Sentry.setTag('terminalId', terminalId);
      this.terminalConfiguration = {
        storeId,
        terminalId,
        retailDetails,
        authToken: '',
      };
      this.authToken = authToken;
    };

    // TODO: remove this
    const initV2 = async (config: PlatformConfig) => {
      const { retailDetails, authToken } = config;
      const storeCode = retailDetails?.shop?.code;
      const terminalCode = retailDetails?.terminal?.code;
      localStorage.setItem('storeId', storeCode);
      localStorage.setItem('terminalId', terminalCode);
      localStorage.setItem('authToken', authToken);
      Sentry.setTag('storeId', storeCode);
      Sentry.setTag('terminalId', terminalCode);
      this.terminalConfiguration = {
        storeId: storeCode,
        terminalId: terminalCode,
        retailDetails,
        authToken: '',
      };
      this.authToken = authToken;
    };

    HostService.onIntegrationToken((payload: any) => {
      const { authToken } = payload?.data;

      if (authToken) {
        this.authToken = authToken;
      }
    });

    HostService.onTerminalConfig(async (payload: HostPayload<PlatformConfig | ITerminalConfig | ErrorData>) => {
      if (!payload?.data || isConfigError(payload.data)) {
        if(!common.environment.isDevelopment) {
          const message = payload.data ? `ProductService: ${(payload.data as ErrorData).error}` : 'ProductService: Received config is empty';

          Sentry.captureMessage(message, {
            extra: {
              payload
            },
            level: Sentry.Severity.Error
          })
        }

        openBettingServiceErrorModal(NotReadySource.RetailConn, 'Config is not valid');
        return;
      };

      const config = payload.data;

      try {
        await FeatureFlagService.getInstance().setInHouseCtx(config.retailDetails.terminal, config.retailDetails.shop);
        store.dispatch('data/flags/initFlags', null, { root: true });
      } catch (error) {
        console.error('FeatureFlagService failed to initialize', error);
      } finally {
        // Initialize the inactivity monitor
        this.setupInactivityMonitor();
      }

      await (FeatureFlagService.getInstance().usePlatformV2()
        ? initV2(config as PlatformConfig)
        : initV1(config as ITerminalConfig));

      try {
        if (this.getSlaveId() !== 'CheckTicket') {
          RetailService.createInstance(this.config, this.getSlaveId());
        }
      } catch (error) {
        console.error('RetailService failed to initialize', error);
      }
    });
    HostService.onBalanceChanged((payload: HostPayload<BalanceChange>) => {
      console.log('SSBT::Balance changed >>', payload);
      const balance = _get(payload, 'data.balance', null);
      this.setTerminalBalance(balance);
    });
    HostService.onScanOutcome((payload: any) => {
      console.log('SSBT::Scan outcome >>', payload);
      const {
        data: { value },
      } = payload;
      this.checkTicket(value);
    });
    HostService.onPrintTicketOutcome((payload: HostPayload<PrintOutcome>) => {
      console.log('SSBT::Print ticket outcome >>', payload);
      const { status } = payload;

      if (status >= 200 && status < 300) {
        const title = i18n.t('print success').toString().toUpperCase();
        const text = i18n.t('ticket print success').toString().toUpperCase();
        this.sendNotification({ title, text, type: 'success' });
      } else {
        const title = i18n.t('ticket error').toString().toUpperCase();
        const text = i18n.t('ticket failed to print').toString().toUpperCase();
        this.sendNotification({ title, text, type: 'error' });
      }

      store.dispatch('data/tickets/setTicketSubmitLoading', false);
    });
    HostService.onTicketRebetOutcome((payload: any) => {
      console.log('SSBT::Rebet ticket outcome >>', payload);
      const {
        data: { value },
      } = payload;
      recreateTicket(value);
    });
    HostService.onStatus((payload: HostPayload<OnStatus>) => {
      console.log('SSBT::Status Update Received', payload);
      store.dispatch('data/country/setStatuses', { payload: payload.data }, { root: true });
    });
    HostService.onNavigate((payload: any) => {
      console.log('SSBT::On navigate ~>', payload);
      const product = _get(payload, 'data.value.product.id', null);
      const ticketId = _get(payload, 'data.data.ticketId', null);
      if (ticketId) {
        this.setupInactivityMonitor();
        router.push({ path: links.checkTicket }, () => {});
      } else if (product && product === 'SuperbetInPlay' && window.name === 'SuperbetPrematch') {
        router.push({ path: links.live }, () => {});
        this.setupInactivityMonitor();
      } else if (product && product === 'SuperbetPrematch' && window.name === 'SuperbetPrematch') {
        router.push({ path: links.landing }, () => {});
        this.setupInactivityMonitor();
      } else if (product && product === 'HomeScreen') {
        // If the Sportsbook is being navigated to the HomeScreen, start the activity monitor.
        clearTimeout(this.inactivityTimer);
        this.checkTicket('CLEAR');
      } else {
        // If the Sportsbook is being navigated away from, catch all, stop the activity monitor.
        clearTimeout(this.inactivityTimer);
      }
    });
    HostService.onCardSerialNumberOutcome((payload: any) => {
      console.log('SSBT::On card serial number ~>', payload);
      const serialNumber = _get(payload, 'data.value', '');
      store.dispatch('data/country/setUserCardSerialNumber', serialNumber, { root: true });
    });
    HostService.emitTerminalConfigRequest(window.name);
    HostService.emitBalanceStatusRequest();
    HostService.emitStatusRequest();

    BettingAvailableService.getInstance().unset(NotReadySource.Printer);
  }

  // Method to setup inactivity listeners and timer
  private setupInactivityMonitor(): void {
    const value = FeatureFlagService.getInstance().inactivityTimerValue();
    if (value <= 0) return;

    // Reset timer on various events
    const resetTimer = () => {
      clearTimeout(this.inactivityTimer);
      this.inactivityTimer = setTimeout(this.triggerInactivityAction, value);
    };

    // Event listeners to detect activity
    window.addEventListener('mousemove', resetTimer);
    window.addEventListener('click', resetTimer);
    window.addEventListener('keydown', resetTimer);
    window.addEventListener('touchstart', resetTimer);
    window.addEventListener('touchmove', resetTimer);
    window.addEventListener('touchend', resetTimer);

    // Start the timer initially
    resetTimer();
  }

  // Method to trigger inactivity alert after timeout
  private triggerInactivityAction = (): void => {
    console.log('SSBT::Inactivity detected');
    HostService.emitNavigateRequest('HomeScreen');
  };

  public refreshAuth(): void {
    HostService.emitIntegrationTokenRequest(window.name);
  }

  public async resetBetSlip() {
    await store.dispatch('ui/sportOffer/betSlip/setAutoUpdateChanges', true, { root: true });
    await store.dispatch('ui/sportOffer/betSlip/clear', null, { root: true });
  }

  getGravityAuth(): string {
    throw new Error('Method not implemented.');
  }
  postMessage(message: any, source?: any): void {
    throw new Error('Method not implemented.');
  }

  public static getInstance(): ProductService {
    return instanceGuard(ProductService.instance);
  }

  public static createInstance(config: ServerConfig) {
    if (!ProductService.instance) {
      ProductService.instance = new ProductService(config);
    }
    return ProductService.instance;
  }

  public requestBalanceStatusRequest() {
    HostService.emitBalanceStatusRequest();
  }

  private checkTicket(id: string) {
    store.dispatch('data/country/setCheckedTicket', { id, timeChecked: Date.now() }, { root: true });
  }

  public getTerminalConfiguration(): any {
    return this.terminalConfiguration;
  }

  public getAuthToken(): string {
    return this.authToken;
  }

  public getDeviceUUID() {
    return this.terminalConfiguration.retailDetails.terminal.externalId;
  }

  public setDeviceUUID(uuid: string) {}

  public getSlaveId(): string {
    return this.slaveId;
  }
  public setSlaveId(value: string) {
    this.slaveId = value;
  }

  public areFoundsAvailable(amount: number): boolean {
    console.log('SSBT::Current Terminal Balance', this.getTerminalBalance());
    return this.getTerminalBalance() >= amount;
  }

  public getTerminalBalance(): number {
    return this.terminalBalance;
  }

  public setTerminalBalance(value: number) {
    this.terminalBalance = value;
  }

  public checkTicketExit() {
    const name = routeNames.landing;
    router.push({ name });
  }

  public sendNotification({ title, text, type }: INotification) {
    const notification = {
      title,
      text,
      type: type || 'info',
    };
    HostService.emitNotificationDispatchRequest(notification);
  }

  public checkTicketCancel = (ticketId?: string) => {
    const root = { root: true };
    const config = {
      code: Modals.cancelTicket.code,
      containerClass: 'cancle-ticket-modal--terminal',
      data: {
        ticketId,
        button: {
          label: 'confirm',
          enabled: true,
          className: 'btn modal-btn--terminal btn--block btn--cancel-ticket',
        },
      },
    };
    store.dispatch('ui/modal/setModal', config, root);
  };

  public checkPayoutTicket(ticketId?: string) {
    const root = { root: true };
    const config = {
      code: Modals.payoutTicket.code,
      containerClass: 'payout-ticket-modal--terminal',
      data: {
        ticketId,
        button: {
          label: 'confirm',
          enabled: true,
          className: 'btn modal-btn--terminal btn--block btn--payout-ticket',
        },
      },
    };
    store.dispatch('ui/modal/setModal', config, root);
  }

  public checkTicketRecreateRequest(ticketId: string, product: string) {
    HostService.emitTicketRebetRequest({ ticketId, productId: product });
  }

  public async sendTicketPrint(ticket: Ticket | any): Promise<any> {
    const preparedTicket = await (FeatureFlagService.getInstance().usePlatformV2()
      ? prepareTicketForPrintV2(ticket, this.config)
      : prepareTicketForPrintV1(ticket, this.config));
    console.log('SSBT::Prepared Ticket for Print >>', preparedTicket);
    HostService.emitPrintTicketRequest(preparedTicket);
    return preparedTicket;
  }

  public scanTicket(value: string) {
    const payload = { data: { value: value.toUpperCase() } };
    console.log('SSBT::Manual Input As Scan ~>', payload);
    HostService.emitScanRequest(payload);
  }

  public static clearInstance() {
    if (process.env.NODE_ENV !== 'test') {
      throw new Error('For use in tests only');
    }
    delete (ProductService as any).instance;
  }
}

export default ProductService as Singleton<ProductService>;

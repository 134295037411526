import Odd from '@core/models/offer/Odd';

/**
 *  A comparator used for odd sorting. Sorts by odd order, then by odd id.
 *
 * @param x The first argument for the comparison.
 * @param y The second argument for the comparison.
 * @returns 1 if the first odd is greater than the second, -1 if the first odd is smaller than the second, 0 if both
 *          odds are of equal priority.
 */
export default function oddComparator(x: Odd, y: Odd): number {
    if (x.order < y.order) {
        return -1;
    }
    if (x.order > y.order) {
        return 1;
    }
    if (x.id < y.id) {
        return -1;
    }
    if (x.id > y.id) {
        return 1;
    }

    return 0;
}

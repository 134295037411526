<template>
    <ul class="link-list__items--upercase">
        <li
            v-for="link in links"
            :key="link.label"
            class="link-list__item"
        >
            <component
                v-bind="link"
                :is="component(link)"
                :class="{'external-link': link.type === 'external' }"
            />
        </li>
    </ul>
</template>

<script>
    import ExternalLink from '@shared/components/ExternalLink.vue';
    import StaticLink from '@shared/components/StaticLink.vue';
    import CustomRouterLink from '@modules/layout/footer/components/CustomRouterLink.vue';
    import AboutSuperbetLinks from '@countryModules/layout/footer/components/AboutSuperbetLinks';

    export default {
        data() {
            return {
                links: AboutSuperbetLinks(),
            };
        },
        methods: {
            component(link) {
                const components = {
                    external: ExternalLink,
                    static: StaticLink,
                    route: CustomRouterLink
                };
                return components[link.type];
            },
        },
    };
</script>

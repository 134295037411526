<template>
    <div
        class="event-row__expanded-market-header--terminal"
        @click.stop="$emit('toggleExpanded')"
    >
        <span class="event-row__expanded-market-title--terminal">
            {{ isOutright ? market.outrightMarketName : market.name }}
        </span>
        <div class="event-row__expanded-market-actions">
            <i
                @click.stop="$emit('toggleDescription')"
                :title="$t('bets info') | capitalize"
                class="
                                        icon--sm
                                        event-row__expanded-market-icon
                                        icon-info_circle
                                        mr-2
                                        event-sport-summary_icon
                                        "
            >
            </i>
            <MarketIcon
                v-if="isCollapsingEnabled === false"
                size="xl"
                @click.stop="$emit('toggleExpanded')"
                :class="[
                    'event-row__expanded-market-icon',
                    isExpanded ? 'icon-chevron_up' : 'icon-chevron_down',
                ]"
            />
        </div>
    </div>
</template>
<script>
    import MarketIcon from './MarketIcon.vue';

    export default {
        props: {
            market: {
                type: Object,
                required: true,
            },
            isOutright: {
                type: Boolean,
                default: false,
            },
            isCollapsingEnabled: {
                type: Boolean,
                required: false,
                default: false,
            },
            isExpanded: {
                type: Boolean,
                required: false,
                default: false,
            },
        },
        components: {
            MarketIcon
        }
    };
</script>

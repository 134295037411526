import AbstractMapper from '@core/utils/mappers/AbstractMapper';
import IBannerDto from '@core/mappers/banners/dtos/IBannerDto';
import { sumBy as _sumBy } from '@lodash';
import Banner from '@core/models/banners/Banner';
import { ActionType } from '@core/models/banners/enums';
import config from '@config';

type Link = { LINK: string, BLANK: string | [] };

export default class BannerMapper extends AbstractMapper<IBannerDto, Banner> {

    idSeed = 0;

    createTargetObject(dto: IBannerDto): Banner {
        const banner = new Banner();
        banner.title = dto.NAME;
        banner.image = this.createImageUrl(dto);
        banner.description = dto.DESCRIPTION;
        banner.link = this.extractLink(dto);
        banner.linkDescription = dto.LINKTITLE;
        banner.actionType = this.extractActionType(dto);
        banner.id = this.createSimpleUUID(dto);
        return banner;
    }

    createSimpleUUID(bannerDto: IBannerDto) {
        const hashedData = bannerDto.LINKTITLE + bannerDto.NAME;
        return _sumBy(hashedData, (letter) => letter.charCodeAt(0));
    }

    extractLink(dto: IBannerDto): string {
        return dto.POPUP ? (dto.POPUP as { LINK: string }).LINK : (dto.DEFAULT as Link).LINK;
    }

    /**
     * This logic is consequence of terrible mess in CMS.
     * If POPUP is defined then click on banner should invoke popup.
     * If DEFAULT.BLANK is defined then click on banner should invoke new tab.
     * By default click on banner should open in app link.
     */
    extractActionType(dto: IBannerDto): ActionType {
        if (dto.POPUP) {
            return ActionType.openPopup;
        }
        const link = dto.DEFAULT as Link;
        if (!link.BLANK) {
            return ActionType.openInAppLink;
        }
        return ActionType.openNewTab;
    }

    createImageUrl(dto: IBannerDto): string {
        return `${config.services.country.frontEndHost}${dto.IMGSRC}`;
    }
}

import BannerMapper from '@core/mappers/banners/BannerMapper';
import IResponse from '@core/utils/types/IResponse';
import IBannerDto from '@core/mappers/banners/dtos/IBannerDto';
import Banner from '@core/models/banners/Banner';
import * as NetworkUtils from '@utils/network/NetworkUtils';
import ServerConfig from '@core/models/country/ServerConfig';
import { instanceGuard } from '@core/utils/services';
import SingletonError from '../common/errors/SingletonError';
import Singleton from '../common/Singleton';
import RestService from './_private/RestService';

class Mappers {
    bannerMapper: BannerMapper = new BannerMapper();
}

export class BannersService {
    private mappers: Mappers = new Mappers();
    private static instance: BannersService;

    public static getInstance(): BannersService {
        return instanceGuard(BannersService.instance);
    }

    public static createInstance(config: ServerConfig) {
        if (!BannersService.instance) {
            BannersService.instance = new BannersService(config);
        }
        return BannersService.instance;
    }

    private constructor(
        private config: ServerConfig,
        private restService = new RestService(config),
    ) {
        if (BannersService.instance) {
            throw new SingletonError(this.constructor.name);
        }
    }

    /**
     * @throws {RequestError}
     * @throws {Error}
     */
    public async getBannersByCategory(category: string): Promise<Banner[]> {
        const response: IResponse<IBannerDto[]> = await NetworkUtils.processResponse(
            this.restService.getBannersByCategory(category)
        );
        return this.mappers.bannerMapper.map(response.data, true);
    }

    public static clearInstance() {
        if (process.env.NODE_ENV !== 'test') {
            throw new Error('For use in tests only');
        }
        delete BannersService.instance;
    }
}

export default BannersService as Singleton<BannersService>;

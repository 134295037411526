<template>
    <div class="nav-sidebar-sport__tab-group">
        <div
            :class="{ active: isSportOffer }"
            class="tab-item--terminal"
            @click="$emit('selectionChanged', routeNames.sports)"
        >
            <div>
                {{ $t('sports') | capitalize }}
            </div>
        </div>
    </div>
</template>

<script>
    import { routeNames } from 'src/app/router';

    export default {
        name: 'SidebarTabs',
        props: {
            isOuterSelected: {
                type: Boolean,
                required: true,
            },
        },
        created() {
            this.routeNames = {
                sports: routeNames.sportOffer,
            };
        },
        computed: {
            isSportOffer() {
                const currentRoute = this.$router.currentRoute.name;

                return !this.isOuterSelected
                    && (currentRoute === routeNames.sportOffer
                    || currentRoute === routeNames.landing
                    || currentRoute === routeNames.live);
            },
        },
    };
</script>

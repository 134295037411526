<template>
    <tbody>
        <template v-if="sportsOrSpecials(data.ticket.type)">
            <tr
                v-for="(selection, index) in data.ticket.events"
                :key="index + selection.eventId"
                :class="eventStatusClass(selection.status)"
            >
                <td colspan="1" class="p-l-1 p-y-1">{{ sportEventProgress(selection) }}</td>
                <td colspan="2" class="p-y-1">{{ selection.inputCode.event }}</td>
                <td colspan="1" class="p-y-1">{{ displayFix(selection) }}</td>
                <td colspan="1" class="p-y-1">
                    {{ cleanUndefined(displayName(selection)) }}
                </td>
                <td colspan="1" class="p-y-1">{{ displayDate(selection) }}</td>
                <td colspan="1" class="p-y-1">
                    {{ selection.odd.name }}
                </td>
                <td colspan="1" class="p-y-1">
                    <template>
                        N/A
                    </template>
                </td>
                <td v-if="selection.status !== 'refund'" colspan="1" class="p-y-1">
                    {{ numberFormat(selection.odd.coefficient) }}
                </td>
                <td v-else colspan="1" class="p-y-1">
                    1,00
                </td>
            </tr>
        </template>
    </tbody>
</template>

<script>
    import { localeNumberFormat } from '@src/terminal/core/services/gravity/helpers/math';
    import { isSportsOrSpecials } from '@src/terminal/app/modules/checkTicket/util';
    import liveBettingSpecifiersFormatter from '@core/services/offer/_private/LiveBettingSpecifiersFormatter';
    import OfferService from '@core/services/offer/OfferService';
    import { partition as _partition } from '@lodash';

    export default {
        name: 'TicketBody',
        props: {
            data: Object,
        },
        data() {
            return {
                reqEvents: [],
            };
        },
        methods: {
            numberFormat: localeNumberFormat,
            sportsOrSpecials: isSportsOrSpecials,
            sportEventProgress(event) {
                const now = new Date();
                const eventStartDate = new Date(event.date);
                const isDefaultStatus = ['lost', 'win', 'refund'].includes(event.status);
                if (now.getTime() > eventStartDate.getTime() && !isDefaultStatus) {
                    return this.$t('inProgress').toUpperCase();
                }
                return this.$t(event.status).toUpperCase();
            },
            eventStatusClass(eventStatus) {
                switch (eventStatus) {
                    case 'win':
                        return 'win';
                    case 'lost':
                        return 'lost';
                    default:
                        return 'active';
                }
            },
            displayFix(event) {
                return event.odd.isFix ? this.$t('fix') : '';
            },
            cleanUndefined(string) {
                return string.replace('undefined', '');
            },
            displayName(event) {
                return `${event.name[0]}, ${event.name[1]}`;
            },
            displayDate(event) {
                const date = new Date(event.date);
                return `${date.getDate()}. ${date.getMonth() + 1}. ${date.getFullYear()}`;
            }
        },
    };
</script>

<template>
    <div>
        <slot v-if="arePartsInMaintenance" name="message"></slot>
        <slot v-else/>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'MaintenanceChecker',
        props: {
            checkedParts: {
                type: Array,
                required: true
            },
        },
        watch: {
            arePartsInMaintenance(value) {
                if (value) {
                    this.closeOpenedEvents();
                    this.scrollToTop();
                }
            }
        },
        computed: {
            ...mapGetters('data/country', [
                'config',
                'disabled'
            ]),
            arePartsInMaintenance() {
                return this.checkedParts.some((part) => !!this.config.applicationStatus.maintainedParts[part]);
            },
            // arePartsInMaintenance() {
            //     return this.disabled;
            // },
        },
        methods: {
            closeOpenedEvents() {
                this.$router.push({ path: this.$route.path });
            },
            scrollToTop() {
                window.scrollTo(0, 0);
            }
        }
    };
</script>

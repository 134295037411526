import ServerConfig from '@core/models/country/ServerConfig';
import TicketService from '@core/services/tickets/TicketService';
import OfferService from '@core/services/offer/OfferService';
import ResultsService from '@core/services/results/ResultsService';
import SportValidationService from '@core/services/betSlip/validation/sport/SportValidationService';
import MaintenanceService from '@core/services/maintenance/MaintenanceService';
import StructService from '@core/services/offer/StructService';
import BannersService from '@core/services/banners/BannersService';
import TaxService from '@core/services/betSlip/TaxService';
import SuperbetsService from '@core/services/superbets/SuperbetsService';
import PayoutService from '@core/services/betSlip/PayoutService';
import AllowancesService from '@src/terminal/core/services/allowances/AllowancesService';
import PlatformService from '@src/terminal/core/services/platform/PlatformService';
import BettingAvailableService from '@src/terminal/core/services/betting/BettingAvailableService';
import FeatureFlagService from '@src/terminal/core/services/flags/FeatureFlagService';
import VirtualTournamentService from "@app/competitions/services/VirtualTournamentService";
import BetBuilderService from '@src/terminal/core/services/betBuilder/BetBuilderService';

export default class ServerConfigProvider {

    private static config: ServerConfig;

    public static setConfig(config: ServerConfig) {
        this.config = config;

        // a list of all services where we need to call `setConfig` after getting server config
        const configurableServices = [
            MaintenanceService,
            ResultsService,
            SportValidationService,
            TicketService,
            BannersService,
            TaxService, // TODO needs to be injected. Not standalone
            SuperbetsService,
            PayoutService,
            BetBuilderService
        ];

        configurableServices.forEach(s => {
            s.createInstance(config);
        });

        BettingAvailableService.createInstance(config);
        FeatureFlagService.createInstance(config);
        PlatformService.createInstance(config);
        VirtualTournamentService.createInstance(config);

        AllowancesService.getInstance().initAllowancesFn();
        StructService.createInstance(config);
        OfferService.createInstance(config);


        TaxService.getInstance();
    }

    public static getConfig(): ServerConfig {
        return this.config;
    }
}

<template>
    <div
        class="event-row-container"
        v-on="$listeners"
    >
        <div class="event-row-result--terminal">
            <div class="event-row__layout--terminal">
                <div class="event-summary--terminal">
                    <div class="event-summary__match-indicator-wrapper--terminal">
                        <div class="date-wrapper">
                            {{ date | formatDateTime('HHMM') }}
                        </div>
                    </div>
                    <div class="event-summary__competitors-and-scores">
                        <div class="event-summary__competitors-wrapper--terminal">
                            <span
                                :title="team1Name"
                                class="event-summary__competitors-team1"
                            >
                                {{ team1Name }}
                                <i
                                    v-if="results.isGoingToNextRound === 1"
                                    class="icon icon--xs icon-thumbs_up results__competitor-winner-icon"
                                />
                            </span>
                            <span
                                :title="team2Name"
                                class="event-summary__competitors-team2"
                            >
                                {{ team2Name }}
                                <i
                                    v-if="results.isGoingToNextRound === 2"
                                    class="icon icon--xs icon-thumbs_up results__competitor-winner-icon"
                                />
                            </span>
                        </div>
                        <div class="event-summary__score-wrapper left">
                            <div class="live-score-widget server-0">
                                <div
                                    v-for="(score, i) in results.score"
                                    :key="i"
                                    class="live-score-widget__column uncolored"
                                >
                                    <template v-if="score.value">
                                        <div class="live-score-widget__item">
                                            {{ score.value.team1Score }}
                                        </div>
                                        <div class="live-score-widget__item">
                                            {{ score.value.team2Score }}
                                        </div>
                                    </template>
                                </div>
                                <div
                                    v-if="results.winner"
                                    class="live-score-widget__column uncolored"
                                >
                                    <div class="live-score-widget__item">
                                        <span
                                            v-if="results.winner == 1"
                                            class="results__winner-indicator"
                                        >W</span>
                                    </div>
                                    <div class="live-score-widget__item">
                                        <span
                                            v-if="results.winner == 2"
                                            class="results__winner-indicator"
                                        >W</span>
                                    </div>
                                </div>
                                <div
                                    v-else-if="results.finalScore"
                                    class="live-score-widget__column uncolored"
                                >
                                    <div class="live-score-widget__item">
                                        {{ results.finalScore.team1Score }}
                                    </div>
                                    <div class="live-score-widget__item">
                                        {{ results.finalScore.team2Score }}
                                    </div>
                                </div>
                                <div
                                    v-else-if="results.totalPoints"
                                    class="live-score-widget__column uncolored"
                                >
                                    <div class="live-score-widget__item">
                                        {{ results.totalPoints }}
                                    </div>
                                    <div class="live-score-widget__item">
                                        {{ results.totalPoints }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Markets from './Markets.vue';

    export default {
        name: 'MatchRow',
        mounted() {
            if (this.isExpanded) {
                this.$emit('rowHeightChanged', this.$el.clientHeight);
            }
        },
        updated() {
            if (this.isExpanded) {
                this.$emit('rowHeightChanged', this.$el.clientHeight);
            }
        },
        props: {
            id: {
                type: Number,
                required: true,
            },
            team1Name: {
                type: String,
                required: true,
            },
            team2Name: {
                type: String,
                required: true,
            },
            code: {
                type: Number,
                required: true,
            },
            date: {
                type: Date,
                required: true,
            },
            results: {
                type: Object,
                required: true,
            },
            isLoading: {
                type: Boolean,
                required: true,
            },
            components: {
                type: Object,
                default: () => ({
                    Markets,
                })
            }
        },
    };
</script>

<template>
    <button
        :disabled="!value || isEventLocked || isLocked"
        :class="['pick-sport actionable' +
            (!value || isEventLocked || isLocked ? ' is-locked' : '') +
            (valueChangeDynamics && valueChangeDynamics.oddDirection === 1 ? ' up1' : '') +
            (valueChangeDynamics && valueChangeDynamics.oddDirection === 2 ? ' up2' : '') +
            (valueChangeDynamics && valueChangeDynamics.oddDirection === -1 ? ' down1' : '') +
            (valueChangeDynamics && valueChangeDynamics.oddDirection === -2 ? ' down2' : '') +
            (isAllMarkets ? ' all-markets' : '') + (isTooltipShown ? ' pick-sport-tall' : '') +
            (isSuperbet ? ' pick--superbet' : '')]"
        @click="$emit('click')"
    >
        <span class="indicator actionable" />
        <span class="market actionable" :title="symbol">
            <template v-if="isSuperbet">
                <span
                    v-for="part in marketName.split(';')"
                    :key="part"
                    class="superbet__segment"
                >
                    {{ interpolatePart(part, specifiers) }}
                </span>
            </template>
            <template v-else>
                {{ isOutright ? name : symbol }}
                {{
                    specialBetValue && showSpecialBetValue
                        ? `(${specialBetValue})`
                        : ""
                }}
            </template>
        </span>
        <span
            v-if="isTooltipShown"
            class="market actionable"
            :title="symbol"
        >
            {{ tooltipDescription }}
        </span>
        <span v-if="value" class="odd actionable">
            <span v-if="isRefund" class="odd--refunded">
                {{ value | twoDecimals }}
            </span>
            <span>
                <template v-if="isRefund">1</template>
                <template v-else>
                    <span class="value new actionable">
                        {{ value | twoDecimals }}
                    </span>
                    <span class="value old actionable">
                        {{ value | twoDecimals }}
                    </span>
                </template>
            </span>
        </span>
        <span v-else class="odd actionable">-</span>
    </button>
</template>

<script>
    import liveBettingSpecifiersFormatter from '@core/services/offer/_private/LiveBettingSpecifiersFormatter';

    export default {
        name: 'SportOfferPick',
        props: {
            id: {
                type: Number,
                required: true,
            },
            value: {
                type: Number,
                required: false,
                default: null,
            },
            specialBetValue: {
                type: [String, Number],
                required: false,
                default: '',
            },
            symbol: {
                type: String,
                required: true,
            },
            isLocked: {
                type: Boolean,
                required: true,
            },
            isTooltipShown: {
                type: Boolean,
                required: false,
                default: false,
            },
            tooltipDescription: {
                type: String,
                default: '',
            },
            marketId: {
                type: Number,
                default: null,
            },
            marketName: {
                type: String,
                required: true,
            },
            isAllMarkets: {
                type: Boolean,
                default: false,
            },
            isEventLocked: {
                type: Boolean,
                required: false,
                default: false,
            },
            valueChangeDynamics: {
                type: Object,
                required: false,
                default: null,
            },
            isRefund: {
                type: Boolean,
                required: false,
                default: false,
            },
            isSuperbet: {
                type: Boolean,
                default: false,
            },
            specifiers: {
                type: Object,
                required: false,
            },
            name: {
                type: String,
                required: true,
            },
            isOutright: {
                type: Boolean,
                required: false,
                default: false,
            },
            showSpecialBetValue: {
                type: Boolean,
                default: true,
            },
        },
        methods: {
            interpolatePart(part, specifiers) {
                return liveBettingSpecifiersFormatter.format(part, specifiers);
            }
        },
    };
</script>

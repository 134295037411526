<template>
    <div>
        <div class="number-display__wrapper">
            <p v-for="msg in errors" :key="msg" class="error--cancel-ticket">
                {{ msg }}
            </p>
            <div class="number-display" style="min-height: 60px; line-height: 30px">
                <span v-if="internalValue">{{ internalValue }}</span>
                <span v-else>{{ $t("input ticket control code") }}</span>
            </div>
        </div>
        <div class="betslip__system-box--terminal">
            <div
                v-for="numberKey in fetchNumberKeys"
                :key="numberKey.value"
                class="betslip-system__wrap--terminal"
            >
                <div
                    class="betslip-input__modal--terminal"
                    @click="onKeypadClick(numberKey)"
                >
                    <div>
                        <span class="form-check-label__text">
                            {{ numberKey.value }}
                        </span>
                    </div>
                </div>
            </div>
            <div
                v-for="numberKey in fetchAdditionalKeys"
                :key="numberKey.value"
                class="betslip-system__wrap--terminal"
            >
                <div
                    class="betslip-input__modal--terminal"
                    @click="onKeypadClick(numberKey)"
                >
                    <div>
                        <span class="form-check-label__text">
                            {{ numberKey.value }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="betslip-system__wrap--terminal">
                <div
                    class="betslip-input__modal--terminal"
                    @click="onKeypadClick(delKey)"
                >
                    <div>
                        <span class="form-check-label__text">
                            {{ delKey.value }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="betslip-system__wrap--terminal w-full">
                <button
                    :class="[buttonClass, { 'btn-cancel-ticket--disabled': isButtonDisabled }]"
                    :disabled="isButtonDisabled"
                    type="button"
                    @click="submit"
                >
                    {{ $t(buttonLabel) | capitalize }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import { get as _get, range as _range } from '@lodash';
    import TicketService from '@core/services/tickets/TicketService';
    import PlatformService from '@src/terminal/core/services/platform/PlatformService';
    import commonConfig from '@src/config/common';

    const DEFAULT_VALUE = '';

    export default {
        name: 'TicketCancelModal',
        data() {
            return {
                delKey: { value: 'Del' },
                internalValue: DEFAULT_VALUE,
                errors: [],
                submitDisabled: false,
                isLoading: false,
            };
        },
        computed: {
            ...mapGetters('ui/modal', ['modalData']),
            textClass() {
                return _get(this, 'modalData.text.className') || '';
            },
            textContent() {
                return (
                    _get(this, 'modalData.text.content')
                    || 'Something went wrong. Please try again'
                );
            },
            buttonClass() {
                return (
                    _get(this, 'modalData.button.className')
                    || 'btn btn__primary btn--block'
                );
            },
            buttonLabel() {
                return _get(this, 'modalData.button.label') || 'ok';
            },
            fetchNumberKeys() {
                return _range(9).map((i) => ({ value: `${i + 1}` }));
            },
            ticketId() {
                return _get(this, 'modalData.ticketId', 'INVALID_TICKET_ID');
            },
            btnDisabled() {
                return this.internalValue.toString().length < 4;
            },
            fetchAdditionalKeys() {
                return [{ value: 'C' }, { value: '0' }];
            },
            isButtonDisabled() {
                return this.btnDisabled || this.isLoading;
            },
        },
        methods: {
            ...mapActions('ui/modal', ['closeModal']),
            onKeypadClick(numberKey) {
                switch (numberKey.value) {
                    case 'C':
                        this.internalValue = DEFAULT_VALUE;
                        break;
                    case 'Del':
                        this.removeValueFromInternalValue();
                        break;
                    default:
                        this.appendValueToInternalValue(numberKey.value);
                        break;
                }
            },
            appendValueToInternalValue(value) {
                const newValue = this.internalValue + this.validateValue(value);
                this.internalValue = newValue;
            },
            removeValueFromInternalValue() {
                const currentValue = this.internalValue;
                this.internalValue = currentValue.slice(0, -1);
            },
            validateValue(value) {
                if (this.hasFourDigits()) {
                    return DEFAULT_VALUE;
                }
                return value;
            },
            hasFourDigits() {
                return this.internalValue.toString().length >= 4;
            },
            clearErrors() {
                this.errors = [];
            },
            pushError(error) {
                this.errors.push(error);
            },
            async submit() {
                if (this.btnDisabled && !this.ticketId) {
                    return;
                }
                this.clearErrors();
                const ticketId = this.ticketId.toLowerCase();
                const controlCode = this.internalValue;
                this.isLoading = true;
                const response = await TicketService.getInstance().cancelTicket(
                    ticketId,
                    controlCode
                );
                this.isLoading = false;
                const { error, errorMessage } = response;
                if (!error) {
                    const msg = this.$t('ticket canceled successfully').toLocaleUpperCase();
                    PlatformService.getInstance().sendNotification({
                        title: msg,
                        text: msg,
                        type: 'success',
                    });
                    if (commonConfig.environment.isInHouse) {
                        PlatformService.getInstance().requestBalanceStatusRequest();
                    }
                    this.closeModal();
                } else if (error && _get(response, 'data.ticketStatusCanceled', false)) {
                    const msg = this.$t('ticket already canceled').toLocaleUpperCase();
                    PlatformService.getInstance().sendNotification({
                        title: msg,
                        text: msg,
                        type: 'error',
                    });
                    this.closeModal();
                } else if (error && _get(response, 'data.isWrongTicketControlCode', false)) {
                    this.pushError(this.$t('wrong control code', { controlCode, ticketId }).toLocaleUpperCase());
                } else {
                    const msg = errorMessage;
                    PlatformService.getInstance().sendNotification({
                        title: msg,
                        text: msg,
                        type: 'error',
                    });
                    this.closeModal();
                }
            },
        },
    };
</script>

<style lang="scss">
.cancle-ticket-modal--terminal {
    width: 520px;
  max-width: 820px;
}

.btn--cancel-ticket {
    height: 48px;
}

.error--cancel-ticket {
  color: red;
  font-weight: 500;
  font-size: 1.1em;
}

.btn-cancel-ticket--disabled {
  opacity: 0.45;
}
</style>

<template>
    <div>
        <slot></slot>
        <div class="button-group">
            <button
                @click="$emit('cancel')"
                class="btn btn__secondary"
            >
                {{ $t(cancelLabel) | capitalize }}
            </button>
            <button
                @click="$emit('continue')"
                class="btn btn__primary"
            >
                {{ $t(continueLabel) | capitalize }}
            </button>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'DialogComponent',
        props: {
            cancelLabel: {
                type: String,
                required: false,
                default: 'cancel'
            },
            continueLabel: {
                type: String,
                required: false,
                default: 'continue'
            },
        },
    };
</script>
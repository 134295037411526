<template>
    <div    class="betslip-stake-input__stake--terminal"
            @click="onStakeClicked(betSlipNamespace)"
    >
        {{rawStake}} {{$t('currencyAbbreviation')}}
    </div>
</template>

<script>
    import StoreUtil from '@app/store/StoreUtil';
    import { mapActions } from 'vuex';
    import Modals from '@store/modules/ui/shared/modal/modals';

    export default {
        name: 'StakeInput',
        props: {
            stake: {
                type: Number,
                required: false,
            },
            betSlipNamespace: {
                type: String,
                required: true,
                default: 'ui/sportOffer/betSlip',
            },
        },
        created() {
            this.setStake(this.stake);
        },
        methods: {
            ...mapActions('ui/modal', [
                'setModal',
            ]),
            ...StoreUtil.mapActionsDynamic('betSlipNamespace', [
                'setStake',
            ]),
            onStakeClicked(betSlipNamespace) {
                this.setModal({
                    code: Modals.numberInput.code,
                    containerClass: 'number-input-modal--terminal',
                    data: {
                        button: {
                            enabled: true,
                            className: 'btn modal-btn--terminal btn--block',
                        },
                        betSlipNamespace,
                    }
                });
            },
        },
        computed: {
            ...StoreUtil.mapGettersDynamic('betSlipNamespace', [
                'rawStake'
            ]),
        }
    };
</script>

import { get as _get } from '@lodash';
import Struct from '@models/struct/Struct';
import Match from '@core/models/offer/Match';
import Odd from '@models/offer/Odd';
import { PayoutService } from '../betSlip/PayoutService';
import { LiveBettingSpecifiersFormatter } from '@core/services/offer/_private/LiveBettingSpecifiersFormatter';
import { instanceGuard } from '@core/utils/services';
import SingletonError from './errors/SingletonError';
import Singleton from './Singleton';
import MarketGroupInfo from '@models/struct/MarketGroupInfo';
import { SuperOfferKeys } from '@core/models/offer/enums';

export class StructManager {
    private static instance: StructManager | undefined;
    private struct: Struct;
    private specifierFormatter: LiveBettingSpecifiersFormatter = new LiveBettingSpecifiersFormatter();

    private constructor(struct: Struct) {
        if (StructManager.instance) {
            throw new SingletonError(this.constructor.name);
        }
        this.struct = struct;
    }

    public static getInstance(): StructManager {
        return instanceGuard(StructManager.instance)!;
    }

    public static createInstance({ struct }: { struct: Struct }) {
        if (!StructManager.instance) {
            StructManager.instance = new StructManager(struct);
        }
        return StructManager.instance;
    }

    public denormalizeEvent(event: Match): void {
        event.categoryName = this.getCategoryName(event.categoryId);
        event.sportName = this.getSportName(event.sportId);
        event.tournamentName = this.getTournamentName(event.tournamentId);

        try {
            if (!event.sportOrder) {
                event.sportOrder = this.struct.sports[event.sportId].order!;
                event.tournamentOrder = this.struct.tournaments[event.tournamentId].order!;
                event.specialTournamentOrder = this.struct.tournaments[event.tournamentId].orderPromoted!;
            }
        } catch (e) {
            event.validStruct = false;
            return;
        }
        const oddsValid = this.denormalizeOdds(event);
        event.validStruct = oddsValid && !!event.sportName && !!event.categoryName && !!event.tournamentName;
    }

    public denormalizeEvents(events: Match[]): void {
        events.forEach((event) => this.denormalizeEvent(event));
    }

    public hasStruct() {
        return !!this.struct;
    }

    /**
     * Returns true if fields in odd have all been denormalized successfully.
     */
    public denormalizeOdd(event: Match, odd: Odd): boolean {
        const { marketOriginalName, marketName } = this.getOddMarketNameWithFallback(odd, event);

        odd.marketOriginalName = marketOriginalName;
        odd.marketName = marketName;

        odd.isInPromotion = this.isInPromotion(odd);

        odd.isValidForSuperBonus = PayoutService.getInstance().isOddValidForSuperBonus(odd.tags);

        if (!odd.description) {
            odd.description = this.specifierFormatter
                .format(this.getOddDescription(odd.id), odd.specifiers!, event)
                .trim();
        }

        odd.symbol = event.isOutright
            ? odd.name
            : this.specifierFormatter.format(this.getOddSymbol(odd.id), odd.specifiers!, event);

        return !!odd.marketOriginalName && !!odd.marketName && !!odd.symbol;
    }

    private getOddMarketNameWithFallback(
        odd: Odd,
        event: Readonly<Match>
    ): { marketOriginalName: string; marketName: string } {
        if (odd.initialMarketName) {
            return { marketOriginalName: odd.initialMarketName, marketName: odd.initialMarketName };
        } else {
            const marketOriginalName = this.getMarketName(odd.marketId);
            const marketName = event.isOutright
                ? odd.marketName
                : this.specifierFormatter.format(
                    this.getMarketName(odd.marketId),
                    odd.specifiers!,
                    event,
                );

            return { marketOriginalName, marketName };
        }
    }

    public denormalizeOdds(event: Match): boolean {
        let isValid = true;
        event.getOdds().forEach((odd: Odd) => {
            const result = this.denormalizeOdd(event, odd);
            if (!result) {
                isValid = false;
            }
        });
        return isValid;
    }

    public getSportName(id: number | undefined): string {
        return this.getValueFromStruct('getSportName', id, `sports[${id}].name`);
    }

    public getSportOrder(id: number | undefined): number {
        return this.getValueFromStruct('getSportOrder', id, `sports[${id}].order`);
    }

    public getCategoryName(id: number | undefined): string {
        return this.getValueFromStruct('getCategoryName', id, `categories[${id}].name`);
    }

    public getMarketName(id: number | undefined): string {
        return this.getValueFromStruct('getMarketName', id, `markets[${id}].name`);
    }

    public getTournamentName(id: number | undefined): string {
        return this.getValueFromStruct('getTournamentName', id, `tournaments[${id}].name`);
    }

    public getOddSymbol(oddId: number | undefined) {
        return this.getValueFromStruct('getOddSymbol', oddId, `oddTypes[${oddId}].symbol`);
    }

    // eslint-disable-next-line class-methods-use-this
    private isInPromotion(odd: Odd): boolean {
        return !!odd.tags && Object.keys(SuperOfferKeys).some((tag) => odd.tags!.includes(tag));
    }

    public getOddDescription(oddId: number | undefined) {
        return this.getValueFromStruct('getOddDescription', oddId, `oddTypes[${oddId}].description`);
    }

    public setSpecifierFormatter(formatter: LiveBettingSpecifiersFormatter) {
        this.specifierFormatter = formatter;
    }

    public setStruct(struct: Struct) {
        this.struct = struct;
        delete this._promotionalMarketTags;
    }

    public setMarketGroupInfos(marketGroupInfos: Record<number, MarketGroupInfo>): void {
        this.struct.marketGroupInfos = marketGroupInfos;
    }

    private logIfEmpty(method: string, id: number | undefined, value: string) {
        if (!value) {
            // console.error(new Error(`Empty value in ${method} for ${id}`));
        }
    }

    private logIfUndefined(method: string) {
        // Logger.error(new Error(`Undefined id in ${method}`));
    }

    private getValueFromStruct(method: string, id: number | undefined, path: string, defaultValue = '') {
        if (!this.struct) {
            throw new Error('Struct manager not properly configured. Struct is missing.');
        }
        if (!id) {
            this.logIfUndefined(method);
            return '';
        }

        const value = _get(this.struct, path, defaultValue);
        this.logIfEmpty(method, id, value);
        return value;
    }

    public static clearInstance() {
        if (process.env.NODE_ENV !== 'test') {
            throw new Error('For use in tests only');
        }
        delete (StructManager as any).instance;
    }

    private _promotionalMarketTags?: string[];
    private get promotionalMarketTags() {
        if (!this._promotionalMarketTags) {
            this._promotionalMarketTags = this.struct.superOffer ? Object.keys(this.struct.superOffer) : [];
        }
        return this._promotionalMarketTags;
    }
}

export interface IStructManager extends StructManager { }

export default StructManager as Singleton<StructManager>;

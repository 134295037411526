import AllowancesRestService from './_private/AllowancesRestService';
import {
    CheckerFn,
    getAllowancesChecker,
    AllowancesV2,
    SelectedOutcome
} from '@superbet-group/multiplatform.lib';
type AllowanceV2Type = InstanceType<typeof AllowancesV2>;
export type AllowancesOutcome = {
    marketId: number;
    outcomeId: number;
    specifiers: string;
    isInplay: boolean;
};

export default class AllowancesService {
    private static instance: AllowancesService;
    private allowancesRestService = new AllowancesRestService();
    private allowancesChecker: CheckerFn;
    private allowancesV2: AllowanceV2Type;

    public static getInstance(): AllowancesService {
        if (!AllowancesService.instance) {
            AllowancesService.instance = new this();
        }

        return AllowancesService.instance;
    }

    // Async loading of config and the allowances checking function
    public async initAllowancesFn(): Promise<void> {
        const config = await this.allowancesRestService.getAllowances();
        const configV2 = await this.allowancesRestService.getAllowancesV2();
        this.allowancesV2 = new AllowancesV2(configV2, 'ro');
        this.allowancesChecker = getAllowancesChecker(config);
    }

    public areMarketsAllowed(marketId1: number, marketId2: number): boolean {
        if (typeof this.allowancesChecker === 'function') {
            return this.allowancesChecker(marketId1, marketId2);
            // tslint:disable-next-line:no-else-after-return
        } else {
            /*
                # Why is the fallback `true`?
                1. We allow certain market combinations as defined in the allowances config.
                2. *If the config is not ready*, we are allowing all combinations because the
                backend also has the same logic as we do. We are just delegating the validation
                onto backend if we don't have the config ready.
             */
            return true;
        }
    }

    public areMarketAllowedV2(outcome1: AllowancesOutcome, outcome2: AllowancesOutcome) {
        const sel = new SelectedOutcome(
            outcome1.marketId,
            outcome1.outcomeId,
            outcome1.specifiers,
            outcome1.isInplay
        );
        const sel2 = new SelectedOutcome(
            outcome2.marketId,
            outcome2.outcomeId,
            outcome2.specifiers,
            outcome2.isInplay
        );
        return this.allowancesV2.validateSelection(sel, sel2);
    }

    public validNumberOfMarkets(outcomes: AllowancesOutcome[]) {
        const sel = JSON.stringify(
            outcomes.map(
                (outcome) =>
                    new SelectedOutcome(
                        outcome.marketId,
                        outcome.outcomeId,
                        outcome.specifiers,
                        outcome.isInplay
                    )
            )
        );

        return this.allowancesV2.validateMaxSelectionsForEvent(sel);
    }
}

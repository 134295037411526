<template>
    <span
        style="cursor: pointer"
        @click="onClick"
    >
        {{ label }}
    </span>
</template>

<script>
    export default {
        name: 'ExternalLink',
        props: {
            path: {
                type: String,
                required: true,
            },
            label: {
                type: String,
                required: true,
            },
        },
        methods: {
            onClick() {
                window.open(this.path, '_blank');
            }
        }
    };
</script>

<template>
    <div
        class="spinner-bars"
        :class="colorClass"
    >
        <div class="spinner-bars__el a"></div>
        <div class="spinner-bars__el b"></div>
        <div class="spinner-bars__el c"></div>
    </div>
</template>

<script>
    export default {
        name: 'SpinnerBars',
        props: {
            colorClass: {
                type: String,
                default: ''
            }
        }
    };
</script>

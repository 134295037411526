import Subscriber from './Subscriber';
import Topic from './Topic';

/**
 * The class represents an event broker used in a topic based publish-subscribe
 * system. Its main purpose is to provide a single event hub to be used
 * throughout the application. The channel decouples all possible
 * tracking/analytics code from the main application. Any event that is of
 * interest should be reported to the channel under the appropriate topic. The
 * channel will then inform all interesting parties (which are subscribed to the
 * topic).
 *
 * Everyone uses the same instance of the EventChannel class (this singleton is
 * the default export).
 *
 * Example: Let's say we want to track certain user actions. Let's also say that
 * different objects track the same events, but for different analytics-related
 * purposes. Methods in charge of handling the mentioned actions report the
 * event to the shared channel instance using its 'publish' method (under the
 * topic 'Analytics'). The action handler does not need to know about interested
 * parties. The channel then notifies all subscribers about the event and each
 * of them processes it according to its needs.
 */
export class EventChannel {

    private subscribers: Map<Topic, Subscriber[]>;

    constructor() {
        this.subscribers = new Map();
        Object.keys(Topic).forEach(
            // @ts-ignore
            (key) => this.subscribers.set(Topic[key as any] as Topic, [])
        );
    }

    public subscribe(topic: Topic, subscriber: Subscriber) {
        this.getSubs(topic).push(subscriber);
    }

    public publish(topic: Topic, eventData: Object) {
        this.getSubs(topic).forEach((sub) => sub.notify(eventData));
    }

    private getSubs(topic: Topic) {
        if (!this.subscribers.has(topic)) {
            throw new TypeError('Invalid topic');
        }

        return this.subscribers.get(topic) as Subscriber[];
    }
}

export default new EventChannel();

import AbstractMapper from '@core/utils/mappers/AbstractMapper';
import Period from '@core/models/offer/Period';
import { PeriodDto } from '@superbet-group/offer.clients.lib';

export class PeriodMapper extends AbstractMapper<PeriodDto, Period> {
    createTargetObject(dto: PeriodDto): Period {
        const period: Period = new Period();

        period.team1Score = parseInt(dto.homeTeamScore, 10);
        period.team2Score = parseInt(dto.awayTeamScore, 10);
        period.number = dto.num;
        period.type = dto.type;

        return period;
    }
}

import { Store } from 'vuex';
import { IState } from '@app/store';
import ITicketsState from '@store/modules/data/tickets/types';
import sportOffer, { ISportOfferState, SportOfferGetters } from './sportOffer';
import {
    loadLiveEvents,
} from './tickets/subscriptions';
import country, { ICountryState } from './country/countryIndex';
import flags from '@src/terminal/store/flags'
import tickets from './tickets/index';
import results, { IResultsState } from './results/resultsIndex';
import competitions from '@src/app/competitions/store';
import { CompetitionsState } from '@src/app/competitions/store/types';

export interface IDataState {
    sportOffer: ISportOfferState;
    tickets: ITicketsState;
    country: ICountryState;
    results: IResultsState;
    competitions: CompetitionsState;
}

export interface DataGetters {
    sportOffer: SportOfferGetters;
}

export function subscriptions(store: Store<IState>, options: {
    moduleNamespace: string[],
}) {
    loadLiveEvents(store);
}

export default {
    namespaced: true,

    modules: {
        country,
        flags,
        sportOffer,
        tickets,
        results,
        competitions,
    },
};

export const moduleNamespaces = {
    authentication: ['authentication'],
} as Record<string, string[]>;

<template>
    <div>
        <SearchInput
            @submit="onSearchSubmit"
            :suggestionsFunction="searchFunction"
            :predefinedSuggestions="previousQueries"
        />
        <template v-if="hasSomeSearchResults">
            <template v-if="eventsByCompetitors.length">
                <SportOfferEventGrid
                    :id="sectionIds.matches"
                    :events="eventsByCompetitors"
                    :renderDone="true"
                    :areEventsLoaded="true"
                    :filterChangeIndicator="0"
                    :sidebarChangedState="false"
                    @oddClick="onOddClick"
                    class="pinned-event-grid"
                />
            </template>

            <div
                v-if="foundTournaments.length"
                class="search-results__competitions"
            >
                <div class="section-header">
                    <i class="icon section-header__icon icon-competition"></i>
                    <span
                        :id="sectionIds.tournaments"
                        class="section-header__title"
                    >
                        {{ $t('tournaments') | upperCase }}
                    </span>
                </div>
                <LinksList
                    :list="foundTournaments"
                    :linksPrefix="offerLink"
                />
            </div>
        </template>
        <div
            v-else
            class="empty-state__offer--terminal"
        >
            <Illustration large image="/static/img/illustrations/offer__empty-state.svg"/>
            <div class="empty-state__offer-copy">
                {{ $t('no results found for') | capitalize }}: <i>"{{ query }}"</i>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import { links, OFFER_PREFIX } from '@app/router/constants';
    import SportOfferEventGrid from '../../sharedSubmodules/SportOfferEventGrid.vue';
    import SearchInput from '../../sharedSubmodules/SearchInput.vue';
    import LinksList from './components/LinksList.vue';
    import Illustration from '@shared/components/Illustration.vue';

    export default {
        name: 'SearchContainer',
        props: {
            sectionIds: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                offerLink: OFFER_PREFIX,
                teamsLink: links.teams,
            };
        },
        computed: {
            ...mapGetters('ui/sportOffer/search', [
                'query',
                'eventsByCompetitors',
                'foundTournaments',
                'previousQueries',
                'suggestions',
            ]),
            hasSomeSearchResults() {
                return this.eventsByCompetitors.length
                    || this.foundTournaments.length;
            }
        },
        methods: {
            ...mapActions('ui/sportOffer/search', [
                'saveQuery',
            ]),
            ...mapActions('ui/sportOffer/betSlip', [
                'toggleAddRemoveSelection',
            ]),
            onSearchSubmit(query) {
                this.saveQuery(query);
                this.$router.replace({
                    query: {
                        query,
                    },
                });
            },
            searchFunction(query) {
                return this.suggestions(query);
            },
            onOddClick({ eventId, odd }) {
                const { id: oddId, specialBetValue } = odd;

                this.toggleAddRemoveSelection({ eventId, oddId, specialBetValue });
            },
        },
        components: {
            SportOfferEventGrid,
            LinksList,
            SearchInput,
            Illustration
        }
    };
</script>

type BetBuilderRequestConstructorProps = Pick<BetBuilderRequest, keyof BetBuilderRequest>;

export default class BetBuilderRequest {
    match_id: number;
    odd_uuid: string;
    target: string;
    lang: string;

    constructor(props: BetBuilderRequestConstructorProps) {
        Object.assign(this, props);
    }

    public toString() {
        return JSON.stringify(this);
    }

    static createRequest(
        matchId: number,
        oddUuid: string,
        target: string,
        lang: string
    ): BetBuilderRequest {
        return new BetBuilderRequest({
            lang,
            match_id: matchId,
            odd_uuid: oddUuid,
            target
        });
    }
}

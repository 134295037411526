<template>
    <div class="ticket-check__info">
        <span class="info-message">
            {{ $t(message) }}
        </span>
    </div>
</template>

<script>
    export default {
        name: 'TicketCompileInfo',
        props: {
            message: String
        }
    };
</script>

import { OddStatus, OfferType } from '@models/offer/enums';
import IBetSlipOdd from '../base/IBetSlipOdd';
import { MarketAttribute } from '@core/constants';
import Market from '../struct/Market';
import { ISportOfferState } from '@store/modules/data/sportOffer';
import { PayoutService } from "@core/services/betSlip/PayoutService";

export default class Odd implements IBetSlipOdd {
    id: number;
    uuid: string | null;
    value: number;
    //  1, X, 2, 1X, X2, 12....
    symbol: string;
    // Egalite, etc.
    description: string;
    marketId: number;
    marketOriginalName: string;
    marketName: string;
    marketCode?: string;
    marketUuid?: string;
    initialMarketName?: string;
    outrightMarketName: string;
    marketTag?: string;
    order: number;
    status: string;
    marketOrder: number;
    // 1: preEvent , 2: live
    offerType: OfferType;
    tags?: string;
    code: string;
    groupId: number;
    prefix: string;
    typeName?: string;
    name: string;
    sourceType: number;
    public isInPromotion: boolean;
    public isValidForSuperBonus: boolean;

    /**
     * Every match can have handicap market where you can place bets on regulars odds (1, X, 2) but with
     * handicap (handicap means advantage for some team and the whole purpose is to make betting more interesting)
     * Handicap is given to underdog team.  For example, if Barca plays with Dinamo, bookmakers give handicap to Dinamo
     * 1.5, and id you place bet on Barcelona to win, Barcelona must score +2 goals to beat 1.5 handicap.  Handicap
     * is advantage.  Handicap comes with + or -.
     *
     * odd has uniqueId as eventId oddId specialBetValue because we can have the same handicap market but with different
     * specialBetValues
     *
     * handicap(-1.5) 1 X 2
     * handicap(-2.5) 1 X 2
     * handicap(-3.5) 1 X 2
     */
    specialBetValue: string;
    showSpecialBetStatus?: number;
    specifiers?: Record<string, string>;

    // the id isn't unique per market. To have a truly unique value the id has to be combined with the special bet value
    get uniqueId(): string {
        return `${this.id} ${this.specialBetValue}`;
    }

    public getUniqueMarketId(): string {
        return `${this.getUniqueMarketName()} ${this.marketId}`;
    }

    public getMarketName(): string {
        return this.marketName;
    }

    public getUniqueMarketName(): string {
        if (this.specialBetValue && this.showSpecialBetValueInMarketHeader()) {
            return `${this.marketName} ${this.specialBetValue}`;
        }

        return this.marketName;
    }

    public showSpecialBetValueInMarketHeader(): boolean {
        return this.showSpecialBetStatus === 1;
    }

    public hasMarketTag(tag: string): boolean {
        return !!this.marketTag && this.marketTag.includes(tag);
    }
    public isMain() {
        return !!this.marketTag && this.marketTag.includes(MarketAttribute.main);
    }

    public isMarketType(marketName: string) {
        return this.tags && this.tags.includes(marketName) || false;
    }

    // todo test
    public isEqualById(compareOdd: Odd): boolean {
        return this.id === compareOdd.id && this.specialBetValue === compareOdd.specialBetValue;
    }

    public static copy(odd: Odd): Odd | null {
        if (!odd) {
            return null;
        }
        const newInstance = new Odd();
        Object.assign(newInstance, odd);
        return newInstance;
    }

    get isFinished(): boolean {
        return [
            OddStatus.finished,
            OddStatus.win,
            OddStatus.lost,
            OddStatus.refund,
        ].includes(this.status as OddStatus);
    }

    get isShownOnOffer() {
        return this.status === OddStatus.active || this.status === OddStatus.block;
    }

    get isLocked(): boolean {
        return this.status !== OddStatus.active;
    }

    get isBonusable() {
        if (PayoutService.getInstance().isSuperBonusEnabled) {
            return this.isValidForSuperBonus;
        } else {
            return !this.isInPromotion;
        }
    }
}

export function shouldShowSpecialBetValue(
    marketId: Market['id'],
    markets: ISportOfferState['markets'],
    isSpecial: boolean
): boolean {
    if (isSpecial) {
        return true;
    }
    if (markets[marketId]) {
        return markets[marketId].showSpecialBetValue || false;
    }
    return false;
}

// this.value-bind uses for...in which only iterates over own properties on the object
// getters on a class aren't own properties. This marks them as enumerable and
// makes the usable in v-bind
Object.defineProperty(Odd.prototype, 'isFinished', { enumerable: true });
Object.defineProperty(Odd.prototype, 'isShownOnOffer', { enumerable: true });
Object.defineProperty(Odd.prototype, 'isLocked', { enumerable: true });
Object.defineProperty(Odd.prototype, 'isBonusable', { enumerable: true });

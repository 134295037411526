import IMapper from '@core/utils/mappers/IMapper';
import { forEach as _forEach } from '@lodash';
import DateUtils from '@core/utils/date/DateUtils';
import extractLocalizedString from './helpers';

export default abstract class AbstractMapper<S, T> implements IMapper<S, T> {
    public map(source: S, many?: boolean): T;
    public map(source: S[], many?: boolean): T[];
    public map(source: Record<number | string, S>, many?: boolean): T[];
    public map(source: any, many: boolean = false): any {
        if (many) {
            const models: T[] = [];

            _forEach(source, (item: S, key: string | number) => {
                if (source) {
                    models.push(this.createTargetObject(item, key));
                }
            });

            return models;
        }

        return this.createTargetObject(source as S);
    }

    /**
     * returns a date if a date string is passed in
     * @param date {string | null | undefined}
     */
    /*
        These overloads ensure that when a string is passed in a Date is returned
        If null or undefined is passed in undefined is returned
        Other overloads allow for all combinations or null, undefined and string to
        be passed in.
    */
    protected toDate(date: string, options?: { assumeUtc: boolean}): Date;
    protected toDate(date: null | undefined, options?: { assumeUtc: boolean}): undefined;
    protected toDate(date?: string, options?: { assumeUtc: boolean}): Date | undefined;
    protected toDate(date: string | null, options?: { assumeUtc: boolean}): Date | undefined;
    protected toDate(date: string | null | undefined, options?: { assumeUtc: boolean}): Date | undefined {
        if (!date) {
            return undefined;
        }
        if (options && options.assumeUtc) {
            return DateUtils.toDate(DateUtils.fillToUTCDate(date));
        }
        return DateUtils.toDate(date);
    }

    protected prettyDecimal(value: string | number, decimalPlaces = 2) {
        let decimalNumber = value;
        if (typeof value === 'string') {
            decimalNumber = parseFloat(value);
        }
        return (<number>decimalNumber).toFixed(decimalPlaces);
    }

    public extractLocalizedString = extractLocalizedString;

    abstract createTargetObject(obj: S, options?: string | number | any): T;

}

<template>
    <TypeSwitcher
        :types="stakes"
        :selected="stake"
        @click="onClick"
        class="betslip-stake__picker--terminal"
    />
</template>

<script>
    import config from '@config';
    import TypeSwitcher from '@shared/components/TypeSwitcher.vue';

    export default {
        name: 'StakePicker',
        props: {
            stake: {
                type: Number,
                required: false,
            },
        },
        data() {
            return {
                stakes: config.app.betSlip.stakes.map((stake) => ({
                    type: stake,
                    displayName: stake
                }))
            };
        },
        methods: {
            onClick(stake) {
                this.$emit('click', stake);
            }
        },
        components: {
            TypeSwitcher
        }
    };
</script>

export enum OfferType {
    preEvent = 1,
    live = 2
}

export enum OfferStatus {
    active = 'active',
    stop = 'stop',
    block = 'block',
    finished = 'finished',
    canceled = 'canceled'
}

export enum OddStatus {
    active = 'active',
    stop = 'stop',
    block = 'block',
    finished = 'finished',
    lost = 'lost',
    refund = 'refund',
    win = 'win',
}

export enum EventCategory {
    OUTRIGHT = 'OUTRIGHT'
}

export enum SuperOfferKeys {
    superCota = 'superKvota',
    superExtra = 'superExtra',
}

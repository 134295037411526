<template functional>
    <div class="event-row__expanded-market-group">
        <div class="event-row__expanded-market-header">
            <div class="event-row__expanded-market-title">
                {{ props.name }}
            </div>
        </div>
        <div class="event-row__expanded-markets-container">
            <component
                v-for="odd in props.odds"
                :key="odd.id"
                :is="props.components.Pick"
                v-bind="odd"
                :isRefund="props.isRefund(odd)"
                :isLocked="false"
                :class="odd.status"
            />
        </div>
    </div>
</template>

<script>
    import Pick from '@modules/shared/event/Pick.vue';
    import { EventStatus } from '@core/models/tickets/enums';

    export default {
        name: 'MarketRow',
        functional: true,
        inheritAttrs: false,
        props: {
            name: {
                type: String,
                required: true,
            },
            odds: {
                type: Array,
                required: true,
            },
            // hacks
            components: {
                type: Object,
                default: () => ({ Pick }),
            },
            isRefund: {
                type: Function,
                default: (odd) => odd.status === EventStatus.refund,
            }
        }
    };
</script>

<template>
    <DialogComponent
        cancelLabel="cancel"
        continueLabel="retry"
        @cancel="closeModal"
        @continue="resubmit"
    >
        <div class="notice--terminal">{{ $t('genericTicketSubmitError') }}</div>
        <Illustration xl image="/static/img/illustrations/betslip__place-bet-error.svg"/>
    </DialogComponent>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import { errorGuard } from 'src/app/utils';
    import DialogComponent from '@src/terminal/app/modals/components/DialogComponent.vue';
    import Illustration from '@shared/components/Illustration.vue';

    export default {
        name: 'TicketSubmitRetryModal',
        created() {
            this.code = this.modal.code;
            this.betSlipNamespace = this.modalData.betSlipNamespace;
        },
        computed: {
            ...mapGetters('ui/modal', [
                'modalData',
                'modal',
            ]),
        },
        methods: {
            ...mapActions('ui/modal', [
                'closeModal',
                'setModal',
            ]),
            async trySubmit() {
                const betSlipNamespace = this.betSlipNamespace;
                const code = this.code;
                await errorGuard({
                    action: () => this.$store.dispatch(`${betSlipNamespace}/submitTicket`),
                    onError: () => this.setModal({
                        code,
                        data: {
                            betSlipNamespace,
                        },
                    }),
                });
            },
            resubmit() {
                this.trySubmit();
                this.closeModal();
            }
        },
        components: {
            DialogComponent,
            Illustration,
        }
    };
</script>

<template>
    <header class="header header--fixed">
        <nav class="navbar">
            <ul class="navbar__items">
                <li class="navbar__item">
                    <router-link :to="links.landing" class="navbar__link navbar__logo">
                        <img src="/static/img/logo.svg" width="156px" height="23px" alt="Superbet"/>
                    </router-link>
                </li>
                <li class="navbar__item">
                    <router-link
                        @click.native="onPageClick"
                        :to="links.landing"
                        :class="{ 'is-active': isOnSportsPage }"
                        class="navbar__link"
                    >
                        {{ $t('sport') }}
                    </router-link>
                </li>
                <li class="navbar__item">
                    <router-link
                        @click.native="onPageClick"
                        :to="routes.live"
                        class="navbar__link"
                        :class="{ 'is-active': isOnSportLivePage }"
                    >
                        <span
                            class="navbar__live-events-count"
                        >
                            {{ liveEventsCount }}
                        </span>
                        {{ $t('live') }}
                    </router-link>
                </li>
                <li class="navbar__item">
                    <router-link
                        @click.native="onPageClick"
                        :to="links.lottery"
                        class="navbar__link"
                        activeClass="is-active"
                    >
                        {{ $t('lottery') }}
                    </router-link>
                </li>
                <li class="navbar__item">
                    <router-link
                        @click.native="onPageClick"
                        :to="routes.results"
                        class="navbar__link"
                        :class="{ 'is-active': isOnResultsPage }"
                        activeClass="is-active"
                    >
                        {{ $t('results') }}
                    </router-link>
                </li>
            </ul>
        </nav>
        <div class="header__right">
            <DateTimeDisplay class="date-time"/>
        </div>
        <div class="app-version">
            {{ appVersion }} {{ envCluster ? `(${envCluster})`: '' }}
        </div>
    </header>
</template>

<script>
    import { routeNames, links } from '@app/router';
    import DateTimeDisplay from '@shared/components/DateTimeDisplay.vue';
    import config from '@config';
    import { liveTimeFilterSlug } from '@store/modules/ui/shared/timeFilter/utils';

    export default {
        name: 'Header',
        props: {
            liveEventsCount: {
                type: Number,
                required: true
            },
            isOnSportLivePage: {
                type: Boolean,
                required: true
            },
            isOnSportsPage: {
                type: Boolean,
                required: true
            },
            isOnResultsPage: {
                type: Boolean,
                required: true
            },
        },
        created() {
            this.links = links;
            this.appVersion = config.environment.version;
            this.envCluster = config.environment.cluster;
            this.routes = {
                live: {
                    name: routeNames.sportOffer,
                    params: {
                        timeFilter: liveTimeFilterSlug,
                    },
                },
                results: {
                    name: routeNames.sportResults,
                },
            };
        },
        computed: {
            liveLinkClass() {
                return {
                    navbar__link: true,
                };
            },
        },
        methods: {
            onPageClick(event) {
                this.$emit('pageClick', event.target.pathname);
            }
        },
        components: {
            DateTimeDisplay
        },
    };
</script>

import RestRequestFactory from '@core/utils/network/RestRequestFactory';
import ResultsRequest from '@core/services/results/_private/models/ResultsRequest';
import ServerConfig from '@core/models/country/ServerConfig';

export default class RestService {
    private readonly request: any;

    constructor(config: ServerConfig, restRequestFactory = RestRequestFactory) {
        this.request = restRequestFactory.create(config.offer.hostServer);
    }

    public getMatchResults(startDate: Date, endDate: Date, sportId?: number): Promise<any> {
        return this.request({
            url: '/offer/getOfferByDate',
            method: 'get',
            params: ResultsRequest.createMatchResultsRequest(startDate, endDate, sportId)
        });
    }

    public async getMatchResultDetails(matchIds: number[], oddsResults: boolean = true): Promise<any> {
        return this.request({
            url: '/matches/getMatchesByIds',
            method: 'get',
            params: ResultsRequest.createMatchResultDetailsRequest(matchIds, oddsResults)
        });
    }

}

import { NAVBAR_HEIGHT, NAVBAR_MARGIN } from '@app/styles/elementConstants';

let footerHeight = 0;

window.addEventListener('resize', () => {
    footerHeight = 0;
});

export const topMargin = NAVBAR_HEIGHT + NAVBAR_MARGIN;
export const bottomFixedContentOffset = 32; // ticketsStack height

export function getBottomMargin() {
    if (footerHeight) {
        return footerHeight;
    }
    const footer = document.querySelector('footer')!;
    if (footer) {
        footerHeight = footer.getBoundingClientRect().height + 8; // 8px is footer margin
    }
    return footerHeight;
}

import {
    fAddDays,
    fSetHours,
} from '@core/utils/functional/Date';
import { flow } from '@lodash';
import IDuration from '@core/utils/types/IDuration';
import config from '@config';
import DateUtils from '@utils/date/DateUtils';

export default class BookieDate {
    /**
     * StartDate if from now if today or from startOfDay.
     * Examples all in *local time*:
     *  1. If date is not present and currently is 11.2.1980. 13:30 startDate will be current
     *  2. If date param is 3.2.1980. 13:30 startDate will be 3.2.1980. 00:00:00
     *
     * FYI all dates are in local time, but request to offer is formatted to UTC and our examples
     * will be sent as (for Zagreb summer +2):
     *  1. 11.2.1980 11:30
     *  2. 2.2.1980. 22:00:00
     */
    static getStartDateForDate(date?: Date): Date {
        let startDate = date;

        if (date && !DateUtils.isToday(date)) {
            startDate = DateUtils.toStartOfDay(date);
        } else {
            // // we wanna events from now and Offer server accepts only UTC dates
            // startDate = DateUtils.inUtcTime(new Date());
            startDate = new Date();
        }

        return startDate;
    }

    /**
     * EndDate is always endOfDay + hours when local betting shops are opening (10 in romania)
     *
     * Examples all in *local time*:
     *  1. If date is not present and currently is 11.2.1980. 13:30 endDate will be 12.2.1980. 10:00:00
     *  2. If date param is 3.2.1980. 13:30 endDate will be 4.2.1980. 10:00:00
     *
     * FYI all dates are in local time, but request to offer is formatted to UTC and our examples
     * will be sent as (for Zagreb summer +2):
     *  1. 12.2.1980 8:00:00
     *  2. 4.2.1980. 8:00:00
     *
     * If some country spans across multiple time zones everything works as in examples.  UTC formatting is
     * based upon local time.  If local time is +3 our examples will look like:
     *  1. 12.2.1980 7:00:00
     *  2. 4.2.1980. 7:00:00
     */
    static getEndDateForDate(date?: Date): Date {
        let endDate = date || new Date();

        endDate = DateUtils.toStartOfDay(endDate);
        endDate = flow(
            fAddDays(1),
            fSetHours(config.app.utils.bookieShopStartTime),
        )(endDate);

        return endDate;
    }

    static getStartDateForOffset(): Date {
        return new Date();
    }

    static getEndDateForOffset(duration: IDuration): Date {
        const date = this.getStartDateForOffset();
        return DateUtils.addDuration(date, duration);
    }

    static getTimeSpan(date: Date) {
        return {
            startDate: BookieDate.getStartDateForDate(date),
            endDate: BookieDate.getEndDateForDate(date)
        };
    }
}

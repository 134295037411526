import {
    omit as _omit,
} from '@lodash';
import Vue from 'vue';
import Struct from '@core/models/struct/Struct';
import Match from '@core/models/offer/Match';
import Sport from '@core/models/struct/Sport';
import Tournament from '@models/struct/Tournament';
import Category from '@core/models/struct/Category';
import StoreUtil from '@store/StoreUtil';
import { isActiveEvent } from '@store/modules/ui/sportOffer/utils';
import { ISportOfferState } from './index';
import * as types from './mutationTypes';
import { mergeMatch, promoteSpecialTournamentToCategory } from './helpers';
import { ISuperOfferCollections, ISuperOfferConfigs } from './types';
import MarketGroupInfo from '@models/struct/MarketGroupInfo';
// import { logSocketEvent, logSocketRawEvent } from '@utils/network/NetworkUtils';

const defaultSuperOfferConfig: ISuperOfferConfigs = {
    superExtra: {
      id: '4',
      footer: 'Pe bilet vor fi jucate MINIM 6 meciuri (Extra cota+alte 5 meciuri MINIM), si de asemenea EC poate fi combinata cu meciuri de la SC.',
      name: 'Extra cota',
      type: 'superExtra'
    },
    superKvota: {
      id: '4',
      footer: 'Se pot combina cu orice alt meci. Se pot juca pe bilet mai multe Super Cote.',
      name: 'Super Cota',
      type: 'superKvota',
    },
};

export default {
    [types.SET_BET_BUILDER_ODD]: (
        state: ISportOfferState,
        { uuid, ts }: { uuid: string; ts: number }
    ) => {
        Vue.set(state.betBuilderOddTimestamps, uuid, ts);
    },
    [types.SET_STRUCT](state: ISportOfferState, struct: Struct) {
        state.sports = struct.sports;
        state.categories = struct.categories;
        state.tournaments = struct.tournaments;
        state.markets = struct.markets;
        state.oddTypes = struct.oddTypes;
        state.superOfferConfig = defaultSuperOfferConfig;
        state.marketGroupInfos = {};
        state.isStructFetched = true;
    },
    [types.SET_MARKET_GROUP_INFOS](state: ISportOfferState, marketGroupInfos: Record<number, MarketGroupInfo>): void {
        state.marketGroupInfos = marketGroupInfos;
    },
    [types.SET_EVENTS](state: ISportOfferState, events: Match[]) {
        events.forEach((event) => {
            promoteSpecialTournamentToCategory(event, state.specialTournaments);
            freezeOdds(event);
            if (state.events[event.id]) {
                // logSocketEvent('setEventsMutationsInStore', event);
                Vue.set(
                    state.events,
                    event.id.toString(),
                    // mergeMatch freezes object!
                    mergeMatch(
                        state.events[event.id],
                        event,
                    )
                );
            } else {
                // logSocketEvent('setEventsMutationsNotInStore', event);
                Vue.set(
                    state.events,
                    event.id.toString(),
                    Object.freeze(event)
                );
            }

            if (isActiveEvent(event)) {
                Vue.set(
                    state.activeEvents,
                    event.id.toString(),
                    state.events[event.id]
                );
            } else if (state.activeEvents[event.id]) {
                Vue.delete(
                    state.activeEvents,
                    event.id.toString(),
                );
            }
        });
    },
    [types.SET_EVENT](state: ISportOfferState, event: Match) {
        promoteSpecialTournamentToCategory(event, state.specialTournaments);
        freezeOdds(event);
        if (state.events[event.id]) {
            Vue.set(
                state.events,
                event.id.toString(),
                mergeMatch(
                    state.events[event.id],
                    event,
                )
            );
        } else {
            Vue.set(
                state.events,
                event.id.toString(),
                event,
            );
        }

        if (isActiveEvent(event)) {
            Vue.set(
                state.activeEvents,
                event.id.toString(),
                state.events[event.id]
            );
        } else if (state.activeEvents[event.id]) {
            Vue.delete(
                state.activeEvents,
                event.id.toString(),
            );
        }
    },
    [types.UPDATE_ONE_STRUCT_CATEGORY](state: ISportOfferState, category: Category) {
        Vue.set(
            state.categories,
            category.id,
            category
        );
    },
    [types.UPDATE_ONE_STRUCT_SPORT](state: ISportOfferState, sport: Sport) {
        Vue.set(
            state.sports,
            sport.id,
            sport
        );
    },
    [types.UPDATE_ONE_STRUCT_TOURNAMENT](state: ISportOfferState, tournament: Tournament) {
        Vue.set(
            state.tournaments,
            tournament.id,
            tournament
        );
    },
    
    [types.INCREMENT_ALL_CHANGES_ID](state: ISportOfferState) {
        state.allChangesIncrementId = (state.allChangesIncrementId + 1) % 2;
    },
    [types.REMOVE_EVENTS](state: ISportOfferState, events: Match[]) {
        events.forEach((event) => Vue.delete(state.events, event.id.toString()));
        events.forEach((event) => Vue.delete(state.activeEvents, event.id.toString()));
    },
    [types.REMOVE_EVENT](state: ISportOfferState, eventId: number) {
        Vue.delete(state.events, eventId.toString());
        Vue.delete(state.activeEvents, eventId.toString());
    },
    [types.SET_TOP_TEN_EVENTS](state: ISportOfferState, eventsIds: number[]) {
        state.topTenEvents = eventsIds;
    },
    [types.SET_TOP_TEN_EVENT](state: ISportOfferState, eventId: number) {
        state.topTenEvents.push(eventId);
    },
    [types.SET_PROMOTIONAL_EVENTS](state: ISportOfferState, promotionalEvents: ISuperOfferCollections) {
        state.promotionalEvents = promotionalEvents;
    },
    [types.UPDATE_EVENT](state: ISportOfferState, event: Match) {
        promoteSpecialTournamentToCategory(event, state.specialTournaments);
        freezeOdds(event);
        if (state.events[event.id]) {
            state.events[event.id] = mergeMatch(
                state.events[event.id],
                event
            ) as Match;

            if (isActiveEvent(event)) {
                Vue.set(
                    state.activeEvents,
                    event.id.toString(),
                    state.events[event.id]
                );
            } else {
                Vue.delete(state.activeEvents, event.id);
            }
        }
    },
    [types.SET_IS_OFFER_STARTING]: StoreUtil.createSimpleMutator('isOfferStarting'),
    [types.SET_IS_OFFER_READY]: StoreUtil.createSimpleMutator('isOfferReady'),
    [types.SET_SHOULD_FETCH_OFFER]: StoreUtil.createSimpleMutator('shouldFetchOffer'),
    [types.SET_SPECIAL_TOURNAMENTS]: StoreUtil.createSimpleMutator('specialTournaments'),
    [types.SET_BROADCASTS]: StoreUtil.createSimpleMutator('broadcasts'),
    [types.SET_LIVE_EVENTS_COUNT]: StoreUtil.createSimpleMutator('liveEventsCount'),
    [types.SET_IS_OFFER_FETCHED]: StoreUtil.createSimpleMutator('isOfferFetched'),
    [types.SET_IS_SUPER_OFFER_FETCHED]: StoreUtil.createSimpleMutator('isSuperOfferFetched'),
    [types.SET_ARE_TOP_TEN_EVENTS_FETCHED]: StoreUtil.createSimpleMutator('areTopTenEventsFetched'),
    [types.SET_ODD_VALUE_DYNAMICS]: StoreUtil.createSimpleMutator('oddValueDynamics'),
    [types.SET_EXPANDED_MARKET_IDS]: StoreUtil.createSimpleMutator('expandedMarketIds'),
    [types.TOGGLE_EXPANDED_MARKET_ID]: StoreUtil.createSetMutator('expandedMarketIds'),
    [types.SET_ARE_SUBSCRIPTIONS_SET]: StoreUtil.createSimpleMutator('areSubscriptionsSet'),
};

function freezeOdds(match: Match) {
    match.setOdds(match.getOdds().map((odd) => Object.freeze(odd)));
}

import { REQUEST_TIMEOUT } from '@core/constants';
import RestRequestFactory from './RestRequestFactory';
import { AxiosRequestConfig } from 'axios';
import config from '@config';

export default class CTRestRequestFactory {

    static create(
        baseUrl?: string,
        withCredentials = false,
        timeout = REQUEST_TIMEOUT,
        ) {
        const request = RestRequestFactory.create(baseUrl, withCredentials, timeout);
        request.interceptors.request.use((config: AxiosRequestConfig): AxiosRequestConfig => {
            config.data = {
                ...config.data,
                clientSourceType: this.createClientSourceTypeRequestParam(),
            };
            return config;
        });
        return request;
    }

    private static createClientSourceTypeRequestParam() {
        return config.countrySpecific.auth.portalDesktopId;
    }
}

import Odd from '@core/models/offer/Odd';
import { AtLeast } from '@superbet-group/web.lib.utilities';
import MarketDto from '@core/mappers/struct/dtos/MarketDto';
import SuperbetsService from '@core/services/superbets/SuperbetsService';

export interface IMarket {
    // @ts-ignore
    readonly id: number;
    // when we have special bet values
    // @ts-ignore
    readonly name: string;

}

export interface IMarketSimple {
    id: number;
    // when we have special bet values
    uniqueId?: string;
    name: string;
    showSpecialBetValue: boolean;
}

export function isSuperbet(marketId: number, marketName:string) {
    return SuperbetsService.getInstance().isSuperbet(marketId, marketName);
}

export default class Market implements IMarketSimple {
    id: number;
    // when we have special bet values
    uniqueId?: string;
    originalName: string;
    name: string;
    outrightMarketName: string;
    odds: Odd[] = [];
    isLocked = false;
    order: number;
    tags: string;

    constructor({
        id,
        name,
        outrightMarketName,
        order,
        isLocked = false,
        odds = [],
        tags = '',
        uniqueId = '',
        originalName = '',
    }: {
        id: number;
        name: string;
        outrightMarketName: string;
        order: number;
        isLocked?: boolean;
        odds?: Odd[];
        tags?: string;
        uniqueId?: string;
        originalName?: string;
    }) {
        this.id = id;
        this.name = name;
        this.outrightMarketName = outrightMarketName;
        this.odds = odds;
        this.isLocked = isLocked;
        this.order = order;
        this.tags = tags;
        this.uniqueId = uniqueId;
        this.originalName = originalName;
    }
    showSpecialBetValue: boolean;

    get isMain() {
        return this.odds[0] && this.odds[0].isMain();
    }

    get isInPromotion() {
        return this.odds[0] && this.odds[0].isInPromotion;
    }

    get isSuperbet() {
        return isSuperbet(this.id, this.name);
    }

    public containsAttribute(attribute: string) {
        return this.tags.includes(attribute);
    }
}
export function createMarket([id, marketDto]: [string, AtLeast<MarketDto, 'bgn'>]): Market {
    // @ts-ignore
    return {
        id: parseInt(id, 10),
        name: marketDto.bgn,
    };
}

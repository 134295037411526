import ServerConfig from '@core/models/country/ServerConfig';
import { instanceGuard } from '@core/utils/services';
import Singleton from '../common/Singleton';
import Market from '@core/models/struct/Market';

class SuperbetsService {
    private static instance: SuperbetsService;
    private superbetsRange: [number, number] | null;

    public static getInstance(): SuperbetsService {
        return instanceGuard(SuperbetsService.instance);
    }

    public static createInstance(config: ServerConfig) {
        if (!SuperbetsService.instance) {
            SuperbetsService.instance = new SuperbetsService(config);
        }
        return SuperbetsService.instance;
    }

    private constructor(
        private config: ServerConfig,
    ) {
        this.superbetsRange = config.superbets.superbetsRange;
    }

    public isSuperbet(marketId: Market['id'], marketName: string) {
        return marketName.includes(';');
        // if (this.superbetsRange) {
        //     return marketId >= this.superbetsRange[0] && marketId <= this.superbetsRange[1];
        // }
        // return false;
    }

    public static clearInstance() {
        if (process.env.NODE_ENV !== 'test') {
            throw new Error('For use in tests only');
        }
        delete SuperbetsService.instance;
    }
}

export default SuperbetsService as Singleton<SuperbetsService>;

import SelectionChange from '@models/betSlip/SelectionChange';
import { map as _map } from '@lodash';
import StoreUtil from '@store/StoreUtil';
import IBetSlipState from '../types';

export default {
    changes(state: IBetSlipState): SelectionChange[] {
        return _map(state.changes, (change) => change);
    },
    autoUpdateChanges: StoreUtil.createSimpleGetter('autoUpdateChanges'),
};

import {
    getDefaultLayout,
} from '@app/router/utils';
import { routeNames, links, titles } from '@app/router/constants';
// terminal imports
import ResultsPage from '@src/terminal/app/pages/ResultsPageTerminal.vue';
import SportResultsContainer from '@src/terminal/app/modules/results/sportOffer/SportResultsContainer.vue';
import SportSidebar from '@src/terminal/app/modules/results/leftSidebar/SportSidebar.vue';
import DatePickerContainer from '@src/terminal/app/modules/results/datePicker/DatePickerContainer.vue';

const dateFormat = '20\\d{2}-\\d\\d-\\d\\d'; // 2018-03-22

const entityFormat = '[a-zA-Z]+[\\w-]+'; // tenis , tenis-de-masa, grupa2

const sportResultsPath = `${links.sportResults}/\
:sportSlug(${entityFormat})?/\
:categorySlug(${entityFormat})?/\
:tournamentSlug(${entityFormat})?`;

const liveResultsPath = `${links.liveResults}/\
:sportSlug(${entityFormat})?/\
:categorySlug(${entityFormat})?/\
:tournamentSlug(${entityFormat})?`;

export default [
    {
        path: links.results,
        name: routeNames.results,
        components: {
            ...getDefaultLayout(ResultsPage),
        },
        meta: {
            title: titles.results
        },
        redirect: routeNames.sportResults,
        children: [
            {
                path: `${sportResultsPath}/:date(${dateFormat})?`,
                name: routeNames.sportResults,
                components: {
                    leftSidebar: SportSidebar,
                    mainContent: SportResultsContainer,
                    rightSidebar: DatePickerContainer
                },
                meta: {
                    title: titles.sportResults,
                }
            },
            {
                path: `${liveResultsPath}/:date(${dateFormat})?`,
                name: routeNames.liveResults,
                components: {
                    leftSidebar: SportSidebar,
                    mainContent: SportResultsContainer,
                    rightSidebar: DatePickerContainer
                },
                meta: {
                    title: titles.liveResults,
                }
            }
        ],
    },
];

<template>
    <div class="result-tournament--terminal">
        <div class="details-header--terminal">
            <div class="details-header__event-collection--terminal">
                <span class="details-header__event-collection-wrapper--terminal">
                    <span class="details-header__event-collection-title--terminal">{{ name }}</span>
                </span>
            </div>
            <div
                v-if="arePeriodsShown"
                class="score-helper__wrapper"
            >
                <span
                    v-for="period in periodNames"
                    :key="period"
                    class="score-helper__item"
                >
                    {{ period }}
                </span>
            </div>
        </div>
        <slot />
    </div>
</template>

<script>
    export default {
        inheritAttrs: false,
        props: {
            name: {
                type: String,
                required: true,
            },
            periodNames: {
                type: Array,
                required: true,
            },
            arePeriodsShown: {
                type: Boolean,
                required: false,
                default: true,
            }
        },
    };
</script>

import RestService from '@core/services/results/_private/RestService';
import Match from '@core/models/offer/Match';
import IResponse from '@core/utils/types/IResponse';
import { EventMapper } from '@core/mappers/offer/EventMapper';
import ServerConfig from '@core/models/country/ServerConfig';
import StructManager from '@core/services/common/StructManager';
import * as NetworkUtils from '@utils/network/NetworkUtils';
import { instanceGuard } from '@core/utils/services';
import SingletonError from '../common/errors/SingletonError';
import Singleton from '../common/Singleton';
import { EventDto } from '@superbet-group/offer.clients.lib';

class ResultsService {

    private static instance: ResultsService;

    public static getInstance(): ResultsService {
        return instanceGuard(ResultsService.instance);
    }

    public static createInstance(config: ServerConfig) {
        if (!ResultsService.instance) {
            ResultsService.instance = new ResultsService(config);
        }
        return ResultsService.instance;
    }

    private restService: RestService;
    private eventMapper: EventMapper = new EventMapper();

    private constructor(
        private config: ServerConfig,
    ) {
        if (ResultsService.instance) {
            throw new SingletonError(this.constructor.name);
        }
        this.restService = new RestService(this.config);
    }

    public setRestService(restService: RestService) {
        this.restService = restService;
    }

    // ============================
    // Rest methods
    // ============================

    public async getMatchResults(startDate: Date, endDate: Date, sportId?: number): Promise<Match[]> {
        const response: IResponse<EventDto[]> = await NetworkUtils.processResponse(
            this.restService.getMatchResults(startDate, endDate, sportId)
        );
        const matches = this.eventMapper.map(response.data, true);
        StructManager.getInstance().denormalizeEvents(matches);
        return matches;
    }

    public async getMatchResultDetails(matchIds: number[], oddsResults: boolean = true): Promise<Match[]> {
        const response: IResponse<EventDto[]> = await NetworkUtils.processResponse(
            this.restService.getMatchResultDetails(matchIds, oddsResults)
        );
        const matches = this.eventMapper.map(response.data, true);
        StructManager.getInstance().denormalizeEvents(matches);
        return matches;
    }

    public static clearInstance() {
        if (process.env.NODE_ENV !== 'test') {
            throw new Error('For use in tests only');
        }
        delete ResultsService.instance;
    }
}

export interface IResultService extends ResultsService {}
export default ResultsService as Singleton<ResultsService>;

<template>
    <router-link
        :to="{ name: routeName }"
        class="static-link"
        v-bind="$attrs"
    >
        {{ $t(label) | capitalize }}
    </router-link>
</template>

<script>
    export default {
        name: 'CustomRouterLink',
        props: {
            routeName: {
                type: String,
                required: true,
            },
            label: {
                type: String,
                required: true,
            },
        },
    };
</script>

<style scoped>
</style>

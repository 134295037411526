export enum TimeFilter {
    live = 'live',
    upcoming = 'upcoming',
    today = 'today',
    sunday = 'sunday',
    monday = 'monday',
    tuesday = 'tuesday',
    wednesday = 'wednesday',
    thursday = 'thursday',
    friday = 'friday',
    saturday = 'saturday',
    all = 'all',
}

import { ITask } from '@src/terminal/core/services/gravity/_private/Task';
import store from '@src/app/store';

class SlaveSnoozeTask implements ITask {
    execute = async (): Promise<void> => {
        await store.dispatch('ui/sportOffer/betSlip/clear', null, { root: true });
    };
}

export default SlaveSnoozeTask;

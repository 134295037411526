import Odd from '@core/models/offer/Odd';
import { OddDto } from '@superbet-group/offer.clients.lib';
import { SuperOffer } from '@superbet-group/betting.lib.payments';


export class OddMapper {
    createTargetObject(dto: OddDto, key: number, isSpecialFromSportOffer: boolean): Odd {
        const odd: Odd = new Odd();

        odd.uuid = dto.uuid;
        odd.marketUuid = dto.marketUuid;
        odd.id = dto.outcomeId;
        odd.value = dto.price;
        odd.marketId = dto.marketId;
        odd.marketCode = isSpecialFromSportOffer && !dto.marketCode ? '0' : dto.marketCode;
        odd.marketTag = dto.marketTags;
        odd.status = dto.status || '';
        odd.marketOrder = dto.marketGroupOrder;
        odd.offerType = dto.offerStateId;
        odd.showSpecialBetStatus = +dto.showSpecialBetValue;
        odd.tags = dto.tags || '';
        odd.code = dto.code;
        odd.specialBetValue = dto.specialBetValue || '';
        odd.name = dto.name || '';
        odd.specifiers = dto.specifiers;

        // fields for outrights
        odd.name = dto.name || '';
        odd.marketName = dto.marketName || '';

        //prefix is no longer sent via offer, so we add it manually for super offer
        odd.prefix = getSuperOfferPrefix(odd.tags);

        odd.initialMarketName = dto.marketName;
        odd.description = dto.info;

        return odd;
    }

    public map(source: OddDto[], isSpecialFromSportOffer: boolean = false): Odd[] {
        return source.map((item, key) =>
            this.createTargetObject(item, key, isSpecialFromSportOffer)
        );
    }
}

function getSuperOfferPrefix(tags: string | undefined) {
    if (!tags) {
        return '';
    }

    const tagsArray = tags.split(',');

    if (tagsArray.includes(SuperOffer.superKvota)) {
        return 'SC';
    } else if (tagsArray.includes(SuperOffer.superExtra)) {
        return 'SE';
    } else if (tagsArray.includes(SuperOffer.superSix)) {
        return 'S6';
    } else {
        return '';
    }
}

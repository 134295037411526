<template>
    <Header
        :liveEventsCount="eventCount"
        :isOnSportLivePage="isOnSportLivePage"
        :isOnSportsPage="isOnSportsPage"
        :isOnResultsPage="isOnResultsPage"
        @pageClick="onPageClick"
    />
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import { errorGuard } from '@app/utils';
    import Header from '@countryModules/layout/header/components/Header.vue';

    export default {
        name: 'HeaderContainer',
        computed: {
            ...mapGetters('navigation', [
                'isOnSportLivePage',
                'isOnSportsPage',
                'isOnResultsPage'
            ]),
            ...mapGetters('data/sportOffer', [
                'isOfferReady',
            ]),
            ...mapGetters('data/sportOffer', ['liveEventsCount']),
            ...mapGetters('data/country', ['isLiveInMaintenance']),
            eventCount() {
                return this.isLiveInMaintenance ? 0 : this.liveEventsCount;
            },
        },
        methods: {
            ...mapActions('data/country', [
                'fetchConfig'
            ]),
            ...mapActions('ui/modal', [
                'setModal',
            ]),
            ...mapActions('ui/sportOffer/sports', [
                'clearSelectedFeaturedItem',
            ]),
            async onPageClick() {
                this.clearSelectedFeaturedItem();
                await errorGuard({
                    action: () => {
                        this.fetchConfig();
                    }
                });
            }
        },
        components: {
            /* eslint-disable vue/no-unused-components */
            Header,
            /* eslint-enable vue/no-unused-components */
        }
    };
</script>

import { Subscription } from 'rxjs';
import { createEventSourceObservable } from '@core/services/offer/_private/helpers/eventSourceObservable';
import BetBuilderRequest from './BetBuilderRequest';
import { createQueryParamsString } from '@utils/network/NetworkUtils';
import { filter } from 'rxjs/operators';
import ServerConfig from '@core/models/country/ServerConfig';
import { OddDto } from '@superbet-group/offer.clients.lib';


export type SSEOddDTO = { timestamp: number; data?: OddDto };
export type BetBuilderOddMessage = { timestamp: number; data: OddDto };

export default class SSEManager {
    private config: ServerConfig;
    private sseHostEndpoint: string;

    constructor(config: ServerConfig) {
        this.config = config;

        this.sseHostEndpoint = `${ config.offer.betBuilder.betBuilderStreamingHost }`;
    }

    public subscribeToOddChanges(
        matchId: number,
        oddUuid: string,
        target: string,
        lang: string,
        callback: (data: BetBuilderOddMessage) => void
    ): Subscription {
        const params = BetBuilderRequest.createRequest(matchId, oddUuid, target, lang);
        return createEventSourceObservable<SSEOddDTO>(
            ` ${ this.sseHostEndpoint }/v2/stream/trackBetbuilderOddStatus?${ createQueryParamsString(
                params
            ) }`
        )
            .pipe(filter((data: SSEOddDTO) => !!data.data))
            .subscribe(callback as any);
    }
}

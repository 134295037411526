import Socket from './Socket';
import { Subscription } from 'rxjs/internal/Subscription';

export default class SocketManager {
    private socket: Socket;

    constructor(
        private url: string,
        private socketPath: string,
    ) { }

    /**
     * @throws {RequestError}
     * @throws {Error}
     */
    public startStreaming(): void {
        this.socket = new Socket(this.url, this.socketPath);
        this.socket.start();
    }

    public subscribeToTicketOutcome(callback: (payload: any) => void): Subscription {
        return this.socket.getSocketTicketOutcome$().subscribe(callback);
    }

    public subscribeToTicketSubmitStatus(callback: (payload: any) => void): Subscription {
        return this.socket.getSocketTicketSubmitStatus$().subscribe(callback);
    }

    public subscribeToTerminalTicketCheck(callback: (payload: any) => void): Subscription {
        return this.socket.getTerminalTicketCheck$().subscribe(callback);
    }

    /**
     * Emits ticket check request,
     * with the request id that was issued out during the ticket submit request
     *
     * @param ticketSubmitReqId request id received from the retail service on ticket submit
     */
    public requestTerminalTicketCheck(ticketSubmitReqId: string): void {
        this.socket.emitTerminalTicketCheckRequest(ticketSubmitReqId);
    }

    public requestTerminalLog(log: any): void {
        this.socket.emitTerminalLogRequest(log);
    }

    public subscribeToTerminalStatus(callback: (payload: any) => void): Subscription {
        return this.socket.getTerminalStatus$().subscribe(callback);
    }

    public subscribeToConnectErrorStream(callback: (error: Error) => void): Subscription {
        return this.socket.getSocketConnectError$().subscribe(callback);
    }

    public subscribeToDisconnectedStream(callback: () => void): Subscription {
        return this.socket.getSocketDisconnected$().subscribe(callback);
    }

    public subscribeToConnectedStream(callback: (value: any) => void): Subscription {
        return this.socket.getSocketConnected$().subscribe(callback);
    }

    public subscribeToErrorStream(callback: (error: Error) => void): Subscription {
        return this.socket.getSocketError$().subscribe(callback);
    }

    public subscribeToReconnectedStream(callback: () => void): Subscription {
        return this.socket.getSocketReconnected$().subscribe(callback);
    }

    /**
     * @throws {RequestError}
     * @throws {Error}
     */
    public stopStreaming(): void {
        this.socket.stop();
    }
}

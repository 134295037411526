export function sortBy<T>(items: T[] | Record<string, T>, comparators: Comparator<T> | Array<Comparator<T>>): T[] {
    const comparatorsArray = Array.isArray(comparators) ? comparators : [comparators];

    return [...ensureArray(items)].sort((item1, item2) => {
        for (const comparator of comparatorsArray) {
            const compare = typeof comparator === 'function' ? comparator : comparatorForProperty(comparator);

            const result = compare(item1, item2);

            if (result !== 0) {
                return result;
            }
        }

        return 0;
    });
}

export type Comparator<T> =
    | ((a: T, b: T) => number)
    | keyof T;

function ensureArray<T>(data: T[] | Record<string, T>): T[] {
    return Array.isArray(data) ? data : Object.values(data);
}

function comparatorForProperty<T>(propertyName: keyof T): ((a: T, b: T) => number) {
    return (a: T, b: T): number => {
        const valueA = a[propertyName];
        const valueB = b[propertyName];

        if (valueA < valueB) {
            return -1;
        }

        if (valueA > valueB) {
            return 1;
        }

        return 0;
    };
}

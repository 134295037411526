<template>
    <MaintenanceChecker
        :checkedParts="checkedParts"
        class="w-full"
    >
        <template slot="message">
            <div class="maintenance-mode__offer--terminal">
                <h2>{{ $t('Maintenance in progress')}}</h2>
            </div>
        </template>
        <div class="search__page flex w-full">
            <Sidebar />
            <OfferContainer
                class="main--terminal"
                :isFetcher="true"
            >
                <SearchContainer/>
            </OfferContainer>
            <div class="right-sidebar">
                <BetSlipContainer/>
            </div>
        </div>
    </MaintenanceChecker>
</template>

<script>
    import SearchContainer from '@src/terminal/app/modules/sportOffer/search/SearchContainer.vue';
    import OfferContainer from '@src/terminal/app/modules/sportOffer/OfferContainer.vue';
    import BetSlipContainer from '@src/terminal/app/modules/sportOffer/betslip/BetSlipContainer.vue';
    import Sidebar from '@src/terminal/app/modules/sportOffer/search/sidebar/Sidebar.vue';
    import { MaintenanceType } from '@core/models/country/enums';
    import MaintenanceChecker from '@shared/components/MaintenanceChecker.vue';


    export default {
        name: 'SearchPage',
        data() {
            return {
                checkedParts: [MaintenanceType.sport]
            };
        },
        components: {
            SearchContainer,
            OfferContainer,
            BetSlipContainer,
            Sidebar,
            MaintenanceChecker,
        },
    };
</script>

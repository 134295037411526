import Singleton from '@core/services/common/Singleton';
import { instanceGuard } from '@core/utils/services';
import common from '@src/config/common';

export enum LDProject {
  SERVICES_STATUS = 'SERVICES_STATUS',
  DEFAULT_PROJECT = 'DEFAULT_PROJECT',
}

export interface DeviceDetails {
  uuid: string;
  // code: string;
}

export interface BetshopDetails {
  uuid: string;
  code: string;
  name: string;
  address: string;
}

export interface TerminalDetails {
  externalId: string;
  code: string;
}

export interface ShopDetails {
  externalId: string;
  code: string;
  name: string;
  address: string;
  type: string;
}

export interface TerminalContext {
  kind: 'terminal';
  key: string;
  code: string;
  channel: string;
}

export interface ShopContext {
  kind: 'shop';
  key: string;
  code: string;
  name: string;
  address: string;
  type: string;
}

export interface IFlag {
  project: LDProject;
  name: string;
  defaultValue: any;
  description: string;
}

export type LDCallback = <T>(value: T, previous: T) => void;

export const flags: Record<string, IFlag> = {
  inPlay: {
    project: LDProject.SERVICES_STATUS,
    name: 'retail.sport.live',
    defaultValue: true,
    description: 'determines if the in-play product available',
  },
  useAllowancesV2: {
    project: LDProject.DEFAULT_PROJECT,
    name: 'ssbt.use-allowances-v2',
    defaultValue: true,
    description: 'determines if the allowance V2 feature is enabled',
  },
  isProgressiveLuckyLoser: {
    project: LDProject.DEFAULT_PROJECT,
    name: 'ssbt.use-pariul-sansa-progresiv',
    defaultValue: false,
    description: 'determine if we are using pariurl sansa progresiv',
  },
  generosityControlEnabled: {
    project: LDProject.DEFAULT_PROJECT,
    name: 'ssbt.generosity-control-enabled',
    defaultValue: true,
    description: 'determine if we are using double generosity',
  },
  fastlyWafEnabled: {
    project: LDProject.DEFAULT_PROJECT,
    name: 'ssbt.fastly-waf',
    defaultValue: true,
    description: 'determine if we are using fastly urls for rest api or legacy ones',
  },
  platformV2: {
    project: LDProject.DEFAULT_PROJECT,
    name: 'ssbt.use-sportsbook-w-platform-v2',
    defaultValue: false,
    description: 'determine if we are using integration for platformV1 or platformV2',
  },
  inactivityTimerValue: {
    project: LDProject.DEFAULT_PROJECT,
    name: 'ssbt.stop-gap-inactivity-timer-value',
    defaultValue: 0,
    description: 'flag which provides configuration for the stop-gap solution of inactivity monitor for SportsBook product',
  },
};

class FeatureFlagService {
  private static instance: FeatureFlagService;
  private clients: Record<LDProject, LDClient.LDClient> = {
    [LDProject.SERVICES_STATUS]: null,
    [LDProject.DEFAULT_PROJECT]: null,
  };
  private context: LDClient.LDContext;

  public async init(context: LDClient.LDContext) {
    this.clients[LDProject.SERVICES_STATUS] = LDClient.initialize(common.environment.ldServicesStatus, context);
    this.clients[LDProject.DEFAULT_PROJECT] = LDClient.initialize(common.environment.ldDefaultProject, context);
    return Promise.all([
      this.clients[LDProject.SERVICES_STATUS].waitUntilReady(),
      this.clients[LDProject.DEFAULT_PROJECT].waitUntilReady(),
    ]);
  }

  public static createInstance(): FeatureFlagService {
    if (!FeatureFlagService.instance) {
      FeatureFlagService.instance = new FeatureFlagService();
    }

    return FeatureFlagService.instance;
  }

  public static getInstance(): FeatureFlagService {
    return instanceGuard(FeatureFlagService.instance);
  }

  public flag<T>(flag: IFlag): T {
    try {
      return this.clients[flag.project].variation(flag.name, flag.defaultValue) as T;
    } catch (error) {
      console.error('SSBT:Variation fetch error', 'default value', flag.defaultValue, 'for', flag.name, error);
      return flag.defaultValue;
    }
  }

  /**
   * @returns {boolean} Whether in-play is available or not
   */
  public isInPlayAvailable(): boolean {
    return this.flag<boolean>(flags.inPlay);
  }

  public useAllowancesV2(): boolean {
    return this.flag<boolean>(flags.useAllowancesV2);
  }

  public isProgressiveLuckyLoser(): boolean {
    return this.flag<boolean>(flags.isProgressiveLuckyLoser);
  }

  public generosityControlEnabled(): boolean {
    return this.flag<boolean>(flags.generosityControlEnabled);
  }

  public fastlyWafEnabled(): boolean {
    return this.flag<boolean>(flags.fastlyWafEnabled);
  }

  public usePlatformV2(): boolean {
    return this.flag<boolean>(flags.platformV2);
  }

  public inactivityTimerValue(): number {
    return this.flag<number>(flags.inactivityTimerValue);
  }

  public async subscribeToFlagChange<T>(flag: IFlag, callback: LDCallback, context?: LDClient.LDContext) {
    this.clients[flag.project].on(`change:${flag.name}`, callback, context);
  }

  public async unSubscribeToFlagChange<T>(flag: IFlag, callback: LDCallback, context?: LDClient.LDContext) {
    this.clients[flag.project].off(`change:${flag.name}`, callback, context);
  }

  public async close() {
    Object.values(this.clients).forEach((client) => client.close());
  }

  public setNSoftCtx(device: DeviceDetails) {
    const deviceContext = {
      kind: 'terminal',
      key: device.uuid,
      // code: device.code,
      channel: 'LARGE_TERMINAL',
    };

    // const targetContext = {
    //     kind: 'target',
    //     key: 'SB_RO',
    // }

    // const betshopContext = {
    //   kind: 'shop',
    //   key: betshop.uuid,
    //   code: betshop.code,
    //   name: betshop.name,
    //   address: betshop.address,
    // };

    const multiContext = {
      kind: 'multi',
      terminal: deviceContext,
      //   target: targetContext,
      // shop: betshopContext,
    };

    this.context = multiContext;
    return this.init(this.context);
  }

  public setInHouseCtx(terminal: TerminalDetails, shop: ShopDetails) {
    const terminalContext = {
      kind: 'terminal',
      key: terminal.externalId,
      code: terminal.code,
      channel: 'SMALL_TERMINAL',
    };

    // const targetContext = {
    //     kind: 'target',
    //     key: 'SB_RO',
    // }

    const shopContext = {
      kind: 'shop',
      key: shop.externalId,
      code: shop.code,
      name: shop.name,
      address: shop.address,
      type: shop.type,
    };

    const multiContext: LDClient.LDContext = {
      kind: 'multi',
      terminal: terminalContext,
      //   target: targetContext,
      shop: shopContext,
    };

    this.context = multiContext;
    return this.init(this.context);
  }
}

export default FeatureFlagService as unknown as Singleton<FeatureFlagService>;

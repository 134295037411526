import RestRequestFactory from '@utils/network/RestRequestFactory';
import config from '@config';

export default class ConfigRestService {
    private readonly request: any;

    constructor(restRequestFactory = RestRequestFactory) {
        this.request = restRequestFactory.create(config.services.countryConfig.host);
    }

    /**
     * @throws {RequestError}
     */
    public getConfig(): Promise<any> {
        return this.request({
            url: '/rest/SBWeb.Models.Session/returnSiteParams',
            method: 'get',
        });
    }

    /**
     * @throws {RequestError}
     */
    public getDirectusConfig(): Promise<any> {
        return this.request({
            url: '',
            method: 'get',
        });
    }
}

import { TicketStatus, TicketType } from '@core/models/tickets/enums';
import Ticket, { EventCondensed } from '@core/models/tickets/Ticket';
import TicketService from '@core/services/tickets/TicketService';
import router from '@src/app/router';
import { routeNames } from '@src/app/router/constants';
import store from '@store/index';
import FeatureFlagService from '../../flags/FeatureFlagService';

export default async (ticketId: string) => {
  const filteredEvents = (events: EventCondensed[]) => {
    if (FeatureFlagService.getInstance().isInPlayAvailable()) {
      return events;
    }
    // const isEventLive = (event: EventCondensed) => event.type !== EventType.live;
    const isStartedEvent = (event: EventCondensed) => event.date! < new Date();
    return events.filter((event: EventCondensed) => !isStartedEvent(event));
  };

  const mapTicketForRecreate = (ticket: Ticket) => {
    return {
      id: ticket.id,
      type: ticket.type,
      status: ticket.status,
      coefficient: ticket.coefficient,
      system: ticket.system,
      potentialWin: ticket.win.estimated,
      minimumPotentialWin: ticket.win.payoff,
      payoff: ticket.win.payoff,
      events: filteredEvents(ticket.events),
      isWon: ticket.status === TicketStatus.win,
      hasOngoingEvents: filteredEvents(ticket.events).length > 0,
      dateReceived: ticket.dateReceived,
      stake: ticket.payment.total,
      numEventsLeft: ticket.events.length,
      canRecreate: true,
    };
  };

  const cachedTicket = await TicketService.getInstance().getTicketById(ticketId);
  const ticketType = TicketType.sport;
  cachedTicket.type = ticketType;
  const mappedTicketData = mapTicketForRecreate(cachedTicket);
  store.dispatch('ui/ticketsStack/recreateTicket', mappedTicketData, { root: true });
  router.push({ name: routeNames.landing });
};

import Match from '@core/models/offer/Match';
import { has as _has } from '@lodash';

import Logger from '@loggers/Logger';

/**
 * Specifiers are a way to display dynamic names for bet groups or odd types.
 * For example:
 * {$competitor1} has winned round {round}
 * in this case we use competitor description for {$competitor1} and specifiers field for {round} and we will get:

 * Dokovic has winned round 2
 */
export class LiveBettingSpecifiersFormatter {
    private static team1Placeholder = '{$competitor1}';
    private static team2Placeholder = '{$competitor2}';
    private static regexp = /{.+?}/g;

    public format(
        input: string,
        specifiers: Record<string, string>,
        event: Readonly<Match>,
    ): string {
        let result = input;
        const placeholders = input.match(LiveBettingSpecifiersFormatter.regexp);
        let specifier;

        if (placeholders) {
            placeholders.forEach((placeholder) => {
                switch (placeholder) {
                    case LiveBettingSpecifiersFormatter.team1Placeholder:
                        result = result.replace(placeholder, event.team1Name);
                        break;
                    case LiveBettingSpecifiersFormatter.team2Placeholder:
                        result = result.replace(placeholder, event.team2Name);
                        break;
                    default:
                        specifier = placeholder.replace(/[^a-zA-Z0-9_]/g, '');

                        if (_has(specifiers, specifier)) {
                            const specifierValue = this.getSpecifierValueWithPrefix(
                                specifiers[specifier],
                                this.getPrefix(placeholder)
                            );

                            result = result.replace(
                                placeholder,
                                specifierValue + this.getSufix(placeholder)
                            );
                            break;
                        }
                }
            });
        }

        return result;
    }

    public static replacePlaceholdersWithXYZ(input: string): string {
        let result = input;
        const placeholders = input.match(LiveBettingSpecifiersFormatter.regexp);

        if (placeholders) {
            placeholders.forEach((placeholder) => {
                switch (placeholder) {
                    case LiveBettingSpecifiersFormatter.team1Placeholder:
                        result = result.replace(placeholder, 'X');
                        break;
                    case LiveBettingSpecifiersFormatter.team2Placeholder:
                        result = result.replace(placeholder, 'Y');
                        break;
                    default:
                        result = result.replace(placeholder, 'X');
                        break;
                }
            });
        }

        return result;
    }

    /**
     * Method used to calculate SBV prefix +/- before number, so handicap games are clear and
     * transparent in which way handicap goes.
     *
     * TODO we really need to see this in action to have good understand and good test cases
     */
    private getSpecifierValueWithPrefix(value: string, prefix: string): string {
        if (prefix === '+') {
            return value;
        }

        try {
            if (prefix === '-') {
                return (-parseFloat(value)).toString();
            }
        } catch (e) {
            Logger.error(e as Error);
        }

        return prefix + value;
    }

    private getSufix(partToReplace: string): string {
        // TODO WHY?
        if (partToReplace.includes('!')) {
            return '.';
        }

        return '';
    }

    private getPrefix(partToReplace: string): string {
        //  TODO WHY?
        if (partToReplace.includes('+')) {
            return '+';
        }

        if (partToReplace.includes('-')) {
            return '-';
        }

        return '';
    }
}

export default new LiveBettingSpecifiersFormatter();

import { ITask } from '@src/terminal/core/services/gravity/_private/Task';
import { isPrinterReady } from '@src/terminal/core/services/gravity/_private/helpers';

class PeripheralsStateChangedTask implements ITask {
    execute = (message?: any): void => {
        isPrinterReady(message.data.printer);
    };
}

export default PeripheralsStateChangedTask;

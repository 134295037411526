<template>
    <div>
        <div
            v-for="(error, index) in errors"
            :key="index"
        >
            <Error
                :showCloseButton="showCloseButton"
                :duration="duration"
                :error="getErrorMessage(error)"
                :shouldFadeOut="shouldFadeOut"
                @fadeOut="$emit('fadeOut', error)"
            />
        </div>
    </div>
</template>

<script>
    import Error from '@shared/betSlip/Error.vue';
    import BetSlipHelper from '@store/modules/ui/shared/betSlip/Helpers';

    export default {
        name: 'Errors',
        props: {
            showCloseButton: {
                type: Boolean,
                required: false,
                default: true,
            },
            errors: {
                // String[] or IBetSlipError[]
                type: Array,
                required: true,
            },
            areValidationErrors: {
                type: Boolean,
                required: false,
                default: false
            },
            shouldFadeOut: {
                type: Boolean,
                required: false,
                default: false
            },
            duration: {
                type: Number,
                required: false,
            },
        },
        methods: {
            getErrorMessage(error) {
                if (this.areValidationErrors) {
                    return this.$t(
                        BetSlipHelper.getBetSlipErrorMessageTemplate(error),
                        error.params
                    );
                }
                return error;
            }
        },
        components: {
            Error,
        }
    };
</script>
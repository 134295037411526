export default abstract class ResilientSocket {
    protected isConnected = false;

    protected onSocketConnect() {
        this.isConnected = true;
    }

    protected onSocketDisconnect() {
        this.isConnected = false;
    }

    protected abstract onSocketReconnect: Function;

}

export const REQUEST_TIMEOUT = 1000 * 60;
export const SOCKET_CONNECTION_ERROR_LOGGER_THROTTLE = 1000 * 60 * 5; // ms
export const SOCKET_BUFFER_TIME = 1000; // ms
export const SSE_BUFFER_TIME = 2000; // ms
export const SOCKET_RETRY_N_TIMES_ON_ERROR = 10; // ms

export enum MarketAttribute {
    main = 'preselected'
}
export const smsLimitNotice = 'Ai atins numărul maxim de SMS-uri trimise, te rugăm să contactezi echipa de suport';

export const promotionsPrefix = 'P-';

export const competitorDelimiter = '·';
export const competitorDelimiter2 = ' - ';
export class SportInfo {
    constructor(public id: number) {}

    static MAX_NUMBER_OF_PERIODS = 5;

    // TODO
    get periodNames() {
        switch (this.id) {
            case 2:
                return ['G', 'S', 'M']; // tennis
            case 1: // volleyball
            case 24: // table tennis
            case 28: // beach volleyball
                return [
                    'currentPeriodScore',
                    'previousPeriodScore',
                    'totalScore',
                ];
            default:
                return [
                    'totalScore',
                    'currentPeriodScore',
                    'previousPeriodScore',
                ];
        }
    }

    periodNamesFull(periods: any[]) {
        let periodNames = [] as (string | number)[];
        switch (this.id) {
            case 2:
                periodNames = ['G', 'S', 'M'];
                break;
            default:
                periodNames = periods.map((p, i) => i + 1);
                periodNames.unshift('T');
        }
        return periodNames;
    }

}
<template>
    <div>
        <div
            v-for="type in types"
            :key="type.type"
            class="tab-item--terminal actionable"
            @click="onClick(type)"
            :class="{ active: selected === type.type, disabled: type.disabled }"
        >
            <i
                v-if="type.icon"
                :class="type.icon"
            >
            </i>
            {{ type.displayName }}
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';

    export default Vue.component('TypeSwitcher', {
        props: {
            types: {
                type: Array,
                required: true,
            },
            selected: {
                type: [String, Number],
                required: false,
                default: null,
            },
            name: {
                type: String,
                default: null,
            },
        },
        methods: {
            onClick(item) {
                if (item.disabled) {
                    return;
                }
                const value = item.type;
                this.$emit('click', value);
                // todo remove input and valueChanged, make new component for onlineDeposit
                this.$emit('input', value);
                this.$emit('valueChanged', value, this.name);
            },
        }
    });
</script>
import { interval, Subscription } from 'rxjs';
import RestService from './_private/RestService';
import SocketManager from './_private/SocketManager';
import Session from '@models/authentication/Session';
import SportBetSlipToTicketDTOMapper from '@core/mappers/tickets/SportBetSlipToTicketDTOMapper';
import ICoreTicketMapper from '@core/mappers/tickets/ICoreTicketMapper';
import PlayedTicketMapper from '@core/mappers/tickets/PlayedTicketMapper';
import IPlayedTicketDto from '@core/mappers/tickets/dtos/IPlayedTicketDto';
import IBetSlip from '@models/betSlip/IBetSlip';
import TicketNegotiationMapper from '@core/mappers/tickets/TicketNegotiationMapper';
import { TicketNegotiation } from '@models/tickets/TicketNegotiation';
import ServerConfig from '@core/models/country/ServerConfig';
import { instanceGuard } from '@core/utils/services';
import SingletonError from '../common/errors/SingletonError';
import Singleton from '../common/Singleton';
import { TicketType } from '@core/models/tickets/enums';
import ISubmittingTicketDto from '@core/mappers/tickets/dtos/ISubmittingTicketDto';
import AbstractMapper from '@core/utils/mappers/AbstractMapper';
import Ticket from '@core/models/tickets/Ticket';

export enum SortAttribute {
    dateLastModified = 'dateLastModified',
}

export interface GetTicketOptions {
    status?: 'active' | 'finished';
    type?: 'sports';
    count?: number;
    lastId?: number;
    sort?: 'dateLastModified';
}

class TicketService {

    private static instance: TicketService;

    private ticketById: Subscription;

    public static getInstance(): TicketService {
        return instanceGuard(TicketService.instance);
    }

    public static createInstance(config: ServerConfig) {
        if (!TicketService.instance) {
            TicketService.instance = new TicketService(config);
        }
        return TicketService.instance;
    }

    private constructor(
        private config: ServerConfig,
        private restService = new RestService(config),
        private socketManager = new SocketManager(),
        private sportTicketMapper = new SportBetSlipToTicketDTOMapper(),
        private iCoreTicketMapper = new ICoreTicketMapper(),
        private playedTicketMapper = new PlayedTicketMapper(),
        private ticketNegotiationMapper = new TicketNegotiationMapper(),
    ) {
        if (TicketService.instance) {
            throw new SingletonError(this.constructor.name);
        }
    }

    async getTickets(session: Session, options: GetTicketOptions) {
        const tickets = await this.restService.getTickets(session, options) as any;
        return tickets.map((ticket: IPlayedTicketDto) => this.playedTicketMapper.createTargetObject(ticket));
    }

    getTicket = async (ticketId: string) => {
        const ticket = await this.restService.getTicket(ticketId) as any;
        return ticket && this.playedTicketMapper.createTargetObject(ticket);
    }

    getTicketById = (ticketId: string) => this.restService.getTicket(ticketId);

    subscribeToTicketsChanges(sessionId: string, callback: (value: any) => void) {
        return this.socketManager.subscribeToTicketsChanges(sessionId, (ticket: IPlayedTicketDto) => {
            const t = this.playedTicketMapper.createTargetObject(ticket);
            callback(t);
        });
    }

    unsubscribeFromTicketsChanges(subscription: Subscription) {
        this.socketManager.unsubscribeFromTicketsChanges(subscription);
    }

    cancelTicket(ticketId: string, controlCode: string): Promise<any> {
        return this.restService.cancelTicket(ticketId.toUpperCase(), controlCode);
    }
    payoutTicket(ticketId: string, controlCode: string, cardSerialNumber?: string): Promise<any> {
        return this.restService.payoutTicket(ticketId.toUpperCase(), controlCode, cardSerialNumber);
    }

    unsubscribeFromTicketById(): void {
        if (this.ticketById) {
            this.ticketById.unsubscribe();
        }
    }

    subscribeToTicketById(ticketId: string, callback: (value: any) => void): void {
        this.unsubscribeFromTicketById();
        this.ticketById = interval(5000)
            .subscribe(async () => {
                const ticket = await this.getTicketById(ticketId);
                callback(ticket);
            });
    }

    requestReprintTickets = (username: string, password: string) => {
        const limit = this.config.tickets.reprintLastTicketsLimit;
        return this.restService.requestReprintTickets(username, password, limit);
    }

    reprintTicketById = async (username: string, password: string, ticketId: string) => {
        const ticketData = await this.restService.reprintTicketById(username, password, ticketId);
        const ticketType = ticketData.ticketType;
        const ticket = this.iCoreTicketMapper.createTargetObject(ticketData, { type: ticketType }) as any;
        ticket.isCopied = true;
        return ticket;
    }

    async submitTicket<T extends IBetSlip , K extends AbstractMapper<T, ISubmittingTicketDto>>(
        betSlip: T,
        mapper: K,
        type: TicketType,
    ): Promise<TicketNegotiation | Ticket> {
        const ticketDto = mapper.createTargetObject(betSlip);
        const response = await this.restService.submitTicket({ ...ticketDto });

        if (response.data.negotiationData) {
            return {
                ...this.ticketNegotiationMapper.createTargetObject(response.data.negotiationData),
                ticketData: response.data.ticketData,
                ticketId: response.data.ticketId || null,
                isNegotiation: true
            } as TicketNegotiation;
        }

        return this.iCoreTicketMapper.createTargetObject(response.data.ticketData, { type });
    }

    async submitSportTicket(betSlip: IBetSlip) {
        return this.submitTicket(betSlip, this.sportTicketMapper, TicketType.sport);
    }

    /**
     * autoAcceptChanges param in IBetSlip makes the user accept Odd changes automatically
     */
    async respondToTicketNegotiation(negotiation: TicketNegotiation, hasAccepted: boolean) {
        const response = await this.restService.respondToTicketNegotiation(negotiation, hasAccepted);

        if (!hasAccepted) {
            return;
        }
        return this.iCoreTicketMapper.createTargetObject(response.data.ticketData, { type: TicketType.sport });
    }

    startStreaming() {
        return this.socketManager.startStreaming();
    }

    stopStreaming() {
        return this.socketManager.stopStreaming();
    }

    public static clearInstance() {
        if (process.env.NODE_ENV !== 'test') {
            throw new Error('For use in tests only');
        }
        delete TicketService.instance;
    }
}

export default TicketService as Singleton<TicketService>;

import AbstractMapper from '@core/utils/mappers/AbstractMapper';
import { EventCondensed } from '@core/models/tickets/Ticket';
import { EventStatus } from '@core/models/tickets/enums';
import ISelection from '@core/models/betSlip/ISelection';
import SportSelection from '@core/models/betSlip/SportSelection';

export default class BetSlipSelectionToTicketEventMapper extends AbstractMapper<ISelection, EventCondensed> {

    createTargetObject(origin: ISelection) {
        const e = {} as Partial<EventCondensed>;

        if (origin instanceof SportSelection) {
            const event = origin.event;
            e.date = event.date;
            e.eventId = event.id.toString();
            e.name = event.fullName;
            e.status = EventStatus.active;
            e.externalIds = {
                betradar: event.betRadarId !== undefined ? event.betRadarId.toString() : undefined,
            };
            const odd = origin.odd!;

            // we can't submit ticket if we have nullable odds so odd! is just fine
            e.odd = {
                oddId: odd.id.toString(),
                coefficient: odd.value,
                isFix: origin.isFixed,
                systemGroupName: '', // only available on submitted tickets,
                name: odd.name,
                specialValue: odd.specialBetValue,
                marketName: odd.marketName,
            };
        } else {
            // lotto event
            throw 'Not implemented';
        }

        return e as EventCondensed;
    }
}

import i18n from '@app/localization/i18n';
import slugify from '@utils/url/slugify';
import { capitalize } from '@shared/filters';
import { liveTimeFilterSlug } from '@store/modules/ui/shared/timeFilter/utils';

export const OFFER_PREFIX = `/${slugify(i18n.t('sport bets').toString())}`

export const VUE_ROUTER_CONTROLLED = 'data-vue-router-controlled';

export const resultsRouteNames = {
    ticketReprint: 'ticketReprint',
    checkTicket: '/checkTicket',
    sportResults: 'sportResults',
    liveResults: 'liveResults',
    agenciesResults: 'agenciesResults',
    virtualResults: 'virtualResults',
    virtualShopsResults: 'virtualShopsResults',
};

export const resultsLinks = {
    ticketReprint: '/ticketReprint',
    checkTicket: '/check-ticket',
    sportResults: `${slugify(i18n.t('sport').toString())}`,
    liveResults: `${slugify(i18n.t('live').toString())}`,
    virtualResults: `${slugify(i18n.t('virtual').toString())}`,
    virtualShopsResults: `${slugify(i18n.t('virtual shops').toString())}`,
};

const resultsTitles = {
    sportResults: `${i18n.t('sport results').toString()}`,
    liveResults: `${i18n.t('live results').toString()}`,
    virtualResults: `${i18n.t('virtual results').toString()}`,
    virtualShopsResults: `${i18n.t('virtual shops results').toString()}`
};

export const links = {
    sportBets: `/${slugify(i18n.t('sport bets').toString())}`,
    landing: '/',
    live: `${OFFER_PREFIX}/${liveTimeFilterSlug}`,
    filter: `/${slugify(i18n.t('filter').toString())}`,
    search: `/${slugify(i18n.t('search').toString())}`,
    teams: `/${slugify(i18n.t('teams').toString())}`,
    results: `/${slugify(i18n.t('results').toString())}`,
    profile: `/${slugify(i18n.t('profile').toString())}`,
    info: `/${slugify(i18n.t('info').toString())}`,
    matchesLanding: `/${slugify(i18n.t('matches').toString())}`,
    wiki: '/wiki',
    help: `/wiki/${i18n.t('help-url').toString()}`,
    verifyTicket: `/${slugify(i18n.t('verify').toString())}`,
    page_404: '/404',
    page_401: '/401',
    register: `/${slugify(i18n.t('register').toString())}`,
    ...resultsLinks,
};

export const titles = {
    landing: i18n.t('Sports and live betting'),
    lottery: i18n.t('Lottery betting - Greece Kino, Win for Life, Numerone'),
    results: i18n.t('Results of sport events, lotto events'),
    register: capitalize(i18n.t('register').toString()),
    info: capitalize(i18n.t('info').toString()),
    verifyTicket: i18n.t('Verify Ticket'),
    notFound: capitalize(i18n.t('page not found').toString()),
    notAuthorized: capitalize(i18n.t('page not authorized').toString()),
    ...resultsTitles,
};

export const routeNames = {
    landing: 'Landing',
    sports: 'Sports',
    sportOffer: 'Sports/sportOffer',
    filter: 'Filter',
    search: 'Search',
    lotteryGame: 'Lottery Game',
    lotteryLanding: 'Lottery landing',
    lotterySystem: 'Lottery system',
    results: 'Results',
    register: 'Register',
    info: 'Info',
    matchesLanding: 'LinkLandingPage',
    verifyTicket: 'Verify Ticket',
    notAuthorized: 'Not Authorized',
    ...resultsRouteNames,
};

<template functional>
    <div class="event-betslip-summary">
        <div class="event-betslip-summary__match-indicator-wrapper">
            <template v-if="!props.isLive">
                <span class="event-betslip-summary__match-indicator-day" >
                    {{ props.startDate | formatDateTime(props.dateFormat(props.startDate, props.isInGrid)) }}
                </span>
                <span class="event-betslip-summary__match-indicator-time">{{ props.startDate | timeOnly }}</span>
            </template>
            <template v-else>
                <span class="event-betslip-summary__match-indicator-live">
                    <span class="event-betslip-summary__match-indicator-live-dot"></span>
                    <span class="event-betslip-summary__match-indicator-live-text">
                        {{ parent.$t('live') | upperCase }}
                    </span>
                </span>
                <span class="event-betslip-summary__match-indicator-period">
                    {{ props.liveData && `${props.liveData.minute ? props.liveData.minute + "'" : '' }
                     ${props.liveData.isStoppageTime
                    ? '+' + props.liveData.stoppageTime + "'"
                    : props.liveData.periodStatus }` }}
                </span>
            </template>
        </div>
        <div class="event-betslip-summary__competitors-and-scores">
            <div class="event-betslip-summary__competitors-wrapper">
                <span class="event-betslip-summary__competitors-team1" :title="props.team1Name">
                    <span
                        v-if="props.team1PreviousResult"
                        :title="parent.$t('previuos match result') | capitalize"
                        class="event-betslip-summary__competitors-past-result"
                    >
                        {{ props.team1PreviousResult }}
                    </span>
                    {{ props.team1Name }}
                </span>
                <span class="event-betslip-summary__competitors-team2" :title="props.team2Name">
                    <span
                        v-if="props.team2PreviousResult"
                        :title="parent.$t('previuos match result') | capitalize"
                        class="event-betslip-summary__competitors-past-result"
                    >
                        {{ props.team2PreviousResult }}
                    </span>
                    {{ props.team2Name }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    import Tooltip from '@modules/shared/components/Tooltip.vue';
    import { capitalize } from '@modules/shared/filters';
    import config from '@config';
    import DateUtils from '@core/utils/date/DateUtils';
    import ScoreWidget from './ScoreWidget.vue';

    export default {
        functional: true,
        props: {
            team1Name: {
                type: String,
                required: true,
            },
            team2Name: {
                type: String,
                required: true,
            },
            team1PreviousResult: {
                type: String,
                required: false,
                default: ''
            },
            team2PreviousResult: {
                type: String,
                required: false,
                default: ''
            },
            viewingOptions: {
                type: Object,
                required: false,
                default: null,
            },
            isLive: {
                type: Boolean,
                required: true,
            },
            startDate: {
                type: Date,
                required: true,
            },
            liveData: {
                type: Object,
                required: false,
                default: null,
            },
            result: {
                type: Object,
                default: null,
            },
            sportId: {
                type: Number,
                required: false,
            },
            isScoreCompact: {
                type: Boolean,
                default: true,
            },
            isInGrid: {
                type: Boolean,
                required: false,
                default: false,
            },
            isCodeShown: {
                type: Boolean,
                required: false,
                default: false,
            },
            code: {
                type: [Number, String],
                required: false,
                default: ''
            },
            cap: {
                type: Function,
                required: false,
                default: capitalize,
            },
            dateFormat: {
                type: Function,
                required: false,
                default: (date, isInGrid) => {
                    const now = new Date();
                    const isInFuture = DateUtils.differenceInDays(now, date)
                        > config.app.sportOffer.gridDateFutureThreshold;

                    if (isInFuture) {
                        return 'ddmm';
                    }

                    return isInGrid ? 'w' : 'ddmm';
                },
            },
            components: {
                type: Object,
                default() {
                    return {
                        ScoreWidget,
                        Tooltip,
                    };
                }
            }
        },
    };
</script>

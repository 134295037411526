<template>
    <div>
        <VirtualScroller
            v-if="gridData.length"
            :items="gridData"
            contentTag="div"
            :pageMode="false"
            buffer="1800"
            style="height: 94.5vh;"
        >
            <template slot-scope="props">
                <SectionHeader
                    v-if="props.item.type === componentTypes.sport"
                    :icon="`icon-sport-${props.item.id}`"
                >
                    {{ props.item.name }}
                </SectionHeader>
                <TournamentSection
                    v-else
                    v-bind="props.item.data"
                    :arePeriodsShown="true"
                >
                    <MatchRow
                        v-for="match in props.item.data.matches"
                        :key="match.id"
                        :id="match.id"
                        :team1Name="match.team1Name"
                        :team2Name="match.team2Name"
                        :code="match.code"
                        :date="match.date"
                        :results="match.matchResults"
                        :isLoading="isLoading"
                    />
                </TournamentSection>
            </template>
        </VirtualScroller>
        <div
            v-else
            class="empty-state__offer--terminal"
        >
            <Illustration large image="/static/img/illustrations/terminal/offer__empty-state--dark.svg"/>
            <div class="empty-state__offer-copy">
                {{ $t('no results found for') | capitalize }}: <i>{{ selectedDate | formatDateTime('ddmmyyyy')}}</i>
            </div>
        </div>
    </div>
</template>

<script>
    import SectionHeader from '@src/terminal/app/modules/shared/components/SectionHeader.vue';
    import { VirtualScroller } from 'vue-virtual-scroller';
    import { mapActions, mapGetters } from 'vuex';
    import { errorGuard } from 'src/app/utils';
    import MatchRow from './MatchRow.vue';
    import TournamentSection from './TournamentSection.vue';
    import { generateVirtualScrollData, sportGridState, TypeId } from './GridUtil';
    import Illustration from '@shared/components/Illustration.vue';

    export default {
        created() {
            this.componentTypes = TypeId;
            if (this.expandedEventId) {
                this.fetchEventDetails(this.expandedEventId);
            }
        },
        data() {
            return {
                isLoading: false,
            };
        },
        props: {
            matches: {
                type: Array,
                required: true,
            },
            areMatchesExpandable: {
                type: Boolean,
                required: true,
            },
        },
        computed: {
            ...mapGetters('data/sportOffer', [
                'sports',
                'tournaments',
            ]),
            ...mapGetters('ui/results', [
                'expandedEventId',
                'selectedDate'
            ]),
            gridData() {
                return generateVirtualScrollData(this.matches, {
                    sports: this.sports,
                    tournaments: this.tournaments,
                    expandedEventId: this.expandedEventId,
                });
            }
        },
        methods: {
            ...mapActions('data/results/sport', [
                'fetchSportResultById',
            ]),
            ...mapActions('ui/results/sport', [
                'toggleExpandedEventId',
            ]),
            onMatchRowClick(matchId) {
                if (!this.areMatchesExpandable) {
                    return;
                }
                if (matchId !== this.expandedEventId) {
                    this.fetchEventDetails(matchId);
                }
                this.toggleExpandedEventId(matchId);
            },
            isExpanded(matchId) {
                return this.expandedEventId === matchId;
            },
            async fetchEventDetails(matchId) {
                this.isLoading = true;
                await errorGuard({
                    action: () => this.fetchSportResultById(matchId)
                });
                this.isLoading = false;
            },
            onRowHeightChanged(height) {
                sportGridState.selectedEventHeight = height;
            }
        },
        components: {
            VirtualScroller,
            MatchRow,
            TournamentSection,
            SectionHeader,
            Illustration,
        },
    };
</script>

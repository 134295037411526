export default class Period {
    team1Score: number;
    team2Score: number;
    number: number;
    type: string;

    get score(): string {
        return `${this.team1Score}:${this.team2Score}`;
    }
}

<template>
    <div class="betslip__controls--terminal">
        <button
            v-if="undoSelections && !isOfflineTicket"
            @click="$emit('undoClear')"
            class="betslip__controls-action--terminal"
        >
            <span
                v-show="selectionsCount > 0"
                class="betslip__controls-action__text--terminal"
            >
                {{selectionsCount}}
            </span>
            <i class="icon icon-delete"></i>
        </button>
        <button
            v-else-if="selectionsCount > 0 && !isOfflineTicket"
            @click="$emit('clear')"
            class="betslip__controls-action--terminal"
        >
            <span
                v-show="selectionsCount > 0"
                class="betslip__controls-action__text--terminal"
            >
                {{selectionsCount}}
            </span>
            <i class="icon icon-delete"></i>
        </button>
    </div>
</template>

<script>
    export default {
        name: 'Toolbar',
        props: {
            selectionsCount: {
                type: Number,
                required: true,
            },
            undoSelections: {
                type: Array,
                required: false,
                default: null
            },
            selectionNames: {
                type: String,
                required: true,
            },
            isOfflineTicket: {
                type: Boolean,
                required: false,
                default: false,
            },
            totalCoefficient: {
                type: String,
                required: false,
                default: null,
            },
        },
    };
</script>

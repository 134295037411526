<template>
    <div class="date-time">
        <span>
            {{ now | formatDateTime('ddmmyyyy') }}
        </span>
        <span>
            {{ now | formatDateTime('time') }}
        </span>
    </div>
</template>

<script>
    export default {
        name: 'DateTimeDisplay',
        data() {
            return {
                now: new Date()
            };
        },
        mounted() {
            this.$options.interval = setInterval(this.updateDateTime, 1000);
        },
        beforeDestroy() {
            clearInterval(this.$options.interval);
        },
        methods: {
            updateDateTime() {
                const next = new Date();
                if (this.now.getMinutes() !== next.getMinutes()) {
                    this.now = next;
                }
            }
        },
    };
</script>
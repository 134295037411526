<template>
    <Tree
        :items="tree"
        :activeIds="breadcrumbIds"
        :showItemCount="true"
        :showToplevelFlag="true"
        @itemClick="selectTreeItem"
    />
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import Tree from 'src/terminal/app/modules/shared/components/tree/Tree.vue';

    export default {
        computed: mapGetters('ui/results/sport', [
            'tree',
            'breadcrumbIds',
        ]),
        methods: mapActions('ui/results/sport', [
            'selectTreeItem',
        ]),
        components: {
            Tree,
        },
    };
</script>

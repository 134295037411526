<script>
    import { format as formatDate } from 'date-fns';
    import { routeNames } from '@app/router/constants';
    import i18n from '@src/app/localization/i18n';
    import commonConfig from '@src/config/common';

    export default {
        name: 'ResultTypePicker',
        // has to be computed if we want the functionality that the date
        // stays the same when we change virtuals type
        computed: {
            selectedItem() {
                return this.items.find((x) => x.isSelected);
            },
            items() {
                const currentDateParam = formatDate(new Date(), 'YYYY-MM-DD');
                const {
                    params: { date: dateParam = currentDateParam },
                    name: routeName,
                } = this.$route;
                const params = {
                    date: dateParam,
                };
                let items = [
                    {
                        label: `${i18n.t('sport')}`,
                        to: { name: routeNames.sportResults, params },
                        isSelected: routeName === routeNames.sportResults,
                    },
                    {
                        label: `${i18n.t('specials')}`,
                        to: { name: routeNames.specialsResults, params },
                        isSelected: routeName === routeNames.specialsResults,
                    },
                ];
                return items;
            },
        },
        render() {
            return this.$scopedSlots.default({
                items: this.items,
                selectedItem: this.selectedItem,
            });
        },
    };
</script>

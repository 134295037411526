<template>
    <MaintenanceChecker :checkedParts="checkedParts" class="w-full">
        <template slot="message">
            <div class="maintenance-mode__offer--terminal">
                <h2>{{ $t('Maintenance in progress')}}</h2>
            </div>
        </template>
        <div class="results__container w-full flex">
            <div class="main">
                <ResultsTypePicker :class="{'is-expanded': isRightSidebarOpen }"/>
                <router-view name="rightSidebar" class="date-picker--main--terminal"/>
                <MaintenanceChecker :checkedParts="checkedParts" class="w-full">
                    <template slot="message">
                        <div class="flex w-full">
                            {{ $t('Results are currently under maintenance') }}
                        </div>
                    </template>
                    <router-view name="mainContent"/>
                </MaintenanceChecker>
            </div>
            <div class="right-sidebar--terminal">
                <router-view name="rightSidebar" class="date-picker--right"/>
            </div>
        </div>
    </MaintenanceChecker>
</template>

<script>
    import { MaintenanceType } from '@core/models/country/enums';
    import ResultsTypePicker from '@src/terminal/app/modules/results/ResultTypePicker.vue';
    import { resultsRouteNames as routeNames } from '@app/router/constants';
    import MaintenanceChecker from '@shared/components/MaintenanceChecker.vue';

    export default {
        name: 'ResultsPageTerminal',
        created() {
            this.stickyConfig = {
                scrollableContentSelector: '.sidebar__responsive__element',
                staticHigherContentSelector: '.sidebar_static__top',
            };
        },
        computed: {
            checkedParts() {
                switch (this.$route.name) {
                    case routeNames.sportResults:
                        return [MaintenanceType.sportResults];
                    case routeNames.liveResults:
                        return [MaintenanceType.liveResults];
                    case routeNames.virtualResults:
                        return [MaintenanceType.virtualOnlineResults];
                    case routeNames.virtualShopsResults:
                        return [MaintenanceType.virtualShopResults];
                    default:
                        return [];
                }
            },
        },
        components: {
            ResultsTypePicker,
            MaintenanceChecker
        }
    };
</script>

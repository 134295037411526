import { get as _get, set as _set, cloneDeep as _cloneDeep } from '@lodash';

export const redact = (object: any, path: string) => _set(object, path, 'value redacted');

export const redactStringAtPath = (object: any, path: string) => {
    const cloneObj = _cloneDeep(object);
    const strVal = _get(cloneObj, path, null);
    if (!strVal) return object;
    return _set(cloneObj, path, `****${strVal.slice(-4)}`);
};

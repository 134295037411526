export enum MaintenanceType {
    global = 'global',
    sport = 'sport',
    live = 'live',
    casino = 'casino',
    casinoLive = 'casinolive',
    sportResults = 'sport_results',
    liveResults = 'live_results',
    virtualShopResults = 'virtuals_shop_results',
    virtualOnlineResults = 'virtuals_online_results'
}

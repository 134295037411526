export enum BannerCategory {
    quickLinks = 'quickLinks',
    promotions = 'promotions',
    skyscrapers = 'skyscrapers',
    games = 'games',
    promotedTournaments = 'promotedTournaments'
}

export enum ActionType {
    openPopup = 'openPopup',
    openNewTab = 'openNewTab',
    openInAppLink = 'openInAppLink',
}

<template>
    <Modal
        v-if="modal"
        :code="modal.code"
        :title="modal.title"
        :containerClass="modal.containerClass"
        :overlayClass="modal.overlayClass"
        :modalComponent="modalComponent"
        :setModal="setModal"
        :closeModal="onClose"
        :isIndependent="modal.isIndependent"
        @close="onClose"
    />
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import availableModals from '@countryModals/availableModals';
    import Modal from './Modal.vue';

    export default {
        name: 'ModalContainer',
        computed: {
            ...mapGetters('ui/modal', [
                'modal',
            ]),
            modalComponent() {
                const modal = availableModals[this.modal.code];

                if (!modal) {
                    throw Error(`No such modal code: ${this.modal.code}! Check ModalContainer.vue`);
                }

                return modal;
            }
        },
        methods: {
            ...mapActions('ui/modal', [
                'setModal',
                'closeModal',
                'closeAllModals',
            ]),
            onClose() {
                const afterClose = this.modal.afterClose;
                if (this.modal.goBack) {
                    this.closeModal();
                } else {
                    this.closeAllModals();
                }

                if (afterClose) {
                    afterClose(this.$store.dispatch);
                }
            }
        },
        components: {
            Modal,
        }
    };
</script>

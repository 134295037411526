<template>
    <expandable-list class="search-results__section-wrapper--terminal">
        <li
            v-for="item in list"
            :key="item.id"
            class="search-results__row--terminal"
        >
            <i class="icon--md icon-info search-results__icon--terminal"></i>
            <router-link
                :to="createLink(item)"
                class="search-results__link--terminal"
            >
                {{ item[labelPropery] }}
            </router-link>
            <span class="search-results__details--terminal">Details</span>
        </li>
    </expandable-list>
</template>

<script>
    export default {
        props: {
            list: {
                type: Array,
                default: () => [],
            },
            linksPrefix: {
                type: String,
                default: ''
            },
            labelPropery: {
                type: String,
                default: 'name',
            },
            linkProperty: {
                type: String,
                default: 'url',
            }
        },
        methods: {
            createLink(item) {
                return `${this.linksPrefix}/${item[this.linkProperty]}`;
            },
        }
    };

</script>

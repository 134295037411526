import {
    links,
    OFFER_PREFIX,
    routeNames,
    titles
} from '@app/router/constants';
import { Route } from 'vue-router';
import {
    getDefaultLayout,
    timeFilterOptions
} from '@app/router/utils';
import SportsPage from '@src/terminal/app/pages/SportsPageTerminal.vue';
import SearchPage from '@src/terminal/app/pages/SearchPage.vue';
import TicketReprintPage from '@src/terminal/app/pages/TicketReprintPage.vue';
import CheckTicket from '@src/terminal/app/pages/CheckTicket.vue';

export default [
    {
        path: links.sportBets,
        redirect: links.landing
    },
    {
        path: links.ticketReprint,
        name: routeNames.ticketReprint,
        components: {
            ...getDefaultLayout(TicketReprintPage),
        },
    },
    {
        path: links.checkTicket,
        name: routeNames.checkTicket,
        components: {
            ...getDefaultLayout(CheckTicket),
        },
    },
    {
        path: `${links.landing}${timeFilterOptions}?`,
        name: routeNames.landing,
        components: {
            ...getDefaultLayout(SportsPage),
        },
        meta: {
            title: titles.landing,
        },
        beforeEnter(to: Route, from: Route, next: Function) {
            next();
        },
    },
    // must be set before the next route
    {
        path: `${OFFER_PREFIX}/:sportSlug?/:categorySlug?/:tournamentSlug?/${timeFilterOptions}`,
        name: routeNames.sportOffer,
        components: {
            ...getDefaultLayout(SportsPage),
        },
        meta: {
            title: titles.landing
        }
    },
    /*
        When spare time fix
        Duplicate named routes definition:
            { name: "Sports/sportOffer", path: "/offer/:sportSlug?/:categorySlug?/:tournamentSlug?" }
    */
    {
        path: `${OFFER_PREFIX}/:sportSlug?/:categorySlug?/:tournamentSlug?`,
        name: routeNames.sportOffer,
        components: {
            ...getDefaultLayout(SportsPage),
        },
        meta: {
            title: titles.landing
        }
    },
    {
        path: links.search,
        name: routeNames.search,
        components: getDefaultLayout(SearchPage),
        meta: {
            title: titles.landing
        }
    },
];

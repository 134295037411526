<template>
    <div>
        <div v-if="!isEmpty">
            <div class="betslip-selection__list--terminal">
                <transition-group
                    name="animation-list__slide-in-left-"
                    tag="div"
                    class="betslip-selection__list-wrapper--terminal"
                >
                    <!-- Inverting betslip selections so the new selections are more visible, possible filter -->
                    <SystemBetSlipItem
                        v-for="selection in selections.slice().reverse()"
                        :selection="selection"
                        :error="selectionErrors[selection.getEventId()]"
                        :key="getId(selection)"
                        :broadcasts="broadcasts"
                        :oddValueDynamics="oddValueDynamics"
                        :checkShowSbv="checkShowSbv"
                        @fixClick="onFixClick"
                        @removeClick="onRemoveClick"
                        class="betslip-selection__item--terminal"
                    >
                    </SystemBetSlipItem>
                </transition-group>
            </div>
            <div
                class="betslip-system-container--terminal"
                @click="onSystemsClick(systems, fixedSelectionsCount)"
            >
                {{systemsPrint || $t('chooseSystems') | upperCase}}
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import Modals from '@store/modules/ui/shared/modal/modals';
    import SystemBetSlipItem from './SystemBetSlipItem.vue';
    import { shouldShowSpecialBetValue } from '@core/models/offer/Odd';

    export default {
        name: 'SystemBetSlip',
        props: {
            selections: {
                type: Array,
                required: true
            },
            selectionErrors: {
                type: Object,
                required: false,
                default() {
                    return {};
                }
            },
            systems: {
                type: Array,
                required: true
            },
            isEmpty: {
                type: Boolean,
                required: true
            },
            fixedSelectionsCount: {
                type: Number,
                required: true
            },
            broadcasts: {
                type: Object,
                required: true
            },
            oddValueDynamics: {
                type: Object,
                required: false,
            },
        },
        computed: {
            ...mapGetters('data/sportOffer', ['markets']),
            systemsPrint() {
                const systems = this.systems.filter((system) => system.isSelected);
                if (systems && systems[0]) {
                    const first = systems[0].toString();
                    const affix = first.substr(first.indexOf('/'), first.length - 1);
                    const prettySystems = systems
                        .map((system) => system.toString().substr(0, system.toString().indexOf('/')));
                    if (this.fixedSelectionsCount <= 0) {
                        return `${prettySystems}${affix}`;
                    }
                    return `${this.fixedSelectionsCount}F ${prettySystems}${affix}`;
                }
                return null;
            },
        },
        methods: {
            ...mapActions('ui/modal', [
                'setModal',
            ]),
            getId(selection) {
                return selection.getOddUuid() || selection.getEventId();
            },
            onSystemClick(system) {
                this.$emit('systemClick', system);
            },
            onFixClick(item) {
                this.$emit('fixClick', item);
            },
            onRemoveClick(item) {
                this.$emit('removeClick', item);
            },
            onSystemsClick(systems, fixedSelectionCount) {
                this.setModal({
                    code: Modals.sportSystems.code,
                    containerClass: 'systems_modal--terminal',
                    data: {
                        button: {
                            enabled: true,
                            className: 'btn modal-btn--systems--terminal btn--block',
                        },
                        systems,
                        fixedSelectionCount,
                    }
                });
            },
            checkShowSbv(marketId, isSpecial) {
                return shouldShowSpecialBetValue(marketId, this.markets, isSpecial);
            },
        },
        components: {
            SystemBetSlipItem
        },
    };
</script>

import AbstractMapper from '@core/utils/mappers/AbstractMapper';
import { MarketGroupDto } from '@superbet-group/offer.clients.lib';
import MarketGroupInfo from '@models/struct/MarketGroupInfo';
import common from '@src/config/common';

export class MarketGroupMapper extends AbstractMapper<MarketGroupDto, MarketGroupInfo> {
    createTargetObject(dto: MarketGroupDto): MarketGroupInfo {
        return {
            id: dto.id,
            name: dto.localNames[common.offerLang] || '',
            order: dto.order,
            sportId: dto.sportId,
            marketIds: dto.markets !== null ? new Set(dto.markets) : new Set(),
        };
    }
}

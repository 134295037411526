import AbstractMapper from '@core/utils/mappers/AbstractMapper';
import Tournament from '@models/struct/Tournament';
import common from '@src/config/common';
import { TournamentDto } from '@superbet-group/offer.clients.lib';

export class TournamentMapper extends AbstractMapper<TournamentDto, Tournament> {
    createTargetObject(dto: TournamentDto) {
        const tournament: Tournament = new Tournament();

        tournament.id = parseInt(dto.id, 10);
        tournament.name = dto.localNames[common.offerLang];
        tournament.order = dto.order;
        tournament.orderPromoted = dto.orderPromoted;
        tournament.footer = dto.footer;

        return tournament;
    }
}

<template>
    <div class="w-full">
        <slot v-if="!isPageInMaintenance"/>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import localConfig from '@config';

    export default {
        name: 'AppHealthChecker',
        props: {
            checkedParts: {
                type: Array,
                required: true
            }
        },
        created() {
            if (this.isPageInMaintenance) {
                this.redirectToMaintenancePage();
            }
        },
        watch: {
            isPageInMaintenance(value) {
                if (value) {
                    this.redirectToMaintenancePage();
                }
            }
        },
        computed: {
            ...mapGetters('data/country', [
                'config',
            ]),
            maintenanceUrl() {
                return this.config.applicationStatus.maintenanceFrameUrl
                    || localConfig.app.country.defaultMaintenanceUrl;
            },
            isPageInMaintenance() {
                // return !this.checkedParts.every((part) => !this.config.applicationStatus.maintainedParts[part]);
                return false;
            }
        },
        methods: {
            redirectToMaintenancePage() {
                window.location.replace(this.maintenanceUrl);
            }
        },
    };
</script>

const extractKeyFromEvent = (event: KeyboardEvent) => event.key || event.keyCode;

export enum Keys {
    ESC = 'esc',
}

const isESC = (key: string | number) => key === 'Escape' || key === 'Esc' || key === 27;

export const getKey = (event: KeyboardEvent) => {
    const key = extractKeyFromEvent(event);

    if (isESC(key)) {
        return Keys.ESC;
    }
    return key;
};

export enum TicketStatus {
    InProgress = 0,
    Success = 1,
    Error = 2,
    Negotiation = 3,
    NotPrinted = 4,
}

export enum SocketEvents {
    TicketOutcome = 'terminal-ticket-outcome',
    TicketSubmitStatus = 'terminal-ticket-submit-status',
    TerminalTicketCheckRequest = 'terminal-ticket-check-request',
    TerminalLogRequest = 'terminal-logging',
    TerminalTicketCheck = 'terminal-ticket-check',
    TerminalStatus = 'terminal-status',
}

<template functional>
    <div class="section-header">
        <div class="section-header__left">
            <slot name="left-icon" >
                <i
                    v-if="props.icon"
                    class="icon section-header__icon"
                    :class="props.icon"
                />
            </slot>
            <span class="section-header__title">
                <slot />
            </span>
        </div>
        <slot name="right-icon" />
    </div>
</template>

<script>
    export default {
        name: 'SectionHeader',
        functional: true,
        name: 'SectionHeader',
        props: {
            icon: {
                type: String,
                required: false,
            }
        },
    };
</script>

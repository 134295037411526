<template>
    <div>
        <div class="ticket-check">
            <div class="ticket-check-query">
                <input @focus="showVirtualKeyboard($event, { onConfirm, onChange, onHide })" class="ticket-input" type="text"
                    v-model="ticketIdQuery" :placeholder="$t('check ticket')" />
                <button class="btn ticket-btn" @click="emitTicketScan">
                    {{ $t("submit") | upperCase }}
                </button>
            </div>
        </div>
        <div v-if="!!data">
            <ticket-overview :ticketData="data" />
            <div class="toolbar">
                <button v-if="isCancelAvailableValue" @click="cancelTicket(ticketIdQuery)" class="btn ticket-btn">
                    {{ $t("cancel ticket") | upperCase }}
                </button>
                <button v-if="isPayoutAvailableValue" @click="payoutTicket(ticketIdQuery)" class="btn ticket-btn">
                    {{ $t("payout ticket") | upperCase }}
                </button>
                <button v-if="isRebetAvailableValue" @click="rebetTicket(ticketIdQuery, product)" class="btn ticket-btn">
                    {{ $t("repeat ticket") | upperCase }}
                </button>
                <button @click="checkTicketExit" class="btn ticket-btn">
                    {{ $t("exit") | upperCase }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import TicketService from '@core/services/tickets/TicketService';
import TicketOverview from '../../modules/checkTicket/TicketOverview.vue';
import { mapGetters } from 'vuex';
import i18n from '@app/localization/i18n';
import PlatformService from '@src/terminal/core/services/platform/PlatformService';
import { TicketStatus } from '@core/models/tickets/enums';

export default {
    name: 'CheckTicket',
    components: {
        TicketOverview,
    },
    data() {
        return {
            isPayoutAvailableValue: false,
            isCancelAvailableValue: false,
            isRebetAvailableValue: false,
            ticketIdQuery: '',
            product: '',
            subscription: null,
            data: null
        };
    },
    watch: {
        checkedTicket: {
            handler(ticket) {
                if (ticket) {
                    this.ticketIdQuery = ticket.id;
                    this.checkTicketSubmit();
                }
            },
            immediate: true,
        },
    },
    computed: {
        ...mapGetters('data/country', ['checkedTicket']),
    },
    methods: {
        onConfirm(text) {
            this.ticketIdQuery = text;
            PlatformService.getInstance().scanTicket(this.ticketIdQuery);
        },
        emitTicketScan() {
            PlatformService.getInstance().scanTicket(this.ticketIdQuery);
        },
        onChange(value) {
            this.ticketIdQuery = value;
        },
        onHide() { },
        cancelTicket(id) {
            PlatformService.getInstance().checkTicketCancel(id);
        },
        payoutTicket(id) {
            PlatformService.getInstance().checkPayoutTicket(id);
        },
        rebetTicket(id, product) {
            this.checkTicketExit();
            PlatformService.getInstance().checkTicketRecreateRequest(id, product);
        },
        checkTicketExit() {
            this.data = null;
            this.ticketIdQuery = '';
            TicketService.getInstance().unsubscribeFromTicketById();
        },
        async checkTicketSubmit() {
            TicketService.getInstance().unsubscribeFromTicketById();
            this.data = null;
            const value = this.ticketIdQuery.trim().toUpperCase();
            if (!value) return;
            if (value === 'CLEAR') {
                this.checkTicketExit();
                return;
            }
            try {
                const data = await TicketService.getInstance().getTicketById(value);
                this.parseData(data);
                TicketService.getInstance().subscribeToTicketById(value, this.parseData);
            } catch (err) {
                const title = i18n.t('ticket error').toString().toUpperCase();
                PlatformService.getInstance().sendNotification({
                    title,
                    text: err.message,
                    type: 'error',
                });
            }
        },
        parseData(ticket) {
            this.product = 'SuperbetPrematch';
            const actions = {
                rebet: { active: this.isRebetAvailable(ticket) },
                cancel: { active: this.isCancelAvailable(ticket) },
            };

            this.isPayoutAvailableValue = this.isPayoutAvailable(ticket);
            this.isCancelAvailableValue = this.isCancelAvailable(ticket);
            this.isRebetAvailableValue = this.isRebetAvailable(ticket);

            this.data = {
                ticket,
                actions,
            };
        },
        isRebetAvailable(ticket) {
            return ticket.events.some((event) => event.status === TicketStatus.active);
        },
        areSomeEventsStarted(events) {
            return events.some((event) => {
                const now = new Date();
                const startDate = new Date(event.date);
                return startDate < now;
            });
        },
        isCancelAvailable(ticket) {
            if (ticket.isTemplate) return false;
            const nonCancelableStatuses = [
                TicketStatus.win,
                TicketStatus.canceled,
                TicketStatus.lost,
                TicketStatus.payed,
                TicketStatus.refund
            ];
            if (nonCancelableStatuses.includes(ticket.status)) {
                return false;
            }
            if (this.areSomeEventsStarted(ticket.events)) {
                return false;
            }
            if (ticket.status !== TicketStatus.active) return false;
            const now = new Date();
            const cancelLimit = new Date(ticket.dateReceived);
            const cancelTimeout = 60 * 15;
            cancelLimit.setSeconds(cancelLimit.getSeconds() + cancelTimeout);
            return now.getTime() < cancelLimit.getTime();
        },
        isPayoutAvailable(ticket) {
            return ticket.status === TicketStatus.win;
        },
    },
};
// script
</script>

<style lang="scss">
.app-main {
    height: auto;
    min-height: initial;
    padding: 0;
    margin: 0;
}

.toolbar {
    display: flex;

    &__item {
        margin: 4px;
    }
}

.ticket-check {
    display: flex;
    justify-content: center;
    align-items: center;

    &-query {
        display: flex;
        margin: 16px;

        .ticket-input {
            width: 450px;
            padding: 12px 20px;
            margin: 0 4px;
            border-radius: 5px;
            box-sizing: border-box;
            box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
        }
    }
}

.ticket-btn {
    font-weight: 500;
    margin: 0 4px;
    padding: 12px 20px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
    border-radius: 5px;

    color: white;
    background-color: #700042;
}
</style>


import { Commit } from 'vuex';
import StoreUtil from '@store/StoreUtil';
import { detectEffectiveDeviceType } from '@app/styles/scripts/uiHelpers';
import { getDeviceType } from '@core/utils/userAgent/UAUtils';

const SET_EFFECTIVE_DEVICE_TYPE = 'SET_EFFECTIVE_DEVICE_TYPE';

enum DeviceType {
    desktop = 'desktop',
    tablet = 'tablet',
    mobile = 'mobile',
}

export interface IDeviceInfoState {
    deviceType: DeviceType;
    effectiveDeviceType: DeviceType;
}

const detectDeviceType = (type?: string) => {
    switch (type) {
        case 'console':
        case 'mobile':
        case 'wearable':
        case 'embedded':
            return DeviceType.mobile;
        case 'tablet':
            return DeviceType.tablet;
        case 'smarttv':
            return DeviceType.desktop;
        // device type is undefined for desktops (tested on sample size of 1)
        default:
            return DeviceType.desktop;
    }
};

const deviceType = detectDeviceType(getDeviceType());
const effectiveDeviceType = detectEffectiveDeviceType();

export default {
    namespaced: true,

    state: {
        /*
            Device type doesn't have to be in the store because it doesn't have to be reactive.
            It doesn't change. It's put here so the API for reading it is consistent with other
            state properties.
        */
        deviceType,
        effectiveDeviceType,
    } as IDeviceInfoState,

    mutations: {
        [SET_EFFECTIVE_DEVICE_TYPE]: StoreUtil.createSimpleMutator('effectiveDeviceType'),
    },

    actions: {
        setEffectiveDeviceType: ({ commit } : { commit: Commit }) => {
            const type = detectEffectiveDeviceType();
            commit(SET_EFFECTIVE_DEVICE_TYPE, type);
        },
    },

    getters: {
        deviceType: StoreUtil.createSimpleGetter('deviceType'),
        effectiveDeviceType: StoreUtil.createSimpleGetter('effectiveDeviceType'),
    }
};

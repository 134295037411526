import Match from '@core/models/offer/Match';
import { Observable, Subscription } from 'rxjs';
import { isEmpty as _isEmpty } from '@lodash';
import StructManager from '@core/services/common/StructManager';
import SuperOffer from '@core/models/offer/SuperOffer';
import ServerConfig from '@core/models/country/ServerConfig';
import { instanceGuard } from '@core/utils/services';
import SingletonError from '../common/errors/SingletonError';
import Singleton from '../common/Singleton';
import { EventMapper } from '@core/mappers/offer/EventMapper';
import { SuperOfferMapper } from '@core/mappers/offer/SuperOfferMapper';
import IResponse from '@utils/types/IResponse';
import MaintenanceService from '@core/services/maintenance/MaintenanceService';
import { SSEEventMessage } from '@core/services/offer/_private/SSEManager';
import Odd from '@models/offer/Odd';
import { bufferTime, filter, map } from 'rxjs/operators';
import {
  EventDto,
  SSEEventDTO,
  SSEService as OfferSSEManager,
  OfferInterval,
  RestService as OfferRestService,
  OfferIntervalOptions,
  SSEStructDTO,
  PromotedDto,
} from '@superbet-group/offer.clients.lib';
import common from '@src/config/common';
import { SSE_BUFFER_TIME } from '@core/constants';

/**
 * Important!
 *
 * 1. Client is obliged to call startStreaming(struct) after struct is fetched and
 * before using any other method from OfferService.
 *
 * For example:
 *
 *  - struct = await offerService.getStruct()
 *  - offerService.startStreaming(struct) // after that you can use service normally
 *  - offerService.getEvents()
 *  - offerService.subscribeToEvent()
 * ...
 *
 * 2. Client is obliged to call startStreaming(struct) (and fetch new struct) after
 * every call to stopStreaming().
 *
 * For example:
 *  - switch to casino page
 *  - call offerService.stopStreaming()
 *  - switch back to sports page
 *  - struct = await offerService.getStruct()
 *  - offerService.startStreaming(struct) // after that you can use service normally
 *  - offerService.getEvents()
 *  - offerService.subscribeToEvent()
 *
 */

// tslint:disable-next-line variable-name
class OfferService {
  private static instance: OfferService;
  private restService: OfferRestService;
  private sseManager: OfferSSEManager;
  private eventMapper: EventMapper = new EventMapper();
  private superOfferMapper: SuperOfferMapper = new SuperOfferMapper();

  private constructor(private config: ServerConfig) {
    if (OfferService.instance) {
      throw new SingletonError(this.constructor.name);
    }
    const offerHost = this.config.offer.hostServer;
    this.restService = new OfferRestService(offerHost, common.offerLang, 30000, 3);
    this.sseManager = new OfferSSEManager(offerHost, common.offerLang);
  }

  public static getInstance(): OfferService {
    return instanceGuard(OfferService.instance);
  }

  public static createInstance(config: ServerConfig) {
    if (!OfferService.instance) {
      OfferService.instance = new OfferService(config);
    }
    return OfferService.instance;
  }

  /**
   * @throws {RequestError}
   * @throws {Error}
   */
  public async getLiveEvents(startDate: Date): Promise<Match[]> {

    const fetchHandler = () => this.restService.getLiveEvents(startDate);
    return this.fetchEvents(fetchHandler);
  }

  private getEventsByInterval(interval: OfferInterval, options?: OfferIntervalOptions): Promise<Match[]> {
    return this.fetchEvents(() => this.restService.getPrematchEvents(interval, options));
  }

  public getEvents(arg0: Date | OfferInterval, arg1?: Date | OfferIntervalOptions): Promise<Match[]> {

    const interval = arg0 as OfferInterval;
    const intervalOptions = arg1 as OfferIntervalOptions;
    return this.getEventsByInterval(interval, intervalOptions);
  
  }

  public async getOutrights(): Promise<Match[]> {

    const fetchHandler = () => this.restService.getOutrights();
    return this.fetchEvents(fetchHandler);
  }

  /**
   * @throws {RequestError}
   * @throws {Error}
   */
  public async getEventsById(eventIds: number[]): Promise<Match[]> {
    const events = await this.getEventsByIdV2(eventIds);

    StructManager.getInstance().denormalizeEvents(events);

    return events;
  }

  private async getEventsByIdV2(eventIds: number[]): Promise<Match[]> {
    const eventPromises = eventIds.map((eventId) => this.restService.getEventById(eventId));
    const events = await Promise.all(eventPromises);

    return this.eventMapper.map(events, true);
  }

  /**
   * @throws {RequestError}
   * @throws {Error}
   */
  public async getEvent(id: number) {
    let match: Match;
    const event = await this.restService.getEventById(id);
    match = this.eventMapper.map(event);

    // This will be done in the sport offer actions after the market groups have been updated.
    // StructManager.getInstance().denormalizeEvent(match);

    return match || null;
  }

  /**
   * @throws {RequestError}
   * @throws {Error}
   */
  public async getSuperEvents(): Promise<SuperOffer> {
    const response = await this.restService.getPromotedEvents();
    const promotedDto: PromotedDto = response;
    const superOffer = this.superOfferMapper.map(promotedDto);

    StructManager.getInstance().denormalizeEvents(superOffer.superExtra);
    StructManager.getInstance().denormalizeEvents(superOffer.superKvota);
    superOffer.superExtra = this.removeEventsInMaintenance(superOffer.superExtra);
    superOffer.superKvota = this.removeEventsInMaintenance(superOffer.superKvota);
    return superOffer;
  }

  /**
   * @throws {RequestError}
   * @throws {Error}
   */
  public getTopTenEvents(): Promise<Match[]> {

    const fetcher = () => this.restService.getTopTenEvents();
    return this.fetchEvents(fetcher);
  }

  public subscribeToStructChange(callback: ({ timestamp, data, resourceId, type, id }: SSEStructDTO) => void): Subscription {
    return this.subscribeToObservableStruct(this.sseManager.createStructChangesObservable(), callback);
  }

  public subscribeToEventSSE(
    eventId: number,
    callback: ({ resourceId, timestamp, event }: SSEEventMessage) => void,
  ): Subscription {

    return this.subscribeToObservable(this.sseManager.createEventChangesObservable(eventId), callback);
  }

  public subscribeToAllEventChangesSSE(
    callback: ({ resourceId, timestamp, event }: SSEEventMessage) => void,
  ): Subscription {

    return this.subscribeToObservable(this.sseManager.createAllEventChangesObservable(), callback);
  }

  public subscribeToAllLiveEventChangesSSE(
    callback: (liveEventMessages: SSEEventMessage[]) => void,
  ): Subscription {

    return this.subscribeToLiveEventObservable(this.sseManager.createAllLiveEventChangeObservables(), callback);
  }

  public subscribeToAllOutrightEventChanges(
    callback: ({ resourceId, timestamp, event }: SSEEventMessage) => void,
  ): Subscription {

    return this.subscribeToObservable(this.sseManager.createAllOutrightEventChangesObservable(), callback)
  }

  public denormalizeEvent(event: Match) {
    StructManager.getInstance().denormalizeEvent(event);
  }

  public denormalizeOdd(event: Match, odd: Odd) {
    return StructManager.getInstance().denormalizeOdd(event, odd);
  }

  private async fetchEvents(fetcher: () => Promise<IResponse<EventDto[]> | EventDto[]>) {
    const response = await fetcher();

    let matches: Match[];
    matches = this.eventMapper.map(response as EventDto[], true);
    StructManager.getInstance().denormalizeEvents(matches);

    return matches.filter((e) => !MaintenanceService.getInstance().isEventInMaintenance(e));
  }

  private mapSSEMessage(e: SSEEventDTO) {
    let event: Match | null = null;

    if (e.data) {
      event = this.eventMapper.map(e.data);
      StructManager.getInstance().denormalizeEvent(event);
    }

    return {
      event: event,
      resourceId: e.resourceId!.split(':')[1],
      timestamp: e.timestamp,
    };
  }

  private filterSSEMessage(e: SSEEventDTO | SSEStructDTO) {
    return Boolean(e.resourceId) && Boolean(e.timestamp);
  }

  private subscribeToObservable(
    observable: Observable<SSEEventDTO>,
    callback: ({ resourceId, timestamp, event }: SSEEventMessage) => void,
  ) {
    return observable
      .pipe(
        filter((e: SSEEventDTO) => this.filterSSEMessage(e)),
        map((e: SSEEventDTO) => this.mapSSEMessage(e)),
      )
      .subscribe(callback as any);
  }

  private mapLiveEvents(events: SSEEventDTO[]) {
    const fn = (e: SSEEventDTO) => {
        let event: Match | null = null;

        if (e.data) {
          event = this.eventMapper.map(e.data);
          StructManager.getInstance().denormalizeEvent(event);
        }

        return {
            event,
            resourceId: e.resourceId!.split(':')[1],
            timestamp: e.timestamp
        };
    };
    return events.map(fn.bind(this));
}

  private subscribeToLiveEventObservable(
    observable: Observable<SSEEventDTO>,
    callback: (liveEventMessages: SSEEventMessage[]) => void,
  ) {
    return observable
      .pipe(
        filter((e: SSEEventDTO) => this.filterSSEMessage(e)),
        bufferTime(SSE_BUFFER_TIME),
        map((e: SSEEventDTO[]) => this.mapLiveEvents(e)),
      )
      .subscribe(callback as any);
  }

  private subscribeToObservableStruct(
    observable: Observable<SSEStructDTO>,
    callback: (sseStructDto: SSEEventMessage) => void,
  ) {
    return observable.pipe(filter((e: SSEStructDTO) => this.filterSSEMessage(e))).subscribe(callback as any);
  }

  private removeEventsInMaintenance = (events: Match[]): Match[] =>
    events.filter((e) => !MaintenanceService.getInstance().isEventInMaintenance(e));

  public static clearInstance() {
    if (process.env.NODE_ENV !== 'test') {
      throw new Error('For use in tests only');
    }
    delete (OfferService as any).instance;
  }
}

export default OfferService as Singleton<OfferService>;

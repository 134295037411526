<template>
    <ResultsTypePickerContainer>
        <div
            slot-scope="{ items }"
            class="time-filter--terminal"
        >
            <router-link
                v-for="item in items"
                :key="item.label"
                :class="{ 'selected':  item.isSelected }"
                v-bind="item"
                class="time-filter__tab--terminal"
            >
                <span class="time-filter__day--terminal">
                    {{ $t(item.label) | upperCase }}
                </span>
            </router-link>
        </div>
    </ResultsTypePickerContainer>
</template>

<script>
    import ResultsTypePickerContainer from
        'src/terminal/app/shared/components/Results/ResultsTypePickerContainer.vue';

    export default {
        name: 'ResultTypePicker',
        components: {
            ResultsTypePickerContainer,
        },
    };
</script>

<style lang="scss">
    .results-picker {
        background-color: #ffffff;
        border-radius: 4px;
        -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
        height: 36px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin-bottom: 8px;
        z-index: 50;

        &__item {
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: center;
            padding: 4px;
            border-right: 1px solid #eeeeee;
            font-size: 12px;
            color: #333333;
            font-weight: 500;
            cursor: pointer;

            &.selected {
                border-bottom: 2px solid #e42313;
                background-color: #f6f6f6;
            }
        }

        .dropdown-menu:hover {
            background: #F6F6F6;
            color: #333333;
        }

        .dropdown-menu__menu {
            padding: 4px 0;
            background-color: #ffffff;
            border-radius: 4px;
        }

        .dropdown-menu__item {
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: center;
            height: 33px;
            padding: 4px;
            border-right: 1px solid #eeeeee;
            font-size: 12px;
            color: #333333;
            font-weight: 500;
            cursor: pointer;
            width: 240px;

            &:not(:last-child) {
                border-bottom: 1px solid #EEEEEE;
            }
        }
    }
</style>

import { TicketPromotional } from '@core/mappers/country/dtos/IDirectusConfigDto';
import { MaintenanceType } from './enums';
import { SuperBonusConfig, TaxBase, TaxBracket, TaxMode } from '@superbet-group/betting.lib.payments';

export enum HandlingFeeMethod {
  TaxOnNet = 2,
  TaxOnGross = 3,
}

export enum WinTaxMethod {
  OneStep = 1,
  MultiStep = 2,
}

interface ApplicationStatus {
  maintainedParts: Record<MaintenanceType, boolean>;
  maintenanceFrameUrl: string;
  /**
   * Used for application level notification.
   * Example: user plays lottery, in the mean time sport goes in maintenance.
   * We display service message to inform users about the change.
   */
  serviceMessage: string;
}

export interface TaxLimit {
  lowerBound: number;
  percentage: number;
}

interface SSBT {
  backend: string;
  backendSocket: string;
  backendAws: string;
  backendSocketAws: string;
  // silent variants
  silentBackend: string;
  silentBackendSocket: string;
  silentBackendAws: string;
  silentBackendSocketAws: string;
}

interface Tickets {
  paymentHost: string;
  paymentHostFastly: string;
  paymentHostSocket: string;
  socketPath: string;
  cacheV3: string;
  allowancesUrl?: string;
  countryKey?: string | undefined;
  virtualTournamentsEndpoint?: string;
  reprintLastTicketsLimit: number;
  ticketSubmitTimeout: number;
}

interface Offer {
  hostServer: string;
  offerLang: string;
  hostServerAllValues: string[];
  socketIoAllValues: string[];
  betBuilder: {
    betBuilderBackend: string;
    betBuilderServer: string;
    betBuilderStreamingHost: string;
  };
}

interface Auth {
  host: string;
}

interface User {
  host: string;
}

interface Country {
  host: string;
}

interface Superbets {
  superbetsRange: [number, number] | null;
}

interface BetSlip {
  stakeMax: number;
  stakeMin: number;
  minStakePerCombination: number;
  maxWin: number;
  maxSelectedNumbers: number;
  offlineTax: number;
  onlineTax: number;
  winTaxBounds: TaxLimit[];
  minOddBonus: number;
  minOddPariulSansa?: number;
  minSelectionPariulSansa?: number;
  maxBonusPercent: number;
  handlingFeeMethod: HandlingFeeMethod;
  handlingFeeInverse: boolean;
  taxPerSystem: boolean;
  taxMode: TaxMode;
  taxBase: TaxBase;
  taxBrackets: TaxBracket[];
  areSuperBonusSpecialsValidOnline: boolean;
  bonusConfig: SuperBonusConfig;
}

interface CashoutConfig {
  cashoutBackend: string;
  cashoutFrontendEnabled: boolean;
}

interface Retail {
  script?: string;
}

interface SuperSpin {
  superSpinTermsUrl?: string;
  superSpinUrl?: string;
}

interface AntiFraud {
  host: string;
}

export default interface ServerConfig {
  applicationStatus: ApplicationStatus;
  betSlip: BetSlip;
  tickets: Tickets;
  offer: Offer;
  auth: Auth;
  user: User;
  antiFraud: AntiFraud;
  country: Country;
  newsHost: string;
  general: GeneralConfig;
  cashout: CashoutConfig;
  superbets: Superbets;
  retail: Retail;
  superSpin: SuperSpin;
  ticketPromotional: TicketPromotional;
  // TODO: remove this temp configuration once the switch to aws is done
  ssbt: SSBT;
}

export interface GeneralConfig {
  timeZoneOffset: number;
}

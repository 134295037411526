import ErrorType from '@errors/network/ErrorType';

export class RequestError extends Error {
    public readonly error: Error;
    public readonly type: ErrorType;
    public readonly responseData?: any;

    constructor(rawError: Error, type: ErrorType = ErrorType.generic, responseData?: any) {
        super(rawError.message);
        // we need this if we wanna correct instanceof behaviour
        Object.setPrototypeOf(this, new.target.prototype);
        this.type = type;
        this.responseData = responseData;
        this.stack = rawError.stack;
        this.name = rawError.name;
    }
}

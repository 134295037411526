import { slugify } from '@app/utils/slugify';
export const liveTimeFilterSlug = slugify('live');
export const allTimeFilterSlug = slugify('all');
export const todayTimeFilterSlug = slugify('today');

export const getTimeFilterSlugs = () => {
    return [
        todayTimeFilterSlug,
        ...[
            'tomorrow',
            'upcoming',
            'monday',
            'tuesday',
            'wednesday',
            'thursday',
            'friday',
            'saturday',
            'sunday',
        ].map((day) => slugify(day)),
        liveTimeFilterSlug,
        allTimeFilterSlug,
    ];
};

export const defaultTimeFilterSlug = slugify('today');

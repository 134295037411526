export interface TrancheTax {
    value: number;
    percentage: number;
}

// minimum info the user sees
export interface Payout {
    base: number; // coefficient * stake + bonus
    bonus: number; // bonus
    tax: number; // total tax
    final: number; // base after tax
    minFinal: number;
    taxPercentage?: number;
    bonusPercentage?: number;
    hasBeenLimited?: boolean;
    isPariulSansa?: boolean;
    minLuckyLoserCount?: number;
    taxPerTranche?: TrancheTax[];
}

export interface Gain {
    amount: number;
    bonus?: number;
}

export interface TaxedGain extends Gain {
    afterTaxBonus: number;
    afterTaxAmount: number;
    final: number;
    tax: number;
    taxPercentage?: number;
}

export const EMPTY_PAYOUT: Payout = {
    base: 0,
    bonus: 0,
    tax: 0,
    final: 0,
    minFinal: 0,
    taxPercentage: 0,
    bonusPercentage: 0,
    hasBeenLimited: false,
};

/**
 * A rule unit which is used once betSlip validation is requested.
 * Events have rules when fetched from offer
 *
 */
export default class Rule {
    public name: string = '';
    public modifier: string = '';
    public param1: number;
    public param2: number;
}

<template>
    <div class="bonus-wrapper">
        <div class="bonus-notification">
            <i class="icon--md nav-sidebar-tree__icon icon-info_circle">
            </i>
            <div class="text">
                {{ translatedMessage }}
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'TicketNotification',

        props: {
            message: {
                required: true,
                type: String
            }
        },
        computed: {
            translatedMessage() {
                return(this.$t(this.message));
            }
        }
    };
</script>

<style scoped>

</style>
import Vue, { VNodeDirective, VNode } from 'vue';
import { getBottomMargin, topMargin, bottomFixedContentOffset } from './constants';
import { getPageBottomOffset } from './helpers';

interface Config {
    id: string;
    scrollableContentSelector: string;
    staticLowerContentSelector: string;
    staticHigherContentSelector: string;
    fixedLowerContentSelector: string;
}

interface StickyItemState {
    scrollHandler: (event: Event) => void;
    resizeHandler: (event: Event) => void;
    scrollableContentSelector: string;
    staticLowerContentSelector: string;
    staticHigherContentSelector: string;
    fixedLowerContentSelector: string;
}

const state = {} as Record<Config['id'], StickyItemState>;

const getConfig = (binding: VNodeDirective, vnode: VNode) => {
    try {
        const config = (<any>vnode.context)[binding.expression] as Config | undefined;
        if (!(config)) {
            throw new Error();
        }
        return config as Config;
    } catch (e) {
        console.warn('config required: { id: string }');
    }
};

export const adjustHeight = (el: HTMLElement) => {
    const localState: StickyItemState = state[el.id];

    const {
        scrollableContentSelector,
        staticLowerContentSelector,
        staticHigherContentSelector,
        fixedLowerContentSelector,
    } = localState;
    // the number of pixels this component would go over it's bottom limit if it were not shrunk
    const bottomMarginOverflow = Math.max(getBottomMargin() - getPageBottomOffset(), 0);

    const scrollableContent = el.querySelector(scrollableContentSelector) as HTMLElement;
    const staticContentAbove = Array.from(el.querySelectorAll(staticHigherContentSelector) as NodeListOf<HTMLElement>);
    const staticContentBelow = Array.from(el.querySelectorAll(staticLowerContentSelector) as NodeListOf<HTMLElement>);
    const fixedContentBelow = document.querySelector(fixedLowerContentSelector) as HTMLElement | undefined;

    let buffer = 0;
    if (!scrollableContent) {
        return;
    }
    // sums the height of all elements which can't be shrunk
    buffer = [...staticContentAbove, ...staticContentBelow].reduce(
        (sum, element: HTMLElement) => {
            let height = element.getBoundingClientRect().height;
            if (element.classList.contains('nav-sidebar__wrapper') || element.classList.contains('verification-bar')) {
                // this class has margins not detected by getBoundingClientRect
                height += 8;
            }
            return sum + height;
        },
        buffer);

    if (fixedContentBelow) {
        buffer += Math.max(bottomFixedContentOffset, bottomFixedContentOffset - bottomMarginOverflow);
    }

    let newHeight = window.innerHeight
        - buffer;
    newHeight = Math.max(newHeight, 0);
    scrollableContent.style.maxHeight = `${newHeight}px`;
};

export default Vue.directive('sticky', {
    bind(el: HTMLElement, binding: VNodeDirective, vnode: VNode) {
        const config = getConfig(binding, vnode);
        if (config) {
            const updaterFunction = () => {
                adjustHeight(el);
            };
            window.addEventListener('pointermove', updaterFunction);
            window.addEventListener('mouseover', updaterFunction);
            window.addEventListener('mouseenter', updaterFunction);
            window.addEventListener('touchmove', updaterFunction);
            window.addEventListener('scroll', updaterFunction);
            window.addEventListener('resize', updaterFunction);
            state[el.id] = {
                ...config,
                scrollHandler: updaterFunction,
                resizeHandler: updaterFunction,
            };
        }

    },

    componentUpdated(el: HTMLElement, binding: VNodeDirective, vnode: VNode) {
        adjustHeight(el);
    },

    unbind(el: HTMLElement, binding: VNodeDirective, vnode: VNode) {
        const config = getConfig(binding, vnode);
        if (config && state[config.id]) {
            const {
                scrollHandler,
                resizeHandler,
            } = state[config.id];
            window.removeEventListener('scroll', scrollHandler);
            window.removeEventListener('resize', resizeHandler);
            delete state[config.id];
        }
    }
});

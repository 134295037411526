import {
    links,
    routeNames,
    titles
} from '@app/router/constants';
import {
    getDefaultLayout,
} from '@app/router/utils';
import VerifyTicketPage from '@pages/VerifyTicketPage.vue';

export default [
    {
        path: `${links.verifyTicket}`,
        name: routeNames.verifyTicket,
        components: getDefaultLayout(VerifyTicketPage),
        meta: {
            title: titles.verifyTicket
        },
    },
];

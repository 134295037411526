import { Store, MutationPayload, Action } from 'vuex';
import createPersistedState, { Storage } from 'vuex-persistedstate';
import { IState } from '@src/app/store';
import { STATE_STORAGE_KEY } from '@store/persistence/statePersist';
import ISelection from '@models/betSlip/ISelection';
import StoreUtil from '@app/store/StoreUtil';

const BETSLIP_STORAGE_KEY = 'betSlip';
const DESERIALIZE_ACTION = 'ui/sportOffer/betSlip/deserializeBetSlip';

const BET_SLIP_REGEX = /^ui\/sportOffer\/betSlip\/*/;

export default (store: Store<IState>) => createPersistedState({
    key: BETSLIP_STORAGE_KEY,
    filter: (mutation: any) => isBetSlipMutation(mutation.type),
    reducer: (state: any) => serializeBetSlip(state),
    getState: async (key: string, storage: Storage) => {
        try {
            // important! if we don't save to variable, local storage will be overwritten
            // by the time socket is ready!
            const betSlipFromStorage = JSON.parse(storage.getItem(key) as any);
            store.dispatch('ui/sportOffer/betSlip/setIsLoading', true);
            // @ts-ignore

            (async () => {
                await Promise.all([
                    StoreUtil.createMutationSubscriptionPromise(store, 'data/country/SET_CONFIG'),
                    StoreUtil.createActionSubscriptionPromise(store, 'data/sportOffer/setIsOfferReady')
                ]);
                // this is not needed for SSBT because it is public device, betslip should not be persisted
                // store.dispatch(DESERIALIZE_ACTION, betSlipFromStorage);
            })();
            return deserializeState(storage, STATE_STORAGE_KEY);
        } catch (e) {
            store.dispatch('errors/showError', e, { root: true });
        }
    }
})(store);

const isBetSlipMutation = (mutationType: string): boolean => {
    return BET_SLIP_REGEX.test(mutationType);
};

const serializeBetSlip = (state: any) => {
    return {
        selections: state.ui.sportOffer.betSlip.selections.map((s: ISelection) => s.serialize()),
        stake: state.ui.sportOffer.betSlip.stake,
        selectedSystemsMinNumbers: state.ui.sportOffer.betSlip.selectedSystemsMinNumbers,
        purchaseType: state.ui.sportOffer.betSlip.purchaseType,
        type: state.ui.sportOffer.betSlip.type,
    };
};

const deserializeState = (storage: Storage, key: string) => {
    return JSON.parse(storage.getItem(key) as any);
};

import sportModule, { ISportResultsState } from './sport';

export interface IResultsState {
    sport: ISportResultsState;
}

export default {
    namespaced: true,
    modules: {
        sport: sportModule,
    },
};

import {
    BetSlipError,
    BetSlipErrorType,
    SelectionError,
    SelectionErrorType,
    SelectionMaxSelectionForMarketError,
} from '@superbet-group/betting.lib.validation';
import ISelection from '@models/betSlip/ISelection';
import OfferService from '@core/services/offer/OfferService';
import SportSelection from '@models/betSlip/SportSelection';
import IBetSlipError from '@models/betSlip/validation/IBetSlipError';
import {
    BetSlipErrorType as ErrorType,
    SelectionType
} from '@models/shared/betSlip/betSlipEnums';
import BetBuilderService from "@src/terminal/core/services/betBuilder/BetBuilderService";
import { OddSourceType } from '@models/tickets/enums';
import Match from '@core/models/offer/Match';

export default {
    findSelectionByEventId(selections: ISelection[], eventId: number, type: SelectionType): ISelection | undefined {
        return selections.find((s => (s.getEventId() === eventId && s.getItemType() === type)));
    },
    findSelectionIndex(selections: ISelection[], selection: any) {
        return selections.findIndex((s) => {
            return s.getOddUuid() === selection.getOddUuid();
        });
    },
    getSameEventSelections(selections: ISelection[], selection: ISelection): ISelection[] {
        return selections
        .filter((s) => s.getEventId() === selection.getEventId());
    },
    isSameMarketLine(selection: ISelection, other: ISelection): boolean {
        return (
            selection.getMarketId() === other.getMarketId()
            && selection.getOddSpecialBetValue() === other.getOddSpecialBetValue()
        );
    },

    getSelection(selections: ISelection[], selection: ISelection): ISelection | undefined {
        return selections.find((s) => s.isEqual(selection));
    },

    getSelectionIndex(selections: ISelection[], selection: ISelection): number {
        return selections.findIndex((s) => s.isEqual(selection));
    },

    async fetchSelection(
        { oddId, eventId, specialBetValue, type, offerId, oddUuid, sourceType }:
            { oddId?: number | null, eventId: number,
                specialBetValue?: string, type: SelectionType, offerId?: number, oddUuid?: string, sourceType: number}
    ): Promise<ISelection | null> {
        let event;
        let odd = null;
        if (type === SelectionType.sport) {
            event = await OfferService.getInstance().getEvent(eventId);
            OfferService.getInstance().denormalizeEvent(event);
        }
        if (!event) {
            return null;
        }

        if (sourceType === OddSourceType.BET_BUILDER && oddUuid) {
            const betBuilderOdd = await BetBuilderService.getInstance().getOdd(eventId, oddUuid);
            const isValid = OfferService.getInstance().denormalizeOdd(
                event as Match,
                betBuilderOdd
            );
            if (isValid) {
                odd = betBuilderOdd;
            }
        } else if (oddId) {
            // specialBetValue is ignored for specials
            odd = event.getOdd(oddId, specialBetValue, oddUuid);
            event.setOdds([]);
        }

        if (type === SelectionType.sport) {
            if (!odd) return null;
            return SportSelection.createItem(event, odd, {
                source: {
                    type: sourceType
                }
            });
        }
        if (!odd) return null;
        return null
    },

    getBetSlipErrorMessageTemplate(betSlipError: ValidationError): string {
        switch (betSlipError.type) {
            case ErrorType.errorsInSelection:
                return 'check your events, you have errors';
            case ErrorType.RetailEventStarted:
                return 'event has started';
            case ErrorType.chooseTicketNegotiation:
                return 'you must accept/decline ticket negotiation';
            case BetSlipErrorType.EmptyBetSlip:
            case BetSlipErrorType.MinimumBetSlipStakeError:
            case BetSlipErrorType.MinimumBetSlipStakePerCombinationError:
                // tslint:disable-next-line:max-line-length
                return 'The minimum stake per combination is {minimumStakePerCombination}. Your current value is {stakePerCombination}';
            case BetSlipErrorType.MaximumBetSlipStakeError:
            case BetSlipErrorType.WinLowerThanStake:
                return 'potential winning amount lower than the stake';
            case BetSlipErrorType.LiveEventOnOfflineType:
                return 'live events are not allowed on offline betSlip';
            case BetSlipErrorType.NoSelectedSystemCombination:
                return 'please select at least one combination';
            case BetSlipErrorType.MaxNumberOfSelections:
            case BetSlipErrorType.WinLowerThanStake:
                return 'potential winning amount lower than the stake';
            case SelectionErrorType.SelectionIsLocked:
                return 'event/odd is blocked';
            case SelectionErrorType.OddIsFinished:
                return 'selected odd doesn\'t exist anymore';
            case SelectionErrorType.OddIsUnavailable:
                return 'selected odd is unavailable';
            case SelectionErrorType.EventIsFinished:
                return 'event has finished';
            case SelectionErrorType.SelectionIsUnavailable:
                return 'selection is unavailable';
            case ErrorType.eventCantPlaceBets:
                return 'you can not place bets for this event anymore';
            case SelectionErrorType.MinimumStake:
                return 'your stake is too small';
            case SelectionErrorType.MaximalStake:
                return 'your stake is too big, maximal is {maximum}';
            case SelectionErrorType.SameMarket:
                return 'it is not possible to combine same market';
            case SelectionErrorType.CantCombineEvents:
                return 'It is not possible to combine this event with events you already have on your betslip';
            case SelectionErrorType.CantCombineMarkets:
                return 'It is not possible to combine this market with markets you already have on your betslip';
            case ErrorType.minSystemToPlay:
                return 'minimum system to select is {minNumber}/{fromNumber}';
            case SelectionErrorType.CantCombine:
                return 'It is not possible to combine this with events you already have on your betslip';
            case SelectionErrorType.AddMoreFromSameMarket:
                return 'put more events of same market';
            case SelectionErrorType.CantAddMoreFromThisMarket:
                if ((betSlipError as SelectionMaxSelectionForMarketError).params.maxSelectionForMarket > 1) {
                    return 'You are not allowed to add more' +
                        ' than {maxSelectionForMarket} events from this type of market';
                }
                return 'You are not allowed to add more than one event from this type of market';
            case SelectionErrorType.PutMoreOdds:
                return 'This event requires that you add' +
                    ' {minNonPromotionalSelection} more events with odd even or greater than {minNonPromotionalOdd}';
            case SelectionErrorType.SuperExtraBelowMinimum:
                return 'Super Extra must be combined with' +
                    ' at least {minNonPromotionalSelection} other events with a minimum odd {minNonPromotionalOdd}';
            case SelectionErrorType.AddMoreFromOtherMarket:
                return 'put more events of other markets';
            case BetSlipErrorType.MaxWinAmount:
            case BetSlipErrorType.MinWinAmount:
            default:
                return '';
        }
    }
};

export type ValidationError = IBetSlipError | BetSlipError | SelectionError;

import { Location, Route } from 'vue-router';
import router, { routeNames } from '@app/router';
import { IState } from '@app/store';
import { liveTimeFilterSlug } from '@store/modules/ui/shared/timeFilter/utils';
import StoreUtil from '@app/store/StoreUtil';
import { Store, Commit } from 'vuex';

export const push = (context: any, location: Location) => router.push(location);
export const replace = (context: any, location: Location) => router.replace(location);
export const back = () => router.back();

const SAVE_PREVIOUS_ROUTE = 'SAVE_PREVIOUS_ROUTE';

export interface INavigationState {
    route: Route | null;
    previousRoute: Route | null;
}

export default {
    namespaced: true,

    state: {
        route: null,
        previousRoute: null,
    } as INavigationState,

    actions: {
        push,
        replace,
        back,
        savePreviousRoute: StoreUtil.createSimpleMutatorAction(SAVE_PREVIOUS_ROUTE),
        clearPreviousRoute({ commit }: { commit: Commit }) {
            commit(SAVE_PREVIOUS_ROUTE, null);
        }
    },
    mutations: {
        [SAVE_PREVIOUS_ROUTE]: StoreUtil.createSimpleMutator('previousRoute'),
    },
    getters: {
        route(state: INavigationState, getters: NavigationGetters, rootState: IState) {
            return rootState.route;
        },
        isOnSportLivePage(state: INavigationState, getters: NavigationGetters, rootState: IState) {
            const { name, params: { timeFilter = null } = {} } = rootState.route;
            return name === routeNames.sportOffer && timeFilter === liveTimeFilterSlug;
        },
        isOnSportsPage(state: INavigationState, getters: NavigationGetters, rootState: IState) {
            const { name, params: { timeFilter = null } = {} } = rootState.route;
            return name === routeNames.landing
                || (name === routeNames.sportOffer && timeFilter !== liveTimeFilterSlug);
        },
        isOnResultsPage(state: INavigationState, getters: NavigationGetters, rootState: IState) {
            const { name } = rootState.route;
            const resultRoutes = [
                routeNames.liveResults,
                routeNames.sportResults,
                routeNames.liveResults,
                routeNames.virtualResults,
            ];
            return resultRoutes.includes(name!);
        },
        previousRoute: StoreUtil.createSimpleGetter('previousRoute'),
    },
};

export interface NavigationGetters {
    'isOnSportLivePage': boolean;
    'route': Route;
}

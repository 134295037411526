<template>
    <div
        v-if="visible"
        class="virtual-touch-keyboard"
        :class="[ 'theme-dark', 'palette-grey', `layout-${layoutName}`, customClass ]"
    >
        <div class="keyboard-close" @click="visible = false">
            <i class="icon icon-close virtual-x"></i>
        </div>
        <VueTouchKeyboard
            :options="options"
            :layout="layout"
            :cancel="hide"
            :accept="accept"
            :change="change"
            :input="input"
        />
    </div>
</template>

<script>
    import Vue from 'vue';
    import VueTouchKeyboard from 'vue-touch-keyboard/dist/vue-touch-keyboard';
    import 'vue-touch-keyboard/dist/vue-touch-keyboard.css';
    import keyboardLayouts from '@countryLocalization/lang/translations/keyboardLayouts';

    Vue.use(VueTouchKeyboard);

    export default {
        name: 'VirtualKeyboard',
        data() {
            return {
                visible: false,
                layout: 'modern',
                layoutName: 'normal',
                customClass: '',
                overlay: false,
                input: null,
                options: {
                    useKbEvents: false,
                    preventClickEvent: false
                },
                onConfirm: () => {},
                onChange: () => {},
            };
        },
        created() {
            if (!window.SSBT) window.SSBT = {};
            window.SSBT.VirtualKeyboard = {
                show: this.show,
                hide: this.hide,
                clear: this.clear,
                accept: this.accept,
            };
        },
        methods: {
            accept(text) {
                if (text) {
                    this.onConfirm(text);
                }
                this.hide();
            },
            change(value) {
                if (value || value === '') {
                    this.onChange(value);
                }
            },
            clear() {
                this.input.value = '';
            },
            show(e, virtualKeyboardActions) {
                this.input = e.target;
                this.layout = keyboardLayouts[this.layoutName]

                this.onConfirm = virtualKeyboardActions.onConfirm;
                this.onChange = virtualKeyboardActions.onChange;

                if (!this.visible) this.visible = true;
            },
            hide() {
                this.visible = false;
            },
        },
        components: {
            VueTouchKeyboard: VueTouchKeyboard.component,
        }
    };
</script>

import { ITask } from '@src/terminal/core/services/gravity/_private/Task';
import router, { routeNames } from '@app/router';
import store from '@src/app/store';
import { get as _get } from '@lodash';

class SlaveShownTask implements ITask {
  execute = async (message?: any): Promise<void> => {
    // closing all modals before navigating to other products/screens
    await store.dispatch('ui/modal/closeAllModals', -1, { root: true });

    const { data } = message;
    if (data.route.to === 'results') {
      const routeName = message.productId === 'SuperbetPrematch'
                ? routeNames.sportResults
                : ''
            router.push({ name: routeName });
    }
    if (data.route.to === 'home') {
      const routeName = message.productId === 'SuperbetPrematch'
                ? routeNames.sportOffer
                : ''
            router.push({ name: routeName });
    }
    if (data.route.to === 'ticket-check') {
      const id = _get(message, 'data.ticket.slip.codes[0].id', null);
      store.dispatch('data/country/setCheckedTicket', { id, timeChecked: Date.now() }, { root: true });
      router.push({ name: routeNames.checkTicket });
    }
  };
}

export default SlaveShownTask;

<template>
    <DatePicker
        @dateClick="onDateClick"
        :datesByWeek="datesByWeek"
        :selectedDate="selectedDate"
    />
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import DateUtils from '@core/utils/date/DateUtils';
    import DatePicker from './components/DatePicker.vue';
    import config from '@config';

    const daysInWeek = 7;

    export default {
        name: 'DatePickerContainer',
        created() {
            // +1 because we include today
            this.dates = DateUtils.getDatesArray(config.app.results.maxDaysToFetch + 1);
        },
        computed: {
            ...mapGetters('ui/results', ['selectedDate']),
            /*  expands the dates array so it the first day is in the array index is the same number which
                getDay() method returns
                ex. [_, _, _, Wed...];  [Sun ...]; [_, _, _, _, _, _, Sat]
                and has a multiple of 7 length
                ex. [...Fri, _ ]; [...Thu, _, _]
            */
            datesByWeek() {
                const dates = [...this.dates];
                const daysBefore = (this.dates[0].getDay() + 6) % daysInWeek;
                const daysAfter = Math.ceil((this.dates.length + daysBefore) / daysInWeek)
                    * daysInWeek - (this.dates.length + daysBefore);

                dates.unshift(...Array(daysBefore).fill(undefined));
                dates.push(...Array(daysAfter).fill(undefined));
                return this.chunkDates(dates);
            }
        },
        methods: {
            ...mapActions('ui/results', ['setSelectedDate']),
            onDateClick(date) {
                this.setSelectedDate(date);
            },
            chunkDates(array) {
                const results = [];
                while (array.length) {
                    results.push(array.splice(0, daysInWeek));
                }
                return results;
            }
        },
        components: {
            DatePicker
        }
    };
</script>

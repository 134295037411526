import Vue from 'vue';
import {
    keyBy as _keyBy,
} from '@lodash';
import Ticket from '@models/tickets/Ticket';
import StoreUtil from '@app/store/StoreUtil';
import {
    SET_TICKETS,
    SET_TICKET,
    SET_TICKET_SUBMIT_LOADING,
    SET_TICKET_NEGOTIATION_LOADING,
    CLEAR_ALL,
    ADD_ERROR,
    REMOVE_ERROR,
    CLEAR_ALL_ERRORS,
    SET_ARE_TICKETS_LOADING,
    SET_TICKET_NEGOTIATION,
} from './mutation_types';

import ITicketsState from './types';

export default {
    [SET_TICKETS]: (state: ITicketsState, tickets: Ticket[]) => {
        state.tickets = { ...state.tickets, ..._keyBy(tickets, 'id') };
    },
    [SET_TICKET]: (state: ITicketsState, ticket: Ticket) => {
        Vue.set(state.tickets, ticket.id, ticket);
    },
    [SET_TICKET_SUBMIT_LOADING]: StoreUtil.createSimpleMutator('isTicketSubmitting'),
    [SET_TICKET_NEGOTIATION_LOADING]: StoreUtil.createSimpleMutator('isTicketNegotiating'),
    [CLEAR_ALL]: (state: ITicketsState) => {
        state.tickets = {};
        state.isTicketSubmitting = false;
        state.activeTicket = null;
    },
    [ADD_ERROR]: StoreUtil.createPushToListMutator('submitErrors'),
    [REMOVE_ERROR]: StoreUtil.createRemoveFromListMutator('submitErrors'),
    [CLEAR_ALL_ERRORS]: (state: ITicketsState) => {
        state.submitErrors = [];
    },
    [SET_ARE_TICKETS_LOADING]: StoreUtil.createSimpleMutator('areTicketsLoading'),

    [SET_TICKET_NEGOTIATION]: StoreUtil.createSimpleMutator('ticketNegotiation'),
};

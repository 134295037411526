
import configService from '@core/services/configs/ConfigService';
import RestRequestFactory, { RestClient } from '@core/utils/network/RestRequestFactory';

export default class AllowancesRestService {
    private async fetchAllowances(endpoint: string): Promise<string> {
        const config = await configService.getConfig();
        const allowancesUrl = config.tickets.allowancesUrl;
        const allowancesApi = RestRequestFactory.create(allowancesUrl);
        const allowances = await allowancesApi.get(endpoint);

        return JSON.stringify(allowances);
    }

    public async getAllowances(): Promise<string> {
        const config = await configService.getConfig();
        const countryKey = config.tickets.countryKey || '';
        return this.fetchAllowances(`/${countryKey}`);
    }

    public async getAllowancesV2(): Promise<string> {
        return this.fetchAllowances('/v2');
    }
}

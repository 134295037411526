import { Dispatch } from 'vuex';
import Modals from '@store/modules/ui/shared/modal/modals';
import config from '@config';

export default {
    namespaced: true,
    state: {},

    actions: {
        showError({ dispatch }: { dispatch: Dispatch }, error: Error) {
            if (!config.environment.isProduction) {

            }

            // TODO: show error modal is not dispatched it will be replaced with gravity error notification
            // dispatch(
            //     'ui/modal/setModal',
            //     Modals.error.code,
            //     { root: true }
            // );

            // TODO just show visual error, something went wrong, try again later
        },
    }
};

import { ITask } from '@src/terminal/core/services/gravity/_private/Task';
import PlatformService from '../../platform/PlatformService';

class UserBalanceChangedTask implements ITask {
    execute = (message?: any): void => {
        const { balance } = message.data;
        PlatformService.getInstance().setTerminalBalance(balance);
    };
}

export default UserBalanceChangedTask;

import AbstractMapper from '@core/utils/mappers/AbstractMapper';
import ISubmittingTicketDto from '@core/mappers/tickets/dtos/ISubmittingTicketDto';
import { BetSlipType } from '@models/shared/betSlip/betSlipEnums';
import ISelection from '@core/models/betSlip/ISelection';
import SelectionToTicketItemMapper from '@core/mappers/tickets/SelectionToTicketItemMapper';
import CombinationsMapper from './CombinationsMapper';
import IBetSlip from '@models/betSlip/IBetSlip';

const selectionToTicketItemMapper: SelectionToTicketItemMapper = new SelectionToTicketItemMapper();
const combinationsMapper: CombinationsMapper = new CombinationsMapper();

/* used to map the betslip into the DTO for submitting a ticket */

export default class SportBetSlipToTicketDTOMapper extends AbstractMapper<IBetSlip, ISubmittingTicketDto> {

    createTargetObject(betSlip: IBetSlip) {
        const e : ISubmittingTicketDto = ({
            ticketOnline: betSlip.purchaseType,
            total: betSlip.stake,
            betType: 'prematch',
            combs: betSlip.type === BetSlipType.system ? combinationsMapper.createTargetObject(betSlip) : '',
            items: betSlip.selections.map(s => selectionToTicketItemMapper.createTargetObject(
                s, {
                    isSystem: betSlip.type === BetSlipType.system,
                }
            )),
            clientSourceType: 'desktop',
        });
        if (betSlip.autoUpdateChanges) {
            e.autoAcceptChanges = '1';
        }
        return e;
    }
}

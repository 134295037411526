import AbstractMapper from '@core/utils/mappers/AbstractMapper';
import { ITicketNegotiationOddDto } from './dtos/ITicketNegotiationDto';
import { NegotiationOdd } from '@models/tickets/TicketNegotiation';

export default class TicketNegotiationOddMapper extends AbstractMapper<ITicketNegotiationOddDto, NegotiationOdd> {
    createTargetObject(origin: ITicketNegotiationOddDto) {
        return {
            matchId: origin.matchId,
            oddId: parseInt(origin.oddId, 10),
            betSlipOdd: origin.oldOdd,
            bookieOdd: origin.newOdd,
        } as NegotiationOdd;
    }
}

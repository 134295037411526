<template>
    <div class="nav-sidebar">
        <ul class="nav-sidebar__wrapper">
            <div class="nav-sidebar-tree">
                <li class="nav-sidebar-tree__item-wrapper--terminal">
                    <router-link
                        :to="{ name: allOfferRoute }"
                        tag="div"
                        class="nav-sidebar-tree__item"
                    >
                        <i class="icon--md icon-chevron_left nav-sidebar-tree__icon"/>
                        {{ $t('back to offer') | upperCase }}
                    </router-link>
                </li>
                <li
                    v-if="eventsByCompetitors.length"
                    class="nav-sidebar-tree__item-wrapper--terminal"
                >
                    <div
                        v-scroll-to="{ el: anchors.matches }"
                        class="nav-sidebar-tree__item"
                    >
                        <i class="icon--md icon-events nav-sidebar-tree__icon"/>
                        <span class="nav-sidebar-tree__title">
                            {{ $t('matches') | capitalize }}
                        </span>
                        <span class="nav-sidebar-tree__events-count">
                            {{ eventsByCompetitors.length }}
                        </span>
                    </div>
                </li>
                <li
                    v-if="foundTournaments.length"
                    class="nav-sidebar-tree__item-wrapper--terminal"
                >
                    <div
                        v-scroll-to="{ el: anchors.tournaments }"
                        class="nav-sidebar-tree__item"
                    >
                        <i class="icon--md icon-competition nav-sidebar-tree__icon"/>
                        <span class="nav-sidebar-tree__title">
                            {{ $t('tournaments') | capitalize }}
                        </span>
                        <span class="nav-sidebar-tree__events-count">
                            {{ foundTournaments.length }}
                        </span>
                    </div>
                </li>
            </div>
        </ul>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { routeNames } from '@app/router';
    import sectionIds from '../sectionIds';

    export default {
        created() {
            this.sectionIds = sectionIds;
            this.allOfferRoute = routeNames.landing;
        },
        computed: {
            ...mapGetters('ui/sportOffer/search', [
                'query',
                'eventsByCompetitors',
                'foundTournaments',
            ]),
            anchors() {
                const {
                    matches,
                    tournaments,
                } = this.sectionIds;
                return {
                    matches: `#${matches}`,
                    tournaments: `#${tournaments}`,
                };
            }
        },
    };
</script>

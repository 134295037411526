import translations from '@countryLocalization/lang/translations/translations';
import enDevelop from './translations/enDevelop';
const languages: any = {};

if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line global-require
    languages['en-GB'] = enDevelop
} else if (import.meta.env.VITE_APP_COUNTRY === 'romania') {
    languages['ro-RO'] = translations
}

export default languages;

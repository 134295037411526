import { omit as _omit } from '@lodash';
import store from '../store';
import AuthenticationError from '@errors/authentication/AuthenticationError';
import { ExpandedEventInUrl } from '@app/enums';
import { logError } from '@app/utils/errors';

export async function errorGuard(
    {
        action,
        onError = undefined,
        showGlobalError = true,
    }: {
        action: Function,
        onError?: Function
        showGlobalError?: boolean
    }) {
    try {
        return await action();
    } catch (e) {
        if (e instanceof AuthenticationError) {
            store.dispatch('data/authentication/sessionExpired', null, { root: true });
            return;
        }

        logError(e);

        if (onError) {
            try {
                onError(e);
            } catch (e) {
                logError(e);
            }
        }

        if (!onError && showGlobalError) {
            store.dispatch('errors/showError', e, { root: true });
        }
    }
}

export function removeEventQueryParams(query: Record<string, string>) {
    return _omit(
        query,
        [
            ExpandedEventInUrl.event,
            ExpandedEventInUrl.type,
            ExpandedEventInUrl.odd,
            ExpandedEventInUrl.sbValue,
        ]
    );
}

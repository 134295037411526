<template>
    <div class="footer__legal-section">
        <div class="footer__legal-section--subtitle mb-5">{{ $t('age warning') }}</div>
        <div class="mb-5">{{ $t('disclaimer') }}</div>
        <ul class="link-list__items--horizontal">
            <a
                href="http://onjn.gov.ro/"
                class="link-list__item"
            >
                <img
                    src="/static/img/footer/img_logo_onjn.png"
                    class="footer__legal-section--image mr-4"
                    width="28px"
                    height="28px"
                >
            </a>
            <StaticLink
                tag="a"
                class="link-list__item"
                path="joc-responsabil#18+"
            >
                <img
                    src="/static/img/footer/img_18+.png"
                    class="footer__legal-section--image mr-4"
                    width="26px"
                    height="26px"
                >
            </StaticLink>
            <a
                href="http://www.rombet.com/jocul-responsabil"
                class="link-list__item"
            >
                <img
                    src="/static/img/footer/img_logo_rombet.png"
                    class="footer__legal-section--image mr-4"
                    width="96px"
                    height="26px"
                >
            </a>
        </ul>
    </div>
</template>

<script>
    import StaticLink from '@shared/components/StaticLink.vue';
    export default {
        components: {
            StaticLink
        }
    };
</script>

<style scoped>

</style>

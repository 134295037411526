import { Dispatch } from 'vuex';
import { omit as _omit } from '@lodash';
import { IState } from '@app/store';
import parseDateUrl from '@core/utils/url/parseDateUrl';
import slugifyDate from '@core/utils/url/slugifyDate';
import DateUtils from '@core/utils/date/DateUtils';
import { ExpandedEventInUrl } from '@app/enums';
import sportModule from './sport';

const storePath = 'ui/results';

let today = new Date();

interface UIResultsState {
    isLeftSidebarOpen: Boolean;
}

export default {
    namespaced: true,
    state: {
        isLeftSidebarOpen: false
    } as UIResultsState,
    modules: {
        sport: sportModule(storePath)
    },
    getters: {
        expandedEventId: (state: {}, getters: any, rootState: IState, rootGetters: any) => {
            const eventId = rootGetters['navigation/route'].query[ExpandedEventInUrl.event];
            return eventId ? parseInt(eventId, 10) : null;
        },
        selectedDate(state: any, getters: any, rootState: IState, rootGetters: any) {
            try {
                const dateParam = rootGetters['navigation/route'].params.date;
                const date = parseDateUrl(dateParam);

                if (!DateUtils.isValidDate(date)) {
                    throw date;
                }
                return date;
            } catch (e) {
                if (!DateUtils.isSameDate(today, new Date())) {
                    today = new Date();
                }
                // constant to avoid triggering watchers
                return today;
            }
        },
        timeWindow(state: any, getters: any) {
            const startDate = DateUtils.toStartOfDay(getters.selectedDate);
            const endDate = DateUtils.toStartOfDay(DateUtils.addDays(1, getters.selectedDate));
            return [startDate, endDate];
        },
    },
    actions: {
        setSelectedDate({ dispatch, rootGetters }: { dispatch: Dispatch, rootGetters: any }, date: Date) {
            const dateSlug = slugifyDate(date);
            const {
                name,
                params,
            } = rootGetters['navigation/route'];
            dispatch('navigation/push', { name, params: { ...params, date: dateSlug } } , { root: true });
        },
        toggleExpandedEventId: (
            { dispatch, getters, rootGetters }:
                { dispatch: Dispatch, getters: any, rootGetters: any },
            eventId: number
        ) => {
            const route = rootGetters['navigation/route'];
            let query = route.query;

            if (getters.expandedEventId === eventId) {
                query = _omit(query, [ExpandedEventInUrl.event]);
            } else {
                query = {
                    ...query,
                    [ExpandedEventInUrl.event]: eventId.toString(),
                };
            }
            dispatch('navigation/push', { query }, { root: true });
        },
    }
};

import { NotReadySource } from '../betting/_private/enums';
import BettingAvailableService from '../betting/BettingAvailableService';
import { ErrorData } from './models/Payload';

export const isConfigError = <T>(configData: T | ErrorData): configData is ErrorData => {
    return (configData as ErrorData).error !== undefined;
}

export const openBettingServiceErrorModal = (source: NotReadySource, message: string) => {
    const bettingServiceInstance = BettingAvailableService.getInstance();

    bettingServiceInstance.set(source, {
      reason: message,
      available: false
    });

    const bettingServiceError = bettingServiceInstance.getSource(source)!;
    bettingServiceInstance.setErrorModal(bettingServiceError);
}

import { parse } from 'qs';
import {
    isEmpty as _isEmpty,
    pick as _pick,
    mapValues as _mapValues,
    last as _last
} from '@lodash';
import ClientStorage from '@core/utils/storage/ClientStorage';

export const UTM_DATA = 'utmData';

export interface UtmData {
    'utm_source': string;
    'utm_medium': string;
    'utm_campaign': string;
    'utm_term': string;
    'utm_content': string;
}

const utmQueryParams = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_term',
    'utm_content'
];

export function saveUtmData() {
    const query = parse(window.location.search, { ignoreQueryPrefix: true });
    const utmData = _mapValues(
        _pick(query, utmQueryParams),
        (value) => Array.isArray(value) ? _last(value) : value
    );
    if (!_isEmpty(utmData)) {
        ClientStorage.set(UTM_DATA, utmData);
    }
}

import { ActionContext } from 'vuex';
import { orderBy as _orderBy } from '@lodash';
import StoreUtil from '@store/StoreUtil';
import { IState } from '@src/app/store';
import Ticket from '@models/tickets/Ticket';
import config from '@config';
import { TicketType, TicketStatus } from '@models/tickets/enums';

export interface ITicketsStackState {
    highlightedTickets: Record<string, boolean>;
    isRecreatingTicket: boolean;
}

const initialState: ITicketsStackState = {
    highlightedTickets: {},
    isRecreatingTicket: false,
};

const TOGGLE_HIGHLIGHTED_TICKET = 'TOGGLE_HIGHLIGHTED_TICKET';
const CLEAR_STATE = 'CLEAR_STATE';
const SET_IS_RECREATING_TICKET = 'SET_IS_RECREATING_TICKET';

let subscription: Function | null = null;

const STACK_SIZE = config.app.ticketsPage.stackSize;

export default {
    namespaced: true,
    state: initialState,
    mutations: {
        [TOGGLE_HIGHLIGHTED_TICKET]: StoreUtil.createSetMutator('highlightedTickets'),
        [SET_IS_RECREATING_TICKET]: StoreUtil.createSimpleMutator('isRecreatingTicket'),
        [CLEAR_STATE]: (state: ITicketsStackState) => {
            state.highlightedTickets = {};
        }
    },
    getters: {
        highlightedTickets: StoreUtil.createSimpleGetter('highlightedTickets'),
        isRecreatingTicket: StoreUtil.createSimpleGetter('isRecreatingTicket'),
        tickets(state: ITicketsStackState, getters: any, rootState: IState, rootGetters: any) {
            const tickets = rootGetters['data/tickets/tickets'] as Ticket[];
            return _orderBy(tickets, ['dateLastModified'], ['desc']).slice(0, STACK_SIZE);
        }
    },
    actions: {
        async loadActiveTickets(context: ActionContext<ITicketsStackState, IState>) {
            return context.dispatch('data/tickets/loadLastModifiedTickets', null, { root: true });
        },
        async subscribeToTicketsUpdates(context: ActionContext<ITicketsStackState, IState>) {
            if (!subscription) {
                subscription = await context.dispatch(
                    'data/tickets/subscribeToTicketChanges',
                    (ticket: Ticket, oldTicket?: Ticket) => {
                        if (!oldTicket || shouldShowTicket(ticket, oldTicket)) {
                            context.commit(TOGGLE_HIGHLIGHTED_TICKET, { key: ticket.id, value: true });
                        }
                    },
                    { root: true });
            }
        },
        unsubscribeFromTicketsUpdates() {
            if (subscription) {
                subscription(); // unsubscribe
                subscription = null;
            }
        },
        toggleHighlightedTicket: StoreUtil.createSimpleMutatorAction(TOGGLE_HIGHLIGHTED_TICKET),
        setIsRecreatingTicket: StoreUtil.createSimpleMutatorAction(SET_IS_RECREATING_TICKET),
        clearState: StoreUtil.createSimpleMutatorAction(CLEAR_STATE),
        recreateTicket: async (
            context: ActionContext<ITicketsStackState, IState>,
            ticket: Ticket
        ) => {
            if (ticket.type === TicketType.sport) {
                await context.dispatch('ui/sportOffer/betSlip/recreateTicket', ticket, { root: true });
            }
        },
    },
};

function shouldShowTicket(ticket: Ticket, oldTicket: Ticket) {
    if (ticket.status === TicketStatus.active) {
        return true;
    }
    if (ticket.status !== oldTicket.status) {
        return true;
    }
    return false;
}

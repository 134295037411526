import {
    Observable,
    Subscriber,
} from 'rxjs';
import {
    share,
    map,
    filter,
} from 'rxjs/operators';

// eslint-disable-next-line import/prefer-default-export
export function createEventSourceObservable<T>(url: string, withCredentials = false): Observable<T> {
    const $source = new Observable<string>((observer: Subscriber<string>) => {
        const eventSource = new EventSource(url, { withCredentials });

        eventSource.onmessage = (x): void => {
            observer.next(x.data);
        };
        return (): void => {
            eventSource.close();
        };
    });
    return $source.pipe(
        share(),
        map<string, T>((val) => {
            try {
                return JSON.parse(val);
            } catch {
                return null;
            }
        }),
        filter((val) => Boolean(val)),
    );
}

<template>
    <footer>
        <div class="footer__wrapper">
            <div class="footer__link-section">
                <div class="flex">
                    <div class="link-list">
                        <span class="link-list__title">
                            {{ $t('About Superbet') }}
                        </span>
                        <AboutSuperbet/>
                    </div>

                    <PrintableOffer/>
                    <Help/>
                    <LegalSection/>

                </div>
            </div>
            <div class="footer__info-box">
                <div style="width:75%;">
                    {{ $t('long info') }}
                </div>
                <img src="/static/img/logo.svg" width="auto" height="16px" alt="Superbet"/>
            </div>
        </div>
    </footer>
</template>

<script>
    import ExternalLink from '@shared/components/ExternalLink.vue';
    import StaticLink from '@shared/components/StaticLink.vue';
    import CustomRouterLink from '@modules/layout/footer/components/CustomRouterLink.vue';
    import PrintableOffer from '@modules/layout/footer/components/PrintableOffer.vue';
    import AboutSuperbet from '@modules/layout/footer/components/AboutSuperbet.vue';
    import LegalSection from '@countryModules/layout/footer/components/LegalSection.vue';

    export default {
        methods: {
            component(link) {
                const components = {
                    external: ExternalLink,
                    static: StaticLink,
                    route: CustomRouterLink
                };
                return components[link.type];
            },
        },
        components: {
            PrintableOffer,
            LegalSection,
            AboutSuperbet,
        }
    };
</script>

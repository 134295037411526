import OddType from '@core/models/struct/OddType';
import AbstractMapper from '@core/utils/mappers/AbstractMapper';
import { OutcomeDto } from '@superbet-group/offer.clients.lib';

export class OddTypeMapper extends AbstractMapper<OutcomeDto, OddType> {
    createTargetObject(dto: OutcomeDto) {
        const oddType: OddType = new OddType();

        oddType.id = dto.id;
        oddType.symbol = dto.columnName;

        return oddType;
    }
}

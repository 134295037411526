import config from '@config';
import * as Sentry from '@sentry/vue';

export class Logger {
    public info(msg: string) {
        console.info(msg);
    }

    public error(error: Error): void {
        if (!config.environment.isTest) {
            Sentry.captureException(error);
        }
    }
}

export default new Logger();

import {
    isEmpty as _isEmpty,
    sumBy as _sumBy,
} from '@lodash';
import StoreUtil from '@store/StoreUtil';
import IBetSlipState from '../types';
import System from '@models/betSlip/System';

export default {
    /**
     * Method used to set available systems
     * When we do CRUD on betSlip we use this method to recalculate
     * systems.
     *
     * Example1: if we have three systems (1/3, 2/3 and 3/3) and only the
     * first one is selected (1/3); if we than remove one system item from betslip
     * after recalculation we also have only one selected (1/2).
     * Because the selected minNumber is smaller than the number of new systems
     *
     * Example2: if we have three systems (1/3, 2/3 and 3/3) and only the
     * last one is selected (3/3); if we than remove one system item from betslip
     * after recalculation we don't have any selected systems.
     * Because the selected minNumber is larger than the number of new systems
     *
     */
    // TODO problem, when removing selection biggest selected should be deleted
    // either move systems to base or have subscription action
    // and on clear betslip clear selected
    systems(state: IBetSlipState, getters: any): System[] {
        const systems: System[] = [];

        if (getters.nonFixedSelectionsCount === 0) {
            return systems;
        }

        for (let i = 0; i < getters.nonFixedSelectionsCount; i += 1) {
            const system = new System({
                fromNumber: getters.nonFixedSelectionsCount,
                minNumber: i + 1,
                items: getters.selectionsWithOdd
            });

            system.isSelected = getters.selectedSystemsMinNumbers.hasOwnProperty(system.getMinNumber());
            systems.push(system);
        }

        return systems;
    },
    selectedSystems(state: IBetSlipState, getters: any): System[] {
        return getters.systems.filter((s: System) => s.isSelected);
    },
    selectedSystemsMinNumbers: StoreUtil.createSimpleGetter('selectedSystemsMinNumbers'),
    isAtLeastOneSystemSelected(state: IBetSlipState, getters: any): boolean {
        return !_isEmpty(getters.selectedSystems);
    },
    isOnlyXXSelected(state: IBetSlipState, getters: any): boolean {
        if (getters.selectedSystems.length !== 1) {
            return false;
        }

        const system = getters.selectedSystems[0];
        return system.minNumber === system.fromNumber;
    },
    /**
     * For simple ticket number is 1
     * For system ticket number is sum of selected system's combinations
     */
    totalNumberOfSelectedCombinations(state: IBetSlipState, getters: any): number {
        if (getters.isEmpty) {
            return 0;
        }

        if (getters.isSimple) {
            return 1;
        }

        return _sumBy(
            getters.selectedSystems, (system: System) => system.getNumberOfCombinations());
    },
};

import store from '@app/store';
import i18n from '@app/localization/i18n';
import { SportInfo } from '@core/constants';

export function tournamentName(tournamentId: number) {
    const tournaments = store.getters['data/sportOffer/tournaments'];
    return (tournaments[tournamentId] && tournaments[tournamentId].name) || i18n.t('N/A');
}

export function sportName(sportId: number): string {
    const sports = store.getters['data/sportOffer/sports'];
    return (sports[sportId] && sports[sportId].name) || i18n.t('N/A');
}

export function periodNames(sportId: number): string[] {
    return new SportInfo(sportId).periodNames;
}

<template>
    <div class="betslip__potential-gain--terminal">
        <div class="betslip__potential-gain-simple--terminal">
            <span class="betslip__potential-gain-label--terminal">
                {{ `${$t('potential win')}` | capitalize }}:
            </span>
            <span class="betslip-terminal__potential-gain-amount">
                {{ payout.base | currency }}
                <span class="currency">{{ $t('currencyAbbreviation') }}</span>
            </span>
        </div>
        <div
            v-if="isSystem"
            class="betslip__potential-gain-system--terminal"
        >
            <span class="betslip__potential-gain-label--terminal">
                {{ `${$t('min potential win')}` | capitalize }}:
            </span>
            <span class="betslip__potential-gain-amount--terminal">
                {{ payout.minFinal | currency }}
                <span class="currency">{{ $t('currencyAbbreviation') }}</span>
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PayoutSummary',
        props: {
            payout: {
                type: Object,
                required: true,
            },
            isSystem: {
                type: Boolean,
                required: false,
                default: true
            },
        },
    };
</script>

import Vue from 'vue';
import Vuex from 'vuex';
import { Route } from 'vue-router';
import UI, { IUiState } from './modules/ui/uiIndex';
import navigation, { INavigationState } from './modules/navigation';
import errors from './modules/errors/errorIndex';
import data, {
    IDataState,
    subscriptions as dataSubscriptions,
    moduleNamespaces as dataModuleNamespaces,
} from '@store/modules/data/dataIndex';
import persistState from '@store/persistence/statePersist';
import persistSportBetSlip from '@store/persistence/sportBetSlipPersist';

Vue.use(Vuex);

// building full actions names for dispatching from root

Object.keys(dataModuleNamespaces).forEach((key) => {
    dataModuleNamespaces[key].unshift('data');
});

export interface IState {
    navigation: INavigationState;
    data: IDataState;
    ui: IUiState;
    route: Route;
    errors: any;
}

const store = new Vuex.Store({
    modules: {
        errors,
        data,
        navigation,
        ui: UI,
        route: {}
    },
    plugins: [
        persistState,
        (store) => persistSportBetSlip(store),
    ]
});

export const initializeSubscriptions = () => {
    dataSubscriptions(store, {
        moduleNamespace: dataModuleNamespaces.authentication,
    });
};

export default store;

import {
    IPrinterStatus,
    IBillAcceptorStatus,
    IScannerStatus
} from '@src/terminal/core/services/status/models/StatusInterface';

export const isPrinterWorking = (payload: IPrinterStatus) => payload.connected
    && !payload.coverOpened
    && !payload.stoppedDuePaperEnd
    && !payload.autocutterError
    && !payload.paperEnd
    && !payload.unrecoverableError;
export const isBillAcceptorWorking = (payload: IBillAcceptorStatus) => payload.enabled
        && !payload.cashboxRemoved
        && payload.statusCode === 'READY';
export const isScannerWorking = (payload: IScannerStatus) => payload.connected;

export const isBettingAvailable = (printerStatus: boolean) => printerStatus;

import { instanceGuard } from '@core/utils/services';
// import { MaintenanceType } from '@core/models/country/enums';
import ServerConfig from '@core/models/country/ServerConfig';
import Match from '@core/models/offer/Match';
import SingletonError from '../common/errors/SingletonError';
import Singleton from '../common/Singleton';

class MaintenanceService {

    private static instance: MaintenanceService;

    public static getInstance(): MaintenanceService {
        return instanceGuard(MaintenanceService.instance);
    }

    public static createInstance(config: ServerConfig) {
        if (!MaintenanceService.instance) {
            MaintenanceService.instance = new MaintenanceService(config);
        }
        return MaintenanceService.instance;
    }

    public static setConfig(config: ServerConfig) {
        if (MaintenanceService.instance) {
            MaintenanceService.instance.config = config;
            return;
        }
    }

    private constructor(private config: ServerConfig) {
        if (MaintenanceService.instance) {
            throw new SingletonError(this.constructor.name);
        }
    }

    /**
     * Returns a false constant for the purposes of SSBT
     * @returns const false
     */
    public isLiveInMaintenance() {
        // return !!this.config.applicationStatus.maintainedParts[MaintenanceType.live];
        return false;
    }

    /**
     * Returns a false constant for the purposes of SSBT
     * @returns const false
     */
    public isSportInMaintenance() {
        // return !!this.config.applicationStatus.maintainedParts[MaintenanceType.sport];
        return false;
    }

    public isEventInMaintenance(event: Match) {
        return event.isOngoing() && this.isLiveInMaintenance()
            || !event.isOngoing() && this.isSportInMaintenance();
    }

    public static clearInstance() {
        if (process.env.NODE_ENV !== 'test') {
            throw new Error('For use in tests only');
        }
        delete MaintenanceService.instance;
    }
}

export default MaintenanceService as  Singleton<MaintenanceService>;

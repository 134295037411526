import Vue from 'vue';
import { get as _get } from 'lodash';
import Match from '@models/offer/Match';
import Struct from '@models/struct/Struct';

const sportHeight = 48;
const matchHeight = 97;
const tournamentHeaderHeight = 26;

export const sportGridState = Vue.observable({
    selectedEventHeight: 0,
});

export enum TypeId {
    sport,
    tournament,
}
interface Item {
    id: number;
    height: number;
}

interface SportItem extends Item {
    name: string;
}

interface TournamentItem extends Item {
    data: {
        name: string;
        periodNames: string[];
        matches: Match[];
    };
}

export const generateVirtualScrollData = (
    matches: Match[],
    { sports, tournaments, expandedEventId }:
    { sports: Struct['sports'], tournaments: Struct['tournaments'], expandedEventId: Match['id'] | null },
    ) => {
    const items = [] as Item[];

    const getMatchHeight = (match: Match) => {
        if (match.id === expandedEventId) {
            return matchHeight + sportGridState.selectedEventHeight;
        }
        return matchHeight;
    };

    const createSport = (match: Match) : SportItem => {
        const sport = {
            id: match.sportId,
            height: sportHeight,
            name: _get(sports, [match.sportId, 'name']) || '',
            type: TypeId.sport,
        };
        items.push(sport);
        return sport;
    };

    const createTournament = (match: Match) : TournamentItem => {
        const periodNames = match.matchResults.score.map(score => score.name);
        const tournament = {
            id: match.tournamentId,
            height: tournamentHeaderHeight + getMatchHeight(match),
            type: TypeId.tournament,
            data: {
                periodNames,
                name: _get(tournaments, [match.tournamentId, 'name']) || '',
                matches: [match],
            }
        };
        items.push(tournament);
        return tournament;
    };

    if (!matches.length) {
        return [];
    }

    let currentSport = createSport(matches[0]);
    let currentTournament = createTournament(matches[0]);

    for (let i = 1; i < matches.length; i += 1) {
        const match = matches[i];
        if (currentSport.id !== match.sportId) {
            currentSport = createSport(match);
        }
        if (currentTournament.id !== match.tournamentId) {
            currentTournament = createTournament(match);
        } else {
            currentTournament.data.matches.push(match);
            currentTournament.height += getMatchHeight(match);
        }
    }
    return items;
};

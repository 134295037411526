import {
    minBy as _minBy,
    countBy as _countBy,
} from '@lodash';
import StoreUtil from '@store/StoreUtil';
import ISelection from '@models/betSlip/ISelection';
import { IState } from '@app/store';
import Odd from '@models/offer/Odd';
import IBetSlipState from '../types';

export default {
    selections: StoreUtil.createSimpleGetter('selections'),
    undoSelections: StoreUtil.createSimpleGetter('undoSelections'),
    selectionsWithOdd(state: IBetSlipState): ISelection[] {
        return state.selections.filter((s) => s.getOddValue());
    },
    fixedSelectionsCount(state: IBetSlipState, getters: any): number {
        return _countBy(getters.selectionsWithOdd, (s) => s.isFixed)['true'] | 0;
    },
    nonFixedSelectionsCount(state: IBetSlipState, getters: any): number {
        return getters.selectionsWithOdd.length - getters.fixedSelectionsCount;
    },
    bonusableSelectionsCount(state: IBetSlipState, getters: any): number {
        return getters.selectionsWithOdd.filter((s: ISelection) => s.isBonusable).length;
    },
    selectionWithSmallestOdd(state: IBetSlipState, getters: any): ISelection | undefined {
        return _minBy(getters.selectionsWithOdd, (s) => s.getOddValue());
    },
    hasOngoingEvents(state: IBetSlipState): boolean {
        return state.selections.some((s) => s.isOngoing());
    },
    hasPrematchEvents(state: IBetSlipState): boolean {
        return state.selections.some((s) => !s.isOngoing() && s.exists());
    },
    hasSelectionInCollapsedMarkets(state: IBetSlipState, getters: any, rootState: IState, rootGetters: any) {
        return (eventId: number, prematchTag: string) => {
            const event = rootGetters['data/sportOffer/eventsMap'][eventId];
            const selectedOdd = getters.eventOddMap[eventId];

            if (selectedOdd) {
                // some events does not have odds, and consequently no markets
                const prematchMarket = event.getPrematchMarket(prematchTag);
                return prematchMarket && !prematchMarket
                    .odds
                    .find((odd: Odd) => odd.id === parseInt(selectedOdd.split('-')[0], 10));
            }

            return false;
        };
    },
    eventOddMap(state: IBetSlipState, getters: any) {
        return getters.selectionsWithOdd.reduce(
            (total: any, s: ISelection) => {
                if (!s.exists()) {
                    return total;
                }
                return {
                    [s.getEventId()]: `${s.getOddId()}-${s.getOddSpecialBetValue()}`,
                    ...total,
                };
            },
            {}
        ) as Record<string, string>;
    },
    eventOddsMap(state: IBetSlipState, getters: any) {
        return getters.selectionsWithOdd.map((odd: any) => {
            return {
                match: odd.getEventId(),
                market: odd.getMarketId(),
                group: odd.odd.groupId,
                uuid: odd.odd.uuid,
                specialValue: odd.getOddSpecialBetValue(),
            };
        });
    },
};

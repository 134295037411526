<template>
    <div class="betslip--terminal-header">
        <BinarySwitcher
            v-if="hasTypes"
            class="betslip--terminal-header__binary-switcher"
            v-bind="switcherOptions"
            @change="onTypeClick"
            :value="value"
        />
        <div v-if="!hasTypes" class="betslip--terminal-header__title">
            {{ $t('betSlip') | upperCase }}
        </div>
        <Toolbar
            :selectionsCount="selections.length"
            :undoSelections="undoSelections"
            :selectionNames="$t('events')"
            @clear="$emit('clear')"
            @undoClear="$emit('undoClear')"
            @close="$emit('close')"
            class="betslip__static__top"
        />
    </div>
</template>

<script>
    import Toolbar from '@src/terminal/app/modules/shared/betSlip/ToolbarTerminal.vue';
    import BinarySwitcher from '@shared/components/BinarySwitcher.vue';
    import { BetSlipType } from '@core/models/shared/betSlip/betSlipEnums';
    import { upperCase } from '@modules/shared/filters';

    export default {
        name: 'BetSlipHeader',
        props: {
            hasTypes: {
                type: Boolean,
                default: true,
            },
            isSimple: {
                type: Boolean,
                default: true,
            },
            selections: {
                type: Array,
                required: true,
            },
            undoSelections: {
                type: Array,
                required: false,
                default: null,
            },
        },
        created() {
            if (this.$props.hasTypes) {
                this.switcherOptions = {
                    first: {
                        label: upperCase(this.$t('simple')),
                        value: BetSlipType.simple,
                    },
                    second: {
                        label: upperCase(this.$t('system')),
                        value: BetSlipType.system,
                    }
                };
            }
        },
        methods: {
            onTypeClick(type) {
                this.$emit('typeClick', type);
            },
        },
        computed: {
            value() {
                return this.isSimple ? this.switcherOptions.first.value : this.switcherOptions.second.value;
            }
        },
        components: {
            BinarySwitcher,
            Toolbar,
        }
    };
</script>

import devEnvironments from '@countryConfig/devEnvironment';
import { OfferLangLocale } from '@superbet-group/offer.clients.lib';
import { get as _get } from '@lodash';

const loadRequired = (name: string, fallback?: string): string => {
    const v = import.meta.env[name];
    if (!v && !fallback) {
        throw new Error(`Missing required '${name}' environment variable`);
    }
    if(!v && fallback) {
        return fallback;
    }
    return v;
};

const env = process.env.NODE_ENV || 'development';
//TODO - Define proper env files with correct values and update the build process
const appEnv =loadRequired('VITE_APP_ENV', 'stage');
const platform =loadRequired('VITE_APP_PLATFORM', 'in-house');
const ldServicesStatus = loadRequired('VITE_APP_LD_SERVICES_STATUS', '614c510b0c50ac266b459f6e');
const ldDefaultProject = loadRequired('VITE_APP_LD_DEFAULT_PROJECT', '60f552d49707b8256057b32e');
const dockerImageTag = loadRequired('VITE_APP_DOCKER_IMAGE_TAG', 'development');
console.log('SSBT::App Environment', appEnv, platform);

const isTest = env === 'test';

function getLocale() {
    if (isTest || window.location.host.includes('localhost')) {
        return 'en-GB';
    }

    const countries = {
        romania: 'ro-RO',
    };

    // @ts-ignore
    return countries[import.meta.env.VITE_APP_COUNTRY];
}

function getNumberLocale() {
    return 'ro-RO';
}

function getCountry() {
    return import.meta.env.VITE_APP_COUNTRY || 'romania';
}

const devEnvironment = _get(devEnvironments, `${platform}.${appEnv}`);
console.log('SSBT::Dev Environment proper >>', devEnvironment);

function getOfferLocaleLang() {
    const country = getCountry();
    switch (country) {
        case 'romania':
            return OfferLangLocale.RomanianRomania;
        default:
            return OfferLangLocale.EnglishUnitedKingdom;
    }
};

let backendServer;
let offerServer;
let frontendServer;
let ticketsCache;
let configHost = devEnvironment.configHost;
let newsHost;
let saAPIKey;
let countryKey = devEnvironment.countryKey;
let envCluster;
const paymentHostApi = devEnvironment.paymentHost;
const paymentHostSocket = devEnvironment.paymentHostSocket;
const socketPath = devEnvironment.socketPath;

let appVersion = import.meta.env.VITE_APP_DOCKER_IMAGE_TAG;

export default {
    printedTicket: {
        footerCenterLines: devEnvironment.footerCenterLines,
    },
    services: {
        frontendServer,
        googleAnalytics: {
            id: 'UA-128164307-2'
        },
        allowancesUrl: devEnvironment.allowancesUrl,
        countryKey: devEnvironment.countryKey,
        virtualTournamentsEndpoint: devEnvironment.virtualTournamentsEndpoint,
        country: {
            host: backendServer,
            frontEndHost: frontendServer,
        },
        countryConfig: {
            host: configHost
        },
        offer: {
            host: offerServer,
            socket: offerServer,
            // host: 'https://dev-offer.superbet.ro',
            // socket: 'https://dev-offer.superbet.ro/'
        },
        casino: {
            host: frontendServer,
            bannerReloadPeriod: 5 * 1000,
            bannerBackground: `${frontendServer}/documents/jocuri-cu-jackpot.png`,
        },
        auth: {
            host: backendServer
        },
        user: {
            host: backendServer
        },
        bonus: {
            host: backendServer
        },
        tickets: {
            retrieval: {
                host: ticketsCache,
            },
            payment: {
                socketPath,
                host: paymentHostApi,
                hostSocket: paymentHostSocket,
            },
            timeout: 1000 * 60 * 5
        },
        news: {
            host: newsHost,
            apiKey: saAPIKey,
            country: countryKey,
            fetchNewsPeriod: 1 * 60 * 60 * 1000, // 1 hour
        },
        broadcast: {
            host: 'https://api-staging.score-alarm.eu',
            apiKey: saAPIKey,
            country: countryKey,
        },
        specialTournament: {
            host: 'https://ftv4.sa-api.info',
            apiKey: saAPIKey,
            country: countryKey,
        },
        sentry: {
            dsn: 'https://772c0e783bec477b99d2cec226b45049@o139620.ingest.sentry.io/5853240',
        },
    },

    locale: getLocale(),

    language: getLocale().split('-')[0],

    numberLocale: getNumberLocale(),

    country: getCountry(),

    offerLang: getOfferLocaleLang(),

    format: {
        date: 'YYYY-MM-DD HH:mm:ss'
    },

    app: {
        scrollDuration: 400,
        config: {
            configFetchTimeout: 5 * 1000,
        },
        design: {
            breakingPointWidth: 1440,
        },
        banners: {
            minPromotionBanners: 2,
        },
        country: {
            checkIsMaintenanceModePeriod: 5 * 60 * 1000,
            bannerChangePeriod: 10 * 1000,
            incomingBannersCheckPeriod: 30 * 60 * 1000,
            defaultMaintenanceUrl: 'https://www.superbet.today/03/',
        },
        staticPages: {
            documentsHost: frontendServer,
            imagesHost: frontendServer,
        },
        betSlip: {
            bonus: {
                minimumItems: 6,
                percentageUnit: 5,
            },
            minStakeForSimple: 2,
            minStakeForSystem: 2,
            maxSystemSelections: 16,
            decimalPlaces: 8,
            maxSelections: 16,
            stakes: [
                10,
                100,
                500
            ],
            undoClearDuration: 1000 * 5,
        },

        results: {
            maxDaysToFetch: 31,

            retail: {
                baseUrl: 'https://seven-web-superbet.nsoft.ba'
            },

            special: {
                baseUrl: offerServer
            },

            clab: {
                baseUrl: 'https://offer.superbet.ro/'
            }
        },

        utils: {
            bookieShopStartTime: 10,
        },

        specialOffer: {
            fetchInterval: 1 * 60 * 60 * 1000, // 1 hour
        },

        sportOffer: {
            gridDateFutureThreshold: 6,
            oddDynamicsPreserveTime: 3, // in seconds
            eventGridSort: [
                'sportOrder',
                'specialTournamentOrder',
                'categoryName',
                'tournamentOrder',
                'tournamentName2',
                'tournamentName',
                'date',
                'code',
                'id',
            ],
            initiallyExpandedMarketsNumber: 3, // -1 is off
            popularMarketsNumber: 4,
            filter: {
                alwaysExpandedTournamentsNumber: 5,
                oddTolerance: [
                    {
                        from: 1,
                        to: 2,
                        values: [
                            0.1,
                            0.25,
                            0.5
                        ]
                    },
                    {
                        from: 2,
                        to: 5,
                        values: [
                            1,
                            2,
                            3
                        ]
                    },
                    {
                        from: 5,
                        to: undefined,
                        values: [
                            5,
                            10,
                            15
                        ]
                    }
                ]
            },
            featuredEvents: { /*
                362: {
                    id: 362, // tournament id
                    categoryId: 159,
                    sportId: 5,
                    order: 1,
                    img: {
                        url: '/static/img/specials/basketball-worldcup.svg',
                        height: '64px'
                    }
                },
                344: {
                    id: 344, // tournament id
                    categoryId: 45,
                    sportId: 4,
                    order: 2,
                    img: {
                        url: '/static/img/specials/flag-us-open.svg',
                        height: '64px'
                    }
                }
            */},
        },

        format: {
            date: ['dd', 'mm', 'yyyy'],
            time: ['HH', 'MM'],
            apiDateFormat: 'YYYY-MM-DD HH:mm:ss', // api date format for tickets
        },

        ticketsPage: {
            defaultPageSize: 10,
            stackSize: 10,
        },

        search: {
            numberOfResults: 10,
            numberOfSuggestions: 5,
        }
    },

    environment: {
        variables: devEnvironment,
        appEnv,
        dockerImageTag,
        platform,
        isQa: appEnv === 'qa',
        isSilent: appEnv === 'silent',
        isNSoft: platform === 'nsoft',
        isInHouse: platform === 'in-house',
        ldServicesStatus,
        ldDefaultProject,
        name: env,
        cluster: envCluster,
        isProduction: env === 'production',
        isDevelopment: env === 'development',
        isTest: env === 'test',
        isAxCluster: envCluster === 'ax',
        version: appVersion,
    },

    cache: {
        // set to 31 to cache all results, ask somebody what should be threshold?
        numberOfDaysToCache: 3,
    },
};

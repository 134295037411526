<template>
    <div class="event-row__markets-container">
        <div class="event-row__expanded-markets">
            <MarketRow
                v-for="market in preparedMarkets"
                :key="market.id"
                v-bind="market"
            />
        </div>
    </div>
</template>

<script>
    import { orderBy as _orderBy } from '@lodash';
    import { mapGetters } from 'vuex';
    import MarketRow from './MarketRow.vue';

    export default {
        props: {
            matchId: {
                type: Number,
                required: true,
            },
        },
        computed: {
            ...mapGetters('data/results/sport', [
                'resultsMap'
            ]),
            preparedMarkets() {
                const match = this.resultsMap[this.matchId];
                if (match) {
                    let markets = match.getMarkets();
                    markets = _orderBy(markets, 'order');
                    return markets.map((market) => ({
                        id: market.id,
                        name: market.name,
                        odds: _orderBy(market.odds, 'order'),
                    }));
                }
                return [];
            }
        },
        components: {
            MarketRow
        },
        mounted() {
            this.$emit('mounted');
        },
        beforeDestroy() {
            this.$emit('destroyed');
        }
    };
</script>

<template>
    <div class="w-full" >
        <slot v-if="isAppReady"/>
        <PlaceholderContainer v-else/>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import PlaceholderContainer from '@modules/shared/PlaceholderContainer.vue';

    export default {
        name: 'AppReadyChecker',
        computed: {
            ...mapGetters('data/country', [
                'isAppReady'
            ])
        },
        components: {
            PlaceholderContainer,
        }
    };
</script>

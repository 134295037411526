import sportRoutes from '@app/router/routes/sport';
import searchRoutes from '@app/router/routes/search';
import resultsRoutes from '@app/router/routes/results';
import ticketsRoutes from '@app/router/routes/tickets';

export default [
    ...sportRoutes,
    ...searchRoutes,
    ...resultsRoutes,
    ...ticketsRoutes,
];

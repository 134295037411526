export default class Combinations {
    private n: number;
    private k: number;

    constructor(n: number, k: number) {
        if (n < k) {
            throw new Error('n must be larger than k');
        }

        this.n = n;
        this.k = k;
    }

    public getNumberOfCombinations(): number {
        return this.calculateNumerator() / this.calculateDenominator();
    }

    private calculateNumerator(): number {
        let result = 1;
        for (let i = this.n - this.k + 1; i <= this.n; i += 1) {
            result *= i;
        }
        return result;
    }

    private calculateDenominator(): number {
        let result = 1;
        for (let i = this.k; i > 0; i -= 1) {
            result *= i;
        }
        return result;
    }
}

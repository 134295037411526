<template>
    <div class="date-picker--terminal">
        <div
            class="date-picker__header--terminal"
        >
            <span class="date-picker__header-title--terminal">{{ $t('choose date') | capitalize }}</span>
        </div>
        <table class="date-picker__grid--terminal" >
            <div class="date-picker__grid-header--terminal">
                <div
                    v-for="day in weekDays"
                    :key="day"
                    class="date-picker__grid-header-day--terminal"
                >
                    {{ $t(day) | capitalize | shorten }}
                </div>
            </div>
            <div class="date-picker__grid-dates--terminal">
                <div
                    v-for="(weekDates, index) in datesByWeek"
                    :key="index"
                    class="date-picker__grid-week--terminal"
                >
                    <span
                        v-for="(date, dateIndex) in weekDates"
                        @click="onDateClick(date)"
                        :class="[{ date: !!date }, { selected: isSelectedDate(date)}]"
                        :key="dateIndex"
                        class="date-picker__grid-day-wrapper--terminal"
                    >
                        <template v-if="date">
                            <span class="date-picker__grid-day--terminal">
                                {{ date | formatDateTime('ddmm')}}
                            </span>
                        </template>
                    </span>
                </div>
            </div>
        </table>
    </div>
</template>

<script>
    import DateUtils from '@core/utils/date/DateUtils';

    export default {
        name: 'DatePicker',
        props: {
            datesByWeek: {
                type: Array,
                required: true
            },
            selectedDate: {
                type: Date,
                required: true
            }
        },
        created() {
            this.weekDays = [
                'monday',
                'tuesday',
                'wednesday',
                'thursday',
                'friday',
                'saturday',
                'sunday',
            ];
        },
        methods: {
            onDateClick(date) {
                if (date) {
                    this.$emit('dateClick', date);
                }
            },
            isSelectedDate(date) {
                return date ? DateUtils.isSameDate(date, this.selectedDate) : false;
            },
        }
    };
</script>

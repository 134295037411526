<template functional>
    <div
        class="live-score-widget"
        :class="`server-${props.getLiveData(props.sportId, props.liveData, props.isScoreCompact).serve || 0}`"
    >
        <div
            v-for="(period, i) in props.getLiveData(props.sportId, props.liveData, props.isScoreCompact).score"
            :key="i"
            class="live-score-widget__column"
        >
            <div class="live-score-widget__item">{{ period[0] }}</div>
            <div class="live-score-widget__item">{{ period[1] }}</div>
        </div>
    </div>
</template>

<script>
import {
    processOther,
    processOtherFull,
    processTennis,
} from './helpers';

// TODO handle more sports
const getLiveData = (sportId, liveData, isScoreCompact) => {
    switch (sportId) {
        case 2: // tennis
            return processTennis(liveData);
        case 1: // volleyball
        case 14: // badminton
        case 24: // table tennis
        case 28: // beach volleyball
            return isScoreCompact ? processOther(liveData, true) : processOtherFull(liveData);
        default:
            return isScoreCompact ? processOther(liveData) : processOtherFull(liveData);
    }
};

const isServeSport = (sportId) => [1, 2, 24, 28].includes(sportId);

export default {
    functional: true,
    props: {
        sportId: {
            type: Number,
            required: true,
        },
        liveData: {
            type: Object,
            required: true,
        },
        getLiveData: {
            type: Function,
            default: getLiveData,
        },
        isServeSport: {
            type: Function,
            default: isServeSport,
        },
        isScoreCompact: {
            type: Boolean,
            required: true,
        },
    },
};
</script>

<template>
    <div>
        <Selection
            :selection="selection"
            :error="error"
            :key="getId(selection)"
            :broadcasts="broadcasts"
            :checkShowSbv="checkShowSbv"
            :oddValueDynamics="oddValueDynamics"
            @removeClick="onRemoveClick"
        >
            <input
                :value="isFixed"
                :checked="isFixed"
                @click="setFixed"
                :id="getId(selection).toString()"
                type="radio"
                class="selection-row__fix-switch-checkbox--terminal"
            >
            <label
                :for="getId(selection).toString()"
                class="selection-row__fix-switch-label--terminal"
            >
                <span>{{ $t('fixLabel') }}</span>
            </label>
        </Selection>
    </div>
</template>

<script>
    import Selection from './Selection.vue';

    export default {
        name: 'SystemBetSlipItem',
        props: {
            selection: {
                type: Object,
                required: true
            },
            error: {
                type: Object,
                required: false,
            },
            broadcasts: {
                type: Object,
                required: true
            },
            oddValueDynamics: {
                type: Object,
                required: false,
            },
            checkShowSbv: {
                type: Function,
                required: true,
            },
        },
        data() {
            return {
                isFixed: false,
            };
        },
        watch: {
            'selection.isFixed': {
                handler(value) {
                    this.isFixed = value;
                },
                immediate: true,
            },
        },
        methods: {
            getId(selection) {
                return selection.getOddUuid() || selection.getEventId();
            },
            onRemoveClick(selection) {
                this.$emit('removeClick', selection);
            },
            setFixed() {
                this.$emit('fixClick', this.selection);
            }
        },
        components: {
            Selection
        },
    };
</script>

import {
    find as _find,
    size as _size,
    orderBy as _orderBy,
} from '@lodash';
import StoreUtil from '@store/StoreUtil';
import {
        ISportOfferState,
        Getters,
        PromotionGroupPractical,
        PromotionGroup,
} from './types';
import groupBy from '@utils/dataTransformation/groupBy';

const eventsById = (ids: number[], state: ISportOfferState) => ids
    .filter(id => id in state.events)
    .map(id => state.events[id]);

const getters: Getters = {
    sports(state) {
        return state.sports;
    },
    tournaments(state) {
        return state.tournaments;
    },
    markets(state) {
        return state.markets;
    },
    categories(state) {
        return state.categories;
    },
    oddTypes(state) {
        return state.oddTypes;
    },
    eventsMap(state) {
        return state.events;
    },
    expandedMarketIds(state) {
        return state.expandedMarketIds;
    },
    events(state) {
        return Object.values(state.events);
    },
    topTenEvents(state) {
        return eventsById(state.topTenEvents, state);
    },
    promotionalEvents(state) {
        const eventGroups = [] as PromotionGroupPractical[];

        Object.entries(state.promotionalEvents).forEach(([id, promotion]) => {
            const superOfferConfig = state.superOfferConfig[id];

            if (promotion.matchIds.length) {
                let name = '';
                name = superOfferConfig.name;

                eventGroups.push({
                    id,
                    name,
                    order: superOfferConfig.id,
                    footer: superOfferConfig.footer,
                    primaryMarketAttribute: superOfferConfig.type,
                    type: superOfferConfig.type,
                    events: eventsById(promotion.matchIds, state),
                });
            }
        });
        return eventGroups;
    },
    isOfferStarting(state) {
        return state.isOfferStarting;
    },
    isOfferReady(state) {
        return state.isOfferReady;
    },
    shouldFetchOffer(state) {
        return state.shouldFetchOffer;
    },
    specialTournaments(state) {
        return state.specialTournaments;
    },
    featuredItems(state) {
        return _orderBy(state.featuredTournaments, 'order');
    },
    broadcasts(state) {
        return state.broadcasts;
    },
    getMarketGroupInfosForSportId(state) {
        // Markets groups are sorted in an ascending
        // order based on the `order` property
        const sortedMarketGroupInfos = Object.values(state.marketGroupInfos)
            .sort((a, b) => a.order - b.order);
        const infos = groupBy(sortedMarketGroupInfos, 'sportId');
        // @ts-ignore
        return (sportId: number) => infos.get(sportId) || [];
    },
    liveEventsCount: StoreUtil.createSimpleGetter('liveEventsCount'),
    // doesn't have to be in the store
    subscriptions: StoreUtil.createSimpleGetter('subscriptions'),
    isStructFetched: StoreUtil.createSimpleGetter('isStructFetched'),
    allChangesIncrementId: StoreUtil.createSimpleGetter('allChangesIncrementId'),
    areSubscriptionsSet: StoreUtil.createSimpleGetter('areSubscriptionsSet'),
    isOfferFetched: StoreUtil.createSimpleGetter('isOfferFetched'),
    isSuperOfferFetched: StoreUtil.createSimpleGetter('isSuperOfferFetched'),
    areTopTenEventsFetched: StoreUtil.createSimpleGetter('areTopTenEventsFetched'),
    oddValueDynamics: StoreUtil.createSimpleGetter('oddValueDynamics'),
};

export default getters;

import { Subscription } from 'rxjs';

type SubscriptionKey = number | string;

class Subscriptions {
    subscriptions: Record<SubscriptionKey, Subscription> = {};

    addSubscription(key: SubscriptionKey, subscription: Subscription): void {
        if (key && !this.hasSubscription(key)) {
            this.subscriptions[key] = subscription;
        }
    }

    clearSubscriptions(): void {
        Object.keys(this.subscriptions).forEach((key: SubscriptionKey) => {
            this.subscriptions[key].unsubscribe();
        });
        this.subscriptions = {};
    }

    removeSubscription(key: SubscriptionKey): void {
        if (this.hasSubscription(key)) {
            this.subscriptions[key].unsubscribe();
            delete this.subscriptions[key];
        }
    }

    hasSubscription(key: SubscriptionKey): boolean {
        return !!this.subscriptions[key];
    }
}

export const MatchSubscriptions = new Subscriptions();
export const BetBuilderOddSubscriptions = new Subscriptions();

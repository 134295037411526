export default {
    services: {
        cashout: {
            isAvailable: true,
        },
    },
    auth: {
        usernameValidChars: '[A-Za-z0-9@$!%*#?&ăâîşţąćęłńóśźż]+',
        portalDesktopId: 'Desktop_new',
        portalMobileId: 'Mobile_new',
        registerUrl: 'register',
    },
    footer: {
        whatsAppNumber: '0725 154 154!',
        nonStopPhoneNumber: '1226',
        email: 'support@superbet.ro',
        faqPage: 'https://suport.superbet.ro/hc/ro',
    },
    betSlip: {
        allowSubmitWithMaxWin: true,
        validationsConfig: {
            isEnabledWinLowerThanStake: true,
        },
    },
};

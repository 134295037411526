import {
    links,
    routeNames,
    titles
} from '@app/router/constants';
import {
    getDefaultLayout,
} from '@app/router/utils';
// import SearchPage from '@pages/SearchPage.vue';
import SearchPage from '@src/terminal/app/pages/SearchPage.vue';

export default [
    {
        path: links.search,
        name: routeNames.search,
        components: getDefaultLayout(SearchPage),
        meta: {
            title: titles.landing
        }
    },
];

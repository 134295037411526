<template>
    <div
        :class="overlayClasses"
        @mousedown.self="onClickOutside"
    >
        <component
            v-if="isIndependent"
            :is="modalComponent"
            @close="close"
        />
        <div
            v-else
            :class="containerClasses"
        >
            <div class="modal__header">
                <div class="modal__title">{{  $t(title) }}</div>
                <i
                    @click="close"
                    class="icon icon-close"
                />
            </div>
            <component
                :is="modalComponent"
                @close="close"
            />
        </div>
    </div>
</template>

<script>
    import { Keys, getKey } from '@app/utils/keyListener';

    export default {
        created() {
            this.modalOverLayClass = 'modal__overlay';
        },
        props: {
            code: {
                type: String,
                required: true,
            },
            modalComponent: {
                type: Object,
                required: true,
            },
            setModal: {
                type: Function,
                required: true,
            },
            containerClass: {
                type: String,
                required: false,
                default: '',
            },
            overlayClass: {
                type: String,
                required: false,
                default: '',
            },
            title: {
                type: String,
                required: false,
                default: ''
            },
            goBack: {
                type: Boolean,
                required: false
            },
            // Used when the component itself will be the modal. No header or container around it
            isIndependent: {
                type: Boolean,
                default: false,
            }
        },
        computed: {
            containerClasses() {
                const classes = ['modal__container'];
                if (this.containerClass) {
                    classes.push(this.containerClass);
                }
                return classes;
            },
            overlayClasses() {
                const classes = ['modal__overlay'];
                if (this.overlayClass) {
                    classes.push(this.overlayClass);
                }
                return classes;
            },
        },
        methods: {
            close() {
                this.$emit('close');
            },
            onClickOutside(event) {
                if (event.target.className === this.modalOverLayClass) {
                    this.close();
                }
            },
            onKeyup(event) {
                const key = getKey(event);
                if (key === Keys.ESC) {
                    this.close();
                }
            }
        },
        mounted() {
            document.addEventListener('keyup', this.onKeyup);
        },
        destroyed() {
            document.removeEventListener('keyup', this.onKeyup);
        },
    };
</script>

<template>
    <div>
        <TimeFilterItem
            v-for="(filter, index) in filters"
            :key="index"
            :timeFilterId="filter.id"
            :isUppercase="filter.isUppercase"
            :isSelected="filter.id === selectedFilter"
            @click="onFilterClick"
        />
    </div>
</template>

<script>
    import TimeFilterItem from './TimeFilterItem.vue';

    export default {
        props: {
            filters: {
                type: Array,
                required: true,
            },
            selectedFilter: {
                type: String,
                required: false,
                default: null,
            },
        },
        methods: {
            onFilterClick(filter) {
                this.$emit('click', filter);
            },
        },
        components: {
            TimeFilterItem,
        }
    };
</script>

import AbstractMapper from '@core/utils/mappers/AbstractMapper';
import { ITicketNegotiationDto, ITicketNegotiationOddDto } from './dtos/ITicketNegotiationDto';
import { TicketNegotiation } from '@models/tickets/TicketNegotiation';
import TicketNegotiationOddMapper from '@core/mappers/tickets/TicketNegotiationOddMapper';

const oddMapper: TicketNegotiationOddMapper = new TicketNegotiationOddMapper();

export default class TicketNegotiationMapper extends AbstractMapper<ITicketNegotiationDto, TicketNegotiation> {
    createTargetObject(origin: ITicketNegotiationDto) {
        return {
            betSlipStake: origin.userAmount,
            bookieStake: origin.bookieAmount,
            potentialWin: origin.win,
            odds: origin.odds
                ? oddMapper.map(
                    origin.odds.filter((odd: ITicketNegotiationOddDto) => {
                        return parseFloat(odd.newOdd as any).toFixed(2) !== parseFloat(odd.oldOdd as any).toFixed(2);
                    }),
                    true
                )
                : [],
        } as TicketNegotiation;
    }
}

import AbstractMapper from '@core/utils/mappers/AbstractMapper';
import IRuleDto from '@core/mappers/offer/dtos/IRuleDto';
import Rule from '@core/models/shared/betSlip/Rule';

export default class RuleMapper extends AbstractMapper<IRuleDto, Rule> {
    createTargetObject(dto: IRuleDto, key: number): Rule {
        const rule = new Rule();

        rule.name = dto.name;
        rule.modifier = dto.modifier;
        rule.param1 = dto.param1;
        rule.param2 = dto.param2;

        return rule;
    }
}

<!-- eslint-disable vue/attribute-hyphenation -->
<template>
    <div>
        <div v-if="ticketData" class="ticket-check__top">
            <ticket-compile-info v-if="ticketData.actions.rebet.active" message="repeatTicketMsg"/>
            <ticket-compile-info v-if="showCancelMessage" message="cancelTicketMsg"/>
            <table class="table">
                <ticket-header :data="ticketData"/>
                <ticket-body :data="ticketData"
                />

                <ticket-status-component v-if="showTicketStatus()"
                               :data="ticketData"
                />
                <ticket-lucky-loser v-if="ticketData.ticket.win.isLuckyLoser"/>
                <tr v-if="ticket.system">
                    <td colspan="1" class="p-l-1 p-y-1">
                        {{ $t('system') }}
                    </td>
                    <td colspan="1" class="system-width">
                        <div class="system-container">
                            <div class="system-data" v-for="system in ticket.system.selected">
                                {{ formatSystemStatus(ticket.system, system) }}
                            </div>
                        </div>
                    </td>
                </tr>
            </table>

        </div>
    </div>
</template>

<script lang="ts">
    import TicketStatusComponent from '@src/terminal/app/modules/checkTicket/TicketStatus.vue';
    import TicketNotification from '@src/terminal/app/modules/checkTicket/TicketNotification.vue';
    import TicketLuckyLoser from '@src/terminal/app/modules/checkTicket/TicketLuckyLoser.vue';
    import TicketBody from '@src/terminal/app/modules/checkTicket/TicketBody.vue';
    import TicketHeader from '@src/terminal/app/modules/checkTicket/TicketHeader.vue';
    import TicketCompileInfo from '@src/terminal/app/modules/checkTicket/TicketCompileInfo.vue';
    import { TicketData, CheckTicket, CheckTicketEvent } from '@core/models/tickets/Ticket';
    import { OddStatus} from "@models/offer/enums";
    import {EventStatus, EventType, TicketStatus} from '@core/models/tickets/enums';
    import Combinations from "@utils/math/Combinations";

    export default {
        name: 'TicketOverview',
        components: {
            TicketStatusComponent,
            TicketLuckyLoser,
            TicketCompileInfo,
            TicketHeader,
            TicketBody,
            TicketNotification
        },
        props: {
            ticketData: {
                required: true,
                type: Object as () => TicketData
            }
        },
        computed: {
            parsedData(): TicketData {
                return this.ticketData;
            },
            showCancelMessage () {
                const nonCancelableStatuses = [
                    TicketStatus.win,
                    TicketStatus.canceled,
                    TicketStatus.lost,
                    TicketStatus.payed,
                    TicketStatus.refund
                ];
                if (nonCancelableStatuses.includes(this.ticket.status)) {
                    return false;
                }
                const now = new Date();
                const cancelLimit = new Date(this.ticket.dateReceived);
                const cancelTimeout = 60 * 15;
                cancelLimit.setSeconds(cancelLimit.getSeconds() + cancelTimeout);
                const haveLiveEvents = this.ticket.events.some((event) => event.type === EventType.live);
                return now.getTime() < cancelLimit.getTime() && this.areSomeEventsStarted(this.ticket.events) && !haveLiveEvents ;
            },
            ticket(): CheckTicket {
                return this.parsedData.ticket;
            }
        },
        methods: {
            areSomeEventsStarted(events) {
                return events.some((event) => {
                    const now = new Date();
                    const startDate = new Date(event.date);
                    return startDate < now;
                });
            },
            showTicketStatus(): boolean {
                return this.ticket.status === TicketStatus.active ||
                    this.ticket.status === TicketStatus.canceled ||
                    this.ticket.status === TicketStatus.lost ||
                    this.ticket.status === TicketStatus.payed ||
                    this.ticket.status === TicketStatus.win
            },
            formatSystemStatus(system, systemSelected) {
                const { fixed, count } = system;

                const fixedPrefix = fixed > 0 ? `F${fixed} ` : '';
                const selectedCount = systemSelected;
                const combinationCount = this.calculateCombinations(count, selectedCount);
                return `${fixedPrefix}${selectedCount}/${count} (${combinationCount})`;
            },
            calculateCombinations(totalItems, chosenItems) {
                return new Combinations(totalItems, chosenItems).getNumberOfCombinations();
            }
        }
    };
</script>

<style>
.bonus-wrapper {
    height: 42px;
}
.bonus-notification {
    background: #413001;
    width: 100%;
    color: #413001;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 11px;
    gap: 10px;
}
.bonus-notification .text {
    color: #FBA417;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
}
.ticket-check__top {
    background: #242424;
    color: #fff;
}
.info-message {
    background: #FFB82E29;
    color: #fc3;
    font-size: 18px;
    padding: 0.65em;
    font-weight: 400;
    -webkit-animation: info-keyframes 0.5s infinite;
    animation: info-keyframes 0.5s infinite;
    display: block;
    margin-bottom: 5px;
}
.win {
    background-color: #008000;
}
.lost {
    background-color: #E42313;
}
.active {
    background-color: #242424;
}
.table {
    width: 100%;
    max-width: 100%;
    background: #242424;
    margin-bottom: 0.65rem;
    font-size: 1rem;
    line-height: 1.3;
    color: #fff;
    border-collapse: collapse;
    border-spacing: 0;
}
.table tbody {
    background-color: #242424;
}
.table .is--dark {
    background: rgba(0, 0, 0, 0.4);
}
.table thead, .table tbody, .table tfoot {
    border: none;
}
.table thead tr {
    color: #e6e6e6;
    border: 0;
}
td, th {
    padding: 0;
}
th {
    text-align: left;
}
.table th, .table td {
    line-height: 1.8;
    font-weight: 400;
    display: table-cell;
}
.table tbody td {
    color: #fff;
}
.table thead tr th {
    background-repeat: no-repeat;
    background-position: center;
}
.p-l-1 {
    padding-left: 0.65rem !important;
}
.p-y-1 {
    padding-top: 0.65rem !important;
    padding-bottom: 0.65rem !important;
}
.text-uppercase {
    text-transform: uppercase !important;
}
.results-number {
    display: inline-block;
    width: 2.5em;
    height: 2.5em;
    line-height: 2.5em;
    margin-top: 3px;
    margin-left: 4px;
    background: #424142;
    font-family: robotoRegular;
    color: #acacad;
    text-align: center;
    border-radius: 3px;
}
.results-number.active {
    background: #0066ae;
    color: #fff;
}
.super-advantage-button {
    background-color: #FFA500; /* This is a placeholder orange, replace with the exact color */
    border: none;
    padding: 4px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 20px; /* Adjust as needed to match the curvature in your image */
    box-shadow: 0 4px #999; /* Adjust for the desired depth effect */
}

.super-advantage-button:hover { background-color: #e69500 } /* Darker orange on hover */
.super-advantage-button:active {
    background-color: #e69500;
    box-shadow: 0 2px #666; /* Simulates button press */
    transform: translateY(2px);
}
.grayed-initial-bonus {
    opacity: 0.33;
}
.system-width {
    max-width: 170px;
}
.system-container {
    display: flex;
    flex-wrap: wrap;
    max-width: 260px;
}

.system-data {
    flex: 0 0 calc(50% - 5px);
    margin-right: 5px;
    background-color: #4F5157;
    padding: 3px;
    text-align: center;
    border-radius: 4px;
    box-sizing: border-box;
    margin-bottom: 10px;
}

.system-data:nth-of-type(even) {
    margin-right: 0;
}

.system-data:last-of-type:nth-of-type(odd) {
    margin-right: 0;
}
</style>`;

import AbstractMapper from '@core/utils/mappers/AbstractMapper';
import IActiveTicketDto from './dtos/IActiveTicketDto';
import TicketEventMapper from './TicketEventMapper';
import Ticket, { PotentialBonuses } from '@core/models/tickets/Ticket';
import { EventType, TicketStatus, TicketType } from '@core/models/tickets/enums';

export default class ICoreTicketMapper extends AbstractMapper<IActiveTicketDto, Ticket> {
  constructor(private ticketEventMapper = new TicketEventMapper()) {
    super();
  }

  mapBonusesPotentialEligibility(origin: IActiveTicketDto) {
    const defaultBonuses: PotentialBonuses = {
      LUCKY_LOSER: false,
      SUPER_BONUS: false,
    };
    if (!origin.bonusesPotentialEligibilityMap) {
      return defaultBonuses;
    }

    return origin.bonusesPotentialEligibilityMap.reduce(
      (acc, [bonus, value]) => ({ ...acc, [bonus]: value }),
      defaultBonuses,
    );
  }

  createTargetObject(origin: IActiveTicketDto, propagatedProperties: Partial<Ticket> = {}) {
    const ticket = {} as Ticket;
    ticket.id = origin.ticketId;
    ticket.controlCode = origin.codeTicketControl;
    ticket.type = origin.ticketType as TicketType;
    ticket.coefficient = parseFloat(origin.sumCoefficient);
    ticket.status = origin.status as TicketStatus;
    // TODO see what system looks like
    if (origin.system) {
      const [selected, count] = origin.system.split('/');
      const combinations = selected.split(';').map((e) => parseInt(e, 10));
      const combinationsCount = parseInt(count, 10);
      ticket.system = {
        fixed: origin.events.length - combinationsCount,
        count: combinationsCount,
        selected: combinations,
      };
    }
    ticket.dateReceived = this.toDate(origin.dateReceived, { assumeUtc: true });
    ticket.datePayoff = this.toDate(origin.datePayoff, { assumeUtc: true });

    ticket.win = {
      minPotential: origin.minPotentialWin,
      estimated: origin.winEstimated,
      branchId: '', // doesn't exist for online made printed tickets
      tax: origin.aditionalTax,
      payoff: 0, // todo remove
      bonus: parseFloat(origin.sumBonus.split('%=')[1]),
      bonusPercent: parseInt(origin.sumBonus.split('%=')[0], 10),
    };
    ticket.payment = {
      stake: parseFloat(origin.sumStake),
      total: parseFloat(origin.sumTotal),
      handlingFee: origin.handlingFee,
      tax: origin.aditionalTax,
      branchId: origin.branchName.split(' ')[0],
    };
    ticket.potentialBonuses = this.mapBonusesPotentialEligibility(origin);
    ticket.events = origin.events.map((e) => {
      // live from terminal and does not have live type!
      if (origin.ticketType === 'LiveBetting' && ticket.id.startsWith('X1')) {
        e.eventType = EventType.live;
      }
      return this.ticketEventMapper.createTargetObject(e);
    });
    return {
      ...ticket,
      ...propagatedProperties,
    };
  }
}

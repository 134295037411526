/**
    finds all indices of a substring appearing in a string
*/
export function findAllIndices(searchArea: string, searchTerm: string) {
    if (!searchTerm.length) {
        return [];
    }
    const indices = [];
    let lastIndex = 0;
    do {
        lastIndex = searchArea.indexOf(searchTerm, lastIndex);
        if (lastIndex !== -1) {
            indices.push(lastIndex);
            lastIndex += searchTerm.length;
        }
    } while (lastIndex !== -1);
    return indices;
}

/**
    finds the highest number in array lower than the provided number
    eg. lastLower([3, 11, 17, 18], 12) => 11
*/
export function lastLower(numbers: number[], number: number) {
    if (!numbers.length) {
        console.warn('numbers can\'t be empty');
        return;
    }
    let currentNumber = numbers[0];
    for (let i = 1; i < numbers.length; i += 1) {
        if (numbers[i] > number) {
            break;
        }
        currentNumber = numbers[i];
    }
    return currentNumber;
}

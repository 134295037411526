/* eslint-disable import/no-named-default */
import { ITask } from '@src/terminal/core/services/gravity/_private/Task';
import { TicketStatus, TicketType } from '@models/tickets/enums';
import store from '@app/store';
import Ticket, { EventCondensed } from '@models/tickets/Ticket';
import TicketService from '@core/services/tickets/TicketService';
import i18n from '@src/app/localization/i18n';
import { get as _get } from '@lodash';
import PlatformService from '../../platform/PlatformService';
import FeatureFlagService from '@src/terminal/core/services/flags/FeatureFlagService';

class TicketsRebetTask implements ITask {
  private retryTimeout: any = null;
  private retryCount: number = 1;

  execute = async (message?: any): Promise<void> => {
    try {
      await this.rebetTicket(message);
      clearTimeout(this.retryTimeout);
    } catch (err) {
      if (this.retryCount <= 3) {
        this.retryCount += 1;
        console.log('SSBT::Rebet error, retrying', this.retryCount, '/', 3);
        this.retryTimeout = setTimeout(this.execute, 2000, message);
      } else this.errorNotificationOutOfRetries();
    }
  };

  rebetTicket = async (message?: any) => {
    const ticketId = _get(message, 'data.ticket.slip.codes[0].id', null);
    const data = await TicketService.getInstance().getTicketById(ticketId);
    data.type = TicketType.sport;
    const mappedTicketData = this.mapTicketForRecreate(data);
    await store.dispatch('ui/ticketsStack/recreateTicket', mappedTicketData, { root: true });
  };

  mapTicketForRecreate = (ticket: Ticket) => ({
    id: ticket.id,
    type: ticket.type,
    status: ticket.status,
    coefficient: ticket.coefficient,
    system: ticket.system,
    potentialWin: ticket.win.estimated,
    minimumPotentialWin: ticket.win.payoff,
    payoff: ticket.win.payoff,
    events: this.filteredEvents(ticket.events),
    isWon: ticket.status === TicketStatus.win,
    hasOngoingEvents: true,
    dateReceived: ticket.dateReceived,
    stake: ticket.payment.total,
    numEventsLeft: ticket.events.length,
    canRecreate: true,
  });

  filteredEvents = (events: EventCondensed[]) => {
    if (FeatureFlagService.getInstance().isInPlayAvailable()) {
      return events;
    }
    const isStartedEvent = (event: EventCondensed) => event.date! < new Date();
    return events.filter((event: EventCondensed) => !isStartedEvent(event));
  };

  errorNotificationOutOfRetries = () => {
    const notificationTitle = i18n.t('ticket rebet failed, please try again later').toString();
    PlatformService.getInstance().sendNotification({
      title: notificationTitle,
      text: notificationTitle,
      type: 'error',
    });
  };
}

export default TicketsRebetTask;

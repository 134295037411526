import { fromEvent as observableFromEvent, Observable } from 'rxjs';
import { SocketEvents } from './enums';
import { share } from 'rxjs/operators';
import * as socketIo from 'socket.io-client';
import ErrorType from '@errors/network/ErrorType';
import { RequestError } from '@errors/network/RequestError';
import Logger from '@loggers/Logger';
import BufferedSocket from '@core/services/common/BufferedSocket';
import ConnectOpts = SocketIOClient.ConnectOpts;

export default class Socket extends BufferedSocket<any> {
    public logger = Logger;
    private socket: SocketIOClient.Socket;
    private url: string;
    private connectOptions: any;
    private socketTicketOutcome$: Observable<any>;
    private socketTicketSubmitStatus$: Observable<any>;
    private socketTerminalTicketCheck$: Observable<any>;
    private socketTerminalStatus$: Observable<any>;
    private socketConnected$: Observable<any>;
    private socketReconnected$: Observable<any>;
    private socketConnectError$: Observable<any>;
    private socketError$: Observable<any>;
    private socketDisconnected$: Observable<any>;

    constructor(
        url: string,
        path: string,
        connectOptions: ConnectOpts = {
            path,
            secure: false,
            transports: ['websocket'],
            upgrade: false
        },
    ) {
        super();
        this.url = url;
        this.connectOptions = connectOptions;
    }

    /**
     * @throws {RequestError}
     * @throws {Error}
     */
    public start(): void {
        try {
            this.socket = socketIo.connect(this.url, this.connectOptions);
            this.createStreams();
        } catch (e) {
            this.logger.error(e);
            // if we cant initialize we want the whole thing down
            this.stop();
            throw new RequestError(e, ErrorType.socketConnectionError);
        }
    }

    /**
     * @throws {RequestError}
     * @throws {Error}
     */
    public stop(): void {
        if (this.socket) {
            try {
                this.socket.disconnect();
                this.socket.close();
            } catch (e) {
                this.logger.error(e);
                throw new RequestError(e, ErrorType.socketDisconnectError);
            }
        }
    }

    public getSocketTicketOutcome$(): Observable<any> {
        return this.socketTicketOutcome$;
    }

    public getSocketTicketSubmitStatus$(): Observable<any> {
        return this.socketTicketSubmitStatus$;
    }

    public getTerminalTicketCheck$(): Observable<any> {
        return this.socketTerminalTicketCheck$;
    }

    public getTerminalStatus$(): Observable<any> {
        return this.socketTerminalStatus$;
    }

    public getSocketConnected$(): Observable<any> {
        return this.socketConnected$;
    }

    public getSocketConnectError$(): Observable<any> {
        return this.socketConnectError$;
    }

    public getSocketDisconnected$(): Observable<any> {
        return this.socketDisconnected$;
    }

    public getSocketError$(): Observable<any> {
        return this.socketError$;
    }

    public getSocketReconnected$(): Observable<any> {
        return this.socketReconnected$;
    }

    public emitTerminalTicketCheckRequest(ticketSubmitReqId: string): void {
        this.socket.emit(SocketEvents.TerminalTicketCheckRequest, { requestId: ticketSubmitReqId });
    }

    public emitTerminalLogRequest(log: any): void {
        this.socket.emit(SocketEvents.TerminalLogRequest, log);
    }

    protected onSocketReconnect = () => {
        this.isConnected = true;
    };

    private createStreams() {
        this.socketTicketOutcome$ = observableFromEvent(this.socket, SocketEvents.TicketOutcome).pipe(share());
        this.socketTicketSubmitStatus$ = observableFromEvent(this.socket, SocketEvents.TicketSubmitStatus).pipe(share());
        this.socketTerminalTicketCheck$ = observableFromEvent(
            this.socket,
            SocketEvents.TerminalTicketCheck).pipe(share()
        );
        this.socketTerminalStatus$ = observableFromEvent(this.socket, SocketEvents.TerminalStatus).pipe(share());
        this.socketConnected$ = observableFromEvent(this.socket, 'connected').pipe(share());
        this.socketReconnected$ = observableFromEvent(this.socket, 'reconnect').pipe(share());
        this.socketConnectError$ = observableFromEvent(this.socket, 'connect-error').pipe(share());
        this.socketDisconnected$ = observableFromEvent(this.socket, 'disconnect').pipe(share());
        this.socketError$ = observableFromEvent(this.socket, 'error').pipe(share());
    }

    rawEmit = (event: string, ...args: any[]) => {
        this.socket.emit(event, ...args);
    }
}

import Singleton from '@core/services/common/Singleton';
import Modals from 'src/app/store/modules/ui/shared/modal/modals';
import store from '@app/store';
import { instanceGuard } from '@core/utils/services';
import { BettingAvailable } from './_private/Betting';
import { NotReadySource } from './_private/enums';

class BettingAvailableService {

    private static instance: BettingAvailableService;
    private betting: Map<NotReadySource, BettingAvailable> = new Map();

    private constructor() {}

    public static getInstance(): BettingAvailableService {
        return instanceGuard(BettingAvailableService.instance);
    }

    public static createInstance() {
        if (!BettingAvailableService.instance) {
            BettingAvailableService.instance = new BettingAvailableService();
        }
        return BettingAvailableService.instance;
    }

    public get() {
        return this.betting.entries().next().value;
    }

    public set(source: NotReadySource, reason: BettingAvailable) {
        this.betting.set(source, reason);
    }

    public getSource(source: NotReadySource) {
        return this.betting.get(source);
    }

    public unset(source: NotReadySource) {
        if (!!this.betting.get(source)) {
            this.betting.delete(source);
        }
    }

    public available(): Boolean {
        return this.betting.size <= 0;
    }

    public async setErrorModal(betting: BettingAvailable) {
        const errorModal = {
            code: Modals.error.code,
            data: {
                button: {
                    label: 'ok'
                },
                text: {
                    content: betting.reason
                }
            },
        };
        await store.dispatch('ui/modal/setModal', errorModal, { root: true });
    }

    public static clearInstance() {
        if (process.env.NODE_ENV !== 'test') {
            throw new Error('For use in tests only');
        }
        delete BettingAvailableService.instance;
    }
}

export default BettingAvailableService as Singleton<BettingAvailableService>;

<template>
    <div
        class="info-page__container"
    >
        {{ $t(message) | capitalize }}
        <slot/>
        <StaticLink
            path="help-url"
            label="help"
        />
    </div>
</template>

<script>
    import StaticLink from '@shared/components/StaticLink.vue';

    export default {
        name: 'InfoContainer',
        computed: {
            message() {
                if (!this.$router.currentRoute.params.message) {
                    return '';
                }

                return this.$router.currentRoute.params.message;
            },
        },
        components: {
            StaticLink,
        },
    };
</script>

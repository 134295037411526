import Vue from 'vue';
import {
    keyBy as _keyBy,
} from '@lodash';
import Match from '@core/models/offer/Match';
import ResultsService from '@core/services/results/ResultsService';
import { Commit } from 'vuex';
import StoreUtil from '@store/StoreUtil';

const SET_RESULTS = 'SET_RESULTS';
const SET_RESULT = 'SET_RESULT';

export interface ISportResultsState {
    matchResults: Record<Match['id'], Match>;
}

const initialState = { matchResults: {} } as ISportResultsState;

export default {
    namespaced: true,
    state: initialState,
    mutations: {
        [SET_RESULTS](state: ISportResultsState, results: Match[]) {
            // results shouldn't change over time
            // this order asures results with details don't get overwritten
            state.matchResults = { ..._keyBy(results, 'id'), ...state.matchResults };
        },
        [SET_RESULT](state: ISportResultsState, result: Match) {
            Vue.set(state.matchResults, `${result.id}`, result);
        },
    },
    actions: {
        fetchSportResults: StoreUtil.asyncErrorGuard(async ({ commit }: { commit: Commit }, {
            startDate,
            endDate,
        }: { startDate: Date, endDate: Date }) => {
            const results = await ResultsService.getInstance().getMatchResults(startDate, endDate);
            commit(SET_RESULTS, results);
        }),
        fetchSportResultById: StoreUtil.asyncErrorGuard(async ({ commit }: { commit: Commit }, id: Match['id']) => {
            const result = (await ResultsService.getInstance().getMatchResultDetails([id], true))[0];
            commit(SET_RESULT, result);
        }),
    },
    getters: {
        resultsMap: StoreUtil.createSimpleGetter('matchResults'),
        results(state: ISportResultsState) {
            return Object.values(state.matchResults);
        },
    }
};

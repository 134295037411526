<template>
    <div class="betslip-stake__container--terminal">
        <div
            class="betslip-stake__total-quota--terminal"
        >
        <div class="betslip-stake-container">
            <div class="betslip-stake-info-wrapper">
                {{ $t(totalQuotaOrNumberOfCombinationsLabel) | capitalize }}:
                <span>
                    {{ totalQuotaOrNumberOfCombinations }}
                </span>
            </div>
        </div>
        </div>
        <div class="betslip-stake__row--terminal">
            <div class="betslip-stake--terminal">
                <StakePicker
                    @click="onStakePick"
                    :stake="stake"
                />
            </div>
            <div class="betslip-stake-input-wraper--terminal">
                <span
                    class="betslip-stake__currency-label--terminal"
                    @click="tapTicketReprint"
                >
                    {{ $t('stake') | capitalize }}
                </span>
                <StakeInput
                    :stake="stake"
                    :betSlipNamespace="betSlipNamespace"
                    @stakeChanged="onChange"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import { resultsRouteNames as routeNames } from '@app/router/constants';
    import { twoDecimals } from 'src/app/modules/shared/filters';
    import StakeInput from './StakeInput.vue';
    import StakePicker from './StakePicker.vue';
    import { mapGetters } from "vuex";
    import {
        size as _size
    } from '@lodash';

    export default {
        name: 'Stake',
        props: {
            stake: {
                type: Number,
                require: false,
            },
            isSimple: {
                type: Boolean,
                required: true,
            },
            totalCoefficient: {
                type: Number,
                default: 0,
            },
            totalNumberOfSelectedCombinations: {
                type: Number,
                default: 0,
            },
            betSlipNamespace: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                taps: 0,
                tapsTimeout: null,
            };
        },
        computed: {
            totalQuotaOrNumberOfCombinationsLabel() {
                return this.isSimple ? 'total quota' : 'number of combinations';
            },
            totalQuotaOrNumberOfCombinations() {
                return this.isSimple ? twoDecimals(this.totalCoefficient) : this.totalNumberOfSelectedCombinations;
            }
        },
        methods: {
            onChange(stake) {
                this.$emit('change', stake);
            },
            onStakePick(stake) {
                this.$emit('stakePick', stake);
            },
            tapTicketReprint() {
                if (this.tapsTimeout) {
                    this.tapsTimeout = setTimeout(this.resetTicketReprintTaps, 1000 * 3);
                }
                if (this.taps >= 5) {
                    this.resetTicketReprintTaps();
                    this.$router.push({ name: routeNames.ticketReprint });
                }
                this.taps += 1;
            },
            resetTicketReprintTaps() {
                clearTimeout(this.tapsTimeout);
                this.tapsTimeout = null;
                this.taps = 0;
            },
            drawSize (obj) {
                return _size(obj);
            }
        },
        components: {
            StakeInput,
            StakePicker,
        },
    };
</script>

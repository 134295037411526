export const SET_TICKETS = 'SET_TICKETS';
export const SET_TICKET = 'SET_TICKET';

export const TOGGLE_TICKET_AS_UPDATED = 'TOGGLE_TICKET_AS_UPDATED';
export const SET_TICKET_SUBMIT_LOADING = 'SET_TICKET_SUBMIT_LOADING';
export const SET_TICKET_NEGOTIATION_LOADING = 'SET_TICKET_NEGOTIATION_LOADING';
export const ADD_ERROR = 'ADD_ERROR';
export const REMOVE_ERROR = 'REMOVE_ERROR';
export const CLEAR_ALL_ERRORS = 'CLEAR_ALL_ERRORS';

export const SET_TICKET_NEGOTIATION = 'SET_TICKET_NEGOTIATION';

/*
    maybe in the future we will need 2 flags. One for all tickets
    and second for active only
*/
export const SET_ARE_TICKETS_LOADING = 'SET_ARE_TICKETS_LOADING';

export const CLEAR_ALL = 'CLEAR_ALL';

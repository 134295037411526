import { VirtualTournament } from '../types';

export function findVirtualTournamentByTournamentId(
    virtualTournaments: VirtualTournament[],
    id: number,
): VirtualTournament | undefined {
    return virtualTournaments.find(tournament => tournament.tournamentId === id);
}

export function findVirtualTournamentByChildId(
    virtualTournaments: VirtualTournament[],
    id: number,
): VirtualTournament | undefined {
    return virtualTournaments.find(tournament =>
        tournament.childrenIds.includes(id),
    );
}

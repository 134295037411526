<template>
    <button
        v-on="$listeners"
        :class="{ btn: true, 'is-loading': isLoading }"
        :disabled="disabled || isLoading"
    >
        <SpinnerBars
            v-if="isLoading"
        />
        <slot v-else />
    </button>
</template>

<script>
    import SpinnerBars from '@shared/components/SpinnerBars.vue';

    export default {
        name: 'CustomButton',
        props: {
            isLoading: {
                type: Boolean,
                required: false,
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false,
            }
        },
        components: {
            SpinnerBars,
        },
    };
</script>

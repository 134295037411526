import { TimeFilter } from '@shared/timeFilter/enums';
import {
    getLabelFromTimeFilter,
    getFormattedDateFromTimeFilter,
} from '../helpers';

interface TimeFilterComponent {
    timeFilterId: TimeFilter;
    isDate: boolean;
    $t: (text: string) => string;
}

export const timeFilterMixing = {
    computed: {
        important(this: TimeFilterComponent) {
            return getLabelFromTimeFilter(this.timeFilterId);
        },
        secondary(this: TimeFilterComponent) {
            return getFormattedDateFromTimeFilter(this.timeFilterId);
        },
    }
};

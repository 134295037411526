import { routeNames } from '@app/router';
import i18n from '@app/localization/i18n';

export default () => [
    {
        label: 'about us',
        path: 'about-us',
        type: 'static'
    },
    {
        label: i18n.t('careers'),
        path: 'https://www.super-job.ro/',
        type: 'external'
    },
    {
        label: 'help',
        path: 'help-url',
        type: 'static'
    },
    {
        label: 'data protection',
        path: 'confidence-declaration',
        type: 'static'
    },
    {
        label: 'responsible gaming',
        path: 'responsible-gaming',
        type: 'static'
    },
    {
        label: 'GDPR consent',
        path: 'consimtamant-gdpr',
        type: 'static'
    },
    {
        label: 'Anti-laundering money policy',
        path: 'politica-impotriva-spalarii-banilor',
        type: 'static'
    },
    {
        label: 'game rules',
        path: 'gaming-rules',
        type: 'static'
    },
    {
        label: 'Terms and Conditions',
        path: 'terms-and-conditions',
        type: 'static'
    },
];

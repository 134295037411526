import Ticket, { EventCondensed } from '@models/tickets/Ticket';
import { format } from 'date-fns';
import ServerConfig from '@core/models/country/ServerConfig';
import i18n from '@src/app/localization/i18n';
import capitalize from '@shared/filters/capitalize';
import { calcCombs, localeNumberFormat } from './math';
import commonConfig from '@src/config/common';
import liveBettingSpecifiersFormatter from '@core/services/offer/_private/LiveBettingSpecifiersFormatter';
import { range as _range } from '@lodash';
import SuperbetsService from '@core/services/superbets/SuperbetsService';
import { MarketType } from '@core/models/tickets/enums';
import Match from '@core/models/offer/Match';

const totalCombCount = (selected: number[], count: number): number => {
    const combinations = selected.map((system: number) => calcCombs(count, system));
    return combinations.reduce((a, b) => a + b, 0);
};

const systemsAsString = (selected: number[], count: number, fixed: number): string => {
    const selectedString = selected.join(',');
    const fixedString = fixed > 0 ? `${fixed}F` : '';
    const systemStr = `${i18n.t('system')}`;
    return `${capitalize(systemStr)} ${fixedString}[${selectedString} / ${count}]`;
};

const isSpecialEvent = (eventId: string) => eventId.startsWith('S');

const isFromSuperbetsMarket = (marketId: number, marketName: string) =>
    SuperbetsService.getInstance().isSuperbet(marketId, marketName);

const prepareOddName = (odd: any, event: Match) =>
(odd.specialValue
    ? `${odd.name}(${odd.specialValue})`
    : `${liveBettingSpecifiersFormatter.format(odd.name, odd.specifiers, event)}`);

const compareEventStartDates = (a: any, b: any) => a.date.getTime() - b.date.getTime();

const prepareEnumerationAlphabet = (): string[] => {
    const alphabetLowerCase: string = 'abcdefghijklmnopqrstuvwxyz';
    const alphabetFull: string[] = alphabetLowerCase
        .toUpperCase()
        .concat(alphabetLowerCase)
        .split('')
        .reverse();
    return alphabetFull;
};

const prepareEnumerationNumeric = (): string[] => _range(1, 35)
    .reverse()
    .map((num: number) => String(num));

// remove once the BA pipeline becomes the main ticket receiver
const isOriginBetAcceptance = (event: EventCondensed): boolean => {
    try {
        return typeof event.market.marketId === 'number' && typeof event.odd.oddId === 'number';
    } catch (err) {
        return false;
    }
};

// eslint-disable-next-line import/prefer-default-export
export const prepareTicketForPrintV1 = async (ticket: Ticket | any, config: ServerConfig) => {
    const dateFormat = 'DD.MM.YYYY';
    const timeFormat = 'HH:mm:ss';

    const eventTimeDateFormat = 'DD.MM. HH:mm';

    ticket.ticketId = ` ${ticket.id}`;

    ticket.events = ticket.events.map((event: any) => ({
        preparedDate: format(new Date(event.date), eventTimeDateFormat),
        ...event,
    }));

    const {
        system,
        dateReceived,
        coefficient,
        events,
        win: { bonus },
    } = ticket;

    const combCount = system ? totalCombCount(system.selected, system.count) : 1;

    const preparedEstimatedWin = localeNumberFormat(ticket.win.estimated);
    const preparedMinPotential = localeNumberFormat(ticket.win.minPotential);
    const preparedHandlingFee = localeNumberFormat(ticket.payment.handlingFee);
    const preparedTotal = localeNumberFormat(ticket.payment.total);
    const preparedStake = localeNumberFormat(ticket.payment.stake);
    const preparedCoefficient = localeNumberFormat(coefficient);
    const preparedIsBonusAvailable = bonus > 0.00;
    const preparedBonus = localeNumberFormat(bonus);
    const preparedBonusPercent = ticket.win.bonusPercent;
    const preparedExtraWinTax = localeNumberFormat(+ticket.win.tax) || localeNumberFormat(0.00);
    const { appEnv } = commonConfig.environment;
    // remove once the BA pipeline becomes the main ticket receiver
    const isBATicketPrepared = ['qa', 'silent'].includes(appEnv) ? ticket.events.some(isOriginBetAcceptance) : false;

    const preparedTicket = {
        isBATicketPrepared,
        preparedEstimatedWin,
        preparedMinPotential,
        preparedHandlingFee,
        preparedTotal,
        preparedStake,
        preparedCoefficient,
        preparedBonus,
        preparedBonusPercent,
        preparedIsBonusAvailable,
        preparedExtraWinTax,
        footerCenterLines: config.ticketPromotional.footer,
        preparedLocale: commonConfig.locale,
        preparedExtraWinTaxLabel: i18n.t('extraWinTax'),
        preparedTicketIdLabel: i18n.t('ticketId'),
        preparedTicketCodeLabel: i18n.t('ticketCode'),
        preparedTicketCopyLabel: i18n.t('copy'),
        preparedTicketTypeLabel: i18n.t('ticketType'),
        preparedEventCountLabel: i18n.t('eventsCount'),
        preparedTotalLabel: i18n.t('totalStake'),
        preparedHandlingFeeLabel: i18n.t('cda'),
        preparedHandlingFeePercent: config.betSlip.offlineTax,
        preparedStakeLabel: i18n.t('cleanStake'),
        preparedTotalCoefficientLabel: i18n.t('totalCoefficient'),
        preparedMinimumWinLabel: i18n.t('minPotentialWin'),
        preparedBonusLabel: i18n.t('bonus'),
        preparedMaxPotentialWinLabel: i18n.t('potentialWin'),
        preparedLuckyLoserLabel: i18n.t('luckyLoser'),
        preparedTicketType: !system ? 'NORMAL' : 'SYSTEM',
        ticketType: !system ? `${i18n.t('simpleTicket')}` : `${i18n.t('systemTicket')}`,
        preparedCombName: `${i18n.t('numberCombinations')}:`,
        preparedQuotaName: `${i18n.t('totalCoefficient')}:`,
        preparedCombQuotaValue: !system ? 1 : combCount,
        preparedSystemsString: !system ? '' : systemsAsString(system.selected, system.count, system.fixed),
        preparedDateReceived: format(new Date(dateReceived), dateFormat),
        preparedTimeReceived: format(new Date(dateReceived), timeFormat),
        ...ticket,
    };

    if (preparedTicket.type === 'sport') {
        const enumPool: any = commonConfig.locale === 'pl-PL'
            ? prepareEnumerationNumeric()
            : prepareEnumerationAlphabet();

        preparedTicket.events.sort(compareEventStartDates);

        preparedTicket.preparedIsLuckyLoser = ticket.potentialBonuses.LUCKY_LOSER;

        preparedTicket.events = ticket.events.map((event: EventCondensed) => {
            // remove once the BA pipeline becomes the main ticket receiver
            const isOriginBA = isOriginBetAcceptance(event);

            const { eventId } = event;
            const isSpecial = isSpecialEvent(eventId);
            const isOutright = event.IsSpecialFromSportOffer;
            const enumeratedFix: string = event.eventNo?.includes('FIX')
                ? `${i18n.t('eventFix')}`
                : `${enumPool.pop()}` || '';

            const isSuperbet = isFromSuperbetsMarket(+event.market.marketId, event.market.name);
            const preparedOddName = isSuperbet
                ? `${i18n.t('yes')}`.toUpperCase()
                : prepareOddName(event.odd, { team1Name: event.teamNameOne || '', team2Name: event.teamNameTwo || '' } as Match);

            const prepareName = () => {
                if (isOutright) return `${event.market.name}, ${event.name}`;
                if (isOriginBA && (isSpecial)) return `${event.market.name} ${event.name}`;
                return event.name;
            };

            const isPreparedLive = event.marketType === MarketType.IN_PLAY;

            return {
                isOriginBA,
                isSuperbet,
                preparedOddName,
                enumeratedFix,
                isSpecial: isSpecial || isOutright,
                preparedName: prepareName(),
                preparedOddCoefficient: localeNumberFormat(event.odd.coefficient),
                hasSBV: !!event.odd.specialValue,
                preparedTournamentName: event.tournamentName,
                isPreparedLive,
                ...event,
            };
        });
    }

    return preparedTicket;
};

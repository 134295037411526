<template>
    <div>
        <div :class="textClass">
            <Illustration xl image="/static/img/illustrations/network__error.svg"/>
            <div class="notice">{{ $t(textContent) }}</div>
        </div>
        <button
            v-if="modalData.button"
            :class="buttonClass"
            type="button"
            @click="closeModal"
        >
            {{ $t(buttonLabel) | capitalize }}
        </button>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { get as _get } from '@lodash';
import Illustration from '@shared/components/Illustration.vue';
export default {
    name: 'ErrorModal',
    created() {
        this.showHelp();
    },
    computed: {
        ...mapGetters('ui/modal', [
            'modalData',
        ]),
        textClass() {
            return _get(this, 'modalData.text.className') || '';
        },
        textContent() {
            return _get(this, 'modalData.text.content')
                || 'Something went wrong. Please try again';
        },
        buttonClass() {
            return _get(this, 'modalData.button.className') || 'btn btn__primary btn--block';
        },
        buttonLabel() {
            return _get(this, 'modalData.button.label') || 'ok';
        }
    },
    methods: {
        ...mapActions('ui/help', [
            'showHelp',
        ]),
        ...mapActions('ui/modal', [
            'closeModal'
        ])
    },
    components: {
        Illustration,
    },
};
</script>

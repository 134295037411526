import FeatureFlagService, { flags } from '@src/terminal/core/services/flags/FeatureFlagService';
import StoreUtil from '@store/StoreUtil';
import { Commit, Store } from 'vuex';

export interface IFlagModuleState {
  isInPlayAvailable: boolean;
  useAllowancesV2: boolean;
  isProgressiveLuckyLoser: boolean;
  generosityControlEnabled: boolean;
  fastlyWafEnabled: boolean;
  usePlatformV2: boolean;
  inactivityTimerValue: number;
}

function defaultState(): IFlagModuleState {
  return {
    // ssbt.product.in-play
    isInPlayAvailable: flags.inPlay.defaultValue,
    // allowances v2
    useAllowancesV2: flags.useAllowancesV2.defaultValue,
    isProgressiveLuckyLoser: flags.isProgressiveLuckyLoser.defaultValue,
    generosityControlEnabled: flags.generosityControlEnabled.defaultValue,
    fastlyWafEnabled: flags.fastlyWafEnabled.defaultValue,
    usePlatformV2: flags.platformV2.defaultValue,
    inactivityTimerValue: flags.inactivityTimerValue.defaultValue,
  };
}

export const Mutations = {
  SET_IS_IN_PLAY_AVAILABLE: 'SET_IS_IN_PLAY_AVAILABLE',
  SET_IS_ALLOWANCES_V2_ENABLED: 'SET_IS_ALLOWANCES_V2_ENABLED',
  SET_IS_PROGRESSIVE_LUCKY_LOSER: 'SET_IS_PROGRESSIVE_LUCKY_LOSER',
  SET_IS_GENEROSITY_CONTROL_ENABLED: 'SET_IS_GENEROSITY_CONTROL_ENABLED',
  SET_IS_FASTLY_WAF_ENABLED: 'SET_IS_FASTLY_WAF_ENABLED',
  SET_IS_PLATFORM_V2: 'SET_IS_PLATFORM_V2',
  SET_INACTIVITY_TIMER_VALUE: 'SET_INACTIVITY_TIMER_VALUE',
};

const mutations = {
  [Mutations.SET_IS_IN_PLAY_AVAILABLE]: StoreUtil.createSimpleMutator('isInPlayAvailable'),
  [Mutations.SET_IS_ALLOWANCES_V2_ENABLED]: StoreUtil.createSimpleMutator('isAllowancesV2Enabled'),
  [Mutations.SET_IS_PROGRESSIVE_LUCKY_LOSER]: StoreUtil.createSimpleMutator('isProgressiveLuckyLoser'),
  [Mutations.SET_IS_GENEROSITY_CONTROL_ENABLED]: StoreUtil.createSimpleMutator('generosityControlEnabled'),
  [Mutations.SET_IS_FASTLY_WAF_ENABLED]: StoreUtil.createSimpleMutator('fastlyWafEnabled'),
  [Mutations.SET_IS_PLATFORM_V2]: StoreUtil.createSimpleMutator('usePlatformV2'),
  [Mutations.SET_INACTIVITY_TIMER_VALUE]: StoreUtil.createSimpleMutator('inactivityTimerValue'),
};

const actions = {
  initFlags: ({ commit }: { commit: Commit }): void => {
    commit(Mutations.SET_IS_IN_PLAY_AVAILABLE, FeatureFlagService.getInstance().isInPlayAvailable());
    commit(Mutations.SET_IS_ALLOWANCES_V2_ENABLED, FeatureFlagService.getInstance().useAllowancesV2());
    commit(Mutations.SET_IS_PROGRESSIVE_LUCKY_LOSER, FeatureFlagService.getInstance().isProgressiveLuckyLoser());
    commit(Mutations.SET_IS_GENEROSITY_CONTROL_ENABLED, FeatureFlagService.getInstance().generosityControlEnabled());
    commit(Mutations.SET_IS_FASTLY_WAF_ENABLED, FeatureFlagService.getInstance().fastlyWafEnabled());
    commit(Mutations.SET_IS_PLATFORM_V2, FeatureFlagService.getInstance().usePlatformV2());

    // subscriptions
    FeatureFlagService.getInstance().subscribeToFlagChange(flags.inPlay, (value) => {
      console.log(`SSBT:Flag ${flags.inPlay.name} changed to ${value}`);
      commit(Mutations.SET_IS_IN_PLAY_AVAILABLE, value);
    });
    FeatureFlagService.getInstance().subscribeToFlagChange(flags.useAllowancesV2, (value) => {
      console.log(`SSBT:Flag ${flags.useAllowancesV2.name} changed to ${value}`);
      commit(Mutations.SET_IS_ALLOWANCES_V2_ENABLED, value);
    });
    FeatureFlagService.getInstance().subscribeToFlagChange(flags.isProgressiveLuckyLoser, (value) => {
        console.log(`SSBT:Flag ${flags.isProgressiveLuckyLoser.name} changed to ${value}`);
        commit(Mutations.SET_IS_PROGRESSIVE_LUCKY_LOSER, value);
    });
    FeatureFlagService.getInstance().subscribeToFlagChange(flags.fastlyWafEnabled, (value) => {
        console.log(`SSBT:Flag ${flags.fastlyWafEnabled.name} changed to ${value}`);
        commit(Mutations.SET_IS_FASTLY_WAF_ENABLED, value);
    });

    FeatureFlagService.getInstance().subscribeToFlagChange(flags.generosityControlEnabled, (value) => {
      console.log(`SSBT:Flag ${flags.generosityControlEnabled.name} changed to ${value}`);
      commit(Mutations.SET_IS_GENEROSITY_CONTROL_ENABLED, value);
    });

    FeatureFlagService.getInstance().subscribeToFlagChange(flags.platformV2, (value) => {
      console.log(`SSBT:Flag ${flags.platformV2.name} changed to ${value}`);
      commit(Mutations.SET_IS_PLATFORM_V2, value);
    });

    FeatureFlagService.getInstance().subscribeToFlagChange(flags.inactivityTimerValue, (value) => {
      console.log(`SSBT:Flag ${flags.inactivityTimerValue.name} changed to ${value}`);
      commit(Mutations.SET_INACTIVITY_TIMER_VALUE, value);
    });

    console.log('SSBT:Flags initialized');
  },
};

const getters = {
  isInPlayAvailable: StoreUtil.createSimpleGetter('isInPlayAvailable', flags.inPlay.defaultValue),
  useAllowancesV2: StoreUtil.createSimpleGetter('useAllowancesV2', flags.useAllowancesV2.defaultValue),
  isProgressiveLuckyLoser: StoreUtil.createSimpleGetter('isProgressiveLuckyLoser', flags.isProgressiveLuckyLoser.defaultValue),
  generosityControlEnabled: StoreUtil.createSimpleGetter('generosityControlEnabled', flags.generosityControlEnabled.defaultValue),
  fastlyWafEnabled: StoreUtil.createSimpleGetter('fastlyWafEnabled', flags.fastlyWafEnabled.defaultValue),
  usePlatformV2: StoreUtil.createSimpleGetter('usePlatformV2', flags.platformV2.defaultValue),
  inactivityTimerValue: StoreUtil.createSimpleGetter('inactivityTimerValue', flags.inactivityTimerValue.defaultValue),
};

export default {
  namespaced: true,
  state: defaultState(),
  mutations,
  actions,
  getters,
};

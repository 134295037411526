import BetSlipOdd from '@core/models/base/BetSlipOdd';
import ISelection from '@core/models/betSlip/ISelection';

export const ReplacementType = {
    NewBoosted: 'NewBoosted',
    OldBoosted: 'OldBoosted',
} as const;
export type ReplacementType = typeof ReplacementType[keyof typeof ReplacementType];

export async function checkIfBoostedOddConflictOccurs(selection: ISelection, currentSelections: ISelection[]): Promise<ReplacementType | undefined> {

    if (isBoostedOdd(selection.odd) && !isOddInBetslip(selection.odd, currentSelections) && currentSelections.length > 0) {
        return ReplacementType.NewBoosted;
    } else if (currentSelections.some((s) => isBoostedOdd(s.odd))) {
        return ReplacementType.OldBoosted;
    }
}
export function isBoostedOdd(odd: BetSlipOdd | null): boolean {
    return odd?.tags?.includes('singles_only') ?? false;
}

function isOddInBetslip(odd: BetSlipOdd | null, selections: ISelection[]): boolean {
    if (odd == null) {
        return false;
    }

    return selections.some((s) => s.odd?.uuid === odd.uuid);
}

type ConflictPayload = {
    isNewOddBoosted: boolean;
    selections: ISelection[];
    newSelection: ISelection;
};

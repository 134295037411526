export enum ScreenWidth {
    min = 'min',
    mSm = 'mSm',
    mMd = 'mMd',
    tSm = 'tSm',
    tMd = 'tMd',
    tLg = 'tLg',
    tXl = 'tXl',
    dXs = 'dXs',
    dSm = 'dSm',
    dMd = 'dMd',
    dLg = 'dLg',
    erbp = 'erbp',
    dXl = 'dXl',
}

// WARNING: order must be ascending
// TODO Not 100% overlapping with the sizes in responsive.scss
export const breakpoints = {
    [ScreenWidth.min]: 0,
    [ScreenWidth.mSm]: 320,
    [ScreenWidth.mMd]: 360,
    // ------------------------
    [ScreenWidth.tSm]: 640,
    [ScreenWidth.tMd]: 768,
    [ScreenWidth.tLg]: 944,
    [ScreenWidth.tXl]: 1024,
    // ------------------------
    [ScreenWidth.dXs]: 1279,
    [ScreenWidth.dSm]: 1366,
    [ScreenWidth.dMd]: 1440,
    [ScreenWidth.dLg]: 1680,
    [ScreenWidth.erbp]: 1824, // event row breakpoint
    [ScreenWidth.dXl]: 1920,
};

<template>
    <Tree
        :items="tree"
        :activeIds="this.treeBreadcrumbsIds"
        @itemClick="onSportsItemClick"
    />
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import Tree from 'src/terminal/app/modules/shared/components/tree/Tree.vue';

    export default {
        name: 'SportsSidebarContainer',
        computed: {
            ...mapGetters('ui/sportOffer/sports', [
                'tree',
                'treeBreadcrumbsIds'
            ]),
        },
        methods: {
            ...mapActions('ui/sportOffer/sports', [
                'setSelectedTreeItem',
            ]),
            onSportsItemClick(itemsArray) {
                this.setSelectedTreeItem(itemsArray);
            },
        },
        components: {
            Tree,
        }
    };
</script>

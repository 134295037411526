import { ScrollerItem } from './ScrollerItem';
import { EventCollection, createEventCollection } from './EventCollection';
import { SportInfo } from '@core/constants';
import { HeaderType, SectionSubType } from '../enums';
import { tournamentName } from '../util';

export const SECTION_ID_PREFIX = 'S-';

/** The specification for objects rendered as sections in the event grid.
 * (e.g. Tournaments when they fall under a Sport category or Specific
 * promotions such as Super Cota under the 'Promotions' category).
 */
export interface Section extends ScrollerItem {
    /**
     * Denotes whether the Section belongs to the primary market or is a part of
     * a special promotion.
     */
    primaryMarketName: string;
    eventCollections: EventCollection[];
    /**
     * Used to generate IDs in HTML. This can possibly be removed when the
     * scroll is taken care of.
     */
    key: string;
    footer?: any;
    flagCode?: string;
    subType: SectionSubType;
}

export function createTournamentSection(
    id: number,
    sportId: number,
    footer: any,
    primaryMarketName: string,
    sportName: string
) {
    return{
        footer,
        primaryMarketName,
        id: `${id}`,
        title: tournamentName(id),
        key: `${SECTION_ID_PREFIX}${id}`,
        eventCollections: [createEventCollection(sportId, new SportInfo(sportId).periodNames, sportName)],
        type: HeaderType.section,
        subType: SectionSubType.normal,
        height: 0, // must be set later, after adding all event collections,
    } as Section;
}

export function createPromotionSection({
    id,
    primaryMarketName,
    primaryMarketAttribute,
    title,
    eventCollections,
    footer
}: {
    id: number | string,
    primaryMarketName: string,
    primaryMarketAttribute: string,
    title: string,
    eventCollections: EventCollection[],
    footer: any
}) {
    return {
        id,
        title,
        eventCollections,
        footer,
        primaryMarketName,
        primaryMarketAttribute,
        key: `${id}`,
        type: HeaderType.section,
        subType: SectionSubType.normal,
        height: 0 // must be set later, after adding all event collections
    } as Section;
}

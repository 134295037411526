import createPersistedState from 'vuex-persistedstate';
import StoreUtil from '@app/store/StoreUtil';

export const PERSIST_SUFFIX = 'PERSIST';

const triggerMutations = [
    /^ui\/sportOffer\//,
    /^data\/country\//,
    /^ui\/sportOffer\/search\//,
    /^data\/authentication\//,
    // /^ui\/sportOffer\/betSlip\/TOGGLE_BET_SLIP_AUTO_UPDATE_CHANGES/,
    /^ui\/user\/preferences\//,
];

const persistedModules = [
    'data.authentication.user',
    'data.country',
    'ui.sportOffer.search',
    // 'ui.sportOffer.betSlip.autoUpdateChanges',
    'ui.sportOffer.sports.selectedTreeItems',
    'ui.user.preferences',
];

export const STATE_STORAGE_KEY = 'store';

export default createPersistedState({
    key: STATE_STORAGE_KEY,
    paths: persistedModules,
    filter: (mutation: any) => shouldTriggerMutation(mutation.type),
});

const checkMutationOptions = (mutationType: string) => {
    const options = StoreUtil.getDecorations(mutationType);
    return options.includes(PERSIST_SUFFIX);
};

const shouldTriggerMutation = (mutationType: string): boolean =>
!triggerMutations.every((regex) => !regex.test(mutationType))
    || checkMutationOptions(mutationType);

import * as socketIo from 'socket.io-client';
import { HostPayload, ScanTicket } from '../models/Payload';
import { id } from 'date-fns/locale';

export default (() => {
  let socket: any = null;

  const connect = (url: string, config: any) => {
    socket = socketIo.connect(url, config);
  };

  const disconnect = () => {
    if (socket) {
      socket.close();
    }
  };

  const emit = (eventName: string, payload: any) => {
    socket.emit(eventName, payload);
  };

  const emitBalanceStatusRequest = () => emit('balance-status-request', {});

  const emitPrintTicketRequest = (ticket: any) => emit('print-ticket-request', ticket);

  const emitTerminalConfigRequest = (product: string) => emit('integration-config-request', { product });

  const emitNotificationDispatchRequest = (notification: any) => emit('notification-request', notification);

  const emitLogRequest = (level: string, message: string, data?: any) => {
    emit('log-request', { level, message, data });
  };

  const emitNavigateRequest = (productId: string) =>
    emit('navigate-request', { data: { value: { product: { id: productId } } } });

  const onConnected = (fn: any) => socket.on('connected', fn);

  const onDisconnect = (fn: any) => socket.on('disconnect', fn);

  const onReconnect = (fn: any) => socket.on('reconnect', fn);

  const onBalanceChanged = (fn: any) => socket.on('balance-status', fn);

  const onScanOutcome = (fn: any) => socket.on('scan-outcome', fn);

  const onCardSerialNumberOutcome = (fn: any) => socket.on('serial-number-scan-outcome', fn);

  const onPrintTicketOutcome = (fn: any) => socket.on('print-ticket-outcome', fn);

  const onTerminalConfig = (fn: any) => socket.on('integration-config', fn);

  const emitTicketRebetRequest = (payload: any) => emit('ticket-recreate-request', payload);

  const onTicketRebetOutcome = (fn: any) => socket.on('ticket-recreate-outcome', fn);

  const emitStatusRequest = () => emit('status-request', {});

  const onStatus = (fn: any) => socket.on('status', fn);

  const emitScanRequest = (payload: Partial<HostPayload<ScanTicket>>) => emit('scan-request', payload);

  const onNavigate = (fn: any) => socket.on('navigate', fn);

  const onIntegrationToken = (fn: any) => socket.on('integration-token', fn);

  const emitIntegrationTokenRequest = (product: string) => emit('integration-token-request', { product });

  return {
    connect,
    disconnect,
    emit,
    emitBalanceStatusRequest,
    emitPrintTicketRequest,
    emitTerminalConfigRequest,
    emitNotificationDispatchRequest,
    emitLogRequest,
    emitNavigateRequest,
    onConnected,
    onDisconnect,
    onReconnect,
    onBalanceChanged,
    onScanOutcome,
    onCardSerialNumberOutcome,
    onPrintTicketOutcome,
    onTerminalConfig,
    emitTicketRebetRequest,
    onTicketRebetOutcome,
    emitStatusRequest,
    onStatus,
    emitScanRequest,
    onNavigate,
    onIntegrationToken,
    emitIntegrationTokenRequest,
  };
})();

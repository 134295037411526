import DateUtils from '@core/utils/date/DateUtils';
import i18n from '@app/localization/i18n';
import { slugify } from '@app/utils/slugify';
import { liveTimeFilterSlug, todayTimeFilterSlug, allTimeFilterSlug } from '@store/modules/ui/shared/timeFilter/utils';
import { formatDateTime } from '@modules/shared/filters';
import { TimeFilter } from './enums';
import Match from '@core/models/offer/Match';
import BookieDate from '@core/utils/date/BookieDate';
import { OfferInterval, OfferIntervalOptions } from '@superbet-group/offer.clients.lib';

const dayTimeFilters = [
    TimeFilter.sunday,
    TimeFilter.monday,
    TimeFilter.tuesday,
    TimeFilter.wednesday,
    TimeFilter.thursday,
    TimeFilter.friday,
    TimeFilter.saturday,
];

export function getTimeFilterFromSlug(timeFilterSlug: string) {
    switch (timeFilterSlug) {
        case todayTimeFilterSlug:
            return TimeFilter.today;
        case slugify('monday').toString():
            return TimeFilter.monday;
        case slugify('tuesday').toString():
            return TimeFilter.tuesday;
        case slugify('wednesday').toString():
            return TimeFilter.wednesday;
        case slugify('thursday').toString():
            return TimeFilter.thursday;
        case slugify('friday').toString():
            return TimeFilter.friday;
        case slugify('saturday').toString():
            return TimeFilter.saturday;
        case slugify('sunday').toString():
            return TimeFilter.sunday;
        case liveTimeFilterSlug:
            return TimeFilter.live;
        case slugify('upcoming'):
            return TimeFilter.upcoming;
        case allTimeFilterSlug:
            return TimeFilter.all;
        default:
            return TimeFilter.all;
    }
}

export function getDateFromDateSlug(date: string) {
    return new Date(date);
}

// opposite of getTimeFilterFromSlug
export function getSlugFromTimeFilter(timeFilter: TimeFilter) {
    return slugify(timeFilter.toString());
}

export function getLabelFromTimeFilter(timeFilter: TimeFilter) {
    if (isDate(timeFilter)) {
        return formatDateTime(getDateForSpecificWeekDay(getDayIndexForTimeFilter(timeFilter)), 'w');
    }
    return getTimeFilterLabel(timeFilter as TimeFilter.today | TimeFilter.upcoming | TimeFilter.live | TimeFilter.all);
}

export function getFormattedDateFromTimeFilter(timeFilter: TimeFilter) {
    if (isDate(timeFilter)) {
        return formatDateTime(getDateForSpecificWeekDay(getDayIndexForTimeFilter(timeFilter)), 'ddmm');
    }
    return '';
}

export function getTimeFilterForWeekDay(dayIndex: number) {
    return dayTimeFilters[dayIndex];
}

function getTimeFilterLabel(timeFilter: TimeFilter.today | TimeFilter.upcoming | TimeFilter.live | TimeFilter.all) {
    const labelMap = {
        [TimeFilter.today]: i18n.t('today'),
        [TimeFilter.upcoming]: i18n.t('next3H'),
        [TimeFilter.live]: i18n.t('live'),
        [TimeFilter.all]: i18n.t('all'),
    };
    return labelMap[timeFilter];
}

function isDate(timeFilter: TimeFilter) {
    return dayTimeFilters.includes(timeFilter);
}

function getDayIndexForTimeFilter(timeFilter: TimeFilter) {
    return dayTimeFilters.indexOf(timeFilter);
}

function getDateForSpecificWeekDay(weekDay: number) {
    const today = new Date();
    const desiredDay = DateUtils.addDays((weekDay - today.getDay() + 7) % 7, today);
    return desiredDay;
}

export function getTimeSpanForTimeFilter(timeFilter: TimeFilter) {
    const today = new Date();
    switch (timeFilter) {
        case TimeFilter.all:
            return {
                startDate: today,
                endDate: BookieDate.getEndDateForDate(DateUtils.addYears(1, today)),
            };
        case TimeFilter.live:
        case TimeFilter.today:
            return {
                startDate: today,
                endDate: BookieDate.getEndDateForDate(today),
            };
        case TimeFilter.upcoming:
            return {
                startDate: today,
                endDate: DateUtils.addHours(3, today),
            };
        default:
            break;
    }
    const dayIndex = getDayIndexForTimeFilter(timeFilter);
    const dayDifference = (dayIndex - today.getDay() + 7) % 7;
    const desiredDay = DateUtils.addDays(dayDifference, today);

    return {
        startDate: BookieDate.getStartDateForDate(desiredDay),
        endDate: BookieDate.getEndDateForDate(desiredDay),
    };
}

export function getIntervalForTimeFilter(timeFilter: TimeFilter): { interval: OfferInterval, options?: OfferIntervalOptions } {
    const today = new Date();

    switch (timeFilter) {
        case TimeFilter.all:
            return {
                interval: OfferInterval.CURRENT_YEAR
            };
        case TimeFilter.live:
        case TimeFilter.today:
            return {
                interval: OfferInterval.TODAY
            };
        case TimeFilter.upcoming:
            return {
                interval: OfferInterval.X_HOURS_FROM_NOW,
                options: { hours: 3 },

            };
        default:
            break;
    }

    const dayIndex = getDayIndexForTimeFilter(timeFilter);
    const dayDifference = (dayIndex - today.getDay() + 7) % 7;

    return {
        interval: OfferInterval.X_DAYS_FROM_TODAY,
        options: { days: dayDifference },
    }
}

function getTimeSpanForDate(date: Date) {
    const startDate = DateUtils.toStartOfDay(date);
    return {
        startDate,
        endDate: DateUtils.addDays(1, startDate),
    };
}

export function getTimeFilterFunctionForTimeFilter(timeFilter: TimeFilter) {
    switch (timeFilter) {
        case TimeFilter.all:
            return Boolean;
        case TimeFilter.live:
            // live won't be called for lotto
            return (event: Match) => {
                if (event instanceof Match) {
                    return event.isOngoing();
                }
                return false;
            };
        default:
            const {
                startDate,
                endDate,
            } = getTimeSpanForTimeFilter(timeFilter);
            return (event: Match ) => {
                let betTime = event.date;
                return DateUtils.isDateBetweenInclusive(startDate, endDate, betTime);
            };
    }
}

export function getTimeFilterFunctionForDate(date: Date) {
    const {
        startDate,
        endDate,
    } = getTimeSpanForDate(date);
    return (event: Match) => {
        let betTime = event.date;
        return DateUtils.isDateBetweenInclusive(startDate, endDate, betTime);
    };
}

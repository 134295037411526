<template functional>
    <div class="group-sport-header">
        <div class="group-sport-header__details">
            <component
                v-if="props.flagCode"
                :is="props.components.FlagIcon"
                :flag="`flags/${props.flagCode}.png`"
            />
            <span
                :title="props.title"
                class="group-sport-header__details-title"
            >
                {{ props.title }}
            </span>
        </div>
    </div>
</template>

<script>
    import FlagIcon from '@shared/components/FlagIcon.vue';

    export default {
        functional: true,
        name: 'SectionHeaderRow',
        props: {
            id: {
                type: String,
                required: true,
            },
            flagCode: {
                type: String,
                required: false,
            },
            title: {
                type: String,
                required: true,
            },
            components: {
                type: Object,
                default() {
                    return {
                        FlagIcon,
                    };
                }
            },
        },
    };
</script>

import { ActionType } from './enums';

/**
 * Banners are commercials that fill empty spaces across the app.
 * Click on every banner triggers an action. Action can be:
 * 1) open link in new tab
 * 2) open new popup window
 * 3) open in app link
 */
export default class Banner {
    id: number;
    title: string;
    description: string;
    image: string;
    link: string;
    linkDescription: string;
    // used so we can distinguish what to do with the link
    actionType: ActionType;
}

<template>
    <div class="event-row__footer-content--terminal">
        <i class="icon--sm icon-arrow_new_line event-row__footer-icon" />
        <span>{{ content }}</span>
    </div>
</template>

<script>
    export default {
        name: 'MatchFooterRow',
        props: {
            content: {
                type: String,
                required: true,
            },
        },
    };
</script>
import {
    get as _get,
} from '@lodash';
import { Dispatch, Commit } from 'vuex';
import StoreUtil from '@app/store/StoreUtil';
import Match from '@core/models/offer/Match';
import { IState } from '@app/store';
import { ExpandedEventInUrl } from '@app/enums';
import search, { ISearchState } from './search/searchIndex';
import sports, { ISportsState } from './sports/sportsIndex';
import betSlip from './betSlip/betSlipIndex';
import IBetSlipState from '@store/modules/ui/shared/betSlip/types';
import teams, { ITeamsState } from './teams/teamsIndex';
import { removeEventQueryParams } from '@app/utils';
import { Getters } from './types';


const SET_EVENT_SELECTION = 'SET_EVENT_SELECTION';
const SET_SELECTED_EVENT_HEIGHT = 'SET_SELECTED_EVENT_HEIGHT';

interface ISportOfferStateBase {
    selectedEventHeight: number | null;
}

export interface ISportOfferState extends ISportOfferStateBase {
    sports: ISportsState;
    search: ISearchState;
    teams: ITeamsState;
    betSlip: IBetSlipState;
}

const getters: Getters =  {
    expandedEvent: (state: ISportOfferState, getters: Getters) => {
        return getters.expandedEventRow.id;
    },
    expandedEventRow: (state: ISportOfferState, getters: any, rootState: IState, rootGetters: any) => {
        let eventId = rootGetters['navigation/route'].query[ExpandedEventInUrl.event];
        eventId = eventId ? parseInt(eventId, 10) : null;

        if (!rootGetters['data/sportOffer/eventsMap'][eventId]
            || rootGetters['data/sportOffer/eventsMap'][eventId].isFinished()) {
            return {
                typeId: null,
                id: null,
            };
        }

        const typeId = rootGetters['navigation/route'].query[ExpandedEventInUrl.type];

        return {
            typeId,
            id: eventId
        };
    },
    expandedEventId: (state: ISportOfferState, getters: any) => {
        return getters.expandedEventRow.id;
    },
    selectedEventHeight: StoreUtil.createSimpleGetter('selectedEventHeight'),
};

export default {
    getters,
    state: {
        selectedEventHeight: null,
    } as ISportOfferStateBase,

    mutations: {
        [SET_SELECTED_EVENT_HEIGHT]: StoreUtil.createSimpleMutator('selectedEventHeight'),
    },

    actions: {
        setSelectedEventHeight: StoreUtil.createSimpleMutatorAction(SET_SELECTED_EVENT_HEIGHT),
        toggleExpandedEventSubscription: (
            { dispatch, rootGetters }: { dispatch: Dispatch, rootGetters: any },
            { eventId, oldEventId }: { eventId: number | null, oldEventId: number | null }
        ) => {
            if (oldEventId && rootGetters['data/sportOffer/subscriptions'][oldEventId]) {
                dispatch('data/sportOffer/removeSubscription', oldEventId, { root: true });
            }

            if (eventId) {
                dispatch('data/sportOffer/getEvent', eventId, { root: true });
            }
        },
        toggleExpandedEvent: (
            { getters, rootGetters, dispatch }:
                { dispatch: Dispatch, getters: any, rootGetters: any },
            { eventId, typeId }: { eventId: number, typeId: number }
        ) => {
            let query = rootGetters['navigation/route'].query;

            if (getters.expandedEvent === eventId) {
                query = removeEventQueryParams(query);
            } else {
                query = {
                    ...query,
                    [ExpandedEventInUrl.event]: eventId.toString(),
                    [ExpandedEventInUrl.type]: typeId.toString()
                };
            }

            dispatch(
                'navigation/push',
                {
                    query,
                    path: rootGetters['navigation/route'].path,
                },
                { root: true }
            );
        },
        cancelEventSubscriptions: ({ dispatch, commit, getters }:
            { dispatch: Dispatch, commit: Commit, getters: any }) => {
            if (getters.expandedEventRow.id) {
                dispatch('data/sportOffer/removeSubscription', getters.expandedEventRow.id, { root: true });
                commit(SET_EVENT_SELECTION, null);
            }
        },
    },

    modules: {
        sports,
        search,
        teams,
        betSlip,
    },

    namespaced: true,
};

export default {
    nsoft: {
        qa: {
            configHost: 'https://superbet-content-stage.global.ssl.fastly.net/cached-superbet/app-config?country=ro&environment=stage&platform=ssbt',
            paymentHost: 'https://retail.stage.incubator.superbet.ro/ssbt-api/',
            paymentHostFastly: 'https://stage-superbet-retail-ssbt-api.global.ssl.fastly.net/ssbt-api/',
            paymentHostSocket: 'https://retail.stage.incubator.superbet.ro/betting-terminal-ssbt',
            socketPath: '/ssbt-api/socket.io',
            countryKey: 'ro',
            allowancesUrl: 'https://superbet-content-stage.freetls.fastly.net/cached-superbet/allowances/',
            virtualTournamentsEndpoint: 'https://sports-offer.s3.eu-central-1.amazonaws.com/virtual-tournaments/ro.json',
        },
        stage: {
            configHost: 'https://superbet-content-stage.global.ssl.fastly.net/cached-superbet/app-config?country=ro&environment=stage&platform=ssbt',
            paymentHost: 'https://retail.stage.incubator.superbet.ro/ssbt-api/',
            paymentHostFastly: 'https://stage-superbet-retail-ssbt-api.global.ssl.fastly.net/ssbt-api/',
            paymentHostSocket: 'https://retail.stage.incubator.superbet.ro/betting-terminal-ssbt',
            socketPath: '/ssbt-api/socket.io',
            countryKey: 'ro',
            allowancesUrl: 'https://superbet-content-stage.freetls.fastly.net/cached-superbet/allowances/',
            virtualTournamentsEndpoint: 'https://sports-offer.s3.eu-central-1.amazonaws.com/virtual-tournaments/ro.json',
        },
        silent: {
            configHost: 'https://superbet-content.global.ssl.fastly.net/cached-superbet/app-config?country=ro&environment=prod&platform=ssbt',
            paymentHost: 'https://retail.prod.incubator.superbet.ro/ssbt-api-silent/',
            paymentHostFastly: 'https://prod-superbet-retail-ssbt-api-silent.global.ssl.fastly.net/ssbt-api-silent/',
            paymentHostSocket: 'https://retail.prod.incubator.superbet.ro/betting-terminal-ssbt',
            socketPath: '/ssbt-api-silent/socket.io',
            countryKey: 'ro',
            allowancesUrl: 'https://superbet-content-stage.freetls.fastly.net/cached-superbet/allowances/',
            virtualTournamentsEndpoint: 'https://sports-offer.s3.eu-central-1.amazonaws.com/virtual-tournaments/ro.json',
        },
        prod: {
            configHost: 'https://superbet-content.global.ssl.fastly.net/cached-superbet/app-config?country=ro&environment=prod&platform=ssbt',
            paymentHost: 'https://retail.prod.incubator.superbet.ro/ssbt-api/',
            paymentHostFastly: 'https://prod-superbet-retail-ssbt-api.global.ssl.fastly.net/ssbt-api/',
            paymentHostSocket: 'https://retail.prod.incubator.superbet.ro/betting-terminal-ssbt',
            socketPath: '/ssbt-api/socket.io',
            countryKey: 'ro',
            allowancesUrl: 'https://superbet-content.freetls.fastly.net/cached-superbet/allowances/',
            virtualTournamentsEndpoint: 'https://sports-offer.s3.eu-central-1.amazonaws.com/virtual-tournaments/ro.json',
        }
    },
    'in-house': {
        dev: {
            configHost: 'https://superbet-content-stage.global.ssl.fastly.net/cached-superbet/app-config?country=ro&environment=qa&platform=ssbt',
            paymentHost: 'https://retail.qa.incubator.superbet.ro/ssbt-api/',
            paymentHostFastly: 'https://stage-superbet-retail-ssbt-api.global.ssl.fastly.net/ssbt-api/',
            paymentHostSocket: 'https://retail.qa.incubator.superbet.ro/betting-terminal',
            socketPath: '/ssbt-api/socket.io',
            countryKey: 'ro',
            allowancesUrl: 'https://superbet-content-stage.freetls.fastly.net/cached-superbet/allowances/',
            virtualTournamentsEndpoint: 'https://sports-offer.s3.eu-central-1.amazonaws.com/virtual-tournaments/ro.json',
        },
        qa: {
            configHost: 'https://superbet-content-stage.global.ssl.fastly.net/cached-superbet/app-config?country=ro&environment=qa&platform=ssbt',
            paymentHost: 'https://retail.qa.incubator.superbet.ro/ssbt-api/',
            paymentHostFastly: 'https://stage-superbet-retail-ssbt-api.global.ssl.fastly.net/ssbt-api/',
            paymentHostSocket: 'https://retail.qa.incubator.superbet.ro/betting-terminal',
            socketPath: '/ssbt-api/socket.io',
            countryKey: 'ro',
            allowancesUrl: 'https://superbet-content-stage.freetls.fastly.net/cached-superbet/allowances/',
            virtualTournamentsEndpoint: 'https://sports-offer.s3.eu-central-1.amazonaws.com/virtual-tournaments/ro.json',
        },
        stage: {
            configHost: 'https://superbet-content-stage.global.ssl.fastly.net/cached-superbet/app-config?country=ro&environment=stage&platform=ssbt',
            paymentHost: 'https://retail.stage.incubator.superbet.ro/ssbt-api/',
            paymentHostFastly: 'https://stage-superbet-retail-ssbt-api.global.ssl.fastly.net/ssbt-api/',
            paymentHostSocket: 'https://retail.stage.incubator.superbet.ro/betting-terminal',
            socketPath: '/ssbt-api/socket.io',
            countryKey: 'ro',
            allowancesUrl: 'https://superbet-content-stage.freetls.fastly.net/cached-superbet/allowances/',
            virtualTournamentsEndpoint: 'https://sports-offer.s3.eu-central-1.amazonaws.com/virtual-tournaments/ro.json',
        },
        silent: {
            configHost: 'https://superbet-content.global.ssl.fastly.net/cached-superbet/app-config?country=ro&environment=prod&platform=ssbt',
            paymentHost: 'https://retail.prod.incubator.superbet.ro/ssbt-api-silent/',
            paymentHostFastly: 'https://prod-superbet-retail-ssbt-api-silent.global.ssl.fastly.net/ssbt-api-silent/',
            paymentHostSocket: 'https://retail.prod.incubator.superbet.ro/betting-terminal',
            socketPath: '/ssbt-api-silent/socket.io',
            countryKey: 'ro',
            allowancesUrl: 'https://superbet-content.freetls.fastly.net/cached-superbet/allowances/',
            virtualTournamentsEndpoint: 'https://sports-offer.s3.eu-central-1.amazonaws.com/virtual-tournaments/ro.json',
        },
        prod: {
            configHost: 'https://superbet-content.global.ssl.fastly.net/cached-superbet/app-config?country=ro&environment=prod&platform=ssbt',
            paymentHost: 'https://retail.prod.incubator.superbet.ro/ssbt-api/',
            paymentHostFastly: 'https://prod-superbet-retail-ssbt-api.global.ssl.fastly.net/ssbt-api/',
            paymentHostSocket: 'https://retail.prod.incubator.superbet.ro/betting-terminal',
            socketPath: '/ssbt-api/socket.io',
            countryKey: 'ro',
            allowancesUrl: 'https://superbet-content.freetls.fastly.net/cached-superbet/allowances/',
            virtualTournamentsEndpoint: 'https://sports-offer.s3.eu-central-1.amazonaws.com/virtual-tournaments/ro.json',
        },
    }
};

import AbstractMapper from '@core/utils/mappers/AbstractMapper';
import Sport from '@core/models/struct/Sport';
import { SportDto } from '@superbet-group/offer.clients.lib';
import common from '@src/config/common';


export class SportMapper extends AbstractMapper<SportDto, Sport> {
    createTargetObject(dto: SportDto) {
        const sport: Sport = new Sport();

        sport.id = parseInt(dto.id, 10);
        sport.name = dto.localNames[common.offerLang];
        sport.order = dto.order;

        return sport;
    }
}

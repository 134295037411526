/* tslint:disable */
import AbstractMapper from '@core/utils/mappers/AbstractMapper';
import ServerConfig, {
    HandlingFeeMethod
} from '@core/models/country/ServerConfig';
import config from '@config';
import Logger from '@core/utils/loggers/Logger';
import {
    BonusType,
    getTaxBrackets,
    parseSuperBonusBreakpoints,
    TaxBase,
    TaxMode,
    TicketType,
} from '@superbet-group/betting.lib.payments';
import { SuperBonusCalculationBase } from "@superbet-group/betting.lib.payments";
import { capitalize } from '@shared/filters';
import IDirectusConfigDto from './dtos/IDirectusConfigDto';

export default class ConfigMapper extends AbstractMapper<IDirectusConfigDto, ServerConfig> {
    createTargetObject(dto: IDirectusConfigDto, key: number): ServerConfig {
        return <any>{
            applicationStatus: {
                maintainedParts: [].reduce(
                    (accumulator: any, currentValue: string) => {
                        accumulator[currentValue] = true;
                        return accumulator;
                    },
                    {}),
                maintenanceFrameUrl: "",
                serviceMessage: "",
            },
            betSlip: {
                minOddPariulSansa: parseFloat(dto.luckyLoserMinOddCoef) || 1.30,
                minSelectionPariulSansa: parseInt(dto.luckyLoserMinSelections, 10) || 11,
                stakeMax: parseInt(dto.maxSportStakeSSBT, 10),
                stakeMin: parseInt(dto.minSportStake, 10),
                minStakePerCombination: parseFloat(dto.minSportStakePerCombination) || 0.01,
                maxWin: parseInt(dto.maxWin, 10),
                maxSelectedNumbers: parseInt(dto.maxTicketNum, 10),
                offlineTax: parseInt(dto.taxOffline, 10),
                onlineTax: parseInt(dto.taxOnline, 10),
                winTaxBounds: this.parseTaxLevels(dto.winTax),
                minOddBonus: parseFloat(dto.minOddBonus),
                maxBonusPercent: parseInt(dto.maxBonusPercent, 10),
                handlingFeeMethod: HandlingFeeMethod.TaxOnNet,
                handlingFeeInverse: false,
                taxPerSystem: config.countrySpecific.multiStepTaxForSystemTickets,
                taxMode: TaxMode.Flat,
                taxBase: TaxBase.Base,
                taxBrackets: getTaxBrackets(dto.winTax),
                areSuperBonusSpecialsValidOnline: dto.superBonusSpecialsValidOnline === 'true',
                bonusConfig: {
                    bonusType: BonusType.SuperBonus,
                    calculationBase: dto.superBonusCalculationBase === SuperBonusCalculationBase.Winning ? SuperBonusCalculationBase.Winning : SuperBonusCalculationBase.Profit,
                    superBonusEnabled: dto.superBonusEnabled === 'true',
                    bonusBreakpoints: dto.superBonusBreakpoints
                        ? parseSuperBonusBreakpoints(dto.superBonusBreakpoints)
                        : [],
                    minOddValue: dto.superBonusMinCoef
                        ? Number(dto.superBonusMinCoef)
                        : 0,
                    validSelectionTypes: dto.superBonusValidSelectionTypes
                        ? dto.superBonusValidSelectionTypes.split('|')
                        : [],
                    validTicketTypes: dto.superBonusValidTicketTypes
                        ? this.parseValidTicketTypes(dto.superBonusValidTicketTypes)
                        : [],
                    ignoredOfferTypes: dto.superBonusIgnoredOfferTypes
                        ? dto.superBonusIgnoredOfferTypes.split('|')
                        : [],
                    specialsAllowed: true,
                }
            },
            tickets: {
                cacheV3: dto.axilisTicketServiceV3,
                reprintLastTicketsLimit: parseInt(dto.reprintLastTicketsLimit, 10) || 15,
                ticketSubmitTimeout: dto.ticketSubmitTimeout || 5,
            },
            offer: {
                hostServer: dto.offerServer,
                hostServerAllValues: dto.offerServerAllValues,
                socketIoAllValues: dto.socketIoAllValues,
                betBuilder: {
                    betBuilderBackend: dto.betBuilderBackend,
                    betBuilderServer: dto.betBuilderServer,
                    betBuilderStreamingHost: dto.betBuilderStreamingHost,
                }
            },
            auth: {
                host: dto.webBackend,
            },
            user: {
                host: dto.webBackend,
            },
            country: {
                host: dto.webBackend,
            },
            bonus: {
                host: dto.webBackend,
            },
            antiFraud: {
                host: dto.webBackend,
            },
            superbets: {
                superbetsRange: dto.superbetsRange ? dto.superbetsRange
                    .split(',').map((i) => parseInt(i, 10)) as [number, number] : null,
            },
            ticketPromotional: dto.ticketPromotional,
        };
    }

    // this is the value in romanian CMS. It has no tax but sends this string
    static defaultTaxLevel = '0|1';

    // Tax limits should be in format 'minAmount|tax|minAmount|tax...'
    parseTaxLevels(taxLimits: string): {
        lowerBound: number;
        percentage: number;
    }[] {
        if (!taxLimits || taxLimits === ConfigMapper.defaultTaxLevel) {
            return [];
        }
        const limits = taxLimits.split('|').map(parseFloat);
        // handle odd limits length. Should never happen
        const maxLength = (limits.length % 2) ? limits.length - 1 : limits.length;
        if (limits.length % 2) {
            Logger.error(new Error('Tax limits have invalid format'));
        }
        const limitsList: { lowerBound: number; percentage: number }[] = [];
        for (let i = 0; i < maxLength - 1; i += 2) {
            limitsList.push({
                lowerBound: limits[i],
                percentage: limits[i + 1],
            });
        }
        return limitsList;
    }

    private parseValidTicketTypes(types: string): TicketType[] {
        try {
            return types.split('|').map(type => {
                if (type === 'online') {
                    return TicketType.online;
                } else if (type === 'retail') {
                    return TicketType.retail;
                }
                throw new Error('invalid format');
            });
        } catch (e) {
            Logger.error(
                new Error(
                    `[Superbonus] ticket types have wrong format: ${types}`,
                ),
            );
            return [];
        }
    }
}

import Singleton from '@core/services/common/Singleton';
import { instanceGuard } from '@core/utils/services';
import ServerConfig from '@core/models/country/ServerConfig';
import { IPlatform } from './IPlatform';
import commonConfig from '@src/config/common';
import GravityService from '../gravity/GravityService';
import ProductService from '../product/ProductService';

class PlatformService {

    private static instance: IPlatform;

    private constructor(private config: ServerConfig) {}

    public static getInstance(): IPlatform {
        return instanceGuard(PlatformService.instance);
    }

    public static createInstance(config: ServerConfig) {
        if (!PlatformService.instance) {
            if (commonConfig.environment.isNSoft) {
                PlatformService.instance = GravityService.createInstance(config);
            } else {
                PlatformService.instance = ProductService.createInstance(config);
            }
        }
        return PlatformService.instance;
    }

    public static clearInstance() {
        if (process.env.NODE_ENV !== 'test') {
            throw new Error('For use in tests only');
        }
        delete PlatformService.instance;
    }
}

export default PlatformService as Singleton<IPlatform>;

<template>
    <OfferResultsContainer v-if="isStructFetched" />
</template>

<script>
    import { mapGetters } from 'vuex';
    import OfferResultsContainer from './OfferResultsContainer.vue';

    export default {
        computed: mapGetters('data/sportOffer', [
            'isStructFetched'
        ]),
        components: {
            OfferResultsContainer,
        }
    };
</script>

import RestRequestFactory from '@utils/network/RestRequestFactory';
import ServerConfig from '@core/models/country/ServerConfig';
import config from '@config';
export default class RestService {
    private readonly request: any;

    constructor(conf: ServerConfig, restRequestFactory = RestRequestFactory) {
        this.request = restRequestFactory.create(config.services.country.frontEndHost);
    }

    public getBannersByCategory(category: string): Promise<any> {
        return this.request({
            url: '/rest/SBWeb.Models.Banners/getBanners',
            method: 'get',
            params: {
                category
            },
        });
    }
}

<template>
    <tr>
        <td colspan="1" class="p-l-1 p-y-1">
            {{ $t('luckyLoser') }}
        </td>
    </tr>
</template>

<script>
    export default {
        name: 'TicketLuckyLoser'
    };
</script>

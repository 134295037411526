<template>
    <div class="betslip__system-box--terminal">
        <div
            v-for="system in systems"
            :key="system.id"
            class="betslip-system__wrap--terminal"
        >
            <div class="betslip-system__combinations--terminal"
                 @click="onClick(system)"
                 :class="checkedSystemClass(system.isSelected)"
            >
                <div
                    :id="'inlineCheckbox' + system.id"
                >
                    <span>{{ computeSystem(system) }}</span>
                </div>
            </div>
        </div>
        <div class="w-100">
            <button
                v-if="modalData.button.enabled"
                :class="buttonClass"
                type="button"
                @click="closeModal"
            >
                {{ $t(buttonLabel) | capitalize }}
            </button>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import { get as _get } from '@lodash';

    export default {
        name: 'SportSystemsModal',
        computed: {
            ...mapGetters('ui/modal', [
                'modalData',
            ]),
            textClass() {
                return _get(this, 'modalData.text.className') || '';
            },
            textContent() {
                return _get(this, 'modalData.text.content')
                    || 'Something went wrong. Please try again';
            },
            buttonClass() {
                return _get(this, 'modalData.button.className') || 'btn btn__primary btn--block';
            },
            buttonLabel() {
                return _get(this, 'modalData.button.label') || 'ok';
            },
            systems() {
                return _get(this, 'modalData.systems');
            },
            fixedSelectionCount() {
                return _get(this, 'modalData.fixedSelectionCount');
            },
        },
        methods: {
            ...mapActions('ui/modal', [
                'closeModal'
            ]),
            ...mapActions('ui/sportOffer/betSlip', [
                'toggleSystemSelected',
            ]),
            onClick(system) {
                this.toggleSystemSelected(system);
            },
            computeSystem(system) {
                if (this.fixedSelectionCount < 1) {
                    return system;
                }
                return `${this.fixedSelectionCount}F ${system}`;
            },
            checkedSystemClass(isSelected) {
                return isSelected ? 'selected' : '';
            }
        },
    };
</script>

import MarketGroupInfo from '@models/struct/MarketGroupInfo';
import { MarketForArrange } from '@modules/eventGrid/eventRowUtils';

export const ALL_MARKETS_GROUP_ID = -1;

export interface MarketGroup {
    id: number;
    name: string;
    markets: MarketForArrange[];
}

export function groupMarkets(
    marketGroups: MarketGroupInfo[],
    markets: MarketForArrange[],
    allMarketsName: string,
    allowedMarketGroupIds?: Set<number>,
): Map<number, MarketGroup> {
    const allowedMarketGroups = allowedMarketGroupIds
        ? marketGroups.filter(group => allowedMarketGroupIds.has(group.id))
        : marketGroups;
    const mapper = getMarketGroupMapper(markets);
    const groupedMarkets = allowedMarketGroups.map(mapper).filter(group => group.markets.length);

    // Used for filtering all markets
    const allowedMarkets = allowedMarketGroupIds && getUnionOfAllMarketIds(allowedMarketGroups);

    // ensuring we always have an "All Markets" group (even when there are no markets available)
    groupedMarkets.unshift(getAllMarketsGroup(allMarketsName, markets, allowedMarkets));

    // using a map to preserve the group order
    return new Map(groupedMarkets.map(group => [group.id, group]));
}

export function getMarketGroupMapper(markets: MarketForArrange[]) {
    return (groupInfo: MarketGroupInfo) => ({
        id: groupInfo.id,
        name: groupInfo.name,
        markets: markets.filter(market => groupInfo.marketIds.has(market.id)),
    });
}

export function getAllMarketsGroup(
    name: string,
    markets: MarketForArrange[],
    allowedMarkets?: Set<number>,
) {
    return {
        id: ALL_MARKETS_GROUP_ID,
        // tslint:disable-next-line:object-shorthand-properties-first
        name,
        markets: allowedMarkets ? markets.filter(market => allowedMarkets.has(market.id)) : markets,
    };
}

export function getUnionOfAllMarketIds(marketGroups: MarketGroupInfo[]) {
    return new Set(
        marketGroups
            .map(group => group.marketIds)
            .reduce((acc, ids) => {
                // tslint:disable-next-line:no-parameter-reassignment
                acc = [...acc, ...ids];
                return acc;
            },      [] as number[]),
    );
}

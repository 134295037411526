import {
  forEach as _forEach,
  filter as _filter,
  flatten as _flatten,
  isEmpty as _isEmpty,
  map as _map,
  partition as _partition,
  xorBy as _xorBy,
  keyBy as _keyBy,
} from '@lodash';
import { ActionContext, Commit, Dispatch } from 'vuex';
import StoreUtil from '@store/StoreUtil';
import BookieDate from '@utils/date/BookieDate';
import StructService from '@core/services/offer/StructService';
import config from '@config';
import { ISportOfferState } from './index';
import * as types from './mutationTypes';
import OfferService from '@core/services/offer/OfferService';
import Match from '@core/models/offer/Match';
import StructManager from '@core/services/common/StructManager';
import { ISuperOfferCollections, OddDynamics } from './types';
import { OddValueChange } from '@store/modules/data/sportOffer/enums';
import Odd from '@models/offer/Odd';
import { differenceInSeconds } from 'date-fns';
import { twoDecimals } from '@shared/filters';
import MarketGroupInfo from '@models/struct/MarketGroupInfo';
import { IState } from '@app/store';
import { getIntervalForTimeFilter, getTimeFilterFromSlug } from '@shared/timeFilter/helpers';
import { TimeFilter } from '@shared/timeFilter/enums';
import * as Sentry from '@sentry/vue';
import { MatchSubscriptions, BetBuilderOddSubscriptions } from './Subscriptions';
import BetBuilderService from '@src/terminal/core/services/betBuilder/BetBuilderService';
import { BetBuilderOddMessage } from '@src/terminal/core/services/betBuilder/_private/SSEManager';
import { OddMapper } from '@core/mappers/offer/OddMapper';
import { OfferInterval, OfferIntervalOptions, Phase, SSEStructType, SSEStructDTO } from '@superbet-group/offer.clients.lib';
import { Subscription } from 'rxjs';
import { SportMapper } from '@src/core/mappers/struct/SportMapper';
import { CategoryMapper } from '@core/mappers/struct/CategoryMapper';
import { TournamentMapper } from '@core/mappers/struct/TournamentMapper';

const oddMapper = new OddMapper();

const pendingEvents: Record<string, Match> = {};

let liveEventsSubscription: Subscription | null;

const sseHandler = (
  event: Match | null,
  resourceId: string,
  timestamp: number,
  localEvent: Readonly<Match>,
  addHandler: (event: Match) => void,
  fetchHandler: () => void,
  updateHandler: (event: Match) => void,
  errorMessage: string,
) => {
  // we received event data but event is not in our local store we must add it
  // else if event isn't in our store and there was no event data, fetch the offer manually
  if (!localEvent && !!event) {
    addHandler(event);

    return;
    // tslint:disable-next-line:no-else-after-return
  } else if (!localEvent && !event) {
    fetchHandler();

    return;
  }

  // Local data is newer or same as the one in sse message
  if (timestamp <= localEvent.matchTimestamp!) {
    return;
  }

  // Local data is older, but we don't have event data available, we must fetch it manually
  if (timestamp > localEvent.matchTimestamp! && !event) {
    // @ts-ignore
    fetchHandler();

    return;
  }

  // Local data is older, and we need to update with the event data in the sse message
  if (!!event && timestamp > localEvent.matchTimestamp!) {
    updateHandler(event);
  } else {
    Sentry.captureMessage(errorMessage, {
      extra: {
        localTimestamp: localEvent.matchTimestamp,
        sseData: {
          resourceId,
          timestamp,
        },
      },
    });
  }
};

export default {
  updateMarketGroupInfos: StoreUtil.asyncErrorGuard(
    (
      { commit, dispatch, state }: { commit: any; dispatch: Dispatch; state: ISportOfferState },
      marketGroupInfo: MarketGroupInfo,
    ) => {
      const currentMarketGroupInfos = state.marketGroupInfos || {};
      commit(types.SET_MARKET_GROUP_INFOS, {
        ...currentMarketGroupInfos,
        [marketGroupInfo.id]: marketGroupInfo,
      });
      StructManager.getInstance().setMarketGroupInfos(state.marketGroupInfos);
      dispatch('denormalizePendingEvents');
    },
  ),

  fetchStruct: StoreUtil.asyncErrorGuard(
    async ({ commit, dispatch }: { commit: Commit; dispatch: Dispatch; getters: any }) => {
      const struct = await StructService.getInstance().getStruct();

      let isUpdate = false;

      try {
        isUpdate = StructManager.getInstance().hasStruct();
      } catch (e) {}

      if (isUpdate) {
        StructManager.getInstance().setStruct(struct);
      } else {
        StructManager.createInstance({ struct });
      }

      commit(types.SET_STRUCT, struct);
      return struct;
    },
  ),

  startOffer: StoreUtil.asyncErrorGuard(async ({ dispatch }: { dispatch: Dispatch }) => {
    dispatch('setIsOfferStarting', true);

    await dispatch('fetchStruct');

    dispatch('setIsOfferReady', true);
    dispatch('setIsOfferStarting', false);

    dispatch('data/competitions/prepareCompetitions', null, { root: true });
  }),

  subscribeToStructChanges: StoreUtil.asyncErrorGuard( async ({ dispatch, commit }: { dispatch: Dispatch; commit: Commit }) => {
    const offerService = OfferService.getInstance();
    const categoryMapper: CategoryMapper = new CategoryMapper();
    const sportMapper: SportMapper = new SportMapper();
    const tournamentMapper: TournamentMapper = new TournamentMapper();
    
    offerService.subscribeToStructChange(({ timestamp, data, resourceId, type, id }: SSEStructDTO) => {
    
    switch (type) {
      case SSEStructType.Category:
        const category = categoryMapper.map(data!);
        commit(types.UPDATE_ONE_STRUCT_CATEGORY, category);
        break;
      case SSEStructType.Sport:
        const sport = sportMapper.map(data!);
        commit(types.UPDATE_ONE_STRUCT_SPORT, sport);
        break;
      case SSEStructType.Tournament:
        const tournament = tournamentMapper.map(data!)
        commit(types.UPDATE_ONE_STRUCT_TOURNAMENT, tournament);
        break;
      default:
        if(id){
          dispatch('fetchStruct');
        }
        break;
    }
    });
  }),

  fetchEvents: StoreUtil.asyncErrorGuard(
    async ({ getters, dispatch, commit }: { getters: any; dispatch: Dispatch; commit: Commit }, timeSpan?: any) => {

      
      const { interval, options } = getOfferInterval(timeSpan);
      const prematchEvents = await OfferService.getInstance().getEvents(interval, options);
      const live = await OfferService.getInstance().getLiveEvents(BookieDate.getEndDateForDate(new Date()));
      

      const prematchEventsToAdd: Match[] = [];

      prematchEvents.forEach((event) => {
        if (!getters.eventsMap[event.id]) {
          prematchEventsToAdd.push(event);
        } else if (event.matchTimestamp! >= getters.eventsMap[event.id].matchTimestamp) {
          commit(types.UPDATE_EVENT, event);
        }
      });

      const isLiveEventStale = (event: Match) =>
        !getters.eventsMap[event.id] || getters.eventsMap[event.id].matchTimestamp! < event.matchTimestamp!;

      dispatch('setEvents', {
        events: [...prematchEventsToAdd, ...live.filter(isLiveEventStale)],
      });
      commit(types.SET_IS_OFFER_FETCHED, true);
    },
  ),
  fetchOutrights: StoreUtil.asyncErrorGuard(
    async ({ getters, dispatch, commit }: { getters: any; dispatch: Dispatch; commit: Commit }) => {
      const outrights = await OfferService.getInstance().getOutrights();

      const outrightsEventsToAdd: Match[] = [];

      outrights.forEach((event) => {
        if (!getters.eventsMap[event.id]) {
          outrightsEventsToAdd.push(event);
        } else if (event.incrementId >= getters.eventsMap[event.id].incrementId) {
          commit(types.UPDATE_EVENT, event);
        }
      });

      dispatch('setEvents', {
        events: [...outrightsEventsToAdd],
      });
    },
  ),
  syncLiveEvents: StoreUtil.asyncErrorGuard(async ({ dispatch }: { dispatch: Dispatch }) => {
    const events = await OfferService.getInstance().getLiveEvents(new Date());
    dispatch('setEvents', {
      events
    });
  }),
  syncPrematchEvents: StoreUtil.asyncErrorGuard(async ({ dispatch, rootState }: { dispatch: Dispatch, rootState: IState }) => {
    const timeFilterSlug = rootState.route.params.timeFilter;
    const timeFilter = getTimeFilterFromSlug(timeFilterSlug);
    const timeSpan = getIntervalForTimeFilter(timeFilter);

    const events = await OfferService.getInstance().getEvents(timeSpan.interval, timeSpan.options);
    dispatch('setEvents', {
      events,
    });
  }),
  fetchAll: StoreUtil.asyncErrorGuard(async ({ dispatch }: { dispatch: Dispatch }) => {
    const timeSpan = getIntervalForTimeFilter(TimeFilter.all);
    await dispatch('fetchEvents', timeSpan);
  }),

  fetchTopTenEvents: StoreUtil.asyncErrorGuard(async ({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }) => {
    const topTenEvents = await OfferService.getInstance().getTopTenEvents();
    commit(types.SET_TOP_TEN_EVENTS, _map(topTenEvents, 'id'));
    dispatch('setEvents', {
      events: topTenEvents,
    });
    commit(types.SET_ARE_TOP_TEN_EVENTS_FETCHED, true);
  }),

  fetchSuperEvents: StoreUtil.asyncErrorGuard(async (context: ActionContext<ISportOfferState, IState>) => {
    if (context.getters['isSuperOfferFetched']) {
      return;
    }

    const superOffer = await OfferService.getInstance().getSuperEvents(
      BookieDate.getStartDateForDate(),
      BookieDate.getEndDateForDate(),
    );

    const availableSuperOffers = context.state.superOfferConfig;
    const promotionEvents = {} as ISuperOfferCollections;
    const events = [] as Match[];
    _forEach(superOffer, (promoEvents, key) => {
      const realKey = key;

      const promotionData = availableSuperOffers[realKey];
      if (promotionData && promoEvents.length) {
        const promoMarketOdd = promoEvents[0].getOdds().find((odd) => !!odd.tags && odd.tags.includes(realKey));
        if (!promoMarketOdd) {
          delete promotionEvents[key];
          return;
        }
        promotionEvents[key] = {
          matchIds: promoEvents.map((m) => m.id),
          primaryMarketId: promoMarketOdd!.marketId,
        };
        events.push(...promoEvents);
      }
    });

    context.dispatch('setEvents', {
      events,
    });
    context.commit(types.SET_PROMOTIONAL_EVENTS, promotionEvents);
    context.commit(types.SET_IS_SUPER_OFFER_FETCHED, true);

    _forEach(promotionEvents, (group) => {
      group.matchIds.forEach((id) => {
        context.dispatch('subscribeToEvent', id);
      });
    });
  }),

  subscribeToEventChanges({ dispatch, state }: { dispatch: Dispatch; state: ISportOfferState }) {
    OfferService.getInstance().subscribeToAllEventChangesSSE(({ event, resourceId, timestamp }) => {
      
      if (!MatchSubscriptions.hasSubscription(Number(resourceId))) {
        const addHandler = (event: Match) => dispatch('setEvents', { events: [event] });
        const fetchHandler = () => dispatch('syncPrematchEvents');
        const updateHandler = (event: Match) => {
          dispatch('_purgeOddValueDynamics');
          dispatch('_setOddValueDynamics', [event]);
          dispatch('setEvent', event);
        };
        const errorMessage = 'Something unknown happened while updating all events from sse message';

        sseHandler(
          event,
          resourceId,
          timestamp,
          state.events[resourceId],
          addHandler,
          fetchHandler,
          updateHandler,
          errorMessage,
        );
      }
    });
  },
  subscribeToLiveEventChanges({ dispatch, state }: { dispatch: Dispatch; state: ISportOfferState }) {
    liveEventsSubscription = OfferService.getInstance().subscribeToAllLiveEventChangesSSE((messages) =>
      messages.map(({ event, resourceId, timestamp }) => {
        if (!MatchSubscriptions.hasSubscription(Number(resourceId))) {
          const addHandler = (event: Match) => dispatch('setEvents', { events: [event] });
          const fetchHandler = () => dispatch('syncLiveEvents');
          const updateHandler = (event: Match) => {
            dispatch('_purgeOddValueDynamics');
            dispatch('_setOddValueDynamics', [event]);
            dispatch('setEvent', event);
          };
          const errorMessage = 'Something unknown happened while updating all events from sse message';

          sseHandler(
            event,
            resourceId,
            timestamp,
            state.events[resourceId],
            addHandler,
            fetchHandler,
            updateHandler,
            errorMessage,
          );
        }
      }),
    );
  },
  unSubscribeToLiveEventChanges() {
    if (!!liveEventsSubscription) {
      liveEventsSubscription.unsubscribe();
    }
  },
  subscribeToAllOutrightEventChanges({ dispatch, state }: { dispatch: Dispatch; state: ISportOfferState }) {
    OfferService.getInstance().subscribeToAllOutrightEventChanges(({ event, resourceId, timestamp }) => {
      if (!MatchSubscriptions.hasSubscription(Number(resourceId))) {
        const addHandler = (event: Match) => dispatch('setEvents', { events: [event] });
        const fetchHandler = async () => dispatch('fetchOutrights');
        const updateHandler = (event: Match) => {
          dispatch('_purgeOddValueDynamics');
          dispatch('_setOddValueDynamics', [event]);
          dispatch('setEvent', event);
        };
        const errorMessage = 'Something unknown happened while updating all outrights from sse ';

        sseHandler(
          event,
          resourceId,
          timestamp,
          state.events[resourceId],
          addHandler,
          fetchHandler,
          updateHandler,
          errorMessage,
        );
      }
    });
  },
  async subscribeToBetBuilderOddChange(
    { commit, dispatch, state }: { commit: Commit; dispatch: Dispatch; state: ISportOfferState },
    { matchId, oddUuid }: { matchId: number; oddUuid: string },
  ) {
    if (!BetBuilderOddSubscriptions.hasSubscription(oddUuid)) {

      const handler = async (data:  BetBuilderOddMessage) => {
        const event = state.events[matchId];
        const uuid = data.data.uuid;
        const oldTimestamp = state.betBuilderOddTimestamps[uuid];
        if (!oldTimestamp || oldTimestamp < data.timestamp) {
          const odd = oddMapper.createTargetObject((data as BetBuilderOddMessage).data, 0, false);

          StructManager.getInstance().denormalizeOdd(event, odd);
          dispatch(`ui/sport/betSlip/updateSelectionOdd`, odd, {
            root: true,
          });
          commit(types.SET_BET_BUILDER_ODD, {
            ts: data.timestamp,
            uuid,
          });
        }
      };

      const subscription = BetBuilderService.getInstance().subscribeToOdd(matchId, oddUuid, handler);

      BetBuilderOddSubscriptions.addSubscription(oddUuid, subscription);
    }
  },

  setEvents({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }, { events }: { events: Match[] }) {
    const [valid, invalid] = _partition(events, (e) => e.validStruct);

    commit(types.SET_EVENTS, valid);
    commit(types.INCREMENT_ALL_CHANGES_ID);
    if (invalid.length > 0) {
      dispatch('retryInvalidEvents', invalid);
    }
  },

  retryInvalidEvents: StoreUtil.asyncErrorGuard(async ({ dispatch }: { dispatch: Dispatch }, pending: Match[]) => {
    pending.forEach((event) => (pendingEvents[event.id] = event));
    await dispatch('fetchStruct');
    dispatch('denormalizePendingEvents');
  }),

  denormalizePendingEvents({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }) {
    Object.keys(pendingEvents).forEach((id) => {
      const event = pendingEvents[id];
      OfferService.getInstance().denormalizeEvent(event);
      if (event.validStruct) {
        delete pendingEvents[id];
        dispatch('_setOddValueDynamics', [event]);
        commit(types.SET_EVENT, event);
      }
    });
  },

  removeSubscription({ commit, state }: { commit: Commit; state: ISportOfferState }, eventId: number) {
    MatchSubscriptions.removeSubscription(eventId);
  },
  // TODO remove builder subscription when we implement bet builder on SSBT
  removeBetBuilderSubscription({ commit, state }: { commit: Commit; state: ISportOfferState }, oddUuid: string) {
    BetBuilderOddSubscriptions.removeSubscription(oddUuid);
  },

  getEvent: StoreUtil.asyncErrorGuard(
    async ({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }, eventId: number) => {
      // because pick is a new component and on expanding and it shows
      // odd changes because oddValueDynamics still contains the last value for a short period of time
      dispatch('_deleteFromOddValueDynamics', eventId);
      dispatch('subscribeToEvent', eventId);

      let event = await OfferService.getInstance().getEvent(eventId);

      if (!event) {
        return;
      }
      
      const phase = event.isOngoing() ? Phase.live : Phase.prematch;
      const marketGroupInfos = await StructService.getInstance().getMarketGroupInfos(event.sportId, phase);
      marketGroupInfos.map((marketGroupInfo) => dispatch('updateMarketGroupInfos', marketGroupInfo));

      StructManager.getInstance().denormalizeEvent(event);

      commit(types.SET_EVENT, event);
    },
  ),

  subscribeToEvent: StoreUtil.asyncErrorGuard(
    async (
      { dispatch, state }: { dispatch: Dispatch; state: ISportOfferState },
      eventId: number,
    ) => {
      if (!MatchSubscriptions.hasSubscription(eventId)) {
        const subscription = OfferService.getInstance().subscribeToEventSSE(
          eventId,
          async ({ event, resourceId, timestamp }) => {
            const fetchHandler = async () => {
              const newEvent = await OfferService.getInstance().getEvent(Number(resourceId));
              if (!!newEvent) {
                StructManager.getInstance().denormalizeEvent(newEvent);
                dispatch('setEvent', newEvent);
              }
            };
            const updateHandler = (event: Match) => {
              dispatch('_purgeOddValueDynamics');
              dispatch('_setOddValueDynamics', [event]);
              dispatch('setEvent', event);
            };
            const errorMessage = 'Something unknown happened while updating single event from sse message';
            sseHandler(
              event,
              resourceId,
              timestamp,
              state.events[resourceId],
              () => {}, // we don't need this here
              fetchHandler,
              updateHandler,
              errorMessage,
            );
          },
        );
        MatchSubscriptions.addSubscription(eventId, subscription);
      }
    },
  ),

  fetchEventsByIds: StoreUtil.asyncErrorGuard(
    async ({ getters, dispatch }: { getters: any; dispatch: Dispatch }, eventIds: number[]) => {
      const events = await OfferService.getInstance().getEventsById(eventIds);

      dispatch('setEvents', {
        events: events.filter(
          (event) => !getters.eventsMap[event.id] || getters.eventsMap[event.id].incrementId !== event.incrementId,
        ),
      });
    },
  ),

  setIsOfferStarting: StoreUtil.createSimpleMutatorAction(types.SET_IS_OFFER_STARTING),
  setIsOfferReady: StoreUtil.createSimpleMutatorAction(types.SET_IS_OFFER_READY),
  setShouldFetchOffer: StoreUtil.createSimpleMutatorAction(types.SET_SHOULD_FETCH_OFFER),
  setEvent: StoreUtil.createSimpleMutatorAction(types.SET_EVENT),
  setAreSubscriptionsSet: StoreUtil.createSimpleMutatorAction(types.SET_ARE_SUBSCRIPTIONS_SET),
  _purgeOddValueDynamics({ getters, commit }: { getters: any; commit: Commit }) {
    const oddChanges: Record<number, Record<string, OddDynamics>> = {};

    const now = new Date();
    _forEach(getters.oddValueDynamics, (dynamicsItems: Record<string, OddDynamics>, eventId: string) => {
      const eventIdInt = parseInt(eventId, 10);
      _forEach(dynamicsItems, (oddDynamics: OddDynamics, oddId: string) => {
        if (differenceInSeconds(now, oddDynamics.created) < config.app.sportOffer.oddDynamicsPreserveTime) {
          if (!oddChanges[eventIdInt]) {
            oddChanges[eventIdInt] = {};
          }
          oddChanges[eventIdInt][oddId] = oddDynamics;
        }
      });
    });

    commit(types.SET_ODD_VALUE_DYNAMICS, oddChanges);
  },
  setExpandedMarketIds({ commit }: { commit: Commit }, markets: any) {
    commit(types.SET_EXPANDED_MARKET_IDS, markets);
  },
  toggleExpandedMarketId({ commit }: { commit: Commit }, marketId: number) {
    commit(types.TOGGLE_EXPANDED_MARKET_ID, { key: marketId });
  },
  _deleteFromOddValueDynamics({ getters, commit }: { getters: any; commit: Commit }, eventId: number) {
    const oddChanges = { ...getters.oddValueDynamics };
    delete oddChanges[eventId];
    commit(types.SET_ODD_VALUE_DYNAMICS, oddChanges);
  },
  clearSubscriptions({ dispatch }: { dispatch: Dispatch }) {
    MatchSubscriptions.clearSubscriptions();
    BetBuilderOddSubscriptions.clearSubscriptions();
  },

  _setOddValueDynamics({ getters, commit }: { getters: any; commit: Commit }, events: Match[]) {
    const oddChanges = { ...getters.oddValueDynamics };
    const now = new Date();
    events.forEach((event) => {
      if (getters.eventsMap[event.id]) {
        const oldOdds = getters.eventsMap[event.id].getOdds().reduce((a: Record<string, number>, odd: Odd) => {
          a[odd.uniqueId] = odd.value;
          return a;
        }, {});

        if (!oddChanges[event.id]) {
          oddChanges[event.id] = {};
        }

        event.getOdds().forEach((odd) => {
          const oldOddValue = oldOdds[odd.uniqueId];
          if (oldOddValue && twoDecimals(oldOddValue) !== twoDecimals(odd.value)) {
            let newDirection = odd.value < oldOddValue ? OddValueChange.down1 : OddValueChange.up1;
            const oldChange = oddChanges[event.id][odd.uniqueId];

            if (oldChange) {
              if (newDirection < 0 && oldChange.oddDirection < 0) {
                newDirection = (oldChange.oddDirection % 2) - 1;
              } else if (newDirection > 0 && oldChange.oddDirection > 0) {
                newDirection = (oldChange.oddDirection % 2) + 1;
              }
            }

            oddChanges[event.id][odd.uniqueId] = {
              oddDirection: newDirection,
              created: now,
            };
          }
        });

        if (_isEmpty(oddChanges[event.id])) {
          delete oddChanges[event.id];
        }
      }
    });

    commit(types.SET_ODD_VALUE_DYNAMICS, oddChanges);
  },
};

function getOfferInterval(timeSpan?: { interval: OfferInterval; options?: OfferIntervalOptions }) {
  let interval;
  let options;
  if (timeSpan) {
    ({ interval, options } = timeSpan);
  } else {
    interval = OfferInterval.TODAY;
  }

  return {
    interval,
    options,
  };
}
<template>
    <div class="link-list">
        <span class="link-list__title">
            {{ $t('offer') | capitalize }}
        </span>
        <ul class="link-list__items">
            <a
                :href="offers.daily"
                class="link-list__item-pdf"
                target="_blank"
            >
                <i class="icon--md icon-pdf_file footer__icon"></i>
                {{ $t('daily offer') | capitalize }}
            </a>
            <a
                :href="offers.weekly"
                class="link-list__item-pdf"
                target="_blank"
            >
                <i class="icon--md icon-pdf_file footer__icon"></i>
                {{ $t('weekly offer') | capitalize }}
            </a>
        </ul>
    </div>
</template>

<script>
    import config from '@config';

    export default {
        created() {
            const nowTimestamp = new Date().getTime();
            const oldProd = config.services.frontendServer;

            this.offers = {
                daily: `${oldProd}/pdf/Superbet - Oferta zilnică.pdf?var=${nowTimestamp}`,
                weekly: `${oldProd}/pdf/Superbet - Oferta săptămânală.pdf?var=${nowTimestamp}`,
            };
        },
    };
</script>

<style scoped>

</style>

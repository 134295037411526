<template>
    <div class="offer__wrapper">
        <div
            :class="{ 'active': expandedEvent }"
            class="offer__container"
        >
            <CompetitionContainer
                v-if="isOnTournament"
                :scrollRelatedProps="scrollRelatedProps"
                @eventGridRender="onEventGridRender"
            />
            <SportOfferEventGrid
                v-else
                :events="filteredEvents"
                :promotions="promotions"
                v-bind="scrollRelatedProps"
                @oddClick="onOddClick"
                @eventGridRender="onEventGridRender"
            />
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import {
        orderBy as _orderBy,
    } from '@lodash';
    import { capitalize } from '@modules/shared/filters';
    import { PredefinedItems } from '@store/modules/ui/sportOffer/sports/enums';
    import { promotionsPrefix } from 'src/core/constants';
    import SportOfferEventGrid from '../../sharedSubmodules/SportOfferEventGrid.vue';
    import CompetitionContainer from './CompetitionContainer.vue';

    export default {
        name: 'SportOfferContainer',
        props: {
            parentModule: {
                type: String,
                required: true
            },
        },
        data() {
            return {
                // rendered grid means that all rows have finished expanding/collapsing
                renderedGrid: false,
                filterChangeIndicator: 0,
                // means that sidebar appeared or disappared after the last props changes
                sidebarChangedState: false,
            };
        },
        watch: {
            expandedEvent() {
                this.renderedGrid = false;
            },
            isNarrowed() {
                this.sidebarChangedState = false;
            },
            selectedTreeItem(newValue, oldValue) {
                if (oldValue.type !== newValue.type
                    || oldValue.id !== newValue.id) {
                    this.filterChangeIndicator = (this.filterChangeIndicator + 1) % 3;
                }
            },
            timeFilter() {
                this.filterChangeIndicator = (this.filterChangeIndicator + 1) % 3;
            },
        },
        computed: {
            ...mapGetters('data/sportOffer', [
                'sports',
                'eventsMap',
                'isOfferFetched',
                'isSuperOfferFetched',
                'areTopTenEventsFetched',
            ]),
            ...mapGetters('ui/sportOffer', [
                'expandedEvent',
            ]),
            ...mapGetters('ui/sportOffer/sports', [
                'promotionalEventGroups',
                'topTenEvents',
                'selectedTreeItem',
                'selectedFeaturedItem',
                'timeFilter',
            ]),
            ...mapGetters('data/sportOffer', [
                'promotionsFooterContent',
            ]),
            ...mapGetters('data/country', [
                'isSportInMaintenance'
            ]),
            filteredEvents() {
                return this.$store.getters[`${this.parentModule}/filteredEvents`];
            },
            isOnTournament() {
                return !!this.$route.params.tournamentSlug;
            },
            promotions() {
                const { type } = this.selectedTreeItem;
                if (this.selectedFeaturedItem
                    || (type !== PredefinedItems.all && type !== PredefinedItems.promotions)) {
                    return [];
                }

                const topTen = this.topTenEvents;

                const promotions = this.promotionalEventGroups.map(({
                    id,
                    events,
                    name,
                    ...other // primary market attribute, footer, type
                }) => ({
                    id: `${promotionsPrefix}${id}`,
                    title: name,
                    events: _orderBy(events, ['date', 'sportOrder', 'tournamentOrder']),
                    ...other
                }));

                if (topTen.length) {
                    promotions.push({
                        footer: '',
                        id: `${promotionsPrefix}topTen`,
                        type: 'topTen',
                        title: capitalize(this.$t('top ten').toString()),
                        events: _orderBy(topTen, ['date', 'sportOrder', 'tournamentOrder']),
                    });
                }
                return [
                    ...promotions,
                ];
            },
            isNarrowed() {
                return !!this.expandedEvent;
            },
            /*  we watch when the children (which cause layout changes) are rendered
                so we can handle scroll in the parent component */
            renderedChildren() {
                return this.sidebarChangedState && this.renderedGrid;
            },
            areEventsLoaded() {
                return this.isOfferFetched
                    && this.isSuperOfferFetched
                    && this.areTopTenEventsFetched;
            },
            scrollRelatedProps() {
                return {
                    renderDone: this.renderedChildren,
                    areEventsLoaded: this.areEventsLoaded,
                    filterChangeIndicator: this.filterChangeIndicator,
                    sidebarChangedState: this.sidebarChangedState,
                };
            }
        },
        methods: {
            ...mapActions('ui/modal', [
                'setModal',
            ]),
            ...mapActions('ui/sportOffer/betSlip', [
                'toggleAddRemoveSelection',
            ]),
            onOddClick({ eventId, odd }) {
                const { id: oddId, specialBetValue } = odd;

                this.toggleAddRemoveSelection({ eventId, oddId, specialBetValue });
            },
            onEventGridRender() {
                this.renderedGrid = true;
            },
            onSidebarPartialRender() {
                this.sidebarChangedState = true;
            },
            onSidebarDestroy() {
                this.sidebarChangedState = true;
            }
        },
        components: {
            SportOfferEventGrid,
            CompetitionContainer,
        },
    };
</script>


<template>
    <div class="search-box--terminal">
        <form @submit.prevent="handleSubmit" class="search__form--terminal" autocomplete="off">
            <AutoCompleteInput
                v-model="query"
                @click="showSuggestions"
                @itemClick="handleSubmit"
                @suggestionsStateChange="onSuggestionsStateChange"
                :placeholder="$t('search events') | capitalize"
                :areSuggestionsShown="areSuggestionsShown"
                :results="suggestions"
                :minCharacters="minCharacters"
                :isAsync="true"
                type="text"
                data-lpignore="true"
            />
        </form>
    </div>
</template>

<script>
    import AutoCompleteInput from '@src/terminal/app/modules/shared/components/AutoCompleteInput.vue';

    const minCharacters = 1;

    export default {
        name: 'SearchInput',
        props: {
            suggestionsFunction: {
                type: Function,
                required: true,
            },
            predefinedSuggestions: {
                type: Array,
                default: () => [],
            }
        },
        data() {
            return {
                query: '',
                suggestions: [],
                areSuggestionsShown: false,
                minCharacters,
            };
        },
        methods: {
            handleSubmit() {
                if (this.query.trim().length >= this.minCharacters) {
                    this.$emit('submit', this.query);
                }
            },
            showSuggestions() {
                this.areSuggestionsShown = true;
                this.suggestions = this.predefinedSuggestions;
            },
            onSuggestionsStateChange(value) {
                this.areSuggestionsShown = value;
            }
        },
        watch: {
            query(newValue) {
                if (newValue.trim().length >= minCharacters) {
                    this.suggestions = this.suggestionsFunction(this.query);
                } else {
                    this.suggestions = this.predefinedSuggestions;
                }
                if (!newValue.length) {
                    this.areSuggestionsShown = false;
                }
            }
        },
        components: {
            AutoCompleteInput,
        }
    };
</script>

<style scoped>
</style>

<template>
    <div class="info-page">
        <InfoContainer/>
    </div>
</template>

<script>
    import InfoContainer from '@modules/shared/info/InfoContainer.vue';

    export default {
        name: 'InfoPage',
        components: {
            InfoContainer,
        }
    };
</script>
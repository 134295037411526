<template>
    <div
        id="betslip"
        v-sticky="stickyConfig"
    >
        <BetSlip
            :betSlipNamespace="betSlipNamespace"
            :hasSystems="hasSystems"
            :isOutside="isPreviewOpen"
        />
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import StoreUtil from '@app/store/StoreUtil';
    import BetSlip from '@src/terminal/app/modules/shared/betSlip/BetSlip.vue';
    import Modals from '@store/modules/ui/shared/modal/modals';
    import Errors from '@shared/betSlip/Errors.vue';

    export default {
        name: 'BetSlipContainer',
        props: {
            betSlipNamespace: {
                type: String,
                default: 'ui/sportOffer/betSlip',
            },
            hasSystems: {
                type: Boolean,
                default: true,
            },
        },
        created() {
            this.stickyConfig = {
                scrollableContentSelector: '.responsive__element',
                staticLowerContentSelector: '.betslip__static__bottom',
                staticHigherContentSelector: '.betslip__static__top, .verification-bar',
                fixedLowerContentSelector: '.ticket-stack__container',
            };
        },
        methods: {
            ...mapActions('data/tickets', [
                'clearAllErrors',
            ]),
            ...StoreUtil.mapActionsDynamic('betSlipNamespace', [
                'setSelectionAddError',
                'removeBetSlipError',
            ]),
        },
        computed: {
            ...mapGetters('ui/modal', [
                'modal',
            ]),
            isPreviewOpen() {
                return this.modal && this.modal.code === Modals.betSlipPreview.code;
            },
            ...StoreUtil.mapGettersDynamic('betSlipNamespace', [
                'selectionAddError',
                'errors',
            ]),
        },
        beforeDestroy() {
            this.clearAllErrors();
        },
        components: {
            BetSlip,
            Errors,
        },
    };
</script>

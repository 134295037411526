<template functional>
    <li
        :id="`tree-item-${props.item.id}`"
        :class="{ active: props.isActive }"
        class="nav-sidebar-tree__item-wrapper--terminal"
    >
        <template v-if="props.isActive">
            <ul
                :class="{ active: props.isActive, [`depth--${props.depth}`]: true }"
                class="nav-sidebar-tree__subitem-list"
            >
                <component
                    :is="props.components.TreeItem"
                    v-for="child in props.item.children"
                    :key="child.id"
                    :item="child"
                    :depth="props.depth + 1"
                    :activeIds="props.activeIds"
                    :isActive="props.activeIds[props.depth] === child.id"
                    :isCurrentSelection="props.activeIds[props.depth] === child.id &&
                        props.activeIds.length === (props.depth + 1)"
                    :showItemCount="props.showItemCount"
                    :showToplevelFlag="props.showToplevelFlag"
                    :showSubitemFlag="props.showSubitemFlag"
                    @click="(item, isLeaf) => {
                        listeners.click([props.item, ...item], isLeaf)
                    }"
                />
            </ul>
        </template>
        <div
            @click="() => listeners.click([props.item], props.item.children
                && Object.keys(props.item.children).length === 0)"
            class="nav-sidebar-tree__item"
            :class="{ current: props.isCurrentSelection }"
        >
            <template v-if="props.depth === 1 && (!props.showToplevelFlag || !props.item.flag)">
                <i
                    class="icon--md nav-sidebar-tree__icon"
                    :class="props.item.icon || `icon-sport-${props.item.id}`"
                ></i>
            </template>
            <template v-else-if="props.depth === 1">
                <component :is="props.components.FlagIcon" :flag="`flags/${props.item.flag}.png`" />
            </template>
            <template v-if="props.depth !== 1 && props.showSubitemFlag">
                <component :is="props.components.FlagIcon" :flag="`flags/${props.item.flag}.png`" />
            </template>
            <span class="nav-sidebar-tree__title">
                {{ props.item.name }}
            </span>
            <span
                v-if="props.showItemCount"
                class="nav-sidebar-tree__events-count"
            >
                {{ props.item.eventCount }}
            </span>
        </div>
    </li>
</template>

<script>
    import FlagIcon from '@shared/components/FlagIcon.vue';

    const TreeItem = {
        functional: true,
        name: 'TreeItem',
        props: {
            item: {
                type: Object,
                required: true
            },
            depth: {
                type: Number,
                required: true
            },
            isActive: {
                type: Boolean,
                required: true
            },
            isCurrentSelection: {
                type: Boolean,
                required: true
            },
            activeIds: {
                type: Array,
                required: true
            },
            showItemCount: {
                type: Boolean,
                required: true,
            },
            showToplevelFlag: {
                type: Boolean,
                required: true,
            },
            showSubitemFlag: {
                type: Boolean,
                required: true,
            },
            components: {
                type: Object,
                default() {
                    return {
                        TreeItem,
                        FlagIcon
                    };
                }
            }
        }
    };

    export default TreeItem;
</script>

import Topic from './Topic';
import GTMHandler from './gtm/GTMHandler';
import eventChannel from './EventChannel';
import Subscriber from './Subscriber';

const subscribers: Record<string, Subscriber[]> = {
    [Topic.Analytics]: [new GTMHandler()]
};

export default function setupEventChannel() {
    Object.keys(subscribers).forEach((topic) => subscribers[topic]
        .forEach((subscriber) => eventChannel.subscribe(topic as Topic, subscriber))
    );
}

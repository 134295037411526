<template>
    <div class="offer__wrapper">
        <div class="offer__container">
            <SportResultsGrid
                :matches="filteredMatches"
                :areMatchesExpandable="areMatchesExpandable"
            />
            <div
                v-if="isLoading"
                class="spinner-container--terminal"
            >
                <SpinnerBars/>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import DateUtils from '@core/utils/date/DateUtils';
    import { errorGuard } from 'src/app/utils';
    import SportResultsGrid from './content/SportResultsGrid.vue';
    import SpinnerBars from '@shared/components/SpinnerBars.vue';

    export default {
        name: 'OfferResultsContainer',
        data() {
            return {
                isLoading: false,
            };
        },
        created() {
            this.loadData();
        },
        watch: {
            selectedDate(a, b) {
                if (!DateUtils.isEqual(a, b)) {
                    this.loadData();
                }
            },
        },
        computed: {
            ...mapGetters('ui/results/sport', [
                'filteredMatches',
                'areMatchesExpandable',
            ]),
            ...mapGetters('ui/results', [
                'timeWindow',
                'selectedDate',
            ]),
        },
        methods: {
            ...mapActions('data/results/sport', [
                'fetchSportResults',
            ]),
            async loadData() {
                this.isLoading = true;
                await errorGuard({
                    action: async () => {
                        const [startDate, endDate] = this.timeWindow;
                        await this.fetchSportResults({ startDate, endDate });
                    },
                });
                this.isLoading = false;
            },
        },
        components: {
            SportResultsGrid,
            SpinnerBars,
        }
    };
</script>

import { join as _join } from '@lodash';
import { CurrentOfferStatus } from '@core/services/results/_private/models/enums';
import DateUtils from '@utils/date/DateUtils';

export default class ResultsRequest {
    public controller?: string;
    public method?: string;
    public sportId?: number;
    public matchId?: string;
    public matchIds?: string;
    public competitionId?: number;
    public tournamentId?: number;
    public compression?: boolean = true;
    public preselected?: boolean;
    public startDate?: string;
    public endDate?: string;
    public all?: boolean;
    public oddsresults?: boolean;
    public offerState?: string;
    public currentStatus?: string;
    public live?: number;

    constructor(props: ResultsRequest) {
        Object.assign(this, props);
    }

    public toString() {
        return JSON.stringify(this);
    }

    // =============================
    // Rest requests
    // =============================

    static createMatchResultsRequest(startDate: Date, endDate: Date, sportId?: number): ResultsRequest {
        return new ResultsRequest({
            sportId,
            currentStatus: CurrentOfferStatus.finished,
            startDate: DateUtils.toString(DateUtils.inUtcTime(startDate)),
            endDate: DateUtils.toString(DateUtils.inUtcTime(endDate)),
        });
    }

    static createMatchResultDetailsRequest(matchIds: number[], oddsResults: boolean = true): ResultsRequest {
        return new ResultsRequest({
            matchIds: _join(matchIds, ','),
            oddsresults: oddsResults,
        });
    }

}

interface IFlag {
    SportId: number;
    Sport: string;
    CategoryId: number;
    Category: string;
    SubCategory: string;
    FlagCode: string;
}

const flagMap = {
    '37-390': {
        SportId: 37,
        Sport: 'World Lottery',
        CategoryId: 390,
        Category: '',
        SubCategory: 'USA',
        FlagCode: 'USA'
    },
    '5-31': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 31,
        Category: 'International Clubs',
        SubCategory: 'International Clubs',
        FlagCode: 'INTERNATIONAL+CLUBS'
    },
    '5-45': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 45,
        Category: 'England',
        SubCategory: 'England',
        FlagCode: 'ENGLAND'
    },
    '5-6': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 6,
        Category: 'England Amateur',
        SubCategory: 'England Amateur',
        FlagCode: 'ENGLAND AMATEUR'
    },
    '5-256': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 256,
        Category: 'Iceland',
        SubCategory: 'Iceland',
        FlagCode: 'ICELAND'
    },
    '5-257': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 257,
        Category: 'Ghana',
        SubCategory: 'Ghana',
        FlagCode: 'GHANA'
    },
    '5-258': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 258,
        Category: 'Faroe Islands',
        SubCategory: 'Faroe Islands',
        FlagCode: 'FAROE ISLANDS'
    },
    '5-259': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 259,
        Category: 'Estonia',
        SubCategory: 'Estonia',
        FlagCode: 'ESTONIA'
    },
    '5-260': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 260,
        Category: 'Denmark Amateur',
        SubCategory: 'Denmark Amateur',
        FlagCode: 'DENMARK AMATEUR'
    },
    '5-261': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 261,
        Category: 'China',
        SubCategory: 'China',
        FlagCode: 'CHINA'
    },
    '5-262': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 262,
        Category: 'Canada',
        SubCategory: 'Canada',
        FlagCode: 'CANADA'
    },
    '5-7': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 7,
        Category: 'Portugal',
        SubCategory: 'Portugal',
        FlagCode: 'PORTUGAL'
    },
    '5-263': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 263,
        Category: 'Cameroon',
        SubCategory: 'Cameroon',
        FlagCode: 'CAMEROON'
    },
    '5-264': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 264,
        Category: 'Bosnia & Herzegovina',
        SubCategory: 'Bosnia & Herzegovina',
        FlagCode: 'BOSNIA & HERZEGOVINA'
    },
    '5-265': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 265,
        Category: 'Belarus',
        SubCategory: 'Belarus',
        FlagCode: 'BELARUS'
    },
    '5-266': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 266,
        Category: 'Austria Amateur',
        SubCategory: 'Austria Amateur',
        FlagCode: 'AUSTRIA AMATEUR'
    },
    '5-267': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 267,
        Category: 'Armenia',
        SubCategory: 'Armenia',
        FlagCode: 'ARMENIA'
    },
    '5-16': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 16,
        Category: 'Albania',
        SubCategory: 'Albania',
        FlagCode: 'ALBANIA'
    },
    '5-17': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 17,
        Category: 'Malta',
        SubCategory: 'Malta',
        FlagCode: 'MALTA'
    },
    '5-18': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 18,
        Category: 'Germany Amateur',
        SubCategory: 'Germany Amateur',
        FlagCode: 'GERMANY AMATEUR'
    },
    '5-19': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 19,
        Category: 'Cyprus',
        SubCategory: 'Cyprus',
        FlagCode: 'CYPRUS'
    },
    '5-20': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 20,
        Category: 'Greece',
        SubCategory: 'Greece',
        FlagCode: 'GREECE'
    },
    '5-21': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 21,
        Category: 'Germany',
        SubCategory: 'Germany',
        FlagCode: 'GERMANY'
    },
    '5-26': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 26,
        Category: 'International Youth',
        SubCategory: 'International Youth',
        FlagCode: 'INTERNATIONAL+CLUBS'
    },
    '5-28': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 28,
        Category: 'United Arab Emirates',
        SubCategory: 'United Arab Emirates',
        FlagCode: 'UNITED ARAB EMIRATES'
    },
    '5-29': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 29,
        Category: 'Israel',
        SubCategory: 'Israel',
        FlagCode: 'ISRAEL'
    },
    '5-295': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 295,
        Category: 'Kenya',
        SubCategory: 'Kenya',
        FlagCode: 'KENYA'
    },
    '5-297': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 297,
        Category: 'Philippines',
        SubCategory: 'Philippines',
        FlagCode: 'PHILIPPINES'
    },
    '5-43': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 43,
        Category: 'Italy',
        SubCategory: 'Italy',
        FlagCode: 'ITALY'
    },
    '5-44': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 44,
        Category: 'Scotland',
        SubCategory: 'Scotland',
        FlagCode: 'SCOTLAND'
    },
    '5-47': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 47,
        Category: 'Saudi Arabia',
        SubCategory: 'Saudi Arabia',
        FlagCode: 'SAUDI ARABIA'
    },
    '5-303': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 303,
        Category: 'Panama',
        SubCategory: 'Panama',
        FlagCode: 'PANAMA'
    },
    '5-48': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 48,
        Category: 'Spain',
        SubCategory: 'Spain',
        FlagCode: 'SPAIN'
    },
    '5-50': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 50,
        Category: 'Egipt',
        SubCategory: 'Egipt',
        FlagCode: 'EGYPT'
    },
    '5-306': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 306,
        Category: 'Jamaica',
        SubCategory: 'Jamaica',
        FlagCode: 'JAMAICA'
    },
    '5-54': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 54,
        Category: 'South Africa',
        SubCategory: 'South Africa',
        FlagCode: 'SOUTH AFRICA'
    },
    '5-55': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 55,
        Category: 'Finland',
        SubCategory: 'Finland',
        FlagCode: 'FINLAND'
    },
    '5-60': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 60,
        Category: 'France',
        SubCategory: 'France',
        FlagCode: 'FRANCE'
    },
    '5-62': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 62,
        Category: 'Qatar',
        SubCategory: 'Qatar',
        FlagCode: 'QATAR'
    },
    '5-63': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 63,
        Category: 'Iran',
        SubCategory: 'Iran',
        FlagCode: 'IRAN'
    },
    '5-69': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 69,
        Category: 'Australia',
        SubCategory: 'Australia',
        FlagCode: 'AUSTRALIA'
    },
    '5-70': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 70,
        Category: 'Ecuador',
        SubCategory: 'Ecuador',
        FlagCode: 'ECUADOR'
    },
    '5-74': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 74,
        Category: 'Brazil',
        SubCategory: 'Brazil',
        FlagCode: 'BRAZIL'
    },
    '5-76': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 76,
        Category: 'Chile',
        SubCategory: 'Chile',
        FlagCode: 'CHILE'
    },
    '5-78': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 78,
        Category: 'Venezuela',
        SubCategory: 'Venezuela',
        FlagCode: 'VENEZUELA'
    },
    '5-79': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 79,
        Category: 'Mexico',
        SubCategory: 'Mexico',
        FlagCode: 'MEXICO'
    },
    '5-83': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 83,
        Category: 'Vietnam',
        SubCategory: 'Vietnam',
        FlagCode: 'VIETNAM'
    },
    '5-84': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 84,
        Category: 'Azerbaijan',
        SubCategory: 'Azerbaijan',
        FlagCode: 'AZERBAIJAN'
    },
    '5-85': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 85,
        Category: 'Costa Rica',
        SubCategory: 'Costa Rica',
        FlagCode: 'COSTA RICA'
    },
    '5-86': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 86,
        Category: 'Honduras',
        SubCategory: 'Honduras',
        FlagCode: 'HONDURAS'
    },
    '5-87': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 87,
        Category: 'Hong Kong',
        SubCategory: 'Hong Kong',
        FlagCode: 'HONG KONG'
    },
    '5-153': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 153,
        Category: 'Romania',
        SubCategory: 'Romania',
        FlagCode: 'ROMANIA'
    },
    '5-99': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 99,
        Category: 'Pakistan',
        SubCategory: 'Pakistan',
        FlagCode: 'PAKISTAN'
    },
    '5-100': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 100,
        Category: 'Guatemala',
        SubCategory: 'Guatemala',
        FlagCode: 'GUATEMALA'
    },
    '5-102': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 102,
        Category: 'International',
        SubCategory: 'International',
        FlagCode: 'INTERNATIONAL+CLUBS'
    },
    '5-111': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 111,
        Category: 'Turkey',
        SubCategory: 'Turkey',
        FlagCode: 'TURKEY'
    },
    '5-114': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 114,
        Category: 'Bolivia',
        SubCategory: 'Bolivia',
        FlagCode: 'BOLIVIA'
    },
    '5-116': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 116,
        Category: 'Senegal',
        SubCategory: 'Senegal',
        FlagCode: 'SENEGAL'
    },
    '5-127': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 127,
        Category: 'Netherlands',
        SubCategory: 'Netherlands',
        FlagCode: 'NETHERLANDS'
    },
    '5-135': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 135,
        Category: 'New Zealand',
        SubCategory: 'New Zealand',
        FlagCode: 'NEW ZEALAND'
    },
    '5-139': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 139,
        Category: 'Tunisia',
        SubCategory: 'Tunisia',
        FlagCode: 'TUNISIA'
    },
    '5-140': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 140,
        Category: 'Wales',
        SubCategory: 'Wales',
        FlagCode: 'WALES'
    },
    '5-143': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 143,
        Category: 'Czech Republic',
        SubCategory: 'Czech Republic',
        FlagCode: 'CZECH REPUBLIC'
    },
    '5-147': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 147,
        Category: 'Northern Ireland',
        SubCategory: 'Northern Ireland',
        FlagCode: 'NORTHERN IRELAND'
    },
    '5-149': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 149,
        Category: 'Belgium',
        SubCategory: 'Belgium',
        FlagCode: 'BELGIUM'
    },
    '5-159': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 159,
        Category: 'Croatia',
        SubCategory: 'Croatia',
        FlagCode: 'CROATIA'
    },
    '5-162': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 162,
        Category: 'Turkey Amateur',
        SubCategory: 'Turkey Amateur',
        FlagCode: 'TURKEY AMATEUR'
    },
    '5-165': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 165,
        Category: 'El Salvador',
        SubCategory: 'El Salvador',
        FlagCode: 'EL SALVADOR'
    },
    '5-166': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 166,
        Category: 'Colombia',
        SubCategory: 'Colombia',
        FlagCode: 'COLOMBIA'
    },
    '5-168': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 168,
        Category: 'Austria',
        SubCategory: 'Austria',
        FlagCode: 'AUSTRIA'
    },
    '5-175': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 175,
        Category: 'San Marino',
        SubCategory: 'San Marino',
        FlagCode: 'SAN MARINO'
    },
    '5-183': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 183,
        Category: 'Argentina',
        SubCategory: 'Argentina',
        FlagCode: 'ARGENTINA'
    },
    '5-184': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 184,
        Category: 'Switzerland',
        SubCategory: 'Switzerland',
        FlagCode: 'SWITZERLAND'
    },
    '5-186': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 186,
        Category: 'Macedonia',
        SubCategory: 'Macedonia',
        FlagCode: 'MACEDONIA'
    },
    '5-187': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 187,
        Category: 'Poland',
        SubCategory: 'Poland',
        FlagCode: 'POLAND'
    },
    '5-193': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 193,
        Category: 'Sweden',
        SubCategory: 'Sweden',
        FlagCode: 'SWEDEN'
    },
    '5-194': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 194,
        Category: 'Norway',
        SubCategory: 'Norway',
        FlagCode: 'NORWAY'
    },
    '5-195': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 195,
        Category: 'Bulgaria',
        SubCategory: 'Bulgaria',
        FlagCode: 'BULGARIA'
    },
    '5-196': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 196,
        Category: 'Hungary',
        SubCategory: 'Hungary',
        FlagCode: 'HUNGARY'
    },
    '5-197': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 197,
        Category: 'Slovenia',
        SubCategory: 'Slovenia',
        FlagCode: 'SLOVENIA'
    },
    '5-198': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 198,
        Category: 'Russia',
        SubCategory: 'Russia',
        FlagCode: 'RUSSIA'
    },
    '5-200': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 200,
        Category: 'Slovakia',
        SubCategory: 'Slovakia',
        FlagCode: 'SLOVAKIA'
    },
    '5-201': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 201,
        Category: 'Ukraine',
        SubCategory: 'Ukraine',
        FlagCode: 'UKRAINE'
    },
    '5-203': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 203,
        Category: 'Serbia',
        SubCategory: 'Serbia',
        FlagCode: 'SERBIA'
    },
    '5-204': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 204,
        Category: 'Denmark',
        SubCategory: 'Denmark',
        FlagCode: 'DENMARK'
    },
    '5-210': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 210,
        Category: 'Bahrain',
        SubCategory: 'Bahrain',
        FlagCode: 'BAHRAIN'
    },
    '5-211': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 211,
        Category: 'India',
        SubCategory: 'India',
        FlagCode: 'INDIA'
    },
    '5-212': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 212,
        Category: 'Indonesia',
        SubCategory: 'Indonesia',
        FlagCode: 'INDONESIA'
    },
    '5-213': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 213,
        Category: 'Latvia',
        SubCategory: 'Latvia',
        FlagCode: 'LATVIA'
    },
    '5-214': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 214,
        Category: 'Gibraltar',
        SubCategory: 'Gibraltar',
        FlagCode: 'GIBRALTAR'
    },
    '5-215': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 215,
        Category: 'Ivory Coast',
        SubCategory: 'Ivory Coast',
        FlagCode: 'IVORY COAST'
    },
    '5-216': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 216,
        Category: 'Kuwait',
        SubCategory: 'Kuwait',
        FlagCode: 'KUWAIT'
    },
    '5-218': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 218,
        Category: 'Andorra',
        SubCategory: 'Andorra',
        FlagCode: 'ANDORRA'
    },
    '5-220': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 220,
        Category: 'Paraguay',
        SubCategory: 'Paraguay',
        FlagCode: 'PARAGUAY'
    },
    '5-221': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 221,
        Category: 'Algeria',
        SubCategory: 'Algeria',
        FlagCode: 'ALGERIA'
    },
    '5-222': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 222,
        Category: 'Jordan',
        SubCategory: 'Jordan',
        FlagCode: 'JORDAN'
    },
    '5-224': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 224,
        Category: 'Malaysia',
        SubCategory: 'Malaysia',
        FlagCode: 'MALAYSIA'
    },
    '5-225': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 225,
        Category: 'Singapore',
        SubCategory: 'Singapore',
        FlagCode: 'SINGAPORE'
    },
    '5-227': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 227,
        Category: 'Ireland',
        SubCategory: 'Ireland',
        FlagCode: 'IRELAND'
    },
    '5-228': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 228,
        Category: 'Georgia',
        SubCategory: 'Georgia',
        FlagCode: 'GEORGIA'
    },
    '5-240': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 240,
        Category: 'Uzbekistan',
        SubCategory: 'Uzbekistan',
        FlagCode: 'UZBEKISTAN'
    },
    '5-241': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 241,
        Category: 'USA',
        SubCategory: 'USA',
        FlagCode: 'USA'
    },
    '5-242': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 242,
        Category: 'Uruguay',
        SubCategory: 'Uruguay',
        FlagCode: 'URUGUAY'
    },
    '5-243': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 243,
        Category: 'Thailand',
        SubCategory: 'Thailand',
        FlagCode: 'THAILAND'
    },
    '5-244': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 244,
        Category: 'Sweden Amateur',
        SubCategory: 'Sweden Amateur',
        FlagCode: 'SWEDEN AMATEUR'
    },
    '5-245': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 245,
        Category: 'South Korea',
        SubCategory: 'South Korea',
        FlagCode: 'SOUTH KOREA'
    },
    '5-246': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 246,
        Category: 'Peru',
        SubCategory: 'Peru',
        FlagCode: 'PERU'
    },
    '5-247': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 247,
        Category: 'Oman',
        SubCategory: 'Oman',
        FlagCode: 'OMAN'
    },
    '5-248': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 248,
        Category: 'Morocco',
        SubCategory: 'Morocco',
        FlagCode: 'MOROCCO'
    },
    '5-249': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 249,
        Category: 'Montenegro',
        SubCategory: 'Montenegro',
        FlagCode: 'MONTENEGRO'
    },
    '5-250': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 250,
        Category: 'Moldova',
        SubCategory: 'Moldova',
        FlagCode: 'MOLDOVA'
    },
    '5-251': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 251,
        Category: 'Luxembourg',
        SubCategory: 'Luxembourg',
        FlagCode: 'LUXEMBOURG'
    },
    '5-252': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 252,
        Category: 'Lithuania',
        SubCategory: 'Lithuania',
        FlagCode: 'LITHUANIA'
    },
    '5-253': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 253,
        Category: 'Liechtenstein',
        SubCategory: 'Liechtenstein',
        FlagCode: 'LIECHTENSTEIN'
    },
    '5-254': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 254,
        Category: 'Kazakhstan',
        SubCategory: 'Kazakhstan',
        FlagCode: 'KAZAKHSTAN'
    },
    '5-255': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 255,
        Category: 'Japan',
        SubCategory: 'Japan',
        FlagCode: 'JAPAN'
    },
    '5-342': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 342,
        Category: 'Uganda',
        SubCategory: 'Uganda',
        FlagCode: 'UGANDA'
    },
    '5-343': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 343,
        Category: 'Yemen',
        SubCategory: 'Yemen',
        FlagCode: 'YEMEN'
    },
    '5-348': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 348,
        Category: 'Rwanda',
        SubCategory: 'Rwanda',
        FlagCode: 'RWANDA'
    },
    '5-350': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 350,
        Category: '',
        SubCategory: 'Lebanon',
        FlagCode: 'LEBANON'
    },
    '5-355': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 355,
        Category: 'San Marino',
        SubCategory: 'San Marino',
        FlagCode: 'SAN MARINO'
    },
    '5-356': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 356,
        Category: '',
        SubCategory: 'Nigeria',
        FlagCode: 'NIGERIA'
    },
    '5-364': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 364,
        Category: '',
        SubCategory: 'Zambia',
        FlagCode: 'ZAMBIA'
    },
    '5-376': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 376,
        Category: '',
        SubCategory: 'Angola',
        FlagCode: 'ANGOLA'
    },
    '5-391': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 391,
        Category: '',
        SubCategory: 'Palestine',
        FlagCode: 'PALESTINA'
    },
    '5-396': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 396,
        Category: '',
        SubCategory: 'Tanzania',
        FlagCode: 'TANZANIA'
    },
    '5-440': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 440,
        Category: '',
        SubCategory: 'KOSOVO',
        FlagCode: 'KOSOVO'
    },
    '5-464': {
        SportId: 5,
        Sport: 'Soccer',
        CategoryId: 464,
        Category: 'Burundi',
        SubCategory: 'Burundi',
        FlagCode: 'BURUNDI'
    },
    '2-2': {
        SportId: 2,
        Sport: 'Tennis',
        CategoryId: 2,
        Category: 'WTA',
        SubCategory: 'WTA',
        FlagCode: 'WTA'
    },
    '2-3': {
        SportId: 2,
        Sport: 'Tennis',
        CategoryId: 3,
        Category: 'ATP',
        SubCategory: 'ATP',
        FlagCode: 'ATP'
    },
    '2-14': {
        SportId: 2,
        Sport: 'Tennis',
        CategoryId: 14,
        Category: 'Federation Cup',
        SubCategory: 'Federation Cup',
        FlagCode: 'FED'
    },
    '2-23': {
        SportId: 2,
        Sport: 'Tennis',
        CategoryId: 23,
        Category: 'ITF Men',
        SubCategory: 'ITF Men',
        FlagCode: 'ITF'
    },
    '2-37': {
        SportId: 2,
        Sport: 'Tennis',
        CategoryId: 37,
        Category: 'ITF Women',
        SubCategory: 'ITF Women',
        FlagCode: 'ITF'
    },
    '2-151': {
        SportId: 2,
        Sport: 'Tennis',
        CategoryId: 151,
        Category: 'Davis Cup',
        SubCategory: 'Davis Cup',
        FlagCode: 'DAVIS'
    },
    '2-205': {
        SportId: 2,
        Sport: 'Tennis',
        CategoryId: 205,
        Category: 'Challenge',
        SubCategory: 'Challenge',
        FlagCode: 'CHALLENGER'
    },
    '2-235': {
        SportId: 2,
        Sport: 'Tennis',
        CategoryId: 235,
        Category: 'Challenge Women',
        SubCategory: 'Challenge Women',
        FlagCode: 'CHALLENGER'
    },
    '4-307': {
        SportId: 4,
        Sport: 'Basketball',
        CategoryId: 307,
        Category: 'Bulgaria',
        SubCategory: 'Bulgaria',
        FlagCode: 'BULGARIA'
    },
    '4-310': {
        SportId: 4,
        Sport: 'Basketball',
        CategoryId: 310,
        Category: 'Portugal',
        SubCategory: 'Portugal',
        FlagCode: 'PORTUGAL'
    },
    '4-311': {
        SportId: 4,
        Sport: 'Basketball',
        CategoryId: 311,
        Category: '',
        SubCategory: 'Switzerland',
        FlagCode: 'SWITZERLAND'
    },
    '4-312': {
        SportId: 4,
        Sport: 'Basketball',
        CategoryId: 312,
        Category: '',
        SubCategory: 'Romania',
        FlagCode: 'ROMANIA'
    },
    '4-447': {
        SportId: 4,
        Sport: 'Basketball',
        CategoryId: 447,
        Category: '',
        SubCategory: 'Iceland',
        FlagCode: 'ICELAND'
    },
    '4-448': {
        SportId: 4,
        Sport: 'Basketball',
        CategoryId: 448,
        Category: '',
        SubCategory: 'Chile',
        FlagCode: 'CHILE'
    },
    '4-456': {
        SportId: 4,
        Sport: 'Basketball',
        CategoryId: 456,
        Category: '',
        SubCategory: 'Cyprus',
        FlagCode: 'CYPRUS'
    },
    '4-465': {
        SportId: 4,
        Sport: 'Basketball',
        CategoryId: 465,
        Category: '',
        SubCategory: 'Indonesia',
        FlagCode: 'INDONESIA'
    },
    '4-5': {
        SportId: 4,
        Sport: 'Basketball',
        CategoryId: 5,
        Category: 'Mexico',
        SubCategory: 'Mexico',
        FlagCode: 'MEXICO'
    },
    '4-25': {
        SportId: 4,
        Sport: 'Basketball',
        CategoryId: 25,
        Category: 'Poland',
        SubCategory: 'Poland',
        FlagCode: 'POLAND'
    },
    '4-33': {
        SportId: 4,
        Sport: 'Basketball',
        CategoryId: 33,
        Category: 'Slovenia',
        SubCategory: 'Slovenia',
        FlagCode: 'SLOVENIA'
    },
    '4-34': {
        SportId: 4,
        Sport: 'Basketball',
        CategoryId: 34,
        Category: 'Ukraine',
        SubCategory: 'Ukraine',
        FlagCode: 'UKRAINE'
    },
    '4-35': {
        SportId: 4,
        Sport: 'Basketball',
        CategoryId: 35,
        Category: 'France',
        SubCategory: 'France',
        FlagCode: 'FRANCE'
    },
    '4-36': {
        SportId: 4,
        Sport: 'Basketball',
        CategoryId: 36,
        Category: 'International',
        SubCategory: 'International',
        FlagCode: 'INTERNATIONAL+CLUBS'
    },
    '4-39': {
        SportId: 4,
        Sport: 'Basketball',
        CategoryId: 39,
        Category: 'South Korea',
        SubCategory: 'South Korea',
        FlagCode: 'SOUTH KOREA'
    },
    '4-40': {
        SportId: 4,
        Sport: 'Basketball',
        CategoryId: 40,
        Category: 'Australia',
        SubCategory: 'Australia',
        FlagCode: 'AUSTRALIA'
    },
    '4-46': {
        SportId: 4,
        Sport: 'Basketball',
        CategoryId: 46,
        Category: 'Denmark',
        SubCategory: 'Denmark',
        FlagCode: 'DENMARK'
    },
    '4-56': {
        SportId: 4,
        Sport: 'Basketball',
        CategoryId: 56,
        Category: 'Italy',
        SubCategory: 'Italy',
        FlagCode: 'ITALY'
    },
    '4-61': {
        SportId: 4,
        Sport: 'Basketball',
        CategoryId: 61,
        Category: 'USA',
        SubCategory: 'USA',
        FlagCode: 'USA'
    },
    '4-72': {
        SportId: 4,
        Sport: 'Basketball',
        CategoryId: 72,
        Category: 'Estonia',
        SubCategory: 'Estonia',
        FlagCode: 'ESTONIA'
    },
    '4-88': {
        SportId: 4,
        Sport: 'Basketball',
        CategoryId: 88,
        Category: 'China',
        SubCategory: 'China',
        FlagCode: 'CHINA'
    },
    '4-93': {
        SportId: 4,
        Sport: 'Basketball',
        CategoryId: 93,
        Category: 'Slovakia',
        SubCategory: 'Slovakia',
        FlagCode: 'SLOVAKIA'
    },
    '4-94': {
        SportId: 4,
        Sport: 'Basketball',
        CategoryId: 94,
        Category: 'Japan',
        SubCategory: 'Japan',
        FlagCode: 'JAPAN'
    },
    '4-101': {
        SportId: 4,
        Sport: 'Basketball',
        CategoryId: 101,
        Category: 'Austria',
        SubCategory: 'Austria',
        FlagCode: 'AUSTRIA'
    },
    '4-103': {
        SportId: 4,
        Sport: 'Basketball',
        CategoryId: 103,
        Category: 'Sweden',
        SubCategory: 'Sweden',
        FlagCode: 'SWEDEN'
    },
    '4-107': {
        SportId: 4,
        Sport: 'Basketball',
        CategoryId: 107,
        Category: 'Brazil',
        SubCategory: 'Brazil',
        FlagCode: 'BRAZIL'
    },
    '4-108': {
        SportId: 4,
        Sport: 'Basketball',
        CategoryId: 108,
        Category: 'Spain',
        SubCategory: 'Spain',
        FlagCode: 'SPAIN'
    },
    '4-110': {
        SportId: 4,
        Sport: 'Basketball',
        CategoryId: 110,
        Category: 'Argentina',
        SubCategory: 'Argentina',
        FlagCode: 'ARGENTINA'
    },
    '4-117': {
        SportId: 4,
        Sport: 'Basketball',
        CategoryId: 117,
        Category: 'Belgium',
        SubCategory: 'Belgium',
        FlagCode: 'BELGIUM'
    },
    '4-118': {
        SportId: 4,
        Sport: 'Basketball',
        CategoryId: 118,
        Category: 'Czech Republic',
        SubCategory: 'Czech Republic',
        FlagCode: 'CZECH REPUBLIC'
    },
    '4-124': {
        SportId: 4,
        Sport: 'Basketball',
        CategoryId: 124,
        Category: 'Latvia',
        SubCategory: 'Latvia',
        FlagCode: 'LATVIA'
    },
    '4-125': {
        SportId: 4,
        Sport: 'Basketball',
        CategoryId: 125,
        Category: 'England',
        SubCategory: 'England',
        FlagCode: 'ENGLAND'
    },
    '4-128': {
        SportId: 4,
        Sport: 'Basketball',
        CategoryId: 128,
        Category: 'Finland',
        SubCategory: 'Finland',
        FlagCode: 'FINLAND'
    },
    '4-129': {
        SportId: 4,
        Sport: 'Basketball',
        CategoryId: 129,
        Category: 'Turkey',
        SubCategory: 'Turkey',
        FlagCode: 'TURKEY'
    },
    '4-136': {
        SportId: 4,
        Sport: 'Basketball',
        CategoryId: 136,
        Category: 'Russia',
        SubCategory: 'Russia',
        FlagCode: 'RUSSIA'
    },
    '4-137': {
        SportId: 4,
        Sport: 'Basketball',
        CategoryId: 137,
        Category: 'Lithuania',
        SubCategory: 'Lithuania',
        FlagCode: 'LITHUANIA'
    },
    '4-145': {
        SportId: 4,
        Sport: 'Basketball',
        CategoryId: 145,
        Category: 'Greece',
        SubCategory: 'Greece',
        FlagCode: 'GREECE'
    },
    '4-155': {
        SportId: 4,
        Sport: 'Basketball',
        CategoryId: 155,
        Category: 'Croatia',
        SubCategory: 'Croatia',
        FlagCode: 'CROATIA'
    },
    '4-157': {
        SportId: 4,
        Sport: 'Basketball',
        CategoryId: 157,
        Category: 'Germany',
        SubCategory: 'Germany',
        FlagCode: 'GERMANY'
    },
    '4-158': {
        SportId: 4,
        Sport: 'Basketball',
        CategoryId: 158,
        Category: 'Netherlands',
        SubCategory: 'Netherlands',
        FlagCode: 'NETHERLANDS'
    },
    '4-174': {
        SportId: 4,
        Sport: 'Basketball',
        CategoryId: 174,
        Category: 'Serbia',
        SubCategory: 'Serbia',
        FlagCode: 'SERBIA'
    },
    '4-185': {
        SportId: 4,
        Sport: 'Basketball',
        CategoryId: 185,
        Category: 'Israel',
        SubCategory: 'Israel',
        FlagCode: 'ISRAEL'
    },
    '4-298': {
        SportId: 4,
        Sport: 'Basketball',
        CategoryId: 298,
        Category: 'Philippines',
        SubCategory: 'Philippines',
        FlagCode: 'PHILIPPINES'
    },
    '11-443': {
        SportId: 11,
        Sport: 'Handball',
        CategoryId: 443,
        Category: '',
        SubCategory: 'Iceland',
        FlagCode: 'ICELAND'
    },
    '11-461': {
        SportId: 11,
        Sport: 'Handball',
        CategoryId: 461,
        Category: '',
        SubCategory: 'Estonia',
        FlagCode: 'ESTONIA'
    },
    '11-32': {
        SportId: 11,
        Sport: 'Handball',
        CategoryId: 32,
        Category: 'Denmark',
        SubCategory: 'Denmark',
        FlagCode: 'DENMARK'
    },
    '11-49': {
        SportId: 11,
        Sport: 'Handball',
        CategoryId: 49,
        Category: 'Romania',
        SubCategory: 'Romania',
        FlagCode: 'ROMANIA'
    },
    '11-52': {
        SportId: 11,
        Sport: 'Handball',
        CategoryId: 52,
        Category: 'Finland',
        SubCategory: 'Finland',
        FlagCode: 'FINLAND'
    },
    '11-53': {
        SportId: 11,
        Sport: 'Handball',
        CategoryId: 53,
        Category: 'Sweden',
        SubCategory: 'Sweden',
        FlagCode: 'SWEDEN'
    },
    '11-77': {
        SportId: 11,
        Sport: 'Handball',
        CategoryId: 77,
        Category: 'International',
        SubCategory: 'International',
        FlagCode: 'INTERNATIONAL+CLUBS'
    },
    '11-115': {
        SportId: 11,
        Sport: 'Handball',
        CategoryId: 115,
        Category: 'Czech Republic',
        SubCategory: 'Czech Republic',
        FlagCode: 'CZECH REPUBLIC'
    },
    '11-120': {
        SportId: 11,
        Sport: 'Handball',
        CategoryId: 120,
        Category: 'Germany',
        SubCategory: 'Germany',
        FlagCode: 'GERMANY'
    },
    '11-121': {
        SportId: 11,
        Sport: 'Handball',
        CategoryId: 121,
        Category: 'Poland',
        SubCategory: 'Poland',
        FlagCode: 'POLAND'
    },
    '11-123': {
        SportId: 11,
        Sport: 'Handball',
        CategoryId: 123,
        Category: 'Norway',
        SubCategory: 'Norway',
        FlagCode: 'NORWAY'
    },
    '11-141': {
        SportId: 11,
        Sport: 'Handball',
        CategoryId: 141,
        Category: 'Russia',
        SubCategory: 'Russia',
        FlagCode: 'RUSSIA'
    },
    '11-142': {
        SportId: 11,
        Sport: 'Handball',
        CategoryId: 142,
        Category: 'Israel',
        SubCategory: 'Israel',
        FlagCode: 'ISRAEL'
    },
    '11-144': {
        SportId: 11,
        Sport: 'Handball',
        CategoryId: 144,
        Category: 'Austria',
        SubCategory: 'Austria',
        FlagCode: 'AUSTRIA'
    },
    '11-173': {
        SportId: 11,
        Sport: 'Handball',
        CategoryId: 173,
        Category: 'Greece',
        SubCategory: 'Greece',
        FlagCode: 'GREECE'
    },
    '11-176': {
        SportId: 11,
        Sport: 'Handball',
        CategoryId: 176,
        Category: 'Slovakia',
        SubCategory: 'Slovakia',
        FlagCode: 'SLOVAKIA'
    },
    '11-177': {
        SportId: 11,
        Sport: 'Handball',
        CategoryId: 177,
        Category: 'Hungary',
        SubCategory: 'Hungary',
        FlagCode: 'HUNGARY'
    },
    '11-178': {
        SportId: 11,
        Sport: 'Handball',
        CategoryId: 178,
        Category: 'Croatia',
        SubCategory: 'Croatia',
        FlagCode: 'CROATIA'
    },
    '11-179': {
        SportId: 11,
        Sport: 'Handball',
        CategoryId: 179,
        Category: 'Spain',
        SubCategory: 'Spain',
        FlagCode: 'SPAIN'
    },
    '11-180': {
        SportId: 11,
        Sport: 'Handball',
        CategoryId: 180,
        Category: 'Slovenia',
        SubCategory: 'Slovenia',
        FlagCode: 'SLOVENIA'
    },
    '11-181': {
        SportId: 11,
        Sport: 'Handball',
        CategoryId: 181,
        Category: 'France',
        SubCategory: 'France',
        FlagCode: 'FRANCE'
    },
    '11-188': {
        SportId: 11,
        Sport: 'Handball',
        CategoryId: 188,
        Category: 'Switzerland',
        SubCategory: 'Switzerland',
        FlagCode: 'SWITZERLAND'
    },
    '11-208': {
        SportId: 11,
        Sport: 'Handball',
        CategoryId: 208,
        Category: 'Portugal',
        SubCategory: 'Portugal',
        FlagCode: 'PORTUGAL'
    },
    '11-223': {
        SportId: 11,
        Sport: 'Handball',
        CategoryId: 223,
        Category: 'Serbia',
        SubCategory: 'Serbia',
        FlagCode: 'SERBIA'
    },
    '11-269': {
        SportId: 11,
        Sport: 'Handball',
        CategoryId: 269,
        Category: 'International Youth',
        SubCategory: 'International Youth',
        FlagCode: 'INTERNATIONAL+CLUBS'
    },
    '3-4': {
        SportId: 3,
        Sport: 'Ice Hockey',
        CategoryId: 4,
        Category: 'Sweden',
        SubCategory: 'Sweden',
        FlagCode: 'SWEDEN'
    },
    '3-15': {
        SportId: 3,
        Sport: 'Ice Hockey',
        CategoryId: 15,
        Category: 'International',
        SubCategory: 'International',
        FlagCode: 'INTERNATIONAL+CLUBS'
    },
    '3-24': {
        SportId: 3,
        Sport: 'Ice Hockey',
        CategoryId: 24,
        Category: 'Slovakia',
        SubCategory: 'Slovakia',
        FlagCode: 'SLOVAKIA'
    },
    '3-27': {
        SportId: 3,
        Sport: 'Ice Hockey',
        CategoryId: 27,
        Category: 'Germany',
        SubCategory: 'Germany',
        FlagCode: 'GERMANY'
    },
    '3-30': {
        SportId: 3,
        Sport: 'Ice Hockey',
        CategoryId: 30,
        Category: 'Russia',
        SubCategory: 'Russia',
        FlagCode: 'RUSSIA'
    },
    '3-38': {
        SportId: 3,
        Sport: 'Ice Hockey',
        CategoryId: 38,
        Category: 'Finland',
        SubCategory: 'Finland',
        FlagCode: 'FINLAND'
    },
    '3-41': {
        SportId: 3,
        Sport: 'Ice Hockey',
        CategoryId: 41,
        Category: 'Norway',
        SubCategory: 'Norway',
        FlagCode: 'NORWAY'
    },
    '3-59': {
        SportId: 3,
        Sport: 'Ice Hockey',
        CategoryId: 59,
        Category: 'Denmark',
        SubCategory: 'Denmark',
        FlagCode: 'DENMARK'
    },
    '3-64': {
        SportId: 3,
        Sport: 'Ice Hockey',
        CategoryId: 64,
        Category: 'Belarus',
        SubCategory: 'Belarus',
        FlagCode: 'BELARUS'
    },
    '3-65': {
        SportId: 3,
        Sport: 'Ice Hockey',
        CategoryId: 65,
        Category: 'Czech Republic',
        SubCategory: 'Czech Republic',
        FlagCode: 'CZECH REPUBLIC'
    },
    '3-68': {
        SportId: 3,
        Sport: 'Ice Hockey',
        CategoryId: 68,
        Category: 'Austria',
        SubCategory: 'Austria',
        FlagCode: 'AUSTRIA'
    },
    '3-75': {
        SportId: 3,
        Sport: 'Ice Hockey',
        CategoryId: 75,
        Category: 'Switzerland',
        SubCategory: 'Switzerland',
        FlagCode: 'SWITZERLAND'
    },
    '3-90': {
        SportId: 3,
        Sport: 'Ice Hockey',
        CategoryId: 90,
        Category: 'Canada',
        SubCategory: 'Canada',
        FlagCode: 'CANADA'
    },
    '3-92': {
        SportId: 3,
        Sport: 'Ice Hockey',
        CategoryId: 92,
        Category: 'USA',
        SubCategory: 'USA',
        FlagCode: 'USA'
    },
    '3-97': {
        SportId: 3,
        Sport: 'Ice Hockey',
        CategoryId: 97,
        Category: 'Italy',
        SubCategory: 'Italy',
        FlagCode: 'ITALY'
    },
    '3-104': {
        SportId: 3,
        Sport: 'Ice Hockey',
        CategoryId: 104,
        Category: 'France',
        SubCategory: 'France',
        FlagCode: 'FRANCE'
    },
    '3-126': {
        SportId: 3,
        Sport: 'Ice Hockey',
        CategoryId: 126,
        Category: 'England',
        SubCategory: 'England',
        FlagCode: 'ENGLAND'
    },
    '3-154': {
        SportId: 3,
        Sport: 'Ice Hockey',
        CategoryId: 154,
        Category: 'Poland',
        SubCategory: 'Poland',
        FlagCode: 'POLAND'
    },
    '1-308': {
        SportId: 1,
        Sport: 'Volleyball',
        CategoryId: 308,
        Category: 'Belgium',
        SubCategory: 'Belgium',
        FlagCode: 'BELGIUM'
    },
    '1-309': {
        SportId: 1,
        Sport: 'Volleyball',
        CategoryId: 309,
        Category: 'Slovakia',
        SubCategory: 'Slovakia',
        FlagCode: 'SLOVAKIA'
    },
    '1-313': {
        SportId: 1,
        Sport: 'Volleyball',
        CategoryId: 313,
        Category: '',
        SubCategory: 'Bulgaria',
        FlagCode: 'BULGARIA'
    },
    '1-365': {
        SportId: 1,
        Sport: 'Volleyball',
        CategoryId: 365,
        Category: '',
        SubCategory: 'South Korea',
        FlagCode: 'SOUTH KOREA'
    },
    '1-450': {
        SportId: 1,
        Sport: 'Volleyball',
        CategoryId: 450,
        Category: '',
        SubCategory: 'Switzerland',
        FlagCode: 'SWITZERLAND'
    },
    '1-452': {
        SportId: 1,
        Sport: 'Volleyball',
        CategoryId: 452,
        Category: '',
        SubCategory: 'Japan',
        FlagCode: 'JAPAN'
    },
    '1-454': {
        SportId: 1,
        Sport: 'Volleyball',
        CategoryId: 454,
        Category: '',
        SubCategory: 'China',
        FlagCode: 'CHINA'
    },
    '1-455': {
        SportId: 1,
        Sport: 'Volleyball',
        CategoryId: 455,
        Category: '',
        SubCategory: 'Argentina',
        FlagCode: 'ARGENTINA'
    },
    '1-1': {
        SportId: 1,
        Sport: 'Volleyball',
        CategoryId: 1,
        Category: 'Russia',
        SubCategory: 'Russia',
        FlagCode: 'RUSSIA'
    },
    '1-8': {
        SportId: 1,
        Sport: 'Volleyball',
        CategoryId: 8,
        Category: 'Greece',
        SubCategory: 'Greece',
        FlagCode: 'GREECE'
    },
    '1-42': {
        SportId: 1,
        Sport: 'Volleyball',
        CategoryId: 42,
        Category: 'Italy',
        SubCategory: 'Italy',
        FlagCode: 'ITALY'
    },
    '1-66': {
        SportId: 1,
        Sport: 'Volleyball',
        CategoryId: 66,
        Category: 'Turkey',
        SubCategory: 'Turkey',
        FlagCode: 'TURKEY'
    },
    '1-67': {
        SportId: 1,
        Sport: 'Volleyball',
        CategoryId: 67,
        Category: 'Poland',
        SubCategory: 'Poland',
        FlagCode: 'POLAND'
    },
    '1-81': {
        SportId: 1,
        Sport: 'Volleyball',
        CategoryId: 81,
        Category: 'Denmark',
        SubCategory: 'Denmark',
        FlagCode: 'DENMARK'
    },
    '1-89': {
        SportId: 1,
        Sport: 'Volleyball',
        CategoryId: 89,
        Category: 'Czech Republic',
        SubCategory: 'Czech Republic',
        FlagCode: 'CZECH REPUBLIC'
    },
    '1-91': {
        SportId: 1,
        Sport: 'Volleyball',
        CategoryId: 91,
        Category: 'Serbia',
        SubCategory: 'Serbia',
        FlagCode: 'SERBIA'
    },
    '1-95': {
        SportId: 1,
        Sport: 'Volleyball',
        CategoryId: 95,
        Category: 'Austria',
        SubCategory: 'Austria',
        FlagCode: 'AUSTRIA'
    },
    '1-106': {
        SportId: 1,
        Sport: 'Volleyball',
        CategoryId: 106,
        Category: 'France',
        SubCategory: 'France',
        FlagCode: 'FRANCE'
    },
    '1-109': {
        SportId: 1,
        Sport: 'Volleyball',
        CategoryId: 109,
        Category: 'Croatia',
        SubCategory: 'Croatia',
        FlagCode: 'CROATIA'
    },
    '1-112': {
        SportId: 1,
        Sport: 'Volleyball',
        CategoryId: 112,
        Category: 'Brazil',
        SubCategory: 'Brazil',
        FlagCode: 'BRAZIL'
    },
    '1-119': {
        SportId: 1,
        Sport: 'Volleyball',
        CategoryId: 119,
        Category: 'Germany',
        SubCategory: 'Germany',
        FlagCode: 'GERMANY'
    },
    '1-133': {
        SportId: 1,
        Sport: 'Volleyball',
        CategoryId: 133,
        Category: 'Sweden',
        SubCategory: 'Sweden',
        FlagCode: 'SWEDEN'
    },
    '1-138': {
        SportId: 1,
        Sport: 'Volleyball',
        CategoryId: 138,
        Category: 'Finland',
        SubCategory: 'Finland',
        FlagCode: 'FINLAND'
    },
    '1-156': {
        SportId: 1,
        Sport: 'Volleyball',
        CategoryId: 156,
        Category: 'Portugal',
        SubCategory: 'Portugal',
        FlagCode: 'PORTUGAL'
    },
    '1-163': {
        SportId: 1,
        Sport: 'Volleyball',
        CategoryId: 163,
        Category: 'International',
        SubCategory: 'International',
        FlagCode: 'INTERNATIONAL+CLUBS'
    },
    '1-172': {
        SportId: 1,
        Sport: 'Volleyball',
        CategoryId: 172,
        Category: 'Spain',
        SubCategory: 'Spain',
        FlagCode: 'SPAIN'
    },
    '6-9': {
        SportId: 6,
        Sport: 'Snooker',
        CategoryId: 9,
        Category: 'International',
        SubCategory: 'International',
        FlagCode: 'INTERNATIONAL+CLUBS'
    },
    '20-326': {
        SportId: 20,
        Sport: 'Baseball',
        CategoryId: 326,
        Category: 'Coreea de Sud',
        SubCategory: 'Coreea de Sud',
        FlagCode: 'SOUTH KOREA'
    },
    '20-329': {
        SportId: 20,
        Sport: 'Baseball',
        CategoryId: 329,
        Category: 'Australia',
        SubCategory: 'Australia',
        FlagCode: 'AUSTRALIA'
    },
    '20-409': {
        SportId: 20,
        Sport: 'Baseball',
        CategoryId: 409,
        Category: '',
        SubCategory: 'South Korea',
        FlagCode: 'SOUTH KOREA'
    },
    '20-457': {
        SportId: 20,
        Sport: 'Baseball',
        CategoryId: 457,
        Category: '',
        SubCategory: 'Australia',
        FlagCode: 'AUSTRALIA'
    },
    '20-202': {
        SportId: 20,
        Sport: 'Baseball',
        CategoryId: 202,
        Category: 'USA',
        SubCategory: 'USA',
        FlagCode: 'USA'
    },
    '20-275': {
        SportId: 20,
        Sport: 'Baseball',
        CategoryId: 275,
        Category: 'Mexico',
        SubCategory: 'Mexico',
        FlagCode: 'MEXICO'
    },
    '20-276': {
        SportId: 20,
        Sport: 'Baseball',
        CategoryId: 276,
        Category: 'Japan',
        SubCategory: 'Japan',
        FlagCode: 'JAPAN'
    },
    '20-277': {
        SportId: 20,
        Sport: 'Baseball',
        CategoryId: 277,
        Category: 'Italy',
        SubCategory: 'Italy',
        FlagCode: 'ITALY'
    },
    '20-278': {
        SportId: 20,
        Sport: 'Baseball',
        CategoryId: 278,
        Category: 'International',
        SubCategory: 'International',
        FlagCode: 'INTERNATIONAL+CLUBS'
    },
    '20-279': {
        SportId: 20,
        Sport: 'Baseball',
        CategoryId: 279,
        Category: 'Germany',
        SubCategory: 'Germany',
        FlagCode: 'GERMANY'
    },
    '15-344': {
        SportId: 15,
        Sport: 'Waterpolo',
        CategoryId: 344,
        Category: 'Croatia',
        SubCategory: 'Croatia',
        FlagCode: 'CROATIA'
    },
    '15-113': {
        SportId: 15,
        Sport: 'Waterpolo',
        CategoryId: 113,
        Category: 'International',
        SubCategory: 'International',
        FlagCode: 'INTERNATIONAL+CLUBS'
    },
    '15-164': {
        SportId: 15,
        Sport: 'Waterpolo',
        CategoryId: 164,
        Category: 'Hungary',
        SubCategory: 'Hungary',
        FlagCode: 'HUNGARY'
    },
    '15-171': {
        SportId: 15,
        Sport: 'Waterpolo',
        CategoryId: 171,
        Category: 'Italy',
        SubCategory: 'Italy',
        FlagCode: 'ITALY'
    },
    '24-442': {
        SportId: 24,
        Sport: 'Table tennis',
        CategoryId: 442,
        Category: '',
        SubCategory: 'France',
        FlagCode: 'FRANCE'
    },
    '24-236': {
        SportId: 24,
        Sport: 'Table tennis',
        CategoryId: 236,
        Category: 'International',
        SubCategory: 'International',
        FlagCode: 'INTERNATIONAL+CLUBS'
    },
    '24-305': {
        SportId: 24,
        Sport: 'Table tennis',
        CategoryId: 305,
        Category: 'Germany',
        SubCategory: 'Germany',
        FlagCode: 'GERMANY'
    },
    '12-57': {
        SportId: 12,
        Sport: 'American Football',
        CategoryId: 57,
        Category: 'USA',
        SubCategory: 'USA',
        FlagCode: 'USA'
    },
    '12-199': {
        SportId: 12,
        Sport: 'American Football',
        CategoryId: 199,
        Category: 'Canada',
        SubCategory: 'Canada',
        FlagCode: 'CANADA'
    },
    '12-301': {
        SportId: 12,
        Sport: 'American Football',
        CategoryId: 301,
        Category: 'International',
        SubCategory: 'International',
        FlagCode: 'INTERNATIONAL+CLUBS'
    },
    '13-58': {
        SportId: 13,
        Sport: 'Darts',
        CategoryId: 58,
        Category: 'International',
        SubCategory: 'International',
        FlagCode: 'INTERNATIONAL+CLUBS'
    },
    '13-207': {
        SportId: 13,
        Sport: 'Darts',
        CategoryId: 207,
        Category: 'England',
        SubCategory: 'England',
        FlagCode: 'ENGLAND'
    },
    '17-327': {
        SportId: 17,
        Sport: 'Futsal',
        CategoryId: 327,
        Category: 'Romania',
        SubCategory: 'Romania',
        FlagCode: 'ROMANIA'
    },
    '17-334': {
        SportId: 17,
        Sport: 'Futsal',
        CategoryId: 334,
        Category: 'Croatia',
        SubCategory: 'Croatia',
        FlagCode: 'CROATIA'
    },
    '17-476': {
        SportId: 17,
        Sport: 'Futsal',
        CategoryId: 476,
        Category: '',
        SubCategory: 'Poland',
        FlagCode: 'POLAND'
    },
    '17-131': {
        SportId: 17,
        Sport: 'Futsal',
        CategoryId: 131,
        Category: 'Czech Republic',
        SubCategory: 'Czech Republic',
        FlagCode: 'CZECH REPUBLIC'
    },
    '17-132': {
        SportId: 17,
        Sport: 'Futsal',
        CategoryId: 132,
        Category: 'Portugal',
        SubCategory: 'Portugal',
        FlagCode: 'PORTUGAL'
    },
    '17-146': {
        SportId: 17,
        Sport: 'Futsal',
        CategoryId: 146,
        Category: 'Russia',
        SubCategory: 'Russia',
        FlagCode: 'RUSSIA'
    },
    '17-150': {
        SportId: 17,
        Sport: 'Futsal',
        CategoryId: 150,
        Category: 'Italy',
        SubCategory: 'Italy',
        FlagCode: 'ITALY'
    },
    '17-161': {
        SportId: 17,
        Sport: 'Futsal',
        CategoryId: 161,
        Category: 'Spain',
        SubCategory: 'Spain',
        FlagCode: 'SPAIN'
    },
    '17-170': {
        SportId: 17,
        Sport: 'Futsal',
        CategoryId: 170,
        Category: 'International',
        SubCategory: 'International',
        FlagCode: 'INTERNATIONAL+CLUBS'
    },
    '17-182': {
        SportId: 17,
        Sport: 'Futsal',
        CategoryId: 182,
        Category: 'Japan',
        SubCategory: 'Japan',
        FlagCode: 'JAPAN'
    },
    '17-270': {
        SportId: 17,
        Sport: 'Futsal',
        CategoryId: 270,
        Category: 'International Clubs',
        SubCategory: 'International Clubs',
        FlagCode: 'INTERNATIONAL+CLUBS'
    },
    '17-271': {
        SportId: 17,
        Sport: 'Futsal',
        CategoryId: 271,
        Category: 'Brazil',
        SubCategory: 'Brazil',
        FlagCode: 'BRAZIL'
    },
    '29-300': {
        SportId: 29,
        Sport: 'Field hockey',
        CategoryId: 300,
        Category: 'International',
        SubCategory: 'International',
        FlagCode: 'INTERNATIONAL+CLUBS'
    },
    '28-299': {
        SportId: 28,
        Sport: 'Beach Volley',
        CategoryId: 299,
        Category: 'International',
        SubCategory: 'International',
        FlagCode: 'INTERNATIONAL+CLUBS'
    },
    '32-321': {
        SportId: 32,
        Sport: 'Cricket',
        CategoryId: 321,
        Category: '',
        SubCategory: 'NewZealand',
        FlagCode: 'NEW ZEALAND'
    },
    '32-322': {
        SportId: 32,
        Sport: 'Cricket',
        CategoryId: 322,
        Category: '',
        SubCategory: 'South Africa',
        FlagCode: 'SOUTH AFRICA'
    },
    '32-335': {
        SportId: 32,
        Sport: 'Cricket',
        CategoryId: 335,
        Category: '',
        SubCategory: 'Australia',
        FlagCode: 'AUSTRALIA'
    },
    '32-362': {
        SportId: 32,
        Sport: 'Cricket',
        CategoryId: 362,
        Category: '',
        SubCategory: 'India',
        FlagCode: 'INDIA'
    },
    '32-366': {
        SportId: 32,
        Sport: 'Cricket',
        CategoryId: 366,
        Category: '',
        SubCategory: 'England',
        FlagCode: 'ENGLAND'
    },
    '32-404': {
        SportId: 32,
        Sport: 'Cricket',
        CategoryId: 404,
        Category: 'Pakistan',
        SubCategory: '',
        FlagCode: 'PAKISTAN'
    },
    '32-453': {
        SportId: 32,
        Sport: 'Cricket',
        CategoryId: 453,
        Category: '',
        SubCategory: 'Bangladesh',
        FlagCode: 'BANGLADESH'
    },
    '32-470': {
        SportId: 32,
        Sport: 'Cricket',
        CategoryId: 470,
        Category: 'Pakistan',
        SubCategory: 'Pakistan',
        FlagCode: 'PAKISTAN'
    },
    '32-472': {
        SportId: 32,
        Sport: 'Cricket',
        CategoryId: 472,
        Category: 'Hong Kong',
        SubCategory: 'Hong Kong',
        FlagCode: 'HONG KONG'
    },
    '32-304': {
        SportId: 32,
        Sport: 'Cricket',
        CategoryId: 304,
        Category: 'International',
        SubCategory: 'International',
        FlagCode: 'INTERNATIONAL+CLUBS'
    },
    '19-337': {
        SportId: 19,
        Sport: 'Aussie rules',
        CategoryId: 337,
        Category: 'International',
        SubCategory: 'International',
        FlagCode: 'INTERNATIONAL+CLUBS'
    },
    '19-192': {
        SportId: 19,
        Sport: 'Aussie rules',
        CategoryId: 192,
        Category: 'Australia',
        SubCategory: 'Australia',
        FlagCode: 'AUSTRALIA'
    },
    '38-393': {
        SportId: 38,
        Sport: 'Squash',
        CategoryId: 393,
        Category: '',
        SubCategory: 'International',
        FlagCode: 'INTERNATIONAL+CLUBS'
    },
    '7-10': {
        SportId: 7,
        Sport: 'Bandy',
        CategoryId: 10,
        Category: 'Finland',
        SubCategory: 'Finland',
        FlagCode: 'FINLAND'
    },
    '7-11': {
        SportId: 7,
        Sport: 'Bandy',
        CategoryId: 11,
        Category: 'Sweden',
        SubCategory: 'Sweden',
        FlagCode: 'SWEDEN'
    },
    '7-82': {
        SportId: 7,
        Sport: 'Bandy',
        CategoryId: 82,
        Category: 'Russia',
        SubCategory: 'Russia',
        FlagCode: 'RUSSIA'
    },
    '7-98': {
        SportId: 7,
        Sport: 'Bandy',
        CategoryId: 98,
        Category: 'Norway',
        SubCategory: 'Norway',
        FlagCode: 'NORWAY'
    },
    '7-169': {
        SportId: 7,
        Sport: 'Bandy',
        CategoryId: 169,
        Category: 'International',
        SubCategory: 'International',
        FlagCode: 'INTERNATIONAL+CLUBS'
    },
    '27-274': {
        SportId: 27,
        Sport: 'Beach Soccer',
        CategoryId: 274,
        Category: 'International',
        SubCategory: 'International',
        FlagCode: 'INTERNATIONAL+CLUBS'
    },
    '9-478': {
        SportId: 9,
        Sport: 'Floorball',
        CategoryId: 478,
        Category: '',
        SubCategory: 'Poland - Ekstraklasa',
        FlagCode: 'POLAND'
    },
    '9-13': {
        SportId: 9,
        Sport: 'Floorball',
        CategoryId: 13,
        Category: 'Sweden',
        SubCategory: 'Sweden',
        FlagCode: 'SWEDEN'
    },
    '9-71': {
        SportId: 9,
        Sport: 'Floorball',
        CategoryId: 71,
        Category: 'Finland',
        SubCategory: 'Finland',
        FlagCode: 'FINLAND'
    },
    '9-105': {
        SportId: 9,
        Sport: 'Floorball',
        CategoryId: 105,
        Category: 'Switzerland',
        SubCategory: 'Switzerland',
        FlagCode: 'SWITZERLAND'
    },
    '9-134': {
        SportId: 9,
        Sport: 'Floorball',
        CategoryId: 134,
        Category: 'Denmark',
        SubCategory: 'Denmark',
        FlagCode: 'DENMARK'
    },
    '9-160': {
        SportId: 9,
        Sport: 'Floorball',
        CategoryId: 160,
        Category: 'Czech Republic',
        SubCategory: 'Czech Republic',
        FlagCode: 'CZECH REPUBLIC'
    },
    '9-272': {
        SportId: 9,
        Sport: 'Floorball',
        CategoryId: 272,
        Category: 'Norway',
        SubCategory: 'Norway',
        FlagCode: 'NORWAY'
    },
    '9-273': {
        SportId: 9,
        Sport: 'Floorball',
        CategoryId: 273,
        Category: 'International',
        SubCategory: 'International',
        FlagCode: 'INTERNATIONAL+CLUBS'
    },
    '14-73': {
        SportId: 14,
        Sport: 'Badminton',
        CategoryId: 73,
        Category: 'International',
        SubCategory: 'International',
        FlagCode: 'INTERNATIONAL+CLUBS'
    },
    '22-217': {
        SportId: 22,
        Sport: 'Curling',
        CategoryId: 217,
        Category: 'International',
        SubCategory: 'International',
        FlagCode: 'INTERNATIONAL+CLUBS'
    },
    '25-239': {
        SportId: 25,
        Sport: 'Cycling',
        CategoryId: 239,
        Category: 'International',
        SubCategory: 'International',
        FlagCode: 'INTERNATIONAL+CLUBS'
    },
    '26-268': {
        SportId: 26,
        Sport: 'Pesapallo',
        CategoryId: 268,
        Category: 'Finland',
        SubCategory: 'Finland',
        FlagCode: 'FINLAND'
    },
    '30-302': {
        SportId: 30,
        Sport: 'Bowls',
        CategoryId: 302,
        Category: 'International',
        SubCategory: 'International',
        FlagCode: 'INTERNATIONAL+CLUBS'
    },
    '34-317': {
        SportId: 34,
        Sport: 'Box',
        CategoryId: 317,
        Category: '',
        SubCategory: 'International',
        FlagCode: 'INTERNATIONAL+CLUBS'
    },
    '43-473': {
        SportId: 43,
        Sport: 'Sailing',
        CategoryId: 473,
        Category: 'International',
        SubCategory: 'International',
        FlagCode: 'INTERNATIONAL+CLUBS'
    },
    '36-320': {
        SportId: 36,
        Sport: 'Netball',
        CategoryId: 320,
        Category: 'International',
        SubCategory: 'International',
        FlagCode: 'INTERNATIONAL+CLUBS'
    }
} as Record<string, IFlag>;

export function getFlag(sportId: number, categoryId: number): string | undefined {
    const flag = flagMap[`${sportId}-${categoryId}`];
    return flag ? flag.FlagCode : 'default-flag';
}

import { countBy as _countBy } from '@lodash';
import Combinations from '@utils/math/Combinations';
import ISelection from '@core/models/betSlip/ISelection';

interface SystemParams {
    fromNumber: number;
    minNumber: number;
    items: ISelection[];
}

/**
 * Contains information for a single system which user can select, like 1/6.
 *
 * Example: For 1/6
 * @property minNumber is 1
 * @property fromNumber is 6
 */
export default class System {
    public isSelected = false;
    // todo rename to selections
    private items: ISelection[];
    private fromNumber: number;
    private minNumber: number;
    private numberOfCombinations: number;

    constructor(params: SystemParams) {
        this.fromNumber = params.fromNumber;
        this.minNumber = params.minNumber;
        this.items = params.items;
        this.numberOfCombinations = this.calculateNumberOfCombinations();
    }

    public getFromNumber(): number {
        return this.fromNumber;
    }

    public getMinNumber(): number {
        return this.minNumber;
    }

    public getNumberOfCombinations() {
        return this.numberOfCombinations;
    }

    public toString() {
        return `${this.minNumber}/${this.fromNumber}`;
    }

    private calculateNumberOfCombinations(): number {
        return new Combinations(this.fromNumber, this.minNumber).getNumberOfCombinations();
    }
}

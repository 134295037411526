<template>
    <ContentPlaceholders
        :rounded="true"
    >
        <ContentPlaceholdersImg
            v-for="index in numberOfRectangles"
            :key="index"
        />
        <ContentPlaceholdersText :lines="numberOfLines" />
    </ContentPlaceholders>
</template>

<script>
    export default {
        name: 'ContentPlaceholder',
        props: {
            numberOfRectangles: {
                type: Number,
                required: false,
                default: 0
            },
            numberOfLines: {
                type: Number,
                required: false,
                default: 0
            },
        },
    };
</script>

<template>
    <transition name="animation-group-selector-" appear>
        <div class="group-selector">
            <div class="group-selector__container">
                <div
                    v-for="group in groups"
                    :key="group.type"
                    class="group-selector__item actionable"
                    @click="onClick(group)"
                    :class="{ active: selected === group.type, disabled: group.disabled }"
                >
                    <Icon
                        v-if="group.icon"
                        :class="group.icon"
                    />
                    {{ group.displayName | capitalize }}
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import Vue from 'vue';

    export default Vue.component('GroupSelector', {
        props: {
            groups: {
                type: Array,
                required: true,
            },
            selected: {
                type: [String, Number],
                required: false,
                default: null,
            },
        },
        methods: {
            onClick(item) {
                if (item.disabled) {
                    return;
                }
                const type = item.type;
                this.$emit('valueChanged', type);
            },
        },
    });
</script>

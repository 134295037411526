import Category from '@core/models/struct/Category';
import AbstractMapper from '@core/utils/mappers/AbstractMapper';
import common from '@src/config/common';
import { CategoryDto } from '@superbet-group/offer.clients.lib';


export class CategoryMapper extends AbstractMapper<CategoryDto, Category> {
    createTargetObject(dto: CategoryDto) {
        const category: Category = new Category();

        (category.id = parseInt(dto.id, 10)),
        (category.name = dto.localNames[common.offerLang]);
        category.order = dto.order;

        return category;
    }
}

import Ticket, { EventCondensed } from '@models/tickets/Ticket';
import { format } from 'date-fns';
import ServerConfig from '@core/models/country/ServerConfig';
import i18n from '@src/app/localization/i18n';
import capitalize from '@shared/filters/capitalize';
import commonConfig from '@src/config/common';
import liveBettingSpecifiersFormatter from '@core/services/offer/_private/LiveBettingSpecifiersFormatter';
import { range as _range } from '@lodash';
import SuperbetsService from '@core/services/superbets/SuperbetsService';
import { MarketType } from '@core/models/tickets/enums';
import Match from '@core/models/offer/Match';
import MathUtils from '@core/utils/math/MathUtils';

export const localeNumberFormat = (number: number) =>
  number.toLocaleString(commonConfig.numberLocale, { minimumFractionDigits: 2, maximumFractionDigits: 2 });

const totalCombinationsCount = (selected: number[], count: number): number => {
  const combinations = selected.map((system: number) => MathUtils.calculateCombinations(count, system));
  return combinations.reduce((a, b) => a + b, 0);
};

const systemsAsString = (selected: number[], count: number, fixed: number): string => {
  const selectedString = selected.join(',');
  const fixedString = fixed > 0 ? `${fixed}F` : '';
  const systemStr = `${i18n.t('system')}`;
  return `${capitalize(systemStr)} ${fixedString}[${selectedString} / ${count}]`;
};

const isSpecialEvent = (eventId: string) => eventId.startsWith('S');

const isFromSuperbetsMarket = (marketId: number, marketName: string) =>
  SuperbetsService.getInstance().isSuperbet(marketId, marketName);

const prepareOddName = (odd: any, event: Match) =>
  odd.specialValue
    ? `${odd.name}(${odd.specialValue})`
    : `${liveBettingSpecifiersFormatter.format(odd.name, odd.specifiers, event)}`;

const compareEventStartDates = (a: any, b: any) => a.date.getTime() - b.date.getTime();

const prepareEnumerationAlphabet = (): string[] => {
  const alphabetLowerCase: string = 'abcdefghijklmnopqrstuvwxyz';
  const alphabetFull: string[] = alphabetLowerCase.toUpperCase().concat(alphabetLowerCase).split('').reverse();
  return alphabetFull;
};

export const prepareTicketForPrintV2 = async (ticket: Ticket | any, config: ServerConfig) => {
  const dateFormat = 'DD.MM.YYYY';
  const timeFormat = 'HH:mm:ss';

  const eventTimeDateFormat = 'DD.MM. HH:mm';

  ticket.ticketId = ` ${ticket.id}`;

  ticket.events = ticket.events.map((event: any) => ({
    preparedDate: format(new Date(event.date), eventTimeDateFormat),
    ...event,
  }));

  const {
    system,
    dateReceived,
    coefficient,
    win: { bonus },
  } = ticket;

  const combCount = system ? totalCombinationsCount(system.selected, system.count) : 1;

  const labels = {
    ticketCopy: i18n.t('copy'),
    extraWinTax: i18n.t('extraWinTax'),
    ticketId: i18n.t('ticketId'),
    ticketCode: i18n.t('ticketCode'),
    ticketType: i18n.t('ticketType'),
    eventCount: i18n.t('eventsCount'),
    stake: i18n.t('cleanStake'),
    total: i18n.t('totalStake'),
    handlingFee: i18n.t('cda'),
    totalCoefficient: i18n.t('totalCoefficient'),
    minimumWin: i18n.t('minPotentialWin'),
    maxPotentialWin: i18n.t('potentialWin'),
    bonus: i18n.t('bonus'),
    luckyLoser: i18n.t('luckyLoser'),
    numberOfCombinations: i18n.t('numberCombinations'),
    coefficient: i18n.t('totalCoefficient'),
  };

  const values = {
    preparedEstimatedWin: localeNumberFormat(ticket.win.estimated),
    preparedMinPotential: localeNumberFormat(ticket.win.minPotential),
    preparedHandlingFee: localeNumberFormat(ticket.payment.handlingFee),
    preparedTotal: localeNumberFormat(ticket.payment.total),
    preparedStake: localeNumberFormat(ticket.payment.stake),
    preparedCoefficient: localeNumberFormat(coefficient),
    preparedIsBonusAvailable: bonus > 0.0,
    preparedBonus: localeNumberFormat(bonus),
    preparedIsLuckyLoser: ticket.potentialBonuses.LUCKY_LOSER,
    preparedBonusPercent: ticket.win.bonusPercent,
    preparedExtraWinTax: localeNumberFormat(+ticket.win.tax) || localeNumberFormat(0.0),
    footerCenterLines: config.ticketPromotional.footer,
    preparedLocale: commonConfig.locale,
    preparedHandlingFeePercent: config.betSlip.offlineTax,
    preparedTicketType: !system ? 'NORMAL' : 'SYSTEM',
    preparedCombQuotaValue: !system ? 1 : combCount,
    preparedSystemsString: !system ? '' : systemsAsString(system.selected, system.count, system.fixed),
    preparedDateReceived: format(new Date(dateReceived), dateFormat),
    preparedTimeReceived: format(new Date(dateReceived), timeFormat),
    ...ticket,
  };

  const enumPool = prepareEnumerationAlphabet();

  values.events.sort(compareEventStartDates);

  values.events = ticket.events.map((event: EventCondensed) => {
    const { eventId } = event;
    const isSpecial = isSpecialEvent(eventId);
    const isOutright = event.IsSpecialFromSportOffer;
    const preparedEnumeratedFix: string = event.eventNo?.includes('FIX') ? `${i18n.t('eventFix')}` : `${enumPool.pop()}` || '';

    const preparedIsSuperbet = isFromSuperbetsMarket(+event.market.marketId, event.market.name);
    const preparedOddName = preparedIsSuperbet
      ? `${i18n.t('yes')}`.toUpperCase()
      : prepareOddName(event.odd, { team1Name: event.teamNameOne || '', team2Name: event.teamNameTwo || '' } as Match);

    const prepareName = () => {
      if (isOutright) return `${event.market.name}, ${event.name}`;
      if (isSpecial) return `${event.market.name} ${event.name}`;
      return event.name;
    };

    const preparedIsLive = event.marketType === MarketType.IN_PLAY;

    return {
      preparedEnumeratedFix,
      preparedOddName,
      preparedIsLive,
      preparedIsSuperbet,
      preparedIsSpecial: isSpecial || isOutright,
      preparedName: prepareName(),
      preparedOddCoefficient: localeNumberFormat(event.odd.coefficient),
      preparedHasSBV: !!event.odd.specialValue,
      ...event,
    };
  });

  return { values, labels };
};

import {
    subDays,
    setHours,
    setMinutes,
    setSeconds,
    addDays,
    addMinutes,
    addHours,
} from 'date-fns';
import { curryRight } from '@lodash';

export const fSubDays = curryRight(subDays);
export const fSetHours = curryRight(setHours);
export const fSetMinutes = curryRight(setMinutes);
export const fSetSeconds = curryRight(setSeconds);
export const fAddDays = curryRight(addDays);
export const fAddMinutes = curryRight(addMinutes);
export const fAddHours = curryRight(addHours);

import config from '@config';
import { round as _round } from '@lodash';
import * as PaymentsLib from '@superbet-group/betting.lib.payments';

export default class CalculationHelper {
    public static round(value: number, numberOfDecimalPlaces: number = config.app.betSlip.decimalPlaces) {
        return _round(value, numberOfDecimalPlaces);
    }
}

export function calculateStakePerCombination(
    stake: number,
    numberOfCombinations: number) {
    if (!numberOfCombinations) {
        return stake;
    }

    return PaymentsLib.calculateStakePerCombination(stake, numberOfCombinations);
}

<template>
    <div class="placeholder flex-row">
        <h2>{{ $t('Application initializing')}}</h2>
        <ContentPlaceholder
            class="col-2"
            :numberOfRectangles="1"
            :numberOfLines="7"
        />
        <ContentPlaceholder
            class="col-7"
            :numberOfRectangles="9"
            :numberOfLines="0"
        />
        <ContentPlaceholder
            class="col-3"
            :numberOfRectangles="3"
            :numberOfLines="0"
        />
    </div>
</template>

<script>
    import ContentPlaceholder from '@modules/shared/components/ContentPlaceholder.vue';

    export default {
        name: 'PlaceholderContainer',
        components: {
            ContentPlaceholder,
        }
    };
</script>

<style lang="scss" scoped>
    .placeholder {
        width: 100vw;
        height: 100vh;
        display: flex;

        justify-content: center;
        align-items: center;

        h2 {
            color: white;
        }
    }
</style>
